import * as accountCreationState from './constants'

/**
 * Initial state function
 */
function getInitialState() {
	return {
		inProgress: false,
		isError: false,
		isSuccess: false,
	}
}

const accountCreationReducer = (state = getInitialState(), action) => {
	switch (action.type) {
		case accountCreationState.ACCOUNT_CREATION_REQUEST:
			return {
				...state,
				inProgress: true,
				isError: false,
			}
		case accountCreationState.ACCOUNT_CREATION_SUCCESS:
			return {
				...state,
				inProgress: false,
				isError: false,
				isSuccess: true,
			}
		case accountCreationState.ACCOUNT_CREATION_RESET:
			return {
				...state,
				inProgress: false,
				isError: false,
				isSuccess: false,
			}
		case accountCreationState.ACCOUNT_CREATION_ERROR:
			return {
				...state,
				inProgress: false,
				isError: true,
			}
		default: {
			return state
		}
	}
}

export default accountCreationReducer
