import {
	CENTS_PER_DOLLAR,
	PRICE_DECIMAL_POINTS,
} from '@ecobee/smartbuildings-library-common/constants'
import routingService from '@/services/routing.service'
import { BASE_ROUTE, ENDASH, RADIX } from './constants'

/**
 * Shallow object equality checking
 *
 * @param  {object} obj1 The first object to compare
 * @param  {object} obj2 The second object to compare
 * @returns {boolean}     True if the objects are equal otherwis false
 */
export const shallowEqual = (obj1, obj2) => {
	if (!obj1 || !obj2) {
		return false
	}
	const obj1Props = Object.getOwnPropertyNames(obj1)
	const obj2Props = Object.getOwnPropertyNames(obj2)

	// Early cheap equality check
	if (obj1Props.length !== obj2Props.length) {
		return false
	}

	return obj1Props.every((propName) => obj1[propName] === obj2[propName])
}

export const parseInteger = (string) => Number.parseInt(string, RADIX)

let _browserCallsOnChangeForIndeterminateCheckbox = null
/**
 * Check whether clicking an indeterminate checkbox calls its onChange handler.
 * (IE 11 does not call onChange, but other browsers do.)
 *
 * @returns {boolean} True if the browser triggers onChange when clicking an
 * indeterminate checkbox, false if the browser does not
 */
/* istanbul ignore next: this can't be tested effectively without IE 11 */
export const browserCallsOnChangeForIndeterminateCheckbox = () => {
	if (_browserCallsOnChangeForIndeterminateCheckbox !== null) {
		return _browserCallsOnChangeForIndeterminateCheckbox
	}

	_browserCallsOnChangeForIndeterminateCheckbox = false
	const testCheckbox = document.createElement('input')
	testCheckbox.type = 'checkbox'
	testCheckbox.indeterminate = true
	testCheckbox.addEventListener('change', () => {
		_browserCallsOnChangeForIndeterminateCheckbox = true
	})

	document.body.append(testCheckbox)
	testCheckbox.click()
	testCheckbox.remove()

	return _browserCallsOnChangeForIndeterminateCheckbox
}

/**
 * Initial sort of list by a primary or secondary sort string
 *
 * @param  {Array} listArray Array of objects to sort
 * @param  {string} primarySort Name of key to sort
 * @param  {string} secondarySort If no primarySort key, secondary key to sort by
 * @returns {Array}                Array of sorted thermostat objects
 */
export const sortList = (listArray, primarySort = 'name', secondarySort) => {
	const sortedList = [...listArray]
	return sortedList.sort((a, b) => {
		const nameA = a[`${primarySort}`]
			? a[`${primarySort}`].toUpperCase()
			: a[`${secondarySort}`].toString()
		const nameB = b[`${primarySort}`]
			? b[`${primarySort}`].toUpperCase()
			: b[`${secondarySort}`].toString()

		if (nameA < nameB) {
			return -1
		}
		if (nameA > nameB) {
			return 1
		}
		return 0
	})
}

/**
 * Returns a reducer function that sorts elements to the beginning of an array based on the supplied test function.
 *
 * @param {Function} testFn Function to call on each element: if the test function returns a truthy value, sort the element
 * to the beginning of the array; otherwise, do not change its position
 * @returns {Function}    A function that can be passed as a callback to Array.prototype.reduce to apply the sorting
 */
export const sortToBeginning = (testFn) => {
	const reducer = (acc, item) => {
		if (testFn(item)) {
			return [item, ...acc]
		}
		return [...acc, item]
	}
	return reducer
}

/**
 * String transformation function to return an EmDash character if string is empty
 *
 * @param  {string} string A string
 * @returns {string}        The original string or an EmDash character
 */
export const isStringEmpty = (string) => (string === '' ? ENDASH : string)

/**
 * Executes a function based on desired keyboard event
 *
 * @param  {object}    event Event object
 * @param  {string}    key   Human readable name for key
 * @param  {Function}  fn    Function to execute
 * @param  {...Mixed} args   Arguments to pass to the function
 * @returns {Mixed}           Result of the passed function
 */
export const keyboardEventExecute = (event, key, fn, ...args) => {
	if (event.key === key) {
		return fn(...args)
	}
	return false
}

/**
 * Get the prefix for the app if it's not running at the host root
 *
 * @param  {object} history React router history object
 * @returns {string}         Application path prefix
 */
export const getPathPrefix = (history) => {
	const fullPath = window.location.pathname
	const appPath = history.location.pathname

	if (fullPath === appPath) {
		return ''
	}

	const [prefix, suffix] = fullPath.split(appPath)
	if (suffix !== '') {
		return fullPath.endsWith(BASE_ROUTE) ? fullPath.slice(0, -1) : fullPath
	}

	return prefix
}

export const capitalize = (string) =>
	string.charAt(0).toUpperCase() + string.slice(1)

/**
 * Returns a path based on the presence of companyId in the current path parameters
 *
 * @param  {string} path The path to append
 * @returns {string}      The final path
 */
export const getCompanyPath = (path) => {
	const hasLeadingSlash = path.charAt(0) === '/'
	const match = routingService.getMatch()
	if (match && match.params && match.params.companyId) {
		return `/${match.params.companyId}${hasLeadingSlash ? '' : '/'}${path}`
	}
	return BASE_ROUTE
}

/**
 * Compare two strings case-insensitively
 *
 * @param {string} a First string to compare
 * @param {string} b Second string to compare
 * @returns {number}  Negative integer if a sorts before b, positive integer if b sorts before a, or 0 if the strings sort equally
 */
export const compareIgnoreCase = (a, b) =>
	a.localeCompare(b, 'en', { numeric: true, sensitivity: 'base' })

/**
 * Round a price to a string, adding a dollar sign and omitting cents if it is a whole number
 *
 * @param  {number} price Price in dollars to round
 * @returns {number} Formatted price
 */
export const formatPrice = (price) => {
	const priceInCents = Math.round(price * CENTS_PER_DOLLAR)
	const isExactDollarAmount = priceInCents % CENTS_PER_DOLLAR === 0
	const decimalPoints = isExactDollarAmount ? 0 : PRICE_DECIMAL_POINTS

	return `$${price.toFixed(decimalPoints)}`
}
