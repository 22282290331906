import analyticsService from '@/services/analytics.service'
import { useEffect } from 'react'

const useModalTracking = (modalId, isOpen) => {
	useEffect(() => {
		if (modalId && isOpen) {
			analyticsService.trackEvent('ModalOpen', { modalId })
		}
	}, [modalId, isOpen])

	const trackModalClose = () => {
		if (modalId) {
			analyticsService.trackEvent('ModalClose', { modalId })
		}
	}

	return { trackModalClose }
}

export default useModalTracking
