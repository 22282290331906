export const UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST'
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS'
export const UPDATE_COMPANY_ERROR = 'UPDATE_COMPANY_ERROR'

export const INITIALIZE_MIGRATED_COMPANY_REQUEST =
	'INITIALIZE_MIGRATED_COMPANY_REQUEST'
export const INITIALIZE_MIGRATED_COMPANY_ERROR =
	'INITIALIZE_MIGRATED_COMPANY_ERROR'
export const INITIALIZE_MIGRATED_COMPANY_SUCCESS =
	'INITIALIZE_MIGRATED_COMPANY_SUCCESS'
