import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Loading from '@/components/loading'
import SystemError from '@/components/system-error'

import styles from './placeholder.layout.module.scss'

class PlaceholderLayout extends PureComponent {
	render() {
		const { isSimple, isError, onRetry } = this.props

		const placeholderLayoutClasses = classNames(
			styles['placeholder-layout__app'],
			'edl-row--column',
		)

		const headerClasses = classNames(
			styles['placeholder-layout__header'],
			'edl-row',
		)

		const heroClasses = classNames(
			styles['placeholder-layout__hero'],
			'edl-row',
		)

		const contentClasses = classNames(
			styles['placeholder-layout__content'],
			'edl-row--column',
			'edl-col--grow',
		)

		return (
			<div className={placeholderLayoutClasses}>
				{!isSimple && (
					<>
						<div className={headerClasses} />
						<div className={heroClasses} />
					</>
				)}
				<div className={contentClasses}>
					{isError ? (
						<SystemError message="Something went wrong" onClick={onRetry} />
					) : (
						<Loading />
					)}
				</div>
			</div>
		)
	}
}

PlaceholderLayout.defaultProps = {
	isSimple: false,
}

PlaceholderLayout.propTypes = {
	isSimple: PropTypes.bool,
	isError: PropTypes.bool,
	onRetry: PropTypes.func,
}

export default PlaceholderLayout
