const {
	MODEL_ID_HECATE,
	MODEL_ID_INTERNAL_SENSOR,
	MODEL_ID_ROOM_SENSOR,
	MODEL_ID_SMART_SENSOR,
	SENSOR_TYPE_WIRED,
	SENSOR_TYPE_WIRELESS,
	THERMOSTAT_TYPE_COMMERCIAL,
	THERMOSTAT_TYPE_LEGACY_RESIDENTIAL,
	THERMOSTAT_TYPE_RESIDENTIAL,
} = require('./constants')

const ALERT_NUMBERS = {
	LOW_TEMPERATURE: 1000,
	HIGH_TEMPERATURE: 1001,
	SENSOR_ACTIVATED_SHUTTING_DOWN_COMPRESSOR: 1002,
	HEATING_ISSUE_1: 1003,
	HEATING_ISSUE_2: 1004,
	HEATING_ISSUE_3: 1005,
	HEATING_ISSUE_4: 1006,
	NOT_CURRENTLY_HEATING_OR_COOLING_1: 1007,
	AUX_HEAT_ON_FOR_TOO_LONG: 1009,
	TOO_WARM_FOR_AUX_HEAT: 1010,
	SENSOR_ACTIVATED_SWITCHING_TO_OCCUPIED: 1011,
	SENSOR_ACTIVATED_SWITCHING_TO_UNOCCUPIED: 1012,
	SENSOR_ACTIVATED_DISABLING_AC: 1013,
	SENSOR_ACTIVATED_SETTING_TEMP_UP_DOWN: 1014,
	SENSOR_ACTIVATED: 1015,
	SENSOR_ACTIVATED_OPENING_CLOSING_RELAY: 1016,
	SENSOR_ACTIVATED_TURNING_ON_FAN: 1017,
	SENSOR_ACTIVATED_SHUTTING_DOWN_AUX_HEAT: 1018,
	SENSOR_ACTIVATED_SHUTTING_DOWN_HEATING_COOLING: 1019,
	LOW_HUMIDITY: 1020,
	HIGH_HUMIDITY: 1021,
	SENSOR_ACTIVATED_SHUTTING_DOWN_HEAT: 1022,
	SENSOR_ACTIVATED_HUMIDIFIER: 1024,
	SENSOR_ACTIVATED_DEHUMIDIFIER: 1025,
	SENSOR_BATTERY_LOW: 1026,
	REMOTE_SENSOR_DETECTED: 1027,
	SENSOR_COMMUNICATION_ISSUE: 1028,
	SENSOR_COMMUNICATION_RESTORED: 1029,
	NOT_CURRENTLY_HEATING_OR_COOLING_2: 1030,
	HEATING_AND_COOLING_RESTORED: 1031,
	FAULTY_HUMIDITY_SENSOR_1: 1032,
	FAULTY_HUMIDITY_SENSOR_2: 1033,
	INCORRECT_ZIGBEE_MODULE_INSTALLED: 1034,
	PERFORMANCE_ISSUE: 1035,
	FURNACE_MAINTENANCE_REMINDER: 3130,
	HUMIDIFIER_MAINTENANCE_REMINDER: 3131,
	VENTILATOR_MAINTENANCE_REMINDER: 3132,
	DEHUMIDIFIER_MAINTENANCE_REMINDER: 3133,
	ECONOMIZER_MAINTENANCE_REMINDER: 3134,
	UV_MAINTENANCE_REMINDER: 3135,
	AC_MAINTENANCE_REMINDER: 3136,
	AIR_FILTER_REMINDER_CLIMATEMASTER_ONLY: 3137,
	AIR_CLEANER_REMINDER_CLIMATEMASTER_ONLY: 3138,
	HVAC_MAINTENANCE_REMINDER: 3140,
	DR_VOLUNTARY_ALERT: 6000,
	DR_VOLUNTARY_UTILITY_MESSAGE: 6001,
	DR_VOLUNTARY_ALERTS_1: 6002,
	DR_VOLUNTARY_ALERTS_2: 6003,
	DR_VOLUNTARY_ALERTS_3: 6004,
	DR_VOLUNTARY_ALERTS_4: 6005,
	DR_MANDATORY_ALERT_1: 6100,
	DR_MANDATORY_MESSAGE: 6101,
	DR_MANDATORY_ALERT_2: 6102,
	MONTHLY_COST_EXCEEDED: 6200,
	MONTHLY_PROJECTED_COST_EXCEEDED: 6201,
	NETWORK_JOIN_SUCCESSFUL: 6300,
	NETWORK_JOIN_FAILED: 6301,
	REGISTRATION_CONFIRMATION: 7000,
	REGISTRATION_REMIND_ME_ALERT: 7001,
	WEB_INITIATED_MESSAGES: 7002,
}

const ALERTS = {
	[ALERT_NUMBERS.LOW_TEMPERATURE]: 'Low temperature',
	[ALERT_NUMBERS.HIGH_TEMPERATURE]: 'High temperature',
	[ALERT_NUMBERS.SENSOR_ACTIVATED_SHUTTING_DOWN_COMPRESSOR]:
		'Sensor activated shutting down compressor',
	[ALERT_NUMBERS.HEATING_ISSUE_1]: 'Heating issue',
	[ALERT_NUMBERS.HEATING_ISSUE_2]: 'Heating issue',
	[ALERT_NUMBERS.HEATING_ISSUE_3]: 'Heating issue',
	[ALERT_NUMBERS.HEATING_ISSUE_4]: 'Cooling issue',
	[ALERT_NUMBERS.NOT_CURRENTLY_HEATING_OR_COOLING_1]:
		'Not currently heating or cooling',
	[ALERT_NUMBERS.AUX_HEAT_ON_FOR_TOO_LONG]: 'Aux heat on for too long',
	[ALERT_NUMBERS.TOO_WARM_FOR_AUX_HEAT]: 'Too warm for aux heat',
	[ALERT_NUMBERS.SENSOR_ACTIVATED_SWITCHING_TO_OCCUPIED]:
		'Sensor activated switching to occupied',
	[ALERT_NUMBERS.SENSOR_ACTIVATED_SWITCHING_TO_UNOCCUPIED]:
		'Sensor activated switching to unoccupied',
	[ALERT_NUMBERS.SENSOR_ACTIVATED_DISABLING_AC]:
		'Sensor activated disabling AC',
	[ALERT_NUMBERS.SENSOR_ACTIVATED_SETTING_TEMP_UP_DOWN]:
		'Sensor activated setting temp up/down',
	[ALERT_NUMBERS.SENSOR_ACTIVATED]: 'Sensor activated',
	[ALERT_NUMBERS.SENSOR_ACTIVATED_OPENING_CLOSING_RELAY]:
		'Sensor activated opening/closing relay',
	[ALERT_NUMBERS.SENSOR_ACTIVATED_TURNING_ON_FAN]:
		'Sensor activated turning on fan',
	[ALERT_NUMBERS.SENSOR_ACTIVATED_SHUTTING_DOWN_AUX_HEAT]:
		'Sensor activated shutting down aux heat',
	[ALERT_NUMBERS.SENSOR_ACTIVATED_SHUTTING_DOWN_HEATING_COOLING]:
		'Sensor activated shutting down heating/cooling',
	[ALERT_NUMBERS.LOW_HUMIDITY]: 'Low humidity',
	[ALERT_NUMBERS.HIGH_HUMIDITY]: 'High humidity',
	[ALERT_NUMBERS.SENSOR_ACTIVATED_SHUTTING_DOWN_HEAT]:
		'Sensor activated shutting down heat',
	[ALERT_NUMBERS.SENSOR_ACTIVATED_HUMIDIFIER]: 'Sensor activated humidifier',
	[ALERT_NUMBERS.SENSOR_ACTIVATED_DEHUMIDIFIER]:
		'Sensor activated dehumidifier',
	[ALERT_NUMBERS.SENSOR_BATTERY_LOW]: 'Sensor battery low',
	[ALERT_NUMBERS.REMOTE_SENSOR_DETECTED]: 'Remote sensor detected',
	[ALERT_NUMBERS.SENSOR_COMMUNICATION_ISSUE]: 'Sensor communication issue',
	[ALERT_NUMBERS.SENSOR_COMMUNICATION_RESTORED]:
		'Sensor communication restored',
	[ALERT_NUMBERS.NOT_CURRENTLY_HEATING_OR_COOLING_2]:
		'Not currently heating or cooling',
	[ALERT_NUMBERS.HEATING_AND_COOLING_RESTORED]: 'Heating and cooling restored',
	[ALERT_NUMBERS.FAULTY_HUMIDITY_SENSOR_1]: 'Faulty humidity sensor',
	[ALERT_NUMBERS.FAULTY_HUMIDITY_SENSOR_2]: 'Faulty humidity sensor',
	[ALERT_NUMBERS.INCORRECT_ZIGBEE_MODULE_INSTALLED]:
		'Incorrect Zigbee module installed',
	[ALERT_NUMBERS.PERFORMANCE_ISSUE]: 'Performance issue',
	[ALERT_NUMBERS.FURNACE_MAINTENANCE_REMINDER]: 'Furnace maintenance reminder',
	[ALERT_NUMBERS.HUMIDIFIER_MAINTENANCE_REMINDER]:
		'Humidifier maintenance reminder',
	[ALERT_NUMBERS.VENTILATOR_MAINTENANCE_REMINDER]:
		'Ventilator maintenance reminder',
	[ALERT_NUMBERS.DEHUMIDIFIER_MAINTENANCE_REMINDER]:
		'Dehumidifier maintenance reminder',
	[ALERT_NUMBERS.ECONOMIZER_MAINTENANCE_REMINDER]:
		'Economizer maintenance reminder',
	[ALERT_NUMBERS.UV_MAINTENANCE_REMINDER]: 'UV maintenance reminder',
	[ALERT_NUMBERS.AC_MAINTENANCE_REMINDER]: 'AC maintenance reminder',
	[ALERT_NUMBERS.AIR_FILTER_REMINDER_CLIMATEMASTER_ONLY]:
		'Air filter reminder (ClimateMaster only)',
	[ALERT_NUMBERS.AIR_CLEANER_REMINDER_CLIMATEMASTER_ONLY]:
		'Air cleaner reminder (ClimateMaster only)',
	[ALERT_NUMBERS.HVAC_MAINTENANCE_REMINDER]: 'Hvac maintenance reminder',
	[ALERT_NUMBERS.DR_VOLUNTARY_ALERT]: 'DR voluntary alert',
	[ALERT_NUMBERS.DR_VOLUNTARY_UTILITY_MESSAGE]: 'DR voluntary utility message',
	[ALERT_NUMBERS.DR_VOLUNTARY_ALERTS_1]: 'DR voluntary alerts',
	[ALERT_NUMBERS.DR_VOLUNTARY_ALERTS_2]: 'DR voluntary alerts',
	[ALERT_NUMBERS.DR_VOLUNTARY_ALERTS_3]: 'DR voluntary alerts',
	[ALERT_NUMBERS.DR_VOLUNTARY_ALERTS_4]: 'DR voluntary alerts',
	[ALERT_NUMBERS.DR_MANDATORY_ALERT_1]: 'DR mandatory alert',
	[ALERT_NUMBERS.DR_MANDATORY_MESSAGE]: 'DR mandatory message',
	[ALERT_NUMBERS.DR_MANDATORY_ALERT_2]: 'DR mandatory alert',
	[ALERT_NUMBERS.MONTHLY_COST_EXCEEDED]: 'Monthly cost exceeded',
	[ALERT_NUMBERS.MONTHLY_PROJECTED_COST_EXCEEDED]:
		'Monthly projected cost exceeded',
	[ALERT_NUMBERS.NETWORK_JOIN_SUCCESSFUL]: 'Network join successful',
	[ALERT_NUMBERS.NETWORK_JOIN_FAILED]: 'Network join failed',
	[ALERT_NUMBERS.REGISTRATION_CONFIRMATION]: 'Registration confirmation',
	[ALERT_NUMBERS.REGISTRATION_REMIND_ME_ALERT]: 'Registration Remind me alert',
	[ALERT_NUMBERS.WEB_INITIATED_MESSAGES]: 'Web initiated messages',
}

const THERMOSTAT_MODEL_MAP = {
	apolloSmart: {
		name: 'ecobee4',
		type: THERMOSTAT_TYPE_RESIDENTIAL,
		remoteSensorType: SENSOR_TYPE_WIRELESS,
	},
	aresSmart: {
		name: 'Smart Thermostat Premium',
		type: THERMOSTAT_TYPE_RESIDENTIAL,
		remoteSensorType: SENSOR_TYPE_WIRELESS,
	},
	artemisSmart: {
		name: 'Smart Thermostat Enhanced',
		type: THERMOSTAT_TYPE_RESIDENTIAL,
		remoteSensorType: SENSOR_TYPE_WIRELESS,
	},
	athenaSmart: {
		name: 'ecobee3',
		type: THERMOSTAT_TYPE_RESIDENTIAL,
		remoteSensorType: SENSOR_TYPE_WIRELESS,
	},
	attisRetail: {
		name: 'ecobee Smart Thermostat Essential',
		type: THERMOSTAT_TYPE_RESIDENTIAL,
		remoteSensorType: SENSOR_TYPE_WIRELESS,
	},
	attisPro: {
		name: 'ecobee Smart Thermostat Lite',
		type: THERMOSTAT_TYPE_RESIDENTIAL,
		remoteSensorType: SENSOR_TYPE_WIRELESS,
	},
	corSmart: {
		name: 'Carrier Cor',
		type: THERMOSTAT_TYPE_LEGACY_RESIDENTIAL,
		remoteSensorType: SENSOR_TYPE_WIRED,
	},
	idtEms: {
		name: 'EMS',
		type: THERMOSTAT_TYPE_COMMERCIAL,
		remoteSensorType: SENSOR_TYPE_WIRED,
	},
	idtSmart: {
		name: 'Smart',
		type: THERMOSTAT_TYPE_LEGACY_RESIDENTIAL,
		remoteSensorType: SENSOR_TYPE_WIRED,
	},
	nikeSmart: {
		name: 'ecobee3 lite',
		type: THERMOSTAT_TYPE_RESIDENTIAL,
		remoteSensorType: SENSOR_TYPE_WIRELESS,
	},
	siEms: {
		name: 'EMS Si',
		type: THERMOSTAT_TYPE_COMMERCIAL,
		remoteSensorType: SENSOR_TYPE_WIRED,
	},
	siSmart: {
		name: 'Smart Si',
		type: THERMOSTAT_TYPE_LEGACY_RESIDENTIAL,
		remoteSensorType: SENSOR_TYPE_WIRED,
	},
	vulcanSmart: {
		name: 'SmartThermostat with voice control',
		type: THERMOSTAT_TYPE_RESIDENTIAL,
		remoteSensorType: SENSOR_TYPE_WIRELESS,
	},
}

const SENSOR_MODEL_MAP = {
	rs2: {
		name: 'ecobee SmartSensor',
		id: MODEL_ID_SMART_SENSOR,
	},
	rs: {
		name: 'ecobee Room Sensor',
		id: MODEL_ID_ROOM_SENSOR,
	},
	ei: {
		name: 'Built-in thermostat sensor',
		id: MODEL_ID_INTERNAL_SENSOR,
	},
	dw: {
		name: 'ecobee SmartSensor for door and windows',
		id: MODEL_ID_HECATE,
	},
}

const PROGRAM_DATA_MAP = {
	CA: {
		currency: 'CAD',
		planId: 'sb1yrcad',
		price: 30,
	},
	'pge-epst': {
		currency: 'USD',
		planId: 'sb3yrusd-pge',
		price: 60,
		programName: 'PGE Energy Partner Smart Thermostat',
		salesChannel: 'Energy',
	},
	US: {
		currency: 'USD',
		planId: 'sb1yrusd',
		price: 24,
	},
}

module.exports = {
	ALERTS,
	ALERT_NUMBERS,
	THERMOSTAT_MODEL_MAP,
	PROGRAM_DATA_MAP,
	SENSOR_MODEL_MAP,
}
