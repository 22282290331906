import status from 'http-status-codes'
import { refreshTokens } from '@/services/fetch-api/fetch.api'
import { getHeader } from '@/services/fetch-api/api.config'

/**
 * Gets standard headers including companyId, platform, and auth
 *
 * @returns {object} Headers used for a query
 */
export const getHeaders = () => {
	const {
		Accept: _accept,
		'Content-Type': _contentType,
		...authHeaders
	} = getHeader(true)
	return {
		headers: {
			...authHeaders,
		},
	}
}

/**
 * Fetch a refresh token if the error is an unauthorized error and return boolean indicating success status
 *
 * @param {object} error Consists of statusCode, error message, and response object
 * @returns {boolean} true if error is 401 and refresh tokens call is successful
 */
export const handleUnauthorized = async (error) => {
	if (error.statusCode === status.UNAUTHORIZED) {
		try {
			await refreshTokens()
			return true
		} catch {
			return false
		}
	}
	return false
}
