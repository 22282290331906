import {
	SENSOR_TYPE_THERMOSTAT,
	SENSOR_TYPE_UNKNOWN,
	SENSOR_TYPE_WIRED,
	SENSOR_TYPE_WIRELESS,
	THERMOSTAT_TYPE_RESIDENTIAL,
} from '@ecobee/smartbuildings-library-common/constants'
import {
	SENSOR_MODEL_MAP,
	THERMOSTAT_MODEL_MAP,
} from '@ecobee/smartbuildings-library-common/maps'
import { RADIX, SECONDS_PER_MINUTE } from './constants'
import { THERMOSTAT_PROGRAM_MAP } from './maps'

/**
 * Get the type of thermostat based on a thermostat object
 *
 * @function
 * @param   {object} thermostat Thermostat object returned from ecobee API
 * @returns {string}            The type of thermostat e.g. 'residential', 'commercial', defaults to residential
 */
export const getThermostatType = (thermostat = {}) => {
	const thermostatModel = THERMOSTAT_MODEL_MAP[thermostat.modelNumber]

	return thermostatModel?.type ?? THERMOSTAT_TYPE_RESIDENTIAL
}

/**
 * Get the default comfort setting ids for a thermostat using the thermostat type
 *
 * @function
 * @param   {string} thermostatType The type of thermostat e.g. 'residential', 'commercial'
 * @returns {Array}                 Array of default comfort setting ids
 */
export const getBuiltInComfortSettingsForThermostatType = (thermostatType) =>
	THERMOSTAT_PROGRAM_MAP[thermostatType] ||
	THERMOSTAT_PROGRAM_MAP[THERMOSTAT_TYPE_RESIDENTIAL]

/**
 * Get the default comfort setting ids for a thermostat
 *
 * @function
 * @param   {object} thermostat Thermostat object returned from ecobee API
 * @returns {object[]}          Array of comfort setting objects, each containing an ID and a name
 */
export const getBuiltInComfortSettings = (thermostat = {}) => {
	const thermostatType = getThermostatType(thermostat)
	return getBuiltInComfortSettingsForThermostatType(thermostatType)
}

/**
 * Get the 'empty' comfort setting based on thermostat type
 *
 * @function
 * @param   {string} thermostatType The type of thermostat e.g. 'residential', 'commercial'
 * @returns {object}                Comfort setting object containing an ID and a name
 */
export const getEmptyComfortSettingForThermostatType = (thermostatType) => {
	const [emptyOption] = getBuiltInComfortSettingsForThermostatType(
		thermostatType,
	)
	return emptyOption
}

/**
 * Get the 'default' comfort setting based on thermostat type
 *
 * @function
 * @param   {string} thermostatType The type of thermostat e.g. 'residential', 'commercial'
 * @returns {object}                Comfort setting object containing an ID and a name
 */
export const getDefaultComfortSettingForThermostatType = (thermostatType) => {
	const [, defaultOption] = getBuiltInComfortSettingsForThermostatType(
		thermostatType,
	)
	return defaultOption
}

/**
 * Get a list of comfort settings options by thermostat type
 *
 * @function
 * @param   {string}  type      Thermostat type
 * @param   {boolean} omitEmpty Flag to omit the empty comfort setting
 * @returns {Array}             Array of objects suitable for select lists
 */
export const getComfortSettingOptionsForThermostatType = (
	type = THERMOSTAT_TYPE_RESIDENTIAL,
	omitEmpty = false,
) => {
	const allOptions = getBuiltInComfortSettingsForThermostatType(type)
	const options = omitEmpty ? allOptions.slice(1) : allOptions

	return options.map((option) => ({
		value: option.climateRef,
		title: option.name,
	}))
}

/**
 * Get the sensor type (thermostat, wired, wireless, or unknown) based on the raw type reported by the API
 *
 * @function
 * @param   {string} rawType The sensor type reported by the API
 * @returns {string}         The sensor type, one of "thermostat", "wired", "wireless", or "unknown"
 */
export const getSensorType = (rawType) => {
	if (rawType === 'thermostat') {
		return SENSOR_TYPE_THERMOSTAT
	}

	if (rawType === 'monitor_sensor' || rawType === 'control_sensor') {
		return SENSOR_TYPE_WIRED
	}

	if (rawType && rawType.endsWith('remote_sensor')) {
		return SENSOR_TYPE_WIRELESS
	}

	return SENSOR_TYPE_UNKNOWN
}

/**
 * Get the sensor model (ecobee RemoteSensor, ecobee SmartSensor) based on ID of the sensor.
 *
 * @param {string} sensorId ID of the sensor
 * @returns {object} Sensor type object with name & description
 */
export const getSensorModel = (sensorId) => {
	const sensorType = sensorId.replace(/:.*$/, '')

	return SENSOR_MODEL_MAP[sensorType] || null
}

/**
 * Parse and convert a random start delay value from string minutes to seconds.
 *
 * @param {string} startDelay start delay in minutes
 * @returns {number} start delay in seconds
 */
export const getStartDelayInSeconds = (startDelay) =>
	Number.parseInt(startDelay, RADIX) * SECONDS_PER_MINUTE
