import config from 'config'
import { useEffect, useState } from 'react'
import errorLoggingService from '@/services/error.logging.service'
import useScript from './useScript'

const CHARGEBEE_INIT_RETRY_COUNT = 5
const CHARGEBEE_INIT_RETRY_DELAY = 100

/* istanbul ignore next - integration test */
class ChargebeeLoader {
	isReady = false

	isError = false

	#onReadyListeners = new Set()

	#onErrorListeners = new Set()

	#remainingRetries = CHARGEBEE_INIT_RETRY_COUNT

	init = () => {
		try {
			if (!window.Chargebee) {
				throw new Error('Chargebee client could not be instantiated')
			}

			window.Chargebee.init({
				site: config.CHARGEBEE_SITE,
				enableGATracking: false,
			})

			this.isReady = true
			this.isError = false

			this.#onReadyListeners.forEach((listener) => listener())
		} catch (error) {
			if (this.#remainingRetries > 0) {
				this.#remainingRetries -= 1
				setTimeout(this.init, CHARGEBEE_INIT_RETRY_DELAY)
			}

			errorLoggingService.notify(error)

			this.isReady = false
			this.isError = true

			this.#onErrorListeners.forEach((listener) => listener(error))
		}
	}

	addOnReadyListener = (listener) => {
		this.#onReadyListeners.add(listener)

		return () => {
			this.#onReadyListeners.delete(listener)
		}
	}

	addOnErrorListener = (listener) => {
		this.#onErrorListeners.add(listener)

		return () => {
			this.#onErrorListeners.delete(listener)
		}
	}
}

// Chargebee loader singleton: ensures that Chargebee only gets loaded once,
// even if multiple components use the useChargebee hook in parallel
const chargebeeLoader = new ChargebeeLoader()

/* istanbul ignore next - integration test */
const useChargebee = () => {
	const [isScriptLoaded, isScriptError] = useScript({
		src: config.CHARGEBEE_URL,
		onInitialLoad: chargebeeLoader.init,
	})
	const [isReady, setIsReady] = useState(chargebeeLoader.isReady)
	const [isError, setIsError] = useState(chargebeeLoader.isError)
	const [chargebeeInstance, setChargebeeInstance] = useState(null)

	useEffect(() => {
		if (isReady) {
			setChargebeeInstance(window.Chargebee.getInstance())
			return
		}

		if (isError) {
			return
		}

		const removeOnReadyListener = chargebeeLoader.addOnReadyListener(() => {
			setIsReady(true)
		})
		const removeOnErrorListener = chargebeeLoader.addOnErrorListener(() => {
			setIsError(true)
		})

		// eslint-disable-next-line consistent-return -- not relevant for useEffect cleanup function
		return () => {
			removeOnReadyListener()
			removeOnErrorListener()
		}
	}, [isReady, isError])

	const isLoading = (!isScriptLoaded || !isReady) && !isScriptError && !isError

	return {
		isLoading,
		isError: isScriptError || isError,
		chargebeeInstance,
	}
}

export default useChargebee
