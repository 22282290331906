import { takeLatest, put, call, all } from 'redux-saga/effects'
import { userRequest } from '@/store/user/sagas'
import AuthService from '@/services/auth.service'
import history from '@/services/history'
import fetchAPI, { safeFetch } from '@/services/fetch-api/fetch.api'
import featureflagsService from '@/services/feature-flags/feature.flags.service'
import { resetThermostatList } from '@/store/thermostat-list/actions'
import { resetThermostat } from '@/store/thermostat/actions'
import { resetRegisterThermostat } from '@/store/register-thermostat/actions'
import { resetUser } from '@/store/user/actions'
import { resetBuildingList } from '@/store/building-list/actions'
import routingService from '@/services/routing.service'
import { BASE_ROUTE } from '@/utils/constants'

import { loginSuccess, loginError } from './actions'
import { LOGIN_REQUEST, LOGOUT } from './constants'

/**
 * @param action
 */
export function* login(action) {
	const { isInviteFlow, token } = action.payload
	const errors = {
		invalid_grant:
			'That username and password combination is not valid. Please try again.',
		account_locked:
			"You've tried to log in too many times. Please try again in 15 minutes.",
		server_error: 'There was a problem communicating with the server.',
	}
	const genericError = 'Something went wrong. Please try again later.'
	const { data, response, error } = yield call(
		fetchAPI,
		'authorize',
		action.payload,
	)

	if (response.ok) {
		yield call(AuthService.authenticate, data.access_token, data.refresh_token)
		// call accept authenticated invite endpoint if the user is logging in through accept invite flow
		if (isInviteFlow !== null && isInviteFlow === true) {
			yield call(safeFetch, 'acceptAuthenticatedInvite', {
				token,
			})
		}

		yield call(userRequest)
		// Active route may change when authenticated state changes even if the URL path is unchanged
		yield call(routingService.updateRoute)
		yield put(
			loginSuccess(null, {
				event: 'LoginSuccess',
			}),
		)
	} else {
		yield call(AuthService.deauthenticate)
		yield put(
			loginError(
				{
					message: errors[error.returnedData.error] || genericError,
				},
				{
					event: 'LoginError',
				},
			),
		)
	}
}

/**
 *
 */
export function* logout() {
	yield all([
		call(AuthService.deauthenticate),
		call([featureflagsService, 'identify']),
	])

	yield put(resetThermostatList())
	yield put(resetThermostat())
	yield put(resetRegisterThermostat())
	yield put(resetBuildingList())
	yield put(resetUser(null, { event: 'Logout' }))

	yield call([history, 'replace'], { pathname: BASE_ROUTE })
}

/**
 *
 */
export function* loginRequestWatcher() {
	yield takeLatest(LOGIN_REQUEST, login)
}

/**
 *
 */
export function* logoutWatcher() {
	yield takeLatest(LOGOUT, logout)
}
