import produce from 'immer'
import * as registerThermostatActionTypes from './constants'
import * as errorConstants from './error.constants'

const initialThermostatState = {
	value: '',
	isRegistered: false,
	isError: false,
	errorDetails: {},
	code: null,
}

/**
 *
 */
function getInitialState() {
	return {
		pendingRequest: false,
		errorMessage: '',
		thermostats: [
			{ ...initialThermostatState },
			{ ...initialThermostatState },
			{ ...initialThermostatState },
			{ ...initialThermostatState },
			{ ...initialThermostatState },
		],
	}
}

const registerThermostatReducer = (state = getInitialState(), action) => {
	switch (action.type) {
		case registerThermostatActionTypes.REGISTER_THERMOSTAT_UPDATE: {
			const { index, value } = action.payload
			const newState = produce(state, (draftState) => {
				draftState.thermostats[index].value = value
				// remove any current error state when the value field is updated
				draftState.thermostats[index].isError = false
				draftState.thermostats[index].code = null
			})
			return newState
		}
		case registerThermostatActionTypes.REGISTER_THERMOSTAT_ADD:
			return {
				...state,
				thermostats: [
					...state.thermostats,
					{
						value: '',
						isError: false,
						isRegistered: false,
					},
				],
			}
		case registerThermostatActionTypes.REGISTER_THERMOSTAT_REQUEST:
			return {
				...state,
				pendingRequest: true,
			}
		case registerThermostatActionTypes.REGISTER_THERMOSTAT_SUCCESS:
			return getInitialState()
		case registerThermostatActionTypes.REGISTER_THERMOSTAT_ERROR: {
			const { errorMessage, markAllFields } = action.payload
			const newState = produce(state, (draftState) => {
				draftState.pendingRequest = false
				draftState.errorMessage = errorMessage
				// For subscription and seat exceeded limit case, mark all fields
				// with values with error state
				if (markAllFields) {
					draftState.thermostats.forEach((thermostat) => {
						if (thermostat.value) {
							thermostat.isError = true
						}
					})
				}
			})
			return newState
		}
		case registerThermostatActionTypes.RESET_REGISTER_THERMOSTAT:
			return getInitialState()
		case registerThermostatActionTypes.REGISTER_THERMOSTAT_UPDATE_ALL: {
			const payloadThermostats = action.payload

			const newState = produce(state, (draftState) => {
				const { thermostats: draftThermostats } = draftState
				payloadThermostats.forEach((payloadThermostat) => {
					const draftThermostat = draftThermostats.find(
						(value) => payloadThermostat.thermostatId === value.value,
					)
					if (draftThermostat) {
						draftThermostat.isRegistered = payloadThermostat.isRegistered
						draftThermostat.isError = payloadThermostat.isError
						draftThermostat.code = payloadThermostat.code
						draftThermostat.errorDetails = payloadThermostat.errorDetails
					}
				})
			})
			return newState
		}
		case registerThermostatActionTypes.REGISTER_THERMOSTAT_SET_DUPLICATES: {
			const duplicateValues = action.payload

			const newState = produce(state, (draftState) => {
				draftState.thermostats.forEach((thermostat) => {
					if (duplicateValues.includes(thermostat.value)) {
						thermostat.isError = true
						thermostat.code = errorConstants.DUPLICATE_ERROR_CODE
					}
				})
			})
			return newState
		}

		default:
			return state
	}
}

export default registerThermostatReducer
