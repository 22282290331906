import { takeLatest, call, put, select } from 'redux-saga/effects'
import * as companyUserActions from '@/store/company-users/actions'
import * as toastActions from '@/store/toast/actions'
import * as modalActions from '@/store/modal/actions'
import { safeFetch } from '@/services/fetch-api/fetch.api'
import { activeCompanyIdSelector } from '@/store/user/selectors'
import { validateApiResponse } from '@/utils/validators'
import * as revokeUserActions from './actions'
import { REVOKE_USER_REQUEST } from './constants'

/**
 * @param action
 */
export function* revokeUser(action) {
	const { payload } = action
	const { userName, userId } = payload
	const activeCompanyId = yield select(activeCompanyIdSelector)
	const results = yield call(safeFetch, 'revokeUserFromCompany', {
		userId,
		companyId: activeCompanyId,
	})
	if (validateApiResponse(results)) {
		yield put(companyUserActions.companyUsersRequest(activeCompanyId))
		yield put(
			toastActions.toastShow({
				message: `${userName} has been revoked.`,
			}),
		)
		yield put(
			revokeUserActions.revokeUserSuccess(null, {
				event: 'UserRevokeSuccess',
				eventDetails: { userId },
			}),
		)
	} else {
		const modalErrorMessage = `${userName} could not be revoked.`
		yield put(
			revokeUserActions.revokeUserError(null, {
				event: 'UserRevokeError',
				eventDetails: { userId },
			}),
		)
		yield put(
			modalActions.modalShow({
				title: 'Something went wrong',
				message: modalErrorMessage,
			}),
		)
	}
}

/**
 *
 */
export function* revokeUserRequestWatcher() {
	yield takeLatest(REVOKE_USER_REQUEST, revokeUser)
}
