import ls from 'local-storage'
import jwtDecode from 'jwt-decode'

const ACCESS_TOKEN_STORAGE_KEY = 'sb.accessToken'
const REFRESH_TOKEN_STORAGE_KEY = 'sb.refreshToken'

class AuthService {
	static authenticate(accessToken, refreshToken) {
		ls.set(ACCESS_TOKEN_STORAGE_KEY, accessToken)
		ls.set(REFRESH_TOKEN_STORAGE_KEY, refreshToken)
	}

	static deauthenticate() {
		ls.remove(ACCESS_TOKEN_STORAGE_KEY)
		ls.remove(REFRESH_TOKEN_STORAGE_KEY)
	}

	static getAccessToken() {
		return ls.get(ACCESS_TOKEN_STORAGE_KEY)
	}

	static getRefreshToken() {
		return ls.get(REFRESH_TOKEN_STORAGE_KEY)
	}

	static isAuthenticated() {
		return this.getAccessToken() != null
	}

	static getUser() {
		try {
			const accessToken = this.getAccessToken()
			if (!accessToken) return null

			const { userId, userName } = jwtDecode(accessToken)
			return { id: userId, email: userName }
		} catch {
			return null
		}
	}
}

export default AuthService
