import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { permissions } from '@ecobee/smartbuildings-library-common/permissions'
import Loading from '@/components/loading'
import Button from '@/components/button'
import { BASE_ROUTE, LOGOUT_ROUTE } from '@/utils/constants'
import IfAuthorized from '@/utils/auth'
import useChargebeePortal from '@/hooks/useChargebeePortal'

import styles from './paywall.modal.module.scss'

const PaywallModal = ({
	hasMultipleClients,
	isFetchingUser,
	onChargebeeError,
	refetchUser,
	title,
}) => {
	const {
		isLoading: isChargebeeLoading,
		isError: isChargebeeError,
		openChargebeePortal,
	} = useChargebeePortal()

	return (
		<>
			<h2 className={styles['cmp__paywall-modal__title']}>{title}</h2>
			<IfAuthorized
				permissions={permissions.SUBSCRIPTION.MANAGE}
				render={() => (
					<>
						<p className={styles['cmp__paywall-modal__message']}>
							Reactivate it by purchasing a subscription.
						</p>
						<div className={styles['cmp__paywall-modal__button']}>
							<Button
								data-qa-id="cmp-paywall-modal-reactivate-button"
								isDisabled={isFetchingUser || isChargebeeLoading}
								onClick={async () => {
									if (isChargebeeError) {
										onChargebeeError()
										return
									}

									await openChargebeePortal() // promise resolves when modal is closed
									refetchUser()
								}}
							>
								Reactivate Subscription
							</Button>
						</div>
					</>
				)}
				fallbackRender={() => (
					<p className={styles['cmp__paywall-modal__message']}>
						Contact your account owner for assistance.
					</p>
				)}
			/>
			<div className={styles['cmp__paywall-modal__link']}>
				{hasMultipleClients ? (
					<Link
						data-qa-id="cmp-paywall-modal-switch-client-link"
						to={BASE_ROUTE}
					>
						Switch client
					</Link>
				) : (
					<Link data-qa-id="cmp-paywall-modal-logout-link" to={LOGOUT_ROUTE}>
						Log out
					</Link>
				)}
			</div>
			{(isFetchingUser || isChargebeeLoading) && (
				<div className={styles['cmp__paywall-modal__loading-overlay']}>
					<Loading />
				</div>
			)}
		</>
	)
}

PaywallModal.propTypes = {
	hasMultipleClients: PropTypes.bool.isRequired,
	isFetchingUser: PropTypes.bool.isRequired,
	onChargebeeError: PropTypes.func.isRequired,
	refetchUser: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
}

export default PaywallModal
