import { createSelector } from 'reselect'

export const applicationSelector = (state) =>
	state.application ? state.application : null

export const applicationIsReadySelector = createSelector(
	[applicationSelector],
	(data) =>
		data !== null && typeof data.isReady === 'boolean' ? data.isReady : null,
)

export const applicationRequestSelector = createSelector(
	[applicationSelector],
	(data) =>
		data !== null && typeof data.inProgress === 'boolean'
			? data.inProgress
			: null,
)

export const applicationErrorSelector = createSelector(
	[applicationSelector],
	(data) =>
		data !== null && typeof data.isError === 'boolean' ? data.isError : null,
)
