const { MIN_CANONICAL_VALUE, MAX_CANONICAL_VALUE } = require('./constants')
const { roundTemperature, mRound } = require('./rounding')

const F_TO_CANONICAL_MULTIPLIER = 10
const F_TO_C_MULTIPLIER = 5 / 9
const F_TO_C_OFFSET = -32

// ------------------------------------
// Simple temperature conversions
// ------------------------------------
const convertFahrenheitToCelsius = (fahrenheitTemperature) =>
	(fahrenheitTemperature + F_TO_C_OFFSET) * F_TO_C_MULTIPLIER

const convertCelsiusToFahrenheit = (celsiusTemperature) =>
	celsiusTemperature / F_TO_C_MULTIPLIER - F_TO_C_OFFSET

const convertFahrenheitToCanonical = (fahrenheitTemperature) =>
	Math.round(fahrenheitTemperature * F_TO_CANONICAL_MULTIPLIER)

const convertCanonicalToFahrenheit = (canonicalTemperature) =>
	canonicalTemperature / F_TO_CANONICAL_MULTIPLIER

const convertCelsiusToCanonical = (celsiusTemperature) =>
	convertFahrenheitToCanonical(convertCelsiusToFahrenheit(celsiusTemperature))

const convertCanonicalToCelsius = (canonicalTemperature) =>
	convertFahrenheitToCelsius(convertCanonicalToFahrenheit(canonicalTemperature))

// ------------------------------------
// Temperature difference conversions (no offset)
// ------------------------------------
const convertFahrenheitDifferenceToCelsius = (fahrenheitDifference) =>
	fahrenheitDifference * F_TO_C_MULTIPLIER

const convertCelsiusDifferenceToFahrenheit = (celsiusDifference) =>
	celsiusDifference / F_TO_C_MULTIPLIER

const convertFahrenheitDifferenceToCanonical = (fahrenheitTemperature) =>
	fahrenheitTemperature * F_TO_CANONICAL_MULTIPLIER

const convertCelsiusDifferenceToCanonical = (celsiusDifference) =>
	convertFahrenheitDifferenceToCanonical(
		convertCelsiusDifferenceToFahrenheit(celsiusDifference),
	)

const convertTemperatureDifferenceToCanonical = (difference, isCelsius) =>
	isCelsius
		? convertCelsiusDifferenceToCanonical(difference)
		: convertFahrenheitDifferenceToCanonical(difference)

// ------------------------------------
// User temperature conversions
// ------------------------------------
const convertUserTemperatureToCanonical = (userTemperature, isCelsius) => {
	const canonical = isCelsius
		? convertCelsiusToCanonical(userTemperature)
		: convertFahrenheitToCanonical(userTemperature)
	return Math.min(MAX_CANONICAL_VALUE, Math.max(MIN_CANONICAL_VALUE, canonical))
}

// ------------------------------------
// Viewable temperature conversions
// ------------------------------------
const convertCanonicalToViewable = (canonicalTemperature, useCelsius) => {
	const degrees = useCelsius
		? convertCanonicalToCelsius(canonicalTemperature)
		: convertCanonicalToFahrenheit(canonicalTemperature)

	return roundTemperature(degrees, useCelsius)
}

const convertCanonicalDifferenceToViewable = (
	canonicalTemperature,
	useCelsius,
	step = 0.1,
) => {
	const degrees = useCelsius
		? convertFahrenheitDifferenceToCelsius(
				convertCanonicalToFahrenheit(canonicalTemperature),
		  )
		: convertCanonicalToFahrenheit(canonicalTemperature)

	// Differences are usually small, so we sometimes need more precision than we'd get from roundTemperature
	return mRound(degrees, step)
}

module.exports = {
	convertFahrenheitToCelsius,
	convertCelsiusToFahrenheit,
	convertFahrenheitToCanonical,
	convertCanonicalToFahrenheit,
	convertCelsiusToCanonical,
	convertCanonicalToCelsius,
	convertTemperatureDifferenceToCanonical,
	convertUserTemperatureToCanonical,
	convertCanonicalToViewable,
	convertCanonicalDifferenceToViewable,
}
