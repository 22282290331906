import { createAction } from '@/utils/actions'
import * as buildingListActionTypes from './constants'

export const getBuildingsListRequest = createAction(
	buildingListActionTypes.GET_BUILDING_LIST_REQUEST,
)
export const getBuildingsListSuccess = createAction(
	buildingListActionTypes.GET_BUILDING_LIST_SUCCESS,
)
export const getBuildingsListError = createAction(
	buildingListActionTypes.GET_BUILDING_LIST_ERROR,
)
export const resetBuildingList = createAction(
	buildingListActionTypes.RESET_BUILDING_LIST,
)
export const showAddBuildingAction = createAction(
	buildingListActionTypes.SHOW_ADD_BUILDING,
)
export const hideAddBuildingAction = createAction(
	buildingListActionTypes.HIDE_ADD_BUILDING,
)
