import { createAction } from '@/utils/actions'
import {
	DELETE_ALERT_ERROR,
	DELETE_ALERT_REQUEST,
	DELETE_ALERT_SUCCESS,
} from './constants'

export const deleteAlertRequest = createAction(DELETE_ALERT_REQUEST)
export const deleteAlertError = createAction(DELETE_ALERT_ERROR)
export const deleteAlertSuccess = createAction(DELETE_ALERT_SUCCESS)
