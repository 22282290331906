import * as revokeUserActionTypes from './constants'

/**
 *
 */
function getInitialState() {
	return {
		inProgress: false,
		isError: false,
		isSuccess: false,
	}
}

const revokeUserReducer = (state = getInitialState(), action) => {
	switch (action.type) {
		case revokeUserActionTypes.REVOKE_USER_REQUEST: {
			return {
				...state,
				inProgress: true,
				isError: false,
				isSuccess: false,
			}
		}
		case revokeUserActionTypes.REVOKE_USER_SUCCESS:
			return {
				...state,
				inProgress: false,
				isError: false,
				isSuccess: true,
			}
		case revokeUserActionTypes.REVOKE_USER_RESET:
			return getInitialState()
		case revokeUserActionTypes.REVOKE_USER_ERROR: {
			return {
				...state,
				inProgress: false,
				isError: true,
				isSuccess: false,
			}
		}
		default: {
			return state
		}
	}
}

export default revokeUserReducer
