import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as modalActions from '@/store/modal/actions'
import * as userActions from '@/store/user/actions'
import {
	hasActiveSubscriptionSelector,
	hasManagementRefSelector,
	userCompaniesSelector,
	userIsFetchingSelector,
} from '@/store/user/selectors'
import SBModal from '@/components/sb-modal'
import PaywallModal from '@/components/paywall-modal'

const PaywallContainer = ({
	clients,
	hasActiveSubscription,
	hasManagementRef,
	isFetchingUser,
	modalShow,
	userRequest,
}) => {
	const title = 'Your SmartBuildings account has been cancelled'
	const onChargebeeError = () => {
		modalShow({
			title: 'Something went wrong',
			message: 'Please check your connection or try again later.',
		})
	}

	return (
		<SBModal
			className="cmp__paywall-modal"
			label={title}
			isOpen={hasActiveSubscription === false && hasManagementRef === false}
		>
			<PaywallModal
				hasMultipleClients={clients.length > 1}
				isFetchingUser={isFetchingUser}
				onChargebeeError={onChargebeeError}
				refetchUser={userRequest}
				title={title}
			/>
		</SBModal>
	)
}

PaywallContainer.propTypes = {
	clients: PropTypes.arrayOf(PropTypes.any).isRequired,
	hasActiveSubscription: PropTypes.bool,
	hasManagementRef: PropTypes.bool.isRequired,
	isFetchingUser: PropTypes.bool.isRequired,
	modalShow: PropTypes.func.isRequired,
	userRequest: PropTypes.func.isRequired,
}

const mapStateToProps = /* istanbul ignore next */ (state) => ({
	hasActiveSubscription: hasActiveSubscriptionSelector(state),
	hasManagementRef: hasManagementRefSelector(state),
	clients: userCompaniesSelector(state),
	isFetchingUser: userIsFetchingSelector(state),
})

const mapDispatchToProps = /* istanbul ignore next */ (dispatch) => ({
	modalShow: (payload) => {
		dispatch(modalActions.modalShow(payload))
	},
	userRequest: () => {
		dispatch(userActions.getUserRequest())
	},
})

export { PaywallContainer as PaywallContainerComponent }

export default connect(mapStateToProps, mapDispatchToProps)(PaywallContainer)
