import { takeLatest, put, call, select } from 'redux-saga/effects'
import history from '@/services/history'
import * as toastActions from '@/store/toast/actions'
import * as modalActions from '@/store/modal/actions'
import { activeCompanyIdSelector } from '@/store/user/selectors'
import RemoteErrorService from '@/services/remote.error.service'
import { safeFetch } from '@/services/fetch-api/fetch.api'
import { BUILDING_NOT_EMPTY_ERROR } from '@/utils/constants'
import { getCompanyPath } from '@/utils/misc'
import * as deleteBuildingActionTypes from './constants'
import * as deleteBuildingActions from './actions'

/**
 * @param root0
 * @param root0.payload
 */
export function* deleteBuilding({ payload }) {
	const params = {
		id: payload.id,
		accountReference: yield select(activeCompanyIdSelector),
	}

	const apiResponse = yield call(safeFetch, 'deleteBuilding', params)
	const { data, response } = apiResponse
	if (response.ok) {
		yield put(
			deleteBuildingActions.deleteBuildingSuccess(null, {
				event: 'BuildingDeleteSuccess',
				eventProperties: {
					buildingId: payload.id,
				},
			}),
		)

		yield call([history, 'replace'], { pathname: getCompanyPath('/building') })
		yield put(
			toastActions.toastShow({
				message: 'Your building has been successfully deleted.',
			}),
		)
	} else if (
		RemoteErrorService.isErrorResponse(data) &&
		RemoteErrorService.hasErrorByCode(BUILDING_NOT_EMPTY_ERROR, data)
	) {
		yield put(
			deleteBuildingActions.deleteBuildingError(null, {
				event: 'BuildingDeleteNotEmptyError',
				eventProperties: {
					buildingId: payload.id,
				},
			}),
		)
		yield put(
			modalActions.modalShow({
				title: 'Unable to delete',
				message: `You can only delete a building if there are no thermostats registered to it. Please move or delete all thermostats in ${payload.name}.`,
			}),
		)
	} else {
		yield put(
			deleteBuildingActions.deleteBuildingError(null, {
				event: 'BuildingDeleteError',
				eventProperties: {
					buildingId: payload.id,
				},
			}),
		)
		yield put(
			modalActions.modalShow({
				title: 'Something went wrong',
				message: 'We were unable to delete your building. Please try again.',
			}),
		)
	}
}

/**
 *
 */
export function* deleteBuildingRequestWatcher() {
	yield takeLatest(
		deleteBuildingActionTypes.DELETE_BUILDING_REQUEST,
		deleteBuilding,
	)
}
