import styled from 'styled-components'
import { EDL_COLOURS } from '@ecobee/smartbuildings-library-common/constants'

export const Wrapper = styled.div`
	margin: -50px; /* SBModal has a wrapper with 50px of padding -- we need to undo that so the loading overlay can fill the whole modal */
	padding: 50px;
	position: relative; /* required for the LoadingOverlay */
	width: 400px;
`

export const Title = styled.h2`
	color: ${EDL_COLOURS.CLOUD_BURST};
	margin: 0 -10px;
	text-align: center;
`

export const Message = styled.p`
	margin: 20px 0 30px 0;
	text-align: center;
`

export const FormRow = styled.div`
	margin-bottom: 16px;
`

export const ButtonWrapper = styled.div`
	margin-top: 30px;
`
