import { createAction } from '@/utils/actions'
import * as revokeUserActionTypes from './constants'

export const revokeUserRequest = createAction(
	revokeUserActionTypes.REVOKE_USER_REQUEST,
)
export const revokeUserReset = createAction(
	revokeUserActionTypes.REVOKE_USER_RESET,
)
export const revokeUserSuccess = createAction(
	revokeUserActionTypes.REVOKE_USER_SUCCESS,
)
export const revokeUserError = createAction(
	revokeUserActionTypes.REVOKE_USER_ERROR,
)
