import config from 'config'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import errorLoggingService from '@/services/error.logging.service'
import routingService from '@/services/routing.service'
import { modalShow } from './modal/actions'
import rootSaga from './root.saga'
import { makeRootReducer } from './reducers'

const isDevelopment = ['development', 'local', 'remote'].includes(config.ENV)

export default (initialState = {}) => {
	let store

	// ======================================================
	// Middleware Configuration
	// ======================================================
	const sagaMiddleware = createSagaMiddleware({
		onError: (error) => {
			// eslint-disable-next-line no-console
			console.error(error)
			errorLoggingService.notify(error)

			if (store) {
				const action = modalShow({
					title: 'Something went wrong',
					message: 'Please contact support.',
				})
				store.dispatch(action)
			}
		},
	})

	// ======================================================
	// Store Enhancers
	// ======================================================
	const reduxDevToolsOptions = {
		trace: isDevelopment,
	}
	const composeEnhancers = composeWithDevTools(reduxDevToolsOptions)

	// ======================================================
	// Store Instantiation
	// ======================================================
	store = createStore(
		makeRootReducer(),
		initialState,
		composeEnhancers(applyMiddleware(sagaMiddleware)),
	)

	let sagaTask = sagaMiddleware.run(rootSaga)
	routingService.subscribe(store)

	if (isDevelopment && module.hot) {
		/* eslint-disable global-require */
		module.hot.accept('./reducers', () => {
			store.replaceReducer(require('./reducers').makeRootReducer())
		})

		module.hot.accept('./root.saga', async () => {
			sagaTask.cancel()
			await sagaTask.toPromise()

			sagaTask = sagaMiddleware.run(require('./root.saga').default)
		})
		/* eslint-enable global-require */
	}

	return store
}
