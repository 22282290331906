import { takeLatest, put, call } from 'redux-saga/effects'
import { safeFetch } from '@/services/fetch-api/fetch.api'

import * as toastActions from '@/store/toast/actions'
import * as modalActions from '@/store/modal/actions'
import * as thermostatActions from '@/store/thermostat/actions'
import { getThermostat } from '@/store/thermostat/sagas'

import { validateApiResponse } from '@/utils/validators'
import { updateSensorSuccess, updateSensorError } from './actions'
import { UPDATE_SENSOR_REQUEST } from './constants'

/**
 * @param action
 */
export function* updateSensor(action) {
	const {
		buildingId,
		sensorId,
		thermostatId,
		name,
		isParticipating,
	} = action.payload

	try {
		const res = yield call(safeFetch, 'updateSensor', {
			buildingId,
			sensorId,
			isParticipating,
			name,
			thermostatId,
		})
		if (validateApiResponse(res)) {
			yield call(
				getThermostat,
				thermostatActions.getThermostatRequest({
					id: thermostatId,
					forceFetch: true,
				}),
			)
			yield put(updateSensorSuccess())

			yield put(
				toastActions.toastShow({
					message:
						'Sensor settings updated. Changes may take a few minutes to be in effect',
				}),
			)
		} else {
			yield put(updateSensorError())
			yield put(
				modalActions.modalShow({
					title: 'Something went wrong',
					message: 'The sensor settings didn’t update. Please try again later.',
				}),
			)
		}
	} catch {
		yield put(updateSensorError())
		yield put(
			modalActions.modalShow({
				title: 'Something went wrong',
				message: 'The sensor settings didn’t update. Please try again later.',
			}),
		)
	}
}

/**
 *
 */
export function* updateSensorRequestWatcher() {
	yield takeLatest(UPDATE_SENSOR_REQUEST, updateSensor)
}
