import * as bulkFunctionsUpdateActions from './constants'

/**
 *
 */
function getInitialState() {
	return {
		isBulkFunctionUpdating: false,
		isBulkFunctionError: false,
	}
}

const bulkFunctionsUpdateReducer = (state = getInitialState(), action) => {
	switch (action.type) {
		case bulkFunctionsUpdateActions.BULK_UPDATE_WITH_FUNCTION_ERROR:
			return {
				...state,
				isBulkFunctionUpdating: false,
				isBulkFunctionError: true,
			}
		case bulkFunctionsUpdateActions.BULK_UPDATE_WITH_FUNCTION_REQUEST:
			return {
				...state,
				isBulkFunctionUpdating: true,
				isBulkFunctionError: false,
			}
		case bulkFunctionsUpdateActions.BULK_UPDATE_WITH_FUNCTION_SUCCESS:
			return {
				...state,
				isBulkFunctionUpdating: false,
				isBulkFunctionError: false,
			}
		default:
			return state
	}
}

export default bulkFunctionsUpdateReducer
