/**
 * Combine all response objects into a single array of thermostats
 *
 * @param  {Mixed} fetchedThermostat Response or array of responses from the API
 * @returns {Array}                   Array of combined thermostat objects
 */
const combineThermostats = (fetchedThermostat) => {
	const fetchedArray = Array.isArray(fetchedThermostat)
		? fetchedThermostat
		: [fetchedThermostat]
	const combinedArray = []
	fetchedArray.forEach(({ data }) => {
		combinedArray.push(...data)
	})
	return combinedArray
}

export default combineThermostats
