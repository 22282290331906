import React, { useContext } from 'react'
import { connect } from 'react-redux'
import config from 'config'
import PropTypes from 'prop-types'
import { userPreferencesSelector } from '@/store/user/selectors'
import UserPreferencesContext from './user.preferences.context'

export const initialState = config.USER_PREFERENCES_DEFAULT

export const useUserPreferences = () => useContext(UserPreferencesContext) || {}

const UserPreferencesProvider = ({ children, userPreferences }) => {
	const updatedState = {
		...initialState,
		...userPreferences,
	}
	return (
		<UserPreferencesContext.Provider value={updatedState}>
			{children}
		</UserPreferencesContext.Provider>
	)
}

UserPreferencesProvider.propTypes = {
	children: PropTypes.node,
	userPreferences: PropTypes.shape({
		useCelsius: PropTypes.bool,
	}),
}

const mapStateToProps = /* istanbul ignore next */ (state) => ({
	userPreferences: userPreferencesSelector(state),
})

const mapDispatchToProps = /* istanbul ignore next */ () => ({})

export { UserPreferencesProvider as UserPreferencesProviderComponent }
export const UserPreferencesConsumer = UserPreferencesContext.Consumer

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(UserPreferencesProvider)
