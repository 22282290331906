import { useState, useEffect } from 'react'

const useDelay = (delayMs = 0) => {
	const [isComplete, setIsComplete] = useState(delayMs === 0)

	useEffect(() => {
		const timeoutId = isComplete
			? null
			: setTimeout(() => setIsComplete(true), delayMs)
		return () => clearTimeout(timeoutId)
	}, [isComplete, delayMs])

	return isComplete
}

export default useDelay
