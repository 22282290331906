import { takeLatest, put, call, select } from 'redux-saga/effects'
import * as addBuildingActionTypes from '@/store/add-building/constants'
import * as thermostatListActionTypes from '@/store/thermostat-list/constants'
import { sortList } from '@/utils/misc'
import { validateApiResponse } from '@/utils/validators'
import { safeFetch } from '@/services/fetch-api/fetch.api'
import { activeCompanyIdSelector } from '@/store/user/selectors'
import * as buildingListActionTypes from './constants'

/**
 * @param action
 */
export function* buildingList(action) {
	const accountReference = yield select(activeCompanyIdSelector)
	const resetThermostatListState =
		action.payload && action.payload.resetThermostatListState
	const fetchParams = {
		accountReference,
	}

	const fetchedBuildings = yield call(
		safeFetch,
		'getBuildingsList',
		fetchParams,
	)

	if (validateApiResponse(fetchedBuildings)) {
		yield put({
			type: buildingListActionTypes.GET_BUILDING_LIST_SUCCESS,
			payload: {
				buildings: sortList(fetchedBuildings.data),
			},
		})
		if (resetThermostatListState) {
			yield put({
				type: thermostatListActionTypes.RESET_THERMOSTAT_LIST_STATE,
			})
		}
	} else {
		yield put({
			type: buildingListActionTypes.GET_BUILDING_LIST_ERROR,
		})
	}
}

/**
 *
 */
export function* hideAddBuilding() {
	yield put({
		type: addBuildingActionTypes.ADD_BUILDING_RESET,
	})
}

/**
 *
 */
export function* buildingsListRequestWatcher() {
	yield takeLatest(
		buildingListActionTypes.GET_BUILDING_LIST_REQUEST,
		buildingList,
	)
	yield takeLatest(buildingListActionTypes.HIDE_ADD_BUILDING, hideAddBuilding)
}
