const flatMap = require('lodash/flatMap')
const uniq = require('lodash/uniq')

// Roles
const OWNER_ROLE = 'owner'
const ADMIN_ROLE = 'admin'
const MANAGER_ROLE = 'manager'
const READ_ONLY_ROLE = 'read_only'

/**
 * Permission definitions
 *
 * Naming convention:
 * - Group similar permissions together (e.g., building related permissions should be under a `BUILDING` key)
 * - Define actions within groups
 *   - Actions can be any of VIEW, ADD, REMOVE, or UPDATE (but other verbs can be used if none of these fit)
 *   - MANAGE is shorthand for VIEW, ADD, REMOVE, and UPDATE
 * - If a permission applies to all resources within a group, suffix with `_ALL` (e.g., `RESOURCES.MANAGE_ALL`)
 * - If a permission applies to a subset of resources within a group, they can be nested more deeply using a key
 *   - e.g., ROLES.MANAGE has subkeys based on roles (`admin`, etc.)
 */
const permissions = {
	COMPANY_USERS: {
		MANAGE: {
			id: 'manage_company_users',
			description: 'View, invite, modify, and revoke company users',
		},
		VIEW: {
			id: 'view_company_users',
			description: 'View list of all company user and their info',
		},
	},
	RESOURCES: {
		MANAGE_ALL: {
			id: 'manage_all_resources',
			description:
				'Add, view, and modify all company buildings and thermostats',
		},
		MANAGE_SOME: {
			id: 'manage_some_resources',
			description:
				'Add, view, and modify company buildings and thermostats depending on resource access',
		},
		VIEW_SOME: {
			id: 'view_some_resources',
			description:
				'View company buildings and thermostats depending on resource access',
		},
	},
	ROLES: {
		MANAGE_ALL: {
			id: 'manage_all_roles',
			description: 'Invite, modify, and revoke users with any role',
		},
		MANAGE: {
			admin: {
				id: 'manage_role_admin',
				description: 'Invite, modify, and revoke users with the "admin" role',
			},
			manager: {
				id: 'manage_role_manager',
				description: 'Invite, modify, and revoke users with the "manager" role',
			},
			read_only: {
				id: 'manage_role_read_only',
				description:
					'Invite, modify, and revoke users with the "read_only" role',
			},
		},
	},
	SUBSCRIPTION: {
		MANAGE: {
			id: 'manage_subscription',
			description: 'View and modify subscription data',
		},
	},
	TAGS: {
		CREATE: {
			id: 'create_tags',
			description: 'Create tags',
		},
		MANAGE: {
			id: 'manage_tags',
			description: 'Rename and delete tags',
		},
		VIEW: {
			id: 'view_tags',
			description: 'View tags',
		},
	},
	// These are required for portal-native because it relies on its own
	// hardcoded set of roles and permissions instead of library-common, but the
	// OL sends back a list of permissions in the GET /user response, so in
	// order to remain backward compatible, we also need to send the old
	// "building" permission names alongside the new ones in any API responses
	// until it can be updated to use the new "resource" ones
	__DEPRECATED__BUILDINGS: {
		MANAGE_ALL: {
			id: 'manage_all_buildings',
			description: 'Add, view, and modify all company buildings',
		},
		MANAGE_SOME: {
			id: 'manage_some_buildings',
			description:
				'Add, view, and modify company buildings depending on resource access',
		},
		VIEW_SOME: {
			id: 'view_some_buildings',
			description: 'View company buildings depending on resource access',
		},
	},
}

/**
 * Role definitions: name, description, and permissions
 */
const roles = {
	[OWNER_ROLE]: {
		name: 'Owner',
		description: 'Full access to this SmartBuildings account.',
		permissions: [
			permissions.RESOURCES.MANAGE_ALL,
			// TODO - remove once portal-native is updated with new permissions
			// (equivalent to permissions.RESOURCES.MANAGE_ALL)
			permissions.__DEPRECATED__BUILDINGS.MANAGE_ALL,
			permissions.ROLES.MANAGE_ALL,
			permissions.COMPANY_USERS.MANAGE,
			permissions.SUBSCRIPTION.MANAGE,
			permissions.TAGS.CREATE,
			permissions.TAGS.MANAGE,
			permissions.TAGS.VIEW,
		],
	},
	[ADMIN_ROLE]: {
		name: 'Admin',
		description:
			'Permission to view and edit all devices, but no access to billing information.',
		permissions: [
			permissions.RESOURCES.MANAGE_ALL,
			// TODO - remove once portal-native is updated with new permissions
			// (equivalent to permissions.RESOURCES.MANAGE_ALL)
			permissions.__DEPRECATED__BUILDINGS.MANAGE_ALL,
			permissions.COMPANY_USERS.MANAGE,
			permissions.ROLES.MANAGE[ADMIN_ROLE],
			permissions.ROLES.MANAGE[MANAGER_ROLE],
			permissions.ROLES.MANAGE[READ_ONLY_ROLE],
			permissions.TAGS.CREATE,
			permissions.TAGS.MANAGE,
			permissions.TAGS.VIEW,
		],
	},
	[MANAGER_ROLE]: {
		name: 'Building manager',
		description: 'Permission to view and edit assigned devices.',
		permissions: [
			permissions.RESOURCES.MANAGE_SOME,
			// TODO - remove once portal-native is updated with new permissions
			// (equivalent to permissions.RESOURCES.MANAGE_SOME)
			permissions.__DEPRECATED__BUILDINGS.MANAGE_SOME,
			permissions.TAGS.CREATE,
			permissions.TAGS.VIEW,
			permissions.COMPANY_USERS.VIEW,
		],
	},
	[READ_ONLY_ROLE]: {
		name: 'Read only',
		description: 'Permission to view assigned devices.',
		permissions: [
			permissions.RESOURCES.VIEW_SOME,
			// TODO - remove once portal-native is updated with new permissions
			// (equivalent to permissions.RESOURCES.VIEW_SOME)
			permissions.__DEPRECATED__BUILDINGS.VIEW_SOME,
			permissions.TAGS.VIEW,
			permissions.COMPANY_USERS.VIEW,
		],
	},
}

/**
 * Get all permissions for a given role
 * @param  {Array} userRoles Array of user role strings
 * @return {Set}             Set of permissions objects
 */
const getPermissionsForRoles = (userRoles) => {
	return uniq(
		flatMap(userRoles, (roleId) => {
			return roleId in roles ? roles[roleId].permissions : []
		}),
	)
}

/**
 * Helper to check if a permissions object is a member of an array of permissions
 * @param  {array}  permissions     Array of permission objects
 * @param  {object} permissionToValidate Permission object to validate
 * @return {boolean}                     True if permission is in array, otherwise false
 */
const doesIncludePermission = (permissions = [], permissionToValidate) => {
	if (!permissionToValidate) {
		return false
	}
	return permissions
		.map((permission) => {
			return permission.id ? permission.id : permission
		})
		.includes(permissionToValidate.id)
}

/**
 * Determines if a role grants access to all resources
 * @param {string} role manager/owner/admin/read_only
 * @return {boolean} True if the role has access to all resources, otherwise false
 */
const isGlobalAccessRole = (role) =>
	role in roles &&
	roles[role].permissions.includes(permissions.RESOURCES.MANAGE_ALL)

/**
 * Determines if a user has access to all resources
 * @param {object} user User object containing an array of roles
 * @return {boolean} True if the user has access to all resources, otherwise false
 */
const isGlobalAccessUser = (user) => user.roles.some(isGlobalAccessRole)

/**
 * Determines if a role grants access to specific resources based on a resourceAccess list
 * @param {string} role manager/owner/admin/read_only
 * @return {boolean} True if the role is a resourceAccess role, otherwise false
 */
const isResourceAccessRole = (role) =>
	role in roles &&
	[
		permissions.RESOURCES.MANAGE_SOME,
		permissions.RESOURCES.VIEW_SOME,
	].some((permission) => roles[role].permissions.includes(permission))

/**
 * Determines if a user has access to specific resources based on a resourceAccess list
 * @param {object} user User object containing an array of roles
 * @return {boolean} True if the user has access to specific resources, otherwise false
 */
const isResourceAccessUser = (user) =>
	!isGlobalAccessUser(user) && user.roles.some(isResourceAccessRole)

module.exports = {
	OWNER_ROLE,
	ADMIN_ROLE,
	MANAGER_ROLE,
	READ_ONLY_ROLE,
	permissions,
	roles,
	getPermissionsForRoles,
	doesIncludePermission,
	isGlobalAccessRole,
	isGlobalAccessUser,
	isResourceAccessRole,
	isResourceAccessUser,
}
