import { createAction } from '@/utils/actions'
import * as updateSensorActionTypes from './constants'

export const updateSensorRequest = createAction(
	updateSensorActionTypes.UPDATE_SENSOR_REQUEST,
)

export const updateSensorSuccess = createAction(
	updateSensorActionTypes.UPDATE_SENSOR_SUCCESS,
)
export const updateSensorError = createAction(
	updateSensorActionTypes.UPDATE_SENSOR_ERROR,
)
