import { takeLatest, put, call, select } from 'redux-saga/effects'
import { getCompanyPath } from '@/utils/misc'
import { geocodeBingMapsAddress } from '@/utils/bing.maps.utils'
import * as buildingListActionTypes from '@/store/building-list/constants'
import * as toastActionTypes from '@/store/toast/constants'
import * as modalActionTypes from '@/store/modal/constants'
import { safeFetch } from '@/services/fetch-api/fetch.api'
import history from '@/services/history'
import RemoteErrorService from '@/services/remote.error.service'
import { activeCompanyIdSelector } from '@/store/user/selectors'
import * as addBuildingActionTypes from './constants'

/**
 * @param action
 */
export function* addBuilding(action) {
	const {
		name,
		address,
		size,
		hasPeakDemand,
		enableRandomStartDelay,
	} = action.payload

	const locationData = yield call(geocodeBingMapsAddress, address)

	const addBuildingPayload = {
		name: name.trim(),
		size,
		accountReference: yield select(activeCompanyIdSelector),
		...locationData,
		energySettings: {
			hasPeakDemand,
			enableRandomStartDelay,
		},
	}

	const apiResponse = yield call(safeFetch, 'addBuilding', addBuildingPayload)
	const { data, response } = apiResponse

	if (response.ok) {
		yield put({
			type: addBuildingActionTypes.ADD_BUILDING_SUCCESS,
			meta: {
				event: 'BuildingCreateSuccess',
				eventProperties: {
					buildingId: data.id,
				},
			},
		})

		yield put({
			type: buildingListActionTypes.HIDE_ADD_BUILDING,
		})
		yield call([history, 'replace'], {
			pathname: getCompanyPath(`/building/${data.id}`),
		})
		yield put({
			type: toastActionTypes.TOAST_SHOW,
			payload: {
				message: 'Your building has been successfully added.',
			},
		})
	} else if (RemoteErrorService.isValidationError(data)) {
		yield put({
			type: addBuildingActionTypes.ADD_BUILDING_VALIDATION_ERROR,
			payload: {
				message: RemoteErrorService.getMessage(data),
			},
		})
	} else {
		yield put({
			type: addBuildingActionTypes.ADD_BUILDING_ERROR,
			meta: {
				event: 'BuildingCreateError',
			},
		})
		yield put({
			type: modalActionTypes.MODAL_SHOW,
			payload: {
				title: 'Something went wrong',
				message: 'We were unable to add your building. Please try again.',
			},
		})
	}
}

/**
 *
 */
export function* addBuildingRequestWatcher() {
	yield takeLatest(addBuildingActionTypes.ADD_BUILDING_REQUEST, addBuilding)
}
