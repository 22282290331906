import { takeLatest, put, delay } from 'redux-saga/effects'
import * as toastActionTypes from './constants'

/**
 * @param action
 */
export function* delayDismissToast(action) {
	const timeout = action.payload.timeout || 4000
	yield delay(timeout)
	yield put({ type: toastActionTypes.TOAST_DISMISS })
}

/**
 *
 */
export function* toastWatcher() {
	yield takeLatest(toastActionTypes.TOAST_SHOW, delayDismissToast)
}
