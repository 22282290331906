import { createAction } from '@/utils/actions'
import * as thermostatListActionTypes from './constants'

export const getThermostatListRequest = createAction(
	thermostatListActionTypes.GET_THERMOSTAT_LIST_REQUEST,
	(payload) => ({
		forceUpdate: true,
		...payload,
	}),
)
export const getThermostatListSuccess = createAction(
	thermostatListActionTypes.GET_THERMOSTAT_LIST_SUCCESS,
)
export const getThermostatListError = createAction(
	thermostatListActionTypes.GET_THERMOSTAT_LIST_ERROR,
)
export const showAddThermostat = createAction(
	thermostatListActionTypes.SHOW_ADD_THERMOSTAT,
)
export const dismissAddThermostat = createAction(
	thermostatListActionTypes.DISMISS_ADD_THERMOSTAT,
)
export const selectThermostat = createAction(
	thermostatListActionTypes.SELECT_THERMOSTAT,
)
export const deselectThermostat = createAction(
	thermostatListActionTypes.DESELECT_THERMOSTAT,
)
export const deselectAllThermostat = createAction(
	thermostatListActionTypes.DESELECT_ALL_THERMOSTAT,
)
export const selectAllThermostat = createAction(
	thermostatListActionTypes.SELECT_ALL_THERMOSTAT,
)
export const bulkUpdateTagsRequest = createAction(
	thermostatListActionTypes.BULK_UPDATE_TAGS_REQUEST,
)
export const bulkUpdateTagsSuccess = createAction(
	thermostatListActionTypes.BULK_UPDATE_TAGS_SUCCESS,
)
export const bulkUpdateTagsError = createAction(
	thermostatListActionTypes.BULK_UPDATE_TAGS_ERROR,
)
export const bulkUpdateThermostatRequest = createAction(
	thermostatListActionTypes.BULK_UPDATE_THERMOSTAT_REQUEST,
)
export const bulkUpdateMoveThermostatRequest = createAction(
	thermostatListActionTypes.BULK_UPDATE_MOVE_THERMOSTAT_REQUEST,
)
export const bulkDeleteThermostatRequest = createAction(
	thermostatListActionTypes.BULK_DELETE_THERMOSTAT_REQUEST,
)
export const bulkUpdateThermostatSuccess = createAction(
	thermostatListActionTypes.BULK_UPDATE_THERMOSTAT_SUCCESS,
)
export const bulkUpdateThermostatError = createAction(
	thermostatListActionTypes.BULK_UPDATE_THERMOSTAT_ERROR,
)
export const setBulkUpdateAction = createAction(
	thermostatListActionTypes.SET_BULK_UPDATE_ACTION,
)
export const resetBulkUpdateAction = createAction(
	thermostatListActionTypes.RESET_BULK_UPDATE_ACTION,
)
export const inlineUpdateThermostatList = createAction(
	thermostatListActionTypes.INLINE_UPDATE_THERMOSTAT_LIST,
)
export const resetThermostatList = createAction(
	thermostatListActionTypes.RESET_THERMOSTAT_LIST_STATE,
)
export const applyFilter = createAction(thermostatListActionTypes.APPLY_FILTER)
export const removeFilter = createAction(
	thermostatListActionTypes.REMOVE_FILTER,
)
export const resetFilter = createAction(thermostatListActionTypes.RESET_FILTER)
export const applySearch = createAction(thermostatListActionTypes.APPLY_SEARCH)
export const resetSearch = createAction(thermostatListActionTypes.RESET_SEARCH)
export const updateLastQueryString = createAction(
	thermostatListActionTypes.UPDATE_LAST_QUERY_STRING,
)
