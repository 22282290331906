import { createSelector } from 'reselect'
import { parseInteger } from '@/utils/misc'

export const routesSelector = (state) => (state.routes ? state.routes : null)

export const routesListSelector = createSelector(routesSelector, (routes) =>
	routes && routes.routesList ? routes.routesList : null,
)

export const activeRouteSelector = createSelector(routesSelector, (routes) =>
	routes && routes.activeRoute ? routes.activeRoute : null,
)

export const matchSelector = createSelector(routesSelector, (routes) =>
	routes && routes.match ? routes.match : null,
)

export const pathParamsSelector = createSelector(matchSelector, (match) =>
	match && match.params ? match.params : {},
)

export const queryParamsSelector = createSelector(routesSelector, (routes) =>
	routes && routes.queryParams ? routes.queryParams : {},
)

export const pageParamSelector = createSelector(
	queryParamsSelector,
	(queryParams) => (queryParams.page ? parseInteger(queryParams.page) : 1),
)

export const searchParamSelector = createSelector(
	queryParamsSelector,
	(queryParams) => queryParams.search,
)
