import { createAction } from '@/utils/actions'
import * as userActionTypes from './constants'

export const getUserRequest = createAction(userActionTypes.GET_USER_REQUEST)
export const getUserError = createAction(userActionTypes.GET_USER_ERROR)
export const getUserSuccess = createAction(userActionTypes.GET_USER_SUCCESS)
export const resetUser = createAction(userActionTypes.RESET_USER)
export const updateUserRequest = createAction(
	userActionTypes.UPDATE_USER_REQUEST,
)
export const updateUserReset = createAction(userActionTypes.UPDATE_USER_RESET)
export const updateUserSuccess = createAction(
	userActionTypes.UPDATE_USER_SUCCESS,
)
export const updateUserError = createAction(userActionTypes.UPDATE_USER_ERROR)
