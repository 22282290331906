export const APPLY_FILTER = 'APPLY_FILTER'
export const APPLY_SEARCH = 'APPLY_SEARCH'
export const BULK_UPDATE_MOVE_THERMOSTAT_REQUEST =
	'BULK_UPDATE_MOVE_THERMOSTAT_REQUEST'
export const BULK_UPDATE_TAGS_REQUEST = 'BULK_UPDATE_TAGS_REQUEST'
export const BULK_UPDATE_TAGS_SUCCESS = 'BULK_UPDATE_TAGS_SUCCESS'
export const BULK_UPDATE_TAGS_ERROR = 'BULK_UPDATE_TAGS_ERROR'
export const BULK_UPDATE_THERMOSTAT_ERROR = 'BULK_UPDATE_THERMOSTAT_ERROR'
export const BULK_UPDATE_THERMOSTAT_REQUEST = 'BULK_UPDATE_THERMOSTAT_REQUEST'
export const BULK_UPDATE_THERMOSTAT_SUCCESS = 'BULK_UPDATE_THERMOSTAT_SUCCESS'
export const BULK_DELETE_THERMOSTAT_REQUEST = 'BULK_DELETE_THERMOSTAT_REQUEST'
export const DESELECT_ALL_THERMOSTAT = 'DESELECT_ALL_THERMOSTAT'
export const DESELECT_THERMOSTAT = 'DESELECT_THERMOSTAT'
export const DISMISS_ADD_THERMOSTAT = 'DISMISS_ADD_THERMOSTAT'
export const GET_THERMOSTAT_LIST_ERROR = 'GET_THERMOSTAT_LIST_ERROR'
export const GET_THERMOSTAT_LIST_REQUEST = 'GET_THERMOSTAT_LIST_REQUEST'
export const GET_THERMOSTAT_LIST_SUCCESS = 'GET_THERMOSTAT_LIST_SUCCESS'
export const INLINE_UPDATE_THERMOSTAT_LIST = 'INLINE_UPDATE_THERMOSTAT_LIST'
export const REMOVE_FILTER = 'REMOVE_FILTER'
export const RESET_FILTER = 'RESET_FILTER'
export const RESET_SEARCH = 'RESET_SEARCH'
export const RESET_THERMOSTAT_LIST_STATE = 'RESET_THERMOSTAT_LIST_STATE'
export const SELECT_ALL_THERMOSTAT = 'SELECT_ALL_THERMOSTAT'
export const SELECT_THERMOSTAT = 'SELECT_THERMOSTAT'
export const SET_BULK_UPDATE_ACTION = 'SET_BULK_UPDATE_ACTION'
export const RESET_BULK_UPDATE_ACTION = 'RESET_BULK_UPDATE_ACTION'
export const SHOW_ADD_THERMOSTAT = 'SHOW_ADD_THERMOSTAT'
export const UPDATE_LAST_QUERY_STRING = 'UPDATE_LAST_QUERY_STRING'
