import { createAction } from '@/utils/actions'
import * as applicationActionTypes from './constants'

export const applicationRequest = createAction(
	applicationActionTypes.APPLICATION_REQUEST,
)
export const applicationReset = createAction(
	applicationActionTypes.APPLICATION_RESET,
)
export const applicationSuccess = createAction(
	applicationActionTypes.APPLICATION_SUCCESS,
)
export const applicationError = createAction(
	applicationActionTypes.APPLICATION_ERROR,
)
