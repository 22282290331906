import { safeFetch } from '@/services/fetch-api/fetch.api'
import useChargebee from './useChargebee'

/* istanbul ignore next - integration test */
const useChargebeePortal = () => {
	const { isLoading, isError, chargebeeInstance } = useChargebee()

	const openChargebeePortal = () =>
		new Promise((resolve, reject) => {
			if (isLoading || isError || !chargebeeInstance) {
				reject(new Error('Chargebee is not initialized'))
				return
			}

			const close = () => {
				chargebeeInstance.logout()
				resolve()
			}

			chargebeeInstance.setPortalSession(async () => {
				const { data } = await safeFetch('getChargebeePortalSession')
				return data
			})

			chargebeeInstance.createChargebeePortal().open({ close })
		})

	return { isLoading, isError, openChargebeePortal }
}

export default useChargebeePortal
