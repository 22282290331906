import { takeLatest, put, call, select } from 'redux-saga/effects'
import pluralize from 'pluralize'
import * as thermostatListActions from '@/store/thermostat-list/actions'
import * as toastActions from '@/store/toast/actions'
import { safeFetch } from '@/services/fetch-api/fetch.api'
import {
	buildingIdSelector,
	buildingNameSelector,
} from '@/store/building/selectors'
import * as registerThermostatActions from './actions'
import * as registerThermostatActionTypes from './constants'
import { ERRORS, isSubscriptionError, getDuplicateValues } from './helpers'
import {
	SUCCESSFUL_RESPONSE_CODE,
	EXCEEDED_LIMIT_ERROR_CODE,
} from './error.constants'

/**
 * @param registrationError
 * @param markAllFields
 * @param meta
 */
export function* setRegistrationError(
	registrationError,
	markAllFields = false,
	meta,
) {
	const { errorMessage } = registrationError
	yield put(
		registerThermostatActions.registerThermostatError(
			{
				markAllFields,
				errorMessage,
			},
			meta,
		),
	)
}

/**
 * @param totalRegisteredThermostats
 * @param successfulThermostatIds
 */
export function* registrationSuccess(
	totalRegisteredThermostats,
	successfulThermostatIds,
) {
	const buildingName = yield select(buildingNameSelector)
	yield put(
		registerThermostatActions.registerThermostatSuccess(null, {
			event: 'ThermostatsBulkRegistrationSuccess',
			eventProperties: {
				thermostatIds: successfulThermostatIds,
			},
		}),
	)

	yield put(thermostatListActions.dismissAddThermostat())
	yield put(
		toastActions.toastShow({
			message: `You've added ${totalRegisteredThermostats} ${pluralize(
				'thermostat',
				totalRegisteredThermostats,
			)} to ${buildingName}`,
		}),
	)

	yield put(
		thermostatListActions.getThermostatListRequest({
			forceUpdate: true,
		}),
	)
}

/**
 *
 * @param {object} action Redux action that contains a payload of thermostats to register and number of registrations that have already succeeded on the form
 * @returns {Array} Array of statuses for all passed in thermostats from payload
 *
 */
export function* registerThermostat(action) {
	const { registrationSuccesses = 0, thermostats } = action.payload

	const buildingId = yield select(buildingIdSelector)

	const duplicateValues = getDuplicateValues(thermostats)

	if (duplicateValues.length > 0) {
		yield call(setRegistrationError, {
			errorMessage: ERRORS.general,
		})
		yield put(
			registerThermostatActions.registerThermostatSetDuplicates(
				duplicateValues,
			),
		)
		return
	}

	const { data: registerThermostatResponse } = yield call(
		safeFetch,
		'registerThermostat',
		{
			buildingId,
			thermostatIds: thermostats.map((thermostat) => thermostat.value),
		},
	)

	const response = registerThermostatResponse
	const responseDetails = response.data
	const { status } = response

	if (status.code === EXCEEDED_LIMIT_ERROR_CODE) {
		yield call(setRegistrationError, {
			errorMessage: status.message,
		})
		return
	}
	if (status.errors && isSubscriptionError(status.errors)) {
		yield call(setRegistrationError, {
			errorMessage: ERRORS.subscription,
		})
		return
	}

	const registerThermostatAllPayload = thermostats.map((thermostat) => {
		const payloadThermostat = {
			thermostatId: thermostat.value,
			isRegistered: false,
			isError: false,
			code: null,
		}

		const registerThermostatAllPayloadObject = responseDetails.find(
			(value) => value.status.thermostatId === payloadThermostat.thermostatId,
		)
		if (registerThermostatAllPayloadObject) {
			const { status: responseStatus } = registerThermostatAllPayloadObject
			payloadThermostat.isError =
				responseStatus.code !== SUCCESSFUL_RESPONSE_CODE
			payloadThermostat.isRegistered =
				responseStatus.code === SUCCESSFUL_RESPONSE_CODE
			payloadThermostat.code = responseStatus.code
			payloadThermostat.errorDetails = {
				buildingName: responseStatus.buildingName,
				thermostatName:
					responseStatus.thermostatName || responseStatus.thermostatId,
			}
		}

		return payloadThermostat
	})

	const thermostatsRegistrationFailures = registerThermostatAllPayload.filter(
		(thermostat) => thermostat.isError,
	)
	const failedThermostatIds = thermostatsRegistrationFailures.map(
		(thermostat) => thermostat.thermostatId,
	)

	const thermostatsRegistrationSuccesses = registerThermostatAllPayload.filter(
		(thermostat) => thermostat.isRegistered,
	)
	const successfulThermostatIds = thermostatsRegistrationSuccesses.map(
		(thermostat) => thermostat.thermostatId,
	)

	const totalRegisteredThermostats =
		thermostatsRegistrationSuccesses.length + registrationSuccesses

	if (thermostatsRegistrationFailures.length > 0) {
		yield call(
			setRegistrationError,
			{
				errorMessage: ERRORS.general,
			},
			false,
			{
				event: 'ThermostatsBulkRegistrationError',
				eventProperties: {
					successfulThermostatIds,
					failedThermostatIds,
				},
			},
		)
	} else if (totalRegisteredThermostats > 0) {
		yield call(
			registrationSuccess,
			totalRegisteredThermostats,
			successfulThermostatIds,
		)
	}

	yield put(
		registerThermostatActions.registerThermostatUpdateAll(
			registerThermostatAllPayload,
		),
	)
}

/**
 *
 */
export function* registerThermostatRequestWatcher() {
	yield takeLatest(
		registerThermostatActionTypes.REGISTER_THERMOSTAT_REQUEST,
		registerThermostat,
	)
}
