import * as editCompanyUserActionTypes from './constants'

/**
 *
 */
function getInitialState() {
	return {
		inProgress: false,
		isError: false,
	}
}

const editCompanyUserReducer = (state = getInitialState(), action) => {
	switch (action.type) {
		case editCompanyUserActionTypes.EDIT_COMPANY_USER_REQUEST: {
			return {
				...state,
				inProgress: true,
				isError: false,
			}
		}
		case editCompanyUserActionTypes.EDIT_COMPANY_USER_SUCCESS:
		case editCompanyUserActionTypes.EDIT_COMPANY_USER_RESET:
			return getInitialState()
		case editCompanyUserActionTypes.EDIT_COMPANY_USER_ERROR: {
			return {
				...state,
				inProgress: false,
				isError: true,
			}
		}
		default: {
			return state
		}
	}
}

export default editCompanyUserReducer
