const CELSIUS_STEP = 0.5
const FAHRENHEIT_STEP = 1.0

/**
 * Round a number to the nearest multiple of a step
 * @param {number} val Number to round
 * @param {number} step Multiple to round to
 * @returns {number} Rounded number
 */
const mRound = (val, step = 1.0) => {
	const inv = 1.0 / step
	return (Math.sign(val) * Math.round(Math.abs(val) * inv)) / inv
}

/**
 * Round a number down to the nearest multiple of a step
 * @param {number} val Number to round
 * @param {number} step Multiple to round down to
 * @returns {number} Rounded number
 */
const mRoundDown = (val, step = 1.0) => {
	const inv = 1.0 / step
	return Math.floor(val * inv) / inv
}

/**
 * Round a number up to the nearest multiple of a step
 * @param {number} val Number to round
 * @param {number} step Multiple to round up to
 * @returns {number} Rounded number
 */
const mRoundUp = (val, step = 1.0) => {
	const inv = 1.0 / step
	return Math.ceil(val * inv) / inv
}

/**
 * Round a temperature depending on the unit (Fahrenheit or Celsius)
 * @param {number} temperature Temperature value in degrees to round
 * @param {boolean} isCelsius If true, treat the temperature as Celsius, otherwise Fahrenheit
 * @returns {number} Temperature value in degrees, rounded to the correct step depending on the unit
 */
const roundTemperature = (temperature, isCelsius) =>
	mRound(temperature, isCelsius ? CELSIUS_STEP : FAHRENHEIT_STEP)

module.exports = {
	mRound,
	mRoundDown,
	mRoundUp,
	roundTemperature,
}
