import queryString from 'query-string'

const urlFormatter = (apiConfig) => {
	const paramsString =
		apiConfig.urlParams &&
		queryString.stringify(apiConfig.urlParams, { skipNull: true })

	return `${apiConfig.baseUrl}${paramsString ? `?${paramsString}` : ''}`
}

const defaultFormatter = (apiConfig) => {
	const url = urlFormatter(apiConfig)

	return {
		url,
		fetchOptions: apiConfig.fetchOptions,
	}
}

export default defaultFormatter
