import { createSelector } from 'reselect'
import { pathParamsSelector } from '@/store/routes/selectors'

export const buildingSelector = (state) =>
	state.building ? state.building : null

export const buildingDataSelector = createSelector(
	buildingSelector,
	(building) => (building !== null && building.data ? building.data : null),
)

export const buildingIdSelector = createSelector(
	pathParamsSelector,
	(pathParams) => pathParams.buildingId,
)

export const buildingIsErrorSelector = createSelector(
	[buildingSelector],
	(building) =>
		building !== null && typeof building.isError === 'boolean'
			? building.isError
			: null,
)

export const buildingIsNotFoundSelector = createSelector(
	[buildingSelector],
	(building) =>
		building !== null && typeof building.isNotFound === 'boolean'
			? building.isNotFound
			: null,
)

export const buildingIsFetchingSelector = createSelector(
	buildingSelector,
	(building) =>
		building !== null && typeof building.isFetching === 'boolean'
			? building.isFetching
			: null,
)

export const buildingIsUpdatingSelector = createSelector(
	buildingSelector,
	(building) =>
		building !== null && typeof building.isUpdating === 'boolean'
			? building.isUpdating
			: null,
)

export const buildingIsUpdatingErrorSelector = createSelector(
	buildingSelector,
	(building) =>
		building !== null && typeof building.isUpdatingError === 'boolean'
			? building.isUpdatingError
			: null,
)

export const buildingIsUpdatingValidationErrorSelector = createSelector(
	buildingSelector,
	(building) =>
		building !== null && typeof building.isValidationError === 'boolean'
			? building.isValidationError
			: null,
)

export const buildingIsUpdatingValidationErrorMessageSelector = createSelector(
	buildingSelector,
	(building) =>
		building !== null && typeof building.validationErrorMessage === 'string'
			? building.validationErrorMessage
			: null,
)

export const buildingNameSelector = createSelector(
	buildingDataSelector,
	(building) => {
		if (!building) {
			return building
		}
		return building.name
	},
)

export const buildingIsReadOnlySelector = createSelector(
	buildingDataSelector,
	(building) => {
		if (!building) {
			return building
		}
		return building.readOnly || false
	},
)
