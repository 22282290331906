import { takeLatest, takeLeading, call, put, all } from 'redux-saga/effects'
import * as toastActions from '@/store/toast/actions'
import * as modalActions from '@/store/modal/actions'
import { validateApiResponse } from '@/utils/validators'
import { safeFetch } from '@/services/fetch-api/fetch.api'
import errorLoggingService from '@/services/error.logging.service'
import userMonitoringService from '@/services/user.monitoring.service'
import featureflagsService from '@/services/feature-flags/feature.flags.service'
import analyticsService from '@/services/analytics.service'
import * as userActions from './actions'
import * as userActionTypes from './constants'

const USER_CACHE_TIMEOUT_MS = 10000

let cachedUserInfo = null

/**
 *
 */
export function* userRequest(action = {}) {
	const { payload = {} } = action
	const { forceFetch } = payload

	// We should never need to fetch the user more than once every 10s -- e.g.,
	// if we fetch on login, we don't need to refetch when the client list
	// mounts, so we can return the cached user data from the last fetch
	if (cachedUserInfo && !forceFetch) {
		yield put(userActions.getUserSuccess({ userInfo: cachedUserInfo }))
		return
	}

	const fetchedUser = yield call(safeFetch, 'getUser')

	if (validateApiResponse(fetchedUser)) {
		const userInfo = fetchedUser.data

		cachedUserInfo = userInfo
		setTimeout(() => {
			cachedUserInfo = null
		}, USER_CACHE_TIMEOUT_MS)

		yield all([
			call([featureflagsService, 'identify'], userInfo),
			call([userMonitoringService, 'identify'], userInfo),
			call([errorLoggingService, 'setUser'], userInfo),
			call([analyticsService, 'setUser'], userInfo),
		])
		yield put(userActions.getUserSuccess({ userInfo }))
	} else {
		yield put(userActions.getUserError())
		yield put(
			modalActions.modalShow({
				title: 'Something went wrong',
				message: 'Please reload the page or contact support.',
			}),
		)
	}
}

/**
 * @param action
 */
export function* updateUser(action) {
	const { payload } = action
	const { userId, userInfo } = payload
	const requestPayload = {
		userId,
		attributes: {
			...userInfo,
		},
	}
	const results = yield call(safeFetch, 'updateUser', requestPayload)
	if (validateApiResponse(results)) {
		yield put(
			userActions.updateUserSuccess(
				{
					userInfo,
				},
				{
					event: 'UserUpdateSuccess',
					eventDetails: { userId },
				},
			),
		)
		yield put(
			toastActions.toastShow({
				message: 'Preferences have been updated',
			}),
		)
	} else {
		const modalErrorMessage = 'Preferences could not be updated.'
		yield put(
			userActions.updateUserError(null, {
				event: 'UserUpdateError',
				eventDetails: { userId },
			}),
		)
		yield put(
			modalActions.modalShow({
				title: 'Something went wrong',
				message: modalErrorMessage,
			}),
		)
	}
}

/**
 *
 */
export function* userRequestWatcher() {
	yield takeLeading(userActionTypes.GET_USER_REQUEST, userRequest)
	yield takeLatest(userActionTypes.UPDATE_USER_REQUEST, updateUser)
}
