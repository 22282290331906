import { createAction } from '@/utils/actions'
import * as companyUsersActionTypes from './constants'

export const companyUsersRequest = createAction(
	companyUsersActionTypes.COMPANY_USERS_REQUEST,
)
export const companyUsersReset = createAction(
	companyUsersActionTypes.COMPANY_USERS_RESET,
)
export const companyUsersSuccess = createAction(
	companyUsersActionTypes.COMPANY_USERS_SUCCESS,
)
export const companyUsersError = createAction(
	companyUsersActionTypes.COMPANY_USERS_ERROR,
)
