const apiErrorFactory = (
	errorType,
	apiType,
	sentData,
	response = { data: null, response: null },
) => ({
	errorType,
	apiType,
	sentData,
	returnedData: response.data,
	rawResponse: response.response,
})

export default apiErrorFactory
