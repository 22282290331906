import React from 'react'
import PropTypes from 'prop-types'
import Icon from '@/components/icon'
import Button from '@/components/button'
import './system.error.scss'

const SystemError = ({ message, buttonText, onClick }) => (
	<section className="cmp__system-error">
		<div className="cmp__system-error__icon">
			<Icon name="warning" label="Warning" />
		</div>
		<div className="cmp__system-error__subtitle">{message}</div>
		{onClick && (
			<div className="cmp__system-error__cta">
				<Button
					onClick={onClick}
					isSecondary
					label={buttonText}
					isContentSize
					isSlim
				>
					{buttonText}
				</Button>
			</div>
		)}
	</section>
)

SystemError.defaultProps = {
	buttonText: 'Try Again',
}

SystemError.propTypes = {
	message: PropTypes.string.isRequired,
	buttonText: PropTypes.string,
	onClick: PropTypes.func,
}

export default SystemError
