import { createSelector } from 'reselect'
import { doesIncludePermission } from '@ecobee/smartbuildings-library-common/permissions'
import { pathParamsSelector } from '@/store/routes/selectors'

export const userSelector = (state) => (state.user ? state.user : null)

export const userIsFetchingSelector = createSelector(userSelector, (user) =>
	user ? user.isFetching : null,
)

export const userIsErrorSelector = createSelector(userSelector, (user) =>
	user ? user.isError : null,
)

export const userInfoSelector = createSelector(userSelector, (user) =>
	user && user.userInfo ? user.userInfo : null,
)

export const userIdSelector = createSelector(userInfoSelector, (userInfo) =>
	userInfo && userInfo.id ? userInfo.id : null,
)

export const userFirstNameSelector = createSelector(
	userInfoSelector,
	(userInfo) => (userInfo && userInfo.firstName ? userInfo.firstName : null),
)

export const userCompaniesSelector = createSelector(
	userInfoSelector,
	(userInfo) => (userInfo && userInfo.companies ? userInfo.companies : []),
)

export const userPreferencesSelector = createSelector(
	userInfoSelector,
	(userInfo) => (userInfo && userInfo.preferences ? userInfo.preferences : {}),
)

export const useCelsiusSelector = createSelector(
	userPreferencesSelector,
	(userPreferences) => userPreferences && userPreferences.useCelsius,
)

export const activeCompanyIdSelector = createSelector(
	pathParamsSelector,
	(pathParams) =>
		pathParams && pathParams.companyId ? pathParams.companyId : null,
)

export const activeCompanySelector = createSelector(
	userCompaniesSelector,
	activeCompanyIdSelector,
	(userCompanies, activeCompanyId) =>
		userCompanies && activeCompanyId
			? userCompanies.find((company) => company.id === activeCompanyId)
			: null,
)

export const activeCompanyUserSelector = createSelector(
	userInfoSelector,
	activeCompanySelector,
	(userInfo, activeCompany) => {
		const { companies, ...user } = userInfo || {}
		const { roles, permissions, resourceAccess } = activeCompany || {}

		return { ...user, roles, permissions, resourceAccess }
	},
)

export const activeCompanyCountrySelector = createSelector(
	activeCompanySelector,
	(activeCompany) => (activeCompany ? activeCompany.country : null),
)

export const activeCompanyNameSelector = createSelector(
	activeCompanySelector,
	(activeCompany) => (activeCompany ? activeCompany.name : null),
)

export const activeCompanyEmsMetadataSelector = createSelector(
	activeCompanySelector,
	(activeCompany) => (activeCompany ? activeCompany.emsMetadata : null),
)

export const activeCompanyDeviceCountSelector = createSelector(
	activeCompanySelector,
	(activeCompany) => (activeCompany ? activeCompany.deviceCount : null),
)

export const hasManagementRefSelector = createSelector(
	activeCompanyEmsMetadataSelector,
	(emsMetadata) => emsMetadata?.managementRef != null,
)

export const hasActiveSubscriptionSelector = createSelector(
	activeCompanySelector,
	(activeCompany) =>
		activeCompany ? activeCompany.hasActiveSubscription : null,
)

export const activeCompanySubscriptionsSelector = createSelector(
	activeCompanySelector,
	(activeCompany) =>
		activeCompany && activeCompany.subscriptions
			? activeCompany.subscriptions
			: null,
)

export const freePlanQuantitySelector = createSelector(
	activeCompanySubscriptionsSelector,
	(activeCompanySubscriptions) =>
		activeCompanySubscriptions
			? activeCompanySubscriptions.freePlanQuantity
			: null,
)

export const paidPlanQuantitySelector = createSelector(
	activeCompanySubscriptionsSelector,
	(activeCompanySubscriptions) =>
		activeCompanySubscriptions
			? activeCompanySubscriptions.paidPlanQuantity
			: null,
)

export const billingInfoSelector = createSelector(
	activeCompanySubscriptionsSelector,
	(activeCompanySubscriptions) => {
		if (activeCompanySubscriptions) {
			const {
				addressLine1,
				addressLine2,
				city,
				companyName,
				email,
				firstName,
				lastName,
				state,
				zip,
			} = activeCompanySubscriptions

			return {
				addressLine1,
				addressLine2,
				city,
				companyName,
				email,
				firstName,
				lastName,
				state,
				zip,
			}
		}

		return null
	},
)

export const userRolesSelector = createSelector(
	activeCompanySelector,
	(activeCompany) =>
		activeCompany && activeCompany.roles ? activeCompany.roles : [],
)

export const userPermissionsSelector = createSelector(
	activeCompanySelector,
	(activeCompany) =>
		activeCompany && activeCompany.permissions ? activeCompany.permissions : [],
)

export const authorizedPermissionsSelector = (_, authorizedPermissions) =>
	authorizedPermissions ? [].concat(authorizedPermissions) : []

export const userAuthorizedPermissionsSelector = createSelector(
	userPermissionsSelector,
	authorizedPermissionsSelector,
	(userPermissions, authorizedPermissions) =>
		authorizedPermissions.filter((authorizedPermission) =>
			doesIncludePermission(userPermissions, authorizedPermission),
		),
)

export const updateUserRequestSelector = createSelector(
	[userSelector],
	(data) =>
		data !== null && typeof data.isUpdating === 'boolean'
			? data.isUpdating
			: null,
)

export const updateUserErrorSelector = createSelector([userSelector], (data) =>
	data !== null && typeof data.isUpdateError === 'boolean'
		? data.isUpdateError
		: null,
)
