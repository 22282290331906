import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './spinner.scss'
import useDelay from '@/hooks/useDelay'

const Spinner = (props) => {
	const { className, stroke, size, color, opacity, delayMs } = props
	const isVisible = useDelay(delayMs)

	const spinnerClassName = classNames({
		cmp__spinner: true,
		'cmp__spinner--hidden': !isVisible,
		[className]: className,
	})
	return (
		<div
			className={spinnerClassName}
			data-testid="cmp-spinner"
			style={{
				width: `${size}px`,
			}}
		>
			<svg
				className="cmp__spinner__svg"
				style={{
					width: `${size}px`,
				}}
				viewBox="25 25 50 50"
			>
				<circle
					style={{
						stroke: color,
						opacity,
					}}
					className="cmp__spinner__path"
					cx="50"
					cy="50"
					r="20"
					fill="none"
					strokeWidth={stroke}
					strokeMiterlimit="10"
				/>
			</svg>
		</div>
	)
}

Spinner.defaultProps = {
	size: 32,
	stroke: 5,
	opacity: 1,
	color: '#ffffff',
	delayMs: 0,
}

Spinner.propTypes = {
	size: PropTypes.number,
	color: PropTypes.string,
	stroke: PropTypes.number,
	opacity: PropTypes.number,
	className: PropTypes.string,
	delayMs: PropTypes.number,
}

export default Spinner
