import * as companyTagsActionTypes from './constants'

/**
 *
 */
function getInitialState() {
	return {
		isFetching: false,
		isError: false,
		isUpdating: false,
		isUpdateError: false,
		errorMessage: null,
		tags: [],
	}
}

const companyTagsReducer = (state = getInitialState(), action) => {
	switch (action.type) {
		case companyTagsActionTypes.GET_COMPANY_TAGS_REQUEST: {
			return {
				...state,
				isFetching: true,
				isError: false,
			}
		}
		case companyTagsActionTypes.CREATE_TAG_REQUEST:
		case companyTagsActionTypes.RENAME_TAG_REQUEST:
		case companyTagsActionTypes.DELETE_TAG_REQUEST: {
			return {
				...state,
				isUpdating: true,
				isUpdateError: false,
			}
		}
		case companyTagsActionTypes.GET_COMPANY_TAGS_SUCCESS: {
			return {
				...state,
				isFetching: false,
				isError: false,
				tags: action.payload,
			}
		}
		case companyTagsActionTypes.CREATE_TAG_SUCCESS: {
			const tag = { ...action.payload, deviceCount: 0 }
			return {
				...state,
				isUpdating: false,
				isUpdateError: false,
				tags: [...state.tags, tag],
			}
		}
		case companyTagsActionTypes.RENAME_TAG_SUCCESS: {
			const { id, name } = action.payload
			return {
				...state,
				isUpdating: false,
				isUpdateError: false,
				tags: state.tags.map((tag) => (tag.id === id ? { ...tag, name } : tag)),
			}
		}
		case companyTagsActionTypes.DELETE_TAG_SUCCESS: {
			const { id } = action.payload
			return {
				...state,
				isUpdating: false,
				isUpdateError: false,
				tags: state.tags.filter((tag) => tag.id !== id),
			}
		}
		case companyTagsActionTypes.GET_COMPANY_TAGS_ERROR: {
			return {
				...state,
				isFetching: false,
				isError: true,
			}
		}
		case companyTagsActionTypes.CREATE_TAG_ERROR:
		case companyTagsActionTypes.RENAME_TAG_ERROR:
		case companyTagsActionTypes.DELETE_TAG_ERROR: {
			const { error } = action.payload || {}
			const errorMessage = error && error.detail ? error.detail : null
			return {
				...state,
				isUpdating: false,
				isUpdateError: true,
				errorMessage,
			}
		}
		case companyTagsActionTypes.RESET_TAG_ERRORS: {
			return {
				...state,
				isError: false,
				isUpdateError: false,
				errorMessage: null,
			}
		}
		default: {
			return state
		}
	}
}

export default companyTagsReducer
