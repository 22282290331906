import { createAction } from '@/utils/actions'
import * as inviteUserActionTypes from './constants'

export const inviteUserRequest = createAction(
	inviteUserActionTypes.INVITE_USER_REQUEST,
)
export const inviteUserReset = createAction(
	inviteUserActionTypes.INVITE_USER_RESET,
)
export const inviteUserSuccess = createAction(
	inviteUserActionTypes.INVITE_USER_SUCCESS,
)
export const inviteUserError = createAction(
	inviteUserActionTypes.INVITE_USER_ERROR,
)
export const inviteUserInvalidEmail = createAction(
	inviteUserActionTypes.INVITE_USER_INVALID_EMAIL,
)
