import { createAction } from '@/utils/actions'
import * as companyTagsActionTypes from './constants'

export const getCompanyTagsRequest = createAction(
	companyTagsActionTypes.GET_COMPANY_TAGS_REQUEST,
)
export const getCompanyTagsSuccess = createAction(
	companyTagsActionTypes.GET_COMPANY_TAGS_SUCCESS,
)
export const getCompanyTagsError = createAction(
	companyTagsActionTypes.GET_COMPANY_TAGS_ERROR,
)

export const createTagRequest = createAction(
	companyTagsActionTypes.CREATE_TAG_REQUEST,
)
export const createTagSuccess = createAction(
	companyTagsActionTypes.CREATE_TAG_SUCCESS,
)
export const createTagError = createAction(
	companyTagsActionTypes.CREATE_TAG_ERROR,
)

export const renameTagRequest = createAction(
	companyTagsActionTypes.RENAME_TAG_REQUEST,
)
export const renameTagSuccess = createAction(
	companyTagsActionTypes.RENAME_TAG_SUCCESS,
)
export const renameTagError = createAction(
	companyTagsActionTypes.RENAME_TAG_ERROR,
)

export const deleteTagRequest = createAction(
	companyTagsActionTypes.DELETE_TAG_REQUEST,
)
export const deleteTagSuccess = createAction(
	companyTagsActionTypes.DELETE_TAG_SUCCESS,
)
export const deleteTagError = createAction(
	companyTagsActionTypes.DELETE_TAG_ERROR,
)

export const resetTagErrors = createAction(
	companyTagsActionTypes.RESET_TAG_ERRORS,
)
