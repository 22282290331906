import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import featureFlagsService from '@/services/feature-flags/feature.flags.service'
import FLAG_DEFAULTS from '@/services/feature-flags/flags'
import FeatureFlagsContext from './feature.flags.context'

// NB: LaunchDarkly provides its own provider implementation, but theirs does
// not support reusing an existing client instance.
// (https://docs.launchdarkly.com/docs/react-sdk-reference)

const FeatureFlagsProvider = ({ children }) => {
	const [flags, setFlags] = useState()

	useEffect(() => {
		featureFlagsService.addListener(setFlags)

		return () => {
			featureFlagsService.removeListener(setFlags)
		}
	}, [])

	return (
		<FeatureFlagsContext.Provider value={{ ...FLAG_DEFAULTS, ...flags }}>
			{children}
		</FeatureFlagsContext.Provider>
	)
}

FeatureFlagsProvider.propTypes = {
	children: PropTypes.node,
}

export default FeatureFlagsProvider
