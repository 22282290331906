import { takeLatest, put, call } from 'redux-saga/effects'
import * as modalActions from '@/store/modal/actions'
import { safeFetch } from '@/services/fetch-api/fetch.api'
import * as runtimeReportActionTypes from './constants'
import * as runtimeReportActions from './actions'

/**
 * @param action
 */
export function* getRuntimeReportChartData(action) {
	const { thermostatId, startDate, endDate } = action.payload

	const runtimeReportChartData = yield call(
		safeFetch,
		'getRuntimeReportChartData',
		{
			thermostatId,
			startDate,
			endDate,
		},
	)

	if (runtimeReportChartData.response.ok) {
		const payload = runtimeReportChartData.data
		yield put(runtimeReportActions.getRuntimeReportChartDataSuccess(payload))
	} else {
		yield put(runtimeReportActions.getRuntimeReportChartDataError())
		yield put(
			modalActions.modalShow({
				title: 'Something went wrong',
				message:
					'We were unable to generate the report you requested. Please try again.',
			}),
		)
	}
}

/**
 * @param action
 */
export function* getRuntimeReportExport(action) {
	const { thermostatId, startDate, endDate } = action.payload

	const runtimeReportExport = yield call(safeFetch, 'getRuntimeReportExport', {
		thermostatId,
		startDate,
		endDate,
	})

	if (runtimeReportExport.response.ok) {
		yield put(runtimeReportActions.getRuntimeReportExportSuccess())
	} else {
		yield put(runtimeReportActions.getRuntimeReportExportError())
		yield put(
			modalActions.modalShow({
				title: 'Something went wrong',
				message:
					'We were unable to export the data you requested. Please try again.',
			}),
		)
	}
}

/**
 *
 */
export function* runtimeReportRequestWatcher() {
	yield takeLatest(
		runtimeReportActionTypes.GET_RUNTIME_REPORT_CHART_DATA_REQUEST,
		getRuntimeReportChartData,
	)
	yield takeLatest(
		runtimeReportActionTypes.GET_RUNTIME_REPORT_EXPORT_REQUEST,
		getRuntimeReportExport,
	)
}
