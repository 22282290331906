import * as addBuildingActionTypes from './constants'

/**
 *
 */
function getInitialState() {
	return {
		isAdding: false,
		isAddError: false,
		isValidationError: false,
		validationErrorMessage: '',
	}
}

const addBuildingReducer = (state = getInitialState(), action) => {
	switch (action.type) {
		case addBuildingActionTypes.ADD_BUILDING_REQUEST:
			return {
				...state,
				isAdding: true,
				isValidationError: false,
				validationErrorMessage: '',
			}
		case addBuildingActionTypes.ADD_BUILDING_SUCCESS:
			return getInitialState()
		case addBuildingActionTypes.ADD_BUILDING_RESET:
			return getInitialState()
		case addBuildingActionTypes.ADD_BUILDING_ERROR: {
			return {
				...state,
				isAdding: false,
				isAddError: true,
			}
		}
		case addBuildingActionTypes.ADD_BUILDING_VALIDATION_ERROR: {
			const { message } = action.payload
			return {
				...state,
				isAdding: false,
				isValidationError: true,
				validationErrorMessage: message,
			}
		}
		default:
			return state
	}
}

export default addBuildingReducer
