import ReactGA from 'react-ga'
import config from 'config'
import routingService from './routing.service'

const getActiveCompanyId = () => {
	const match = routingService.getMatch()
	return match?.params?.companyId
}

export class AnalyticsService {
	currentPath = null

	constructor() {
		this.init = this.init.bind(this)
		this.setUser = this.setUser.bind(this)
		this.trackEvent = this.trackEvent.bind(this)
		this.trackPage = this.trackPage.bind(this)
		this.subscribeToLocationChanges = this.subscribeToLocationChanges.bind(this)
	}

	init() {
		this.analyticsTrackingId = config.GOOGLE_ANALYTICS_TRACKING_ID
		if (this.analyticsTrackingId) {
			ReactGA.initialize(this.analyticsTrackingId)
		}
	}

	setUser(user) {
		const { id: userId } = user

		if (this.analyticsTrackingId) {
			ReactGA.set({ userId })
		}

		window.amplitude?.getInstance().setUserId(userId)
	}

	trackPage(path) {
		// This handles logging the thermostat list page multiple times
		// because of pagination.
		if (this.currentPath !== path) {
			this.currentPath = path

			if (this.analyticsTrackingId) {
				ReactGA.pageview(this.currentPath)
			}

			this.trackEvent('Navigate', { path })
		}
	}

	trackEvent(event, eventProperties) {
		const companyId = getActiveCompanyId()

		if (companyId) {
			window.amplitude
				?.getInstance()
				.logEventWithGroups(event, eventProperties, { companyId })
		} else {
			window.amplitude?.getInstance().logEvent(event, eventProperties)
		}
	}

	subscribeToLocationChanges(history) {
		history.listen((location) => {
			this.trackPage(location.pathname + location.search)
		})
	}
}

export default new AnalyticsService()
