import { createAction } from '@/utils/actions'
import * as createSubscriptionActionTypes from './constants'

export const createSubscriptionRequest = createAction(
	createSubscriptionActionTypes.CREATE_SUBSCRIPTION_REQUEST,
)
export const createSubscriptionReset = createAction(
	createSubscriptionActionTypes.CREATE_SUBSCRIPTION_RESET,
)
export const createSubscriptionSuccess = createAction(
	createSubscriptionActionTypes.CREATE_SUBSCRIPTION_SUCCESS,
)
export const createSubscriptionError = createAction(
	createSubscriptionActionTypes.CREATE_SUBSCRIPTION_ERROR,
)
