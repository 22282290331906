export const GET_BUILDING_REQUEST = 'GET_BUILDING_REQUEST'
export const GET_BUILDING_SUCCESS = 'GET_BUILDING_SUCCESS'
export const GET_BUILDING_ERROR = 'GET_BUILDING_ERROR'
export const BUILDING_NOT_FOUND_ERROR = 'BUILDING_NOT_FOUND_ERROR'
export const RESET_BUILDING = 'RESET_BUILDING'
export const RESET_BUILDING_ERROR = 'RESET_BUILDING_ERROR'
export const UPDATE_BUILDING_REQUEST = 'UPDATE_BUILDING_REQUEST'
export const UPDATE_BUILDING_SUCCESS = 'UPDATE_BUILDING_SUCCESS'
export const UPDATE_BUILDING_ERROR = 'UPDATE_BUILDING_ERROR'
export const UPDATE_BUILDING_VALIDATION_ERROR =
	'UPDATE_BUILDING_VALIDATION_ERROR'
