import { UNOCCUPIED_CLIMATE_REF } from './constants'

/**
 * Add an activity to a schedule
 *
 * @param  {object} activity The activity object to be added
 * @param  {Array} schedule  Raw schedule 2D array
 * @returns {Array}           Updated schedule 2D array with activity added
 */
export const addActivityToSchedule = (activity, schedule) => {
	const { program, startTime, endTime, checkedDays } = activity
	const newEndTime = endTime > startTime ? endTime - 1 : startTime
	return schedule.map((day, index) => {
		const immutableDay = [...day]
		if (checkedDays.includes(index)) {
			for (let i = startTime; i <= newEndTime; i += 1) {
				immutableDay[i] = program
			}
		}
		return immutableDay
	})
}

/**
 * Remove an activity from a schedule
 *
 * @param  {object} activity      The activity object to be removed
 * @param  {Array}  schedule      Raw schedule 2D array
 * @param  {string} emptyProgram  The program name to fill in where the activity was removed
 * @returns {Array}                Updated schedule 2D array with activity removed
 */
export const removeActivityFromSchedule = (
	activity,
	schedule,
	emptyProgram = UNOCCUPIED_CLIMATE_REF,
) => {
	const removeActivity = {
		...activity,
		program: emptyProgram,
	}
	return addActivityToSchedule(removeActivity, schedule)
}

export const buildingToOption = (building) => {
	const { id, fullAddress, name, readOnly } = building
	const label = fullAddress ? `${name}, ${fullAddress}` : name
	return {
		address: fullAddress || '',
		label,
		name,
		value: id,
		readOnly,
	}
}

export const buildingResourceToOption = (building) => {
	const { id, fullAddress, name, readOnly, deviceCount } = building

	return {
		id,
		detail: fullAddress || '',
		label: fullAddress ? `${name}, ${fullAddress}` : name,
		name,
		value: id,
		readOnly,
		type: 'building',
		deviceCount,
	}
}

export const deviceResourcesWithBuildingsToOption = (deviceWithBuildings) => {
	const {
		buildingId,
		id,
		buildingName,
		name,
		isTenantMode,
	} = deviceWithBuildings

	// The thermostatName could be an empty string so return the id.
	const label = [name || '', id, buildingName].join(' ')
	return {
		id,
		detail: `${name ? `${id}, ` : ''}${buildingName}`,
		value: id,
		label,
		buildingId,
		buildingName,
		type: 'device',
		name: name || id,
		isTenantMode,
	}
}

export const thermostatsWithBuildingsToOption = (thermostatWithBuildings) => {
	const {
		buildingId,
		thermostatId,
		buildingName,
		thermostatName,
		type,
		isTenantMode,
	} = thermostatWithBuildings

	// The label is used to match search terms against but not shown directly to the user
	const label = `${buildingName} / ${thermostatName || thermostatId}`
	return {
		id: thermostatId,
		value: thermostatId,
		label,
		buildingId,
		type,
		isTenantMode,
	}
}

export const flattenAllBuildingDevices = (buildings) =>
	buildings.flatMap(({ name: buildingName, id: buildingId, thermostats }) =>
		thermostats.map(({ name, id }) => ({
			name,
			id,
			buildingName,
			buildingId,
		})),
	)

export const buildingToDeviceResources = (selectedResources, buildings) => {
	const buildingIds = new Set(selectedResources.map((resource) => resource.id))
	const selectedBuildings = buildings.filter((building) =>
		buildingIds.has(building.id),
	)

	return flattenAllBuildingDevices(selectedBuildings)
}

export const deviceToBuildingResources = (selectedResources, buildings) => {
	const buildingIds = new Set(
		selectedResources.map((resource) => resource.buildingId),
	)
	const selectedBuildings = buildings.filter((building) =>
		buildingIds.has(building.id),
	)

	return selectedBuildings
}
