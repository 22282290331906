import { call, takeEvery } from 'redux-saga/effects'
import analyticsService from '@/services/analytics.service'

export function* analytics(action) {
	if (action.meta?.event) {
		const { event, eventProperties } = action.meta
		yield call(analyticsService.trackEvent, event, eventProperties)
	}
}

export function* analyticsWatcher() {
	yield takeEvery('*', analytics)
}
