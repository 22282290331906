import { createSelector } from 'reselect'
import moment from 'moment'
import { groupBy } from 'lodash'
import { parseAlert } from '@ecobee/smartbuildings-library-common/thermostatHelpers'
import {
	CELSIUS_UNIT,
	FAHRENHEIT_UNIT,
	SENSOR_TYPE_THERMOSTAT,
	SENSOR_TYPE_WIRELESS,
	THERMOSTAT_TYPE_RESIDENTIAL,
} from '@ecobee/smartbuildings-library-common/constants'
import { THERMOSTAT_MODEL_MAP } from '@ecobee/smartbuildings-library-common/maps'
import { convertScheduleToObjects } from '@/utils/schedule.helpers'
import {
	getSensorType,
	getSensorModel,
	getBuiltInComfortSettingsForThermostatType,
	getEmptyComfortSettingForThermostatType,
	getDefaultComfortSettingForThermostatType,
} from '@/utils/thermostat'
import {
	DEMAND_RESPONSE_EVENT_TYPE,
	UNACKNOWLEDGED,
	VACATION_EVENT_TYPE,
	HIGH_HUMIDITY_THRESHOLD_MAX,
	LOW_HUMIDITY_THRESHOLD_MIN,
} from '@/utils/constants'
import { sortToBeginning } from '@/utils/misc'
import {
	formatMacAddress,
	getThermostatOverview,
	getEvent,
	getThermostatOnlineStatusData,
	getSensorParticipationStatus,
	serializeEventBasedOnType,
	hasDisconnectedValue,
} from './selectors.helpers'

const EVENT_TYPES = new Set([VACATION_EVENT_TYPE, DEMAND_RESPONSE_EVENT_TYPE])
const THERMOSTAT_MODEL_UNKNOWN = 'Unknown'
const SECONDS_PER_MINUTE = 60
const DEFAULT_START_DELAY_MINUTES = 5

export const thermostatSelector = (state) =>
	state.thermostat ? state.thermostat : null

export const thermostatDataSelector = createSelector(
	thermostatSelector,
	(thermostat) =>
		thermostat !== null && thermostat.data ? thermostat.data : null,
)

export const thermostatIsFetchingSelector = createSelector(
	thermostatSelector,
	(thermostat) =>
		thermostat !== null && typeof thermostat.isFetching === 'boolean'
			? thermostat.isFetching
			: null,
)

export const thermostatIsUpdatingSelector = createSelector(
	thermostatSelector,
	(thermostat) =>
		thermostat !== null &&
		typeof thermostat.isUpdating === 'boolean' &&
		typeof thermostat.isRefreshing === 'boolean'
			? thermostat.isUpdating || thermostat.isRefreshing
			: null,
)

export const thermostatIsErrorSelector = createSelector(
	[thermostatSelector],
	(thermostat) =>
		thermostat !== null && typeof thermostat.isError === 'boolean'
			? thermostat.isError
			: null,
)

export const thermostatIsNotFoundSelector = createSelector(
	[thermostatSelector],
	(thermostat) =>
		thermostat !== null && typeof thermostat.isNotFound === 'boolean'
			? thermostat.isNotFound
			: null,
)

export const thermostatIsUpdateErrorSelector = createSelector(
	[thermostatSelector],
	(thermostat) =>
		thermostat !== null && typeof thermostat.isUpdateError === 'boolean'
			? thermostat.isUpdateError
			: null,
)

export const thermostatUpdateErrorModalSelector = createSelector(
	[thermostatSelector],
	(thermostat) =>
		thermostat !== null && typeof thermostat.updateErrorModal === 'boolean'
			? thermostat.updateErrorModal
			: null,
)

export const thermostatIdSelector = createSelector(
	thermostatDataSelector,
	(thermostat) => {
		if (!thermostat) {
			return thermostat
		}
		return thermostat.identifier
	},
)

export const thermostatNameSelector = createSelector(
	thermostatDataSelector,
	(thermostat) => {
		if (!thermostat) {
			return thermostat
		}
		return thermostat.sbMetadata?.name || thermostat.identifier
	},
)

export const thermostatTypeSelector = createSelector(
	thermostatDataSelector,
	(thermostat) => {
		if (!thermostat) {
			return thermostat
		}
		if (
			!thermostat.modelNumber ||
			!THERMOSTAT_MODEL_MAP[thermostat.modelNumber]
		) {
			return THERMOSTAT_TYPE_RESIDENTIAL
		}
		return THERMOSTAT_MODEL_MAP[thermostat.modelNumber].type
	},
)

export const thermostatRemoteSensorTypeSelector = createSelector(
	thermostatDataSelector,
	(thermostat) => {
		const thermostatModel = THERMOSTAT_MODEL_MAP[thermostat?.modelNumber]
		return thermostatModel?.remoteSensorType ?? SENSOR_TYPE_WIRELESS
	},
)

export const thermostatBuiltInComfortSettingsSelector = createSelector(
	thermostatTypeSelector,
	(thermostatType) =>
		getBuiltInComfortSettingsForThermostatType(thermostatType),
)

export const thermostatEmptyComfortSettingSelector = createSelector(
	thermostatTypeSelector,
	(thermostatType) => getEmptyComfortSettingForThermostatType(thermostatType),
)

export const thermostatDefaultComfortSettingSelector = createSelector(
	thermostatTypeSelector,
	(thermostatType) => getDefaultComfortSettingForThermostatType(thermostatType),
)

export const thermostatIsTenantModeSelector = createSelector(
	thermostatDataSelector,
	(thermostat) => {
		if (!thermostat) {
			return thermostat
		}
		return thermostat.sbMetadata?.isTenantMode || false
	},
)

export const thermostatOverviewSelector = createSelector(
	thermostatDataSelector,
	(thermostat) => {
		if (!thermostat) {
			return thermostat
		}
		return getThermostatOverview(thermostat)
	},
)

export const thermostatPreferencesSelector = createSelector(
	thermostatDataSelector,
	thermostatIsTenantModeSelector,
	(thermostat, isTenantMode) => {
		if (!thermostat) {
			return thermostat
		}

		if (isTenantMode) {
			return null
		}

		return {
			backlightOffTime: thermostat.settings.backlightOffTime,
			backlightOnIntensity: thermostat.settings.backlightOnIntensity,
			backlightSleepIntensity: thermostat.settings.backlightSleepIntensity,
			coolMaxTemp: thermostat.settings.coolMaxTemp,
			coolMinTemp: thermostat.settings.coolMinTemp,
			coolRangeHigh: thermostat.settings.coolRangeHigh,
			coolRangeLow: thermostat.settings.coolRangeLow,
			disablePreCooling: thermostat.settings.disablePreCooling,
			disablePreHeating: thermostat.settings.disablePreHeating,
			fanMinOnTime: thermostat.settings.fanMinOnTime,
			heatMaxTemp: thermostat.settings.heatMaxTemp,
			heatMinTemp: thermostat.settings.heatMinTemp,
			heatRangeHigh: thermostat.settings.heatRangeHigh,
			heatRangeLow: thermostat.settings.heatRangeLow,
			holdAction: thermostat.settings.holdAction,
			randomStartDelayCool: thermostat.settings.randomStartDelayCool,
			randomStartDelayHeat: thermostat.settings.randomStartDelayHeat,
			useCelsius: thermostat.settings.useCelsius,
			useTimeFormat12: thermostat.settings.useTimeFormat12,
			heatCoolMinDelta: thermostat.settings.heatCoolMinDelta,
		}
	},
)

export const thermostatDeviceInfoSelector = createSelector(
	thermostatDataSelector,
	(thermostat) => {
		if (!thermostat) {
			return thermostat
		}

		const version = thermostat.version
			? thermostat.version.thermostatFirmwareVersion
			: ''

		const model = THERMOSTAT_MODEL_MAP[thermostat.modelNumber]
			? THERMOSTAT_MODEL_MAP[thermostat.modelNumber].name
			: THERMOSTAT_MODEL_UNKNOWN

		const { neverConnected } = getThermostatOnlineStatusData(thermostat)

		const macAddressValue = thermostat.sbMetadata.macAddress

		return {
			model,
			modelNumber: thermostat.modelNumber,
			serial: thermostat.identifier,
			version,
			macAddress:
				macAddressValue && !neverConnected
					? formatMacAddress(macAddressValue)
					: macAddressValue,
			neverConnected,
		}
	},
)

export const thermostatPasscodeSelector = createSelector(
	thermostatDataSelector,
	thermostatIsTenantModeSelector,
	(thermostat, isTenantMode) => {
		if (!thermostat) {
			return thermostat
		}

		if (isTenantMode) {
			return null
		}

		return {
			userAccessCode: thermostat.securitySettings.userAccessCode,
			allUserAccess: thermostat.securitySettings.allUserAccess,
			programAccess: thermostat.securitySettings.programAccess,
			detailsAccess: thermostat.securitySettings.detailsAccess,
			quickSaveAccess: thermostat.securitySettings.quickSaveAccess,
			vacationAccess: thermostat.securitySettings.vacationAccess,
			systemModeAccess: thermostat.securitySettings.systemModeAccess,
		}
	},
)

export const thermostatProgramRawSelector = createSelector(
	thermostatDataSelector,
	(thermostat) => {
		if (!thermostat || !thermostat.program || !thermostat.program.climates) {
			return []
		}

		return thermostat.program.climates
	},
)

export const thermostatScheduleRawSelector = createSelector(
	thermostatDataSelector,
	(thermostat) => {
		if (!thermostat || !thermostat.program) {
			return []
		}
		return thermostat.program.schedule
	},
)

export const thermostatScheduledClimateRefSelector = createSelector(
	thermostatScheduleRawSelector,
	(thermostatScheduleRaw) => {
		const uniqueClimateRefs = new Set(thermostatScheduleRaw.flat())
		return [...uniqueClimateRefs]
	},
)

export const thermostatProgramSelector = createSelector(
	thermostatProgramRawSelector,
	thermostatEmptyComfortSettingSelector,
	(thermostatProgramsRaw, emptyComfortSetting) => {
		const climateSorter = sortToBeginning(
			(climate) => climate.value === emptyComfortSetting.climateRef,
		)

		return thermostatProgramsRaw
			.map((climate) => ({
				title: climate.name,
				value: climate.climateRef,
				coolTemp: climate.coolTemp,
				heatTemp: climate.heatTemp,
				isOccupied: climate.isOccupied,
			}))
			.reduce(climateSorter, [])
	},
)

export const thermostatScheduleSelector = createSelector(
	thermostatProgramRawSelector,
	thermostatScheduleRawSelector,
	thermostatTypeSelector,
	(thermostatProgramsRaw, thermostatScheduleRaw, thermostatType) => {
		const climateMap = {}
		const colorMap = {}
		thermostatProgramsRaw.forEach((climate, index) => {
			climateMap[climate.climateRef] = climate.name
			colorMap[climate.climateRef] = index - 2
		})
		return thermostatScheduleRaw.map((day, dayIndex) =>
			convertScheduleToObjects(
				day,
				thermostatType,
				dayIndex,
				climateMap,
				colorMap,
			),
		)
	},
)

const convertAlertDateToMoment = (alert) => {
	const { date } = alert

	return {
		...alert,
		date: moment(date),
	}
}

export const thermostatAlertsSelector = createSelector(
	thermostatDataSelector,
	(thermostat) => {
		if (!thermostat || !thermostat.alerts) {
			return []
		}

		const thermostatTemperatureUnit =
			thermostat && thermostat.settings && thermostat.settings.useCelsius
				? CELSIUS_UNIT
				: FAHRENHEIT_UNIT

		return thermostat.alerts
			.filter(
				(alert) => alert.showWeb && alert.acknowledgement === UNACKNOWLEDGED,
			)
			.map((alert) => parseAlert(alert, thermostatTemperatureUnit))
			.map(convertAlertDateToMoment)
	},
)

export const thermostatEquipmentSettingsSelector = createSelector(
	thermostatDataSelector,
	(thermostat) => {
		if (!thermostat) {
			return {}
		}
		const {
			settings: {
				hasHumidifier,
				hasDehumidifier,
				hasUVFilter,
				ventilatorType,
				hasForcedAir,
			},
		} = thermostat

		return {
			hasDehumidifier,
			hasHumidifier,
			hasUVFilter,
			hasForcedAir,
			ventilatorType,
		}
	},
)

export const thermostatAlertSettingsSelector = createSelector(
	thermostatDataSelector,
	thermostatEquipmentSettingsSelector,
	(thermostat, equipmentSettings) => {
		if (!thermostat) {
			return thermostat
		}
		const {
			settings: { disableAlertsOnIdt },
			notificationSettings: { limit, equipment },
		} = thermostat

		const highTemp = limit.find(({ type }) => type === 'highTemp')
		const lowTemp = limit.find(({ type }) => type === 'lowTemp')
		const highHumidity = limit.find(({ type }) => type === 'highHumidity')
		const lowHumidity = limit.find(({ type }) => type === 'lowHumidity')

		const reminderSettings = {
			hvacMaintenanceReminder: equipment.find(({ type }) => type === 'hvac'),
		}
		if (equipmentSettings.hasForcedAir) {
			reminderSettings.furnaceFilterMaintenanceReminder = equipment.find(
				({ type }) => type === 'furnaceFilter',
			)
		} else {
			reminderSettings.airFilterMaintenanceReminder = equipment.find(
				({ type }) => type === 'airFilter',
			)
		}
		if (equipmentSettings.hasHumidifier) {
			reminderSettings.humidifierFilterMaintenanceReminder = equipment.find(
				({ type }) => type === 'humidifierFilter',
			)
		}
		if (equipmentSettings.hasDehumidifier) {
			reminderSettings.dehumidifierFilterMaintenanceReminder = equipment.find(
				({ type }) => type === 'dehumidifierFilter',
			)
		}
		if (equipmentSettings.hasUVFilter) {
			reminderSettings.uvLampMaintenanceReminder = equipment.find(
				({ type }) => type === 'uvLamp',
			)
		}
		if (equipmentSettings.ventilatorType !== 'none') {
			reminderSettings.ventilatorMaintenanceReminder = equipment.find(
				({ type }) => type === 'ventilator',
			)
		}
		return {
			lowTemperatureThreshold: {
				value: lowTemp.limit,
				enabled: lowTemp.enabled,
			},
			highTemperatureThreshold: {
				value: highTemp.limit,
				enabled: highTemp.enabled,
			},
			lowHumidityThreshold: {
				// When POSTing updates to objects of type lowHumidity and highHumidity,
				// if enabled is set to false, then the limit value will be automatically set to
				// -1 on the server for lowHumidity and highHumidity
				value:
					lowHumidity.limit === -1
						? LOW_HUMIDITY_THRESHOLD_MIN
						: lowHumidity.limit,
				enabled: lowHumidity.enabled,
			},
			highHumidityThreshold: {
				value:
					highHumidity.limit === -1
						? HIGH_HUMIDITY_THRESHOLD_MAX
						: highHumidity.limit,
				enabled: highHumidity.enabled,
			},
			...reminderSettings,
			displaySettings: {
				displayAlertsOnDevice: !disableAlertsOnIdt,
			},
		}
	},
)

export const thermostatEventsRawSelector = createSelector(
	thermostatDataSelector,
	(thermostat) => {
		if (!thermostat || !thermostat.events) {
			return []
		}
		return thermostat.events
	},
)

export const thermostatEventsGroupedByYearSelector = createSelector(
	thermostatEventsRawSelector,
	(rawEvents) => {
		const events = rawEvents
			.filter((event) => EVENT_TYPES.has(event.type))
			.map(serializeEventBasedOnType)
			.sort((eventA, eventB) => {
				const dateA = `${eventA.startDate}-${eventA.startTime}-${eventA.endDate}-${eventA.endTime}`
				const dateB = `${eventB.startDate}-${eventB.startTime}-${eventB.endDate}-${eventB.endTime}`
				// Sort date string
				if (dateA < dateB) {
					return -1
				}
				if (dateA > dateB) {
					return 1
				}
				// Sort name
				if (eventA.name < eventB.name) {
					return -1
				}
				if (eventA.name > eventB.name) {
					return 1
				}
				// Must be equal
				return 0
			})
		return groupBy(events, (event) => event.startDate.slice(0, 4))
	},
)

export const thermostatEventsSelector = createSelector(
	thermostatEventsRawSelector,
	(rawEvents) =>
		rawEvents.filter((event) => EVENT_TYPES.has(event.type)).map(getEvent),
)

export const thermostatOnlineStatusSelector = createSelector(
	thermostatDataSelector,
	(thermostat) => {
		if (!thermostat || !thermostat.runtime) {
			return {}
		}

		return getThermostatOnlineStatusData(thermostat)
	},
)

export const thermostatDevicesSelector = createSelector(
	thermostatDataSelector,
	(thermostat) => {
		if (!thermostat || !thermostat.devices) {
			return []
		}

		return thermostat.devices
	},
)

export const thermostatEnergySavingSettingsSelector = createSelector(
	thermostatDataSelector,
	(thermostat) => {
		if (!thermostat) {
			return thermostat
		}
		const {
			settings: {
				disablePreHeating,
				disablePreCooling,
				randomStartDelayCool,
				randomStartDelayHeat,
			},
		} = thermostat

		const delayCoolEnabled = randomStartDelayCool > 0
		const delayHeatEnabled = randomStartDelayHeat > 0

		const getStartDelayInMinutes = (startDelay) =>
			Math.round(startDelay / SECONDS_PER_MINUTE)

		return {
			disablePreHeating,
			disablePreCooling,
			randomStartDelayCool: {
				enabled: delayCoolEnabled,
				value: delayCoolEnabled
					? getStartDelayInMinutes(randomStartDelayCool)
					: DEFAULT_START_DELAY_MINUTES,
			},
			randomStartDelayHeat: {
				enabled: delayHeatEnabled,
				value: delayHeatEnabled
					? getStartDelayInMinutes(randomStartDelayHeat)
					: DEFAULT_START_DELAY_MINUTES,
			},
		}
	},
)

export const thermostatSensorsSelector = createSelector(
	thermostatDataSelector,
	thermostatRemoteSensorTypeSelector,
	thermostatDevicesSelector,
	(thermostat, thermostatRemoteSensorType, thermostatDevices) => {
		if (!thermostat || !thermostat.remoteSensors) {
			return []
		}

		const { remoteSensors } = thermostat
		const thermostatSensorSorter = sortToBeginning(
			(sensor) => sensor.type === SENSOR_TYPE_THERMOSTAT,
		)

		return remoteSensors
			.filter((sensor) => sensor.name && sensor.name.length > 0)
			.map((sensor) => {
				const {
					code,
					id,
					name,
					type: rawType,
					capability: capabilities,
				} = sensor

				const isDisconnected = capabilities.every(hasDisconnectedValue)
				const participationStatus = getSensorParticipationStatus({
					isDisconnected,
					sensor,
					thermostatRemoteSensorType,
					thermostatDevices,
				})

				return {
					code,
					id,
					name,
					type: getSensorType(rawType),
					model: getSensorModel(id),
					capabilities: capabilities.filter(
						(capability) => !hasDisconnectedValue(capability),
					),
					participationStatus,
					isDisconnected,
				}
			})
			.reduce(thermostatSensorSorter, [])
	},
)
