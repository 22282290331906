import produce from 'immer'
import * as runtimeReportActionTypes from './constants'

export const initialState = {
	fileExport: {
		isFetching: false,
		isError: false,
	},
	chartData: {
		isFetching: false,
		isError: false,
		data: null,
		appliedSensors: [],
	},
}

const runtimeReportsReducer = (state = initialState, action) => {
	switch (action.type) {
		case runtimeReportActionTypes.GET_RUNTIME_REPORT_CHART_DATA_REQUEST: {
			return produce(state, ({ chartData }) => {
				chartData.isFetching = true
				chartData.isError = false
			})
		}
		case runtimeReportActionTypes.GET_RUNTIME_REPORT_CHART_DATA_SUCCESS: {
			return produce(state, ({ chartData }) => {
				chartData.isFetching = false
				chartData.isError = false
				chartData.data = action.payload
			})
		}
		case runtimeReportActionTypes.GET_RUNTIME_REPORT_CHART_DATA_ERROR: {
			return produce(state, ({ chartData }) => {
				chartData.isFetching = false
				chartData.isError = true
			})
		}
		case runtimeReportActionTypes.UPDATE_APPLIED_SENSORS: {
			return produce(state, ({ chartData }) => {
				chartData.appliedSensors = action.payload
			})
		}
		case runtimeReportActionTypes.GET_RUNTIME_REPORT_EXPORT_REQUEST:
			return produce(state, ({ fileExport }) => {
				fileExport.isFetching = true
				fileExport.isError = false
			})
		case runtimeReportActionTypes.GET_RUNTIME_REPORT_EXPORT_SUCCESS: {
			return produce(state, ({ fileExport }) => {
				fileExport.isFetching = false
				fileExport.isError = false
			})
		}
		case runtimeReportActionTypes.GET_RUNTIME_REPORT_EXPORT_ERROR:
			return produce(state, ({ fileExport }) => {
				fileExport.isFetching = false
				fileExport.isError = true
			})
		default:
			return state
	}
}

export default runtimeReportsReducer
