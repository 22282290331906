import { takeLatest, put, call, select } from 'redux-saga/effects'
import * as buildingListActionTypes from '@/store/building-list/constants'
import * as toastActionTypes from '@/store/toast/constants'
import * as modalActionTypes from '@/store/modal/constants'
import { validateApiResponse } from '@/utils/validators'
import { safeFetch } from '@/services/fetch-api/fetch.api'
import { allBuildingsSelector } from '@/store/building-list/selectors'
import { activeCompanyIdSelector } from '@/store/user/selectors'
import { geocodeBingMapsAddress } from '@/utils/bing.maps.utils'
import RemoteErrorService from '@/services/remote.error.service'
import { buildingIdSelector } from './selectors'
import * as buildingActions from './actions'
import * as buildingActionTypes from './constants'

/**
 * Find the array index of a building based on building ID
 *
 * @param  {string} id           The building id to find
 * @param  {Array}  buildingList An array of building objects
 * @returns {number}              Array index of matching building
 */
const findBuildingIndex = (id, buildingList) =>
	buildingList.findIndex((building) => id === building.id)

/**
 * @param action
 */
export function* getBuilding(action) {
	const { id } = action.payload
	const accountReference = yield select(activeCompanyIdSelector)
	const buildingList = yield select(allBuildingsSelector)
	const idMatchIndex = findBuildingIndex(id, buildingList)

	// If we found the building in the local store use that
	if (idMatchIndex !== -1) {
		yield put({
			type: buildingActionTypes.GET_BUILDING_SUCCESS,
			payload: {
				building: { ...buildingList[idMatchIndex] },
			},
		})
		/* istanbul ignore next */
		return buildingList[idMatchIndex]
		// Otherwise fetch the building from the API
	}

	const fetchParams = {
		accountReference,
		id,
	}
	const building = yield call(safeFetch, 'getBuildingsList', fetchParams)

	if (validateApiResponse(building) && building.data) {
		yield put({
			type: buildingActionTypes.GET_BUILDING_SUCCESS,
			payload: {
				building: building.data,
			},
		})
		/* istanbul ignore next */
		return building.data
	}
	if (building.response.status === 404) {
		yield put(buildingActions.buildingNotFoundError())
	} else {
		yield put(buildingActions.getBuildingError())
	}

	/* istanbul ignore next */
	return null
}

/**
 * @param action
 */
export function* updateBuilding(action) {
	const basePayload = {
		id: yield select(buildingIdSelector),
		accountReference: yield select(activeCompanyIdSelector),
	}

	let payload = {}

	if (action.payload.name) {
		payload.name = action.payload.name.trim()
	}

	if (action.payload.energySettings) {
		payload.energySettings = action.payload.energySettings
	}

	if (action.payload.address) {
		const locationData = yield call(
			geocodeBingMapsAddress,
			action.payload.address,
		)
		payload = { ...payload, ...locationData }
	}

	const apiResponse = yield call(safeFetch, 'updateBuilding', {
		...basePayload,
		...payload,
	})
	const { data, response } = apiResponse

	if (response.ok) {
		yield put({
			type: buildingActionTypes.UPDATE_BUILDING_SUCCESS,
			payload,
			meta: {
				event: 'BuildingUpdateSuccess',
				eventProperties: {
					buildingId: basePayload.id,
				},
			},
		})
		yield put({
			type: buildingActionTypes.RESET_BUILDING_ERROR,
		})
		yield put({
			type: toastActionTypes.TOAST_SHOW,
			payload: {
				message: 'Your building has been successfully updated.',
			},
		})
		yield put({
			type: buildingListActionTypes.GET_BUILDING_LIST_REQUEST,
		})
	} else if (RemoteErrorService.isValidationError(data)) {
		yield put({
			type: buildingActionTypes.UPDATE_BUILDING_VALIDATION_ERROR,
			payload: {
				message: RemoteErrorService.getValidationResponse(data),
			},
		})
	} else {
		yield put({
			type: buildingActionTypes.UPDATE_BUILDING_ERROR,
			meta: {
				event: 'BuildingUpdateError',
				eventProperties: {
					buildingId: basePayload.id,
				},
			},
		})
		yield put({
			type: modalActionTypes.MODAL_SHOW,
			payload: {
				title: 'Something went wrong',
				message: 'We were unable to add your building. Please try again.',
			},
		})
	}
}

/**
 *
 */
export function* buildingRequestWatcher() {
	yield takeLatest(buildingActionTypes.GET_BUILDING_REQUEST, getBuilding)
	yield takeLatest(buildingActionTypes.UPDATE_BUILDING_REQUEST, updateBuilding)
}
