import { createSelector } from 'reselect'
import { flatMap, flatten, pick, uniq, uniqBy } from 'lodash'
import { getAvailableHvacModes } from '@ecobee/smartbuildings-library-common/thermostatHelpers'
import { FILTERS } from '@/utils/maps'
import { allBuildingsSelector } from '@/store/building-list/selectors'
import { queryParamsSelector } from '@/store/routes/selectors'
import { getHvacModeOptions } from '@/utils/selectors.helpers'
import { thermostatListGetHvacMode } from '@/utils/helpers/hvac.helpers'
import {
	getThermostatListArray,
	getValidFilterValues,
	matchesConnectionStatusFilter,
	matchesHvacModeFilter,
	matchesProgramFilter,
	matchesTagFilter,
	getTypeForThermostats,
} from './selectors.helpers'

export const rawThermostatListSelector = (state) =>
	state.thermostatList ? state.thermostatList : null

export const filterQueryParamsSelector = createSelector(
	queryParamsSelector,
	(queryParams) => pick(queryParams, Object.keys(FILTERS)),
)

export const appliedFiltersSelector = createSelector(
	filterQueryParamsSelector,
	(filterQueryParams) => {
		const validatedFilterQueryParams = Object.entries(filterQueryParams)
			.map(([filterId, filterValues]) => [
				filterId,
				getValidFilterValues(filterId, filterValues),
			])
			.filter(([, filterValues]) => filterValues.length > 0)

		return Object.fromEntries(validatedFilterQueryParams)
	},
)

export const searchQuerySelector = createSelector(
	queryParamsSelector,
	(queryParams) => queryParams.search,
)

export const filteredRawThermostatListSelector = createSelector(
	rawThermostatListSelector,
	appliedFiltersSelector,
	(thermostatList, appliedFilters) => {
		const { thermostats } = thermostatList

		if (Object.keys(appliedFilters).length === 0) {
			return thermostatList
		}
		const filteredThermostats = thermostats.filter((thermostat) =>
			Object.entries(appliedFilters).every(
				([filterId, appliedFilterValues]) => {
					switch (filterId) {
						case 'connection':
							return matchesConnectionStatusFilter(
								thermostat,
								appliedFilterValues,
							)
						case 'set-mode':
							return matchesHvacModeFilter(thermostat, appliedFilterValues)
						case 'program':
							return matchesProgramFilter(thermostat, appliedFilterValues)
						case 'tags':
							return matchesTagFilter(thermostat, appliedFilterValues)
						/* istanbul ignore next */
						default:
							throw new Error('Unknown filter type')
					}
				},
			),
		)
		return {
			...thermostatList,
			thermostats: filteredThermostats,
		}
	},
)

export const allThermostatSelector = createSelector(
	rawThermostatListSelector,
	(thermostatList) =>
		thermostatList === null ? null : thermostatList.thermostats,
)

export const filteredThermostatSelector = createSelector(
	filteredRawThermostatListSelector,
	(filteredThermostatList) => filteredThermostatList.thermostats,
)

export const filteredThermostatListSelector = createSelector(
	filteredThermostatSelector,
	allBuildingsSelector,
	(thermostats, buildings) => getThermostatListArray(thermostats, buildings),
)

export const thermostatIsFetchingSelector = createSelector(
	rawThermostatListSelector,
	(thermostatList) =>
		thermostatList !== null && typeof thermostatList.isFetching === 'boolean'
			? thermostatList.isFetching
			: null,
)

export const thermostatIsErrorSelector = createSelector(
	[rawThermostatListSelector],
	(thermostatList) =>
		thermostatList !== null && typeof thermostatList.isError === 'boolean'
			? thermostatList.isError
			: null,
)

export const addThermostatSelector = createSelector(
	[rawThermostatListSelector],
	(thermostatList) =>
		thermostatList !== null &&
		typeof thermostatList.showAddThermostat === 'boolean'
			? thermostatList.showAddThermostat
			: null,
)

export const selectedThermostatIdsSelector = createSelector(
	[rawThermostatListSelector],
	(thermostatList) =>
		thermostatList !== null && thermostatList.selectedThermostatIds
			? thermostatList.selectedThermostatIds
			: [],
)

export const selectedThermostatDataSelector = createSelector(
	[selectedThermostatIdsSelector, filteredThermostatSelector],
	(selectedThermostatIds, filteredThermostats) =>
		selectedThermostatIds !== null &&
		selectedThermostatIds.length > 0 &&
		filteredThermostats
			? filteredThermostats.filter((thermostat) =>
					selectedThermostatIds.includes(thermostat.identifier),
			  )
			: [],
)

export const selectedTenantModeThermostatIdsSelector = createSelector(
	[selectedThermostatDataSelector],
	(selectedThermostats) =>
		selectedThermostats
			.filter((thermostat) => thermostat.sbMetadata.isTenantMode)
			.map((thermostat) => thermostat.identifier),
)

export const selectedNonTenantModeThermostatIdsSelector = createSelector(
	[selectedThermostatIdsSelector, selectedTenantModeThermostatIdsSelector],
	(selectedThermostatIds, selectedTenantModeThermostatIds) =>
		selectedThermostatIds.filter(
			(thermostatId) => !selectedTenantModeThermostatIds.includes(thermostatId),
		),
)

export const selectedNonTenantModeThermostatDataSelector = createSelector(
	[selectedThermostatDataSelector, selectedTenantModeThermostatIdsSelector],
	(filteredThermostats, selectedTenantModeThermostatIds) =>
		selectedTenantModeThermostatIds !== null &&
		selectedTenantModeThermostatIds.length > 0 &&
		filteredThermostats
			? filteredThermostats.filter(
					(thermostat) =>
						!selectedTenantModeThermostatIds.includes(thermostat.identifier),
			  )
			: filteredThermostats,
)

export const selectedThermostatTypeSelector = createSelector(
	[selectedThermostatDataSelector],
	(selectedThermostats) => getTypeForThermostats(selectedThermostats),
)

export const selectedThermostatActiveHvacModesSelector = createSelector(
	[selectedThermostatDataSelector],
	(selectedThermostats) =>
		uniq(
			selectedThermostats
				// HVAC mode is considered private data in tenant mode, so for tenant
				// mode thermostats it's returned as null from
				// thermostatListGetHvacMode and then filtered from the list of modes
				.map(thermostatListGetHvacMode)
				.filter((mode) => mode != null),
		),
)

export const capableSelectedThermostatSelector = createSelector(
	[
		selectedThermostatDataSelector,
		selectedNonTenantModeThermostatIdsSelector,
		(_, hvacMode) => hvacMode,
	],
	(thermostats, nonTenantModeThermostatIds, hvacMode) =>
		hvacMode
			? thermostats
					.filter((thermostat) =>
						getAvailableHvacModes(thermostat).some((mode) => mode === hvacMode),
					)
					.map(({ identifier }) => identifier)
					.filter((thermostatId) =>
						nonTenantModeThermostatIds.includes(thermostatId),
					)
			: thermostats
					.map(({ identifier }) => identifier)
					.filter((thermostatId) =>
						nonTenantModeThermostatIds.includes(thermostatId),
					),
)

export const thermostatIsBulkUpdatingSelector = createSelector(
	rawThermostatListSelector,
	(thermostatList) =>
		thermostatList !== null &&
		typeof thermostatList.isBulkUpdating === 'boolean'
			? thermostatList.isBulkUpdating
			: null,
)

export const thermostatIsBulkUpdateErrorSelector = createSelector(
	rawThermostatListSelector,
	(thermostatList) =>
		thermostatList !== null &&
		typeof thermostatList.isBulkUpdateError === 'boolean'
			? thermostatList.isBulkUpdateError
			: null,
)

export const thermostatBulkActionSelector = createSelector(
	rawThermostatListSelector,
	(thermostatList) =>
		thermostatList !== null && typeof thermostatList.bulkAction === 'string'
			? thermostatList.bulkAction
			: '',
)

export const editScheduleSelector = createSelector(
	rawThermostatListSelector,
	(thermostatList) =>
		thermostatList !== null && thermostatList.bulkAction === 'editSchedule',
)

export const selectedThermostatEventsSelector = createSelector(
	[selectedThermostatDataSelector],
	(selectedThermostatData) =>
		selectedThermostatData !== null && selectedThermostatData.length > 0
			? selectedThermostatData.reduce(
					(eventsArray, thermostat) =>
						// Events are considered private data in tenant mode, so no events
						// are added to the eventsArray for tenant mode thermostats
						thermostat.sbMetadata?.isTenantMode || !thermostat.events
							? eventsArray
							: thermostat.events
									.filter((event) => event.type === 'vacation')
									.concat(eventsArray),
					[],
			  )
			: [],
)

export const selectedThermostatsHvacModeOptions = createSelector(
	[selectedThermostatDataSelector],
	(selectedThermostatData) => {
		const availableModes = uniq(
			flatten(selectedThermostatData.map(getAvailableHvacModes)),
		)

		return getHvacModeOptions(availableModes)
	},
)

export const selectedThermostatsHeatCoolMinDelta = createSelector(
	[selectedThermostatDataSelector],
	(selectedThermostatData) =>
		selectedThermostatData !== null && selectedThermostatData.length > 0
			? selectedThermostatData
					.map((thermostat) => thermostat.settings?.heatCoolMinDelta)
					.filter((heatCoolMinDelta) => heatCoolMinDelta != null)
			: [],
)

export const maxHeatCoolMinDeltaSelector = createSelector(
	[selectedThermostatsHeatCoolMinDelta],
	(heatCoolMinDeltas) => Math.max(...heatCoolMinDeltas),
)

export const selectedThermostatUniqueTagsSelector = createSelector(
	[selectedThermostatDataSelector],
	(selectedThermostats) => {
		const selectedThermostatTags = flatMap(
			selectedThermostats,
			(thermostat) => thermostat.sbMetadata.tags,
		)
		const uniqueThermostatTags = uniqBy(selectedThermostatTags, 'id')

		// If there is any thermostat in the selection where none of its tags
		// include the current tag, the tag is considered to be partially applied
		const isPartiallyApplied = (tag) =>
			selectedThermostats.some((thermostat) =>
				thermostat.sbMetadata.tags.every(({ id }) => id !== tag.id),
			)

		return uniqueThermostatTags.map((tag) => ({
			...tag,
			partial: isPartiallyApplied(tag),
		}))
	},
)

export const lastQueryStringSelector = createSelector(
	rawThermostatListSelector,
	(thermostatList) => thermostatList.lastQueryString,
)

export const thermostatEquipmentSettingsSelector = createSelector(
	selectedNonTenantModeThermostatDataSelector,

	(thermostats) => {
		if (thermostats.length === 0) {
			return {}
		}
		const hasFurnaceFilter = thermostats.some(
			(thermostat) => thermostat.settings.hasForcedAir,
		)

		const hasAirFilter = thermostats.some(
			(thermostat) => !thermostat.settings.hasForcedAir,
		)

		const hasHumidifier = thermostats.some(
			(thermostat) => thermostat.settings.hasHumidifier,
		)
		const hasDehumidifier = thermostats.some(
			(thermostat) => thermostat.settings.hasDehumidifier,
		)

		const hasUVFilter = thermostats.some(
			(thermostat) => thermostat.settings.hasUVFilter,
		)

		const hasVentilator = thermostats.some(
			(thermostat) => thermostat.settings.ventilatorType !== 'none',
		)

		return {
			hasDehumidifier,
			hasHumidifier,
			hasUVFilter,
			hasFurnaceFilter,
			hasAirFilter,
			hasVentilator,
		}
	},
)
