import * as companyUsersActionTypes from './constants'

/**
 *
 */
function getInitialState() {
	return {
		inProgress: false,
		isError: false,
		users: [],
	}
}

const companyUsersReducer = (state = getInitialState(), action) => {
	switch (action.type) {
		case companyUsersActionTypes.COMPANY_USERS_REQUEST: {
			return {
				...state,
				inProgress: true,
				isError: false,
			}
		}
		case companyUsersActionTypes.COMPANY_USERS_SUCCESS: {
			return {
				...getInitialState(),
				users: action.payload || [],
			}
		}
		case companyUsersActionTypes.COMPANY_USERS_RESET:
			return getInitialState()
		case companyUsersActionTypes.COMPANY_USERS_ERROR: {
			return {
				...state,
				inProgress: false,
				isError: true,
			}
		}
		default: {
			return state
		}
	}
}

export default companyUsersReducer
