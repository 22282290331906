import { createAction } from '@/utils/actions'
import * as validateEmailActionTypes from './constants'

export const validateEmailRequest = createAction(
	validateEmailActionTypes.VALIDATE_EMAIL_REQUEST,
)
export const validateEmailReset = createAction(
	validateEmailActionTypes.VALIDATE_EMAIL_RESET,
)
export const validateEmailSuccess = createAction(
	validateEmailActionTypes.VALIDATE_EMAIL_SUCCESS,
)
export const validateEmailError = createAction(
	validateEmailActionTypes.VALIDATE_EMAIL_ERROR,
)
export const validateEmailSendSuccess = createAction(
	validateEmailActionTypes.VALIDATE_EMAIL_SEND_SUCCESS,
)
export const validateEmailSendError = createAction(
	validateEmailActionTypes.VALIDATE_EMAIL_SEND_ERROR,
)
