import { createAction } from '@/utils/actions'
import * as editCompanyUserActionTypes from './constants'

export const editCompanyUserRequest = createAction(
	editCompanyUserActionTypes.EDIT_COMPANY_USER_REQUEST,
)
export const editCompanyUserReset = createAction(
	editCompanyUserActionTypes.EDIT_COMPANY_USER_RESET,
)
export const editCompanyUserSuccess = createAction(
	editCompanyUserActionTypes.EDIT_COMPANY_USER_SUCCESS,
)
export const editCompanyUserError = createAction(
	editCompanyUserActionTypes.EDIT_COMPANY_USER_ERROR,
)
