import { takeLatest, call, put } from 'redux-saga/effects'
import { safeFetch } from '@/services/fetch-api/fetch.api'
import { loginError } from '@/store/login/actions'
import history from '@/services/history'
import { TOKEN_KEY, HTTP_TOO_MANY_REQUESTS } from '@/utils/constants'
import { getPathPrefix } from '@/utils/misc'
import * as validateEmailActions from './actions'
import { VALIDATE_EMAIL_REQUEST } from './constants'

const VALIDATE_LOGIN_ERROR =
	'Your account has already been created. Please log in.'
const EMAIL_CANNOT_SEND_MESSAGE =
	'We were unable to create an account. Please try again.'
const EMAIL_CANNOT_SEND_TOO_MANY_ATTEMPTS =
	"You’ve attempted to validate your email address more than 3 times. You'll have to wait 1 hour before trying again. Please ensure your email address is correct."

/**
 * @param user
 * @param programId
 */
export function* sendValidationEmail(user, programId) {
	const pathPrefix = getPathPrefix(history)
	const linkPathPrefix = `${pathPrefix}/validate?${TOKEN_KEY}=`
	const port = location.port ? { port: location.port } : {}
	const sendVerifyEmailApiResponse = yield call(
		safeFetch,
		'sendValidationEmail',
		{
			...user,
			...port,
			programId,
			linkPathPrefix,
		},
	)
	const { response } = sendVerifyEmailApiResponse
	if (response.ok) {
		yield put(validateEmailActions.validateEmailSendSuccess())
	} else {
		let errorMessage = EMAIL_CANNOT_SEND_MESSAGE
		if (response.status === HTTP_TOO_MANY_REQUESTS) {
			errorMessage = EMAIL_CANNOT_SEND_TOO_MANY_ATTEMPTS
		}
		yield put(validateEmailActions.validateEmailSendError(errorMessage))
	}
}

/**
 * @param action
 */
export function* validateEmail(action) {
	const { user, redirectOnError, sendEmail, programId } = action.payload
	const { data, response } = yield call(safeFetch, 'validateCompanyEmail', {
		email: user.email,
	})
	if (response.ok && data.isValid) {
		if (sendEmail) {
			yield call(sendValidationEmail, user, programId)
		} else {
			yield put(validateEmailActions.validateEmailSuccess())
		}
	} else if (response.ok && !data.isValid) {
		yield put(validateEmailActions.validateEmailError())
		if (redirectOnError) {
			yield put(
				loginError({
					message: VALIDATE_LOGIN_ERROR,
				}),
			)
			yield call([history, 'replace'], { pathname: '/' })
		}
	} else {
		yield put(validateEmailActions.validateEmailSendError())
	}
}

/**
 *
 */
export function* validateEmailRequestWatcher() {
	yield takeLatest(VALIDATE_EMAIL_REQUEST, validateEmail)
}
