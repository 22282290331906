import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { withRouter, Switch, Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { BASE_ROUTE } from '@/utils/constants'
import { routesListSelector } from '@/store/routes/selectors'
import { useFeatureFlags } from '@/services/feature-flags'

const RootRedirect = () => <Redirect to={BASE_ROUTE} />

const RoutesList = ({ routes, isAuthenticated }) => {
	const flags = useFeatureFlags()

	const routeComponents = useMemo(
		() =>
			routes
				.filter((route) => isAuthenticated || !route.authenticated)
				.filter((route) => {
					const { flag } = route

					if (!flag || !flag.key) {
						return true
					}

					const { key, defaultValue } = flag
					return key in flags ? flags[key] : defaultValue
				})
				.map((route) => {
					const {
						authenticated,
						component,
						pattern,
						flag,
						...routeProps
					} = route

					if (pattern) {
						return (
							<Route
								{...routeProps}
								key={pattern}
								path={pattern}
								component={
									authenticated === isAuthenticated ? component : RootRedirect
								}
							/>
						)
					}

					return <Route key="fallback" component={component} />
				}),
		[flags, isAuthenticated, routes],
	)

	return <Switch>{routeComponents}</Switch>
}

const mapStateToProps = /* istanbul ignore next */ (state) => ({
	routes: routesListSelector(state),
})

RoutesList.propTypes = {
	isAuthenticated: PropTypes.bool,
	routes: PropTypes.arrayOf(
		PropTypes.shape({
			authenticated: PropTypes.bool.isRequired,
			component: PropTypes.elementType.isRequired,
			exact: PropTypes.bool,
			flag: PropTypes.shape({
				key: PropTypes.string.isRequired,
				defaultValue: PropTypes.oneOfType([
					PropTypes.bool,
					PropTypes.string,
					PropTypes.number,
				]),
			}),
			pattern: PropTypes.string.isRequired,
		}),
	).isRequired,
}

export { RoutesList as RoutesListComponent }

export default withRouter(connect(mapStateToProps)(RoutesList))
