import { takeLatest, put, call } from 'redux-saga/effects'
import { safeFetch } from '@/services/fetch-api/fetch.api'
import { toastShow } from '@/store/toast/actions'
import { getCompanyUserSingle } from '@/store/get-company-user-single/sagas'
import { modalShow } from '@/store/modal/actions'
import { validateApiResponse } from '@/utils/validators'
import * as editCompanyUserActions from './actions'
import { EDIT_COMPANY_USER_REQUEST } from './constants'

/**
 * @param action
 */
export function* editCompanyUser(action) {
	const { payload } = action

	try {
		const response = yield call(safeFetch, 'updateCompanyUser', payload)

		if (validateApiResponse(response)) {
			yield call(getCompanyUserSingle, { payload })

			yield put(
				toastShow({
					message: 'Successfully updated.',
				}),
			)
			yield put(editCompanyUserActions.editCompanyUserSuccess())
		} else {
			throw new Error('Unable to update user')
		}
	} catch {
		yield put(
			modalShow({
				title: 'Something went wrong when updating the user',
				message: 'Please contact support.',
			}),
		)
		yield put(editCompanyUserActions.editCompanyUserError())
	}
}

/**
 *
 */
export function* editCompanyUserRequestWatcher() {
	yield takeLatest(EDIT_COMPANY_USER_REQUEST, editCompanyUser)
}
