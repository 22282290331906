import { timeMap } from './maps'
import { capitalize } from './misc'
import { NOT_FOUND_INDEX } from './constants'
import {
	getEmptyComfortSettingForThermostatType,
	getDefaultComfortSettingForThermostatType,
} from './thermostat'

const SEGMENTS_PER_DAY = 48
const DAYS_PER_WEEK = 7

/**
 * Convert the API schedule array into an objects used for presentation
 *
 * @param {Array} day            Array of program names from the API
 * @param {string} thermostatType String representing the type of thermostat e.g. 'residential'
 * @param {number} dayIndex       Index position of the day
 * @param {object} programMap     Climate id mapped to climate name
 * @param {object} colormap       Climate id mapped to array index value
 * @param colorMap
 * @returns {Array}                 Array of objects that represent the programs
 */
export const convertScheduleToObjects = (
	day,
	thermostatType,
	dayIndex = 0,
	programMap = {},
	colorMap = {},
) => {
	const ONE_SEGMENT = 1
	const schedule = []
	const matchStack = []
	const emptyComfortSetting = getEmptyComfortSettingForThermostatType(
		thermostatType,
	)
	const defaultComfortSetting = getDefaultComfortSettingForThermostatType(
		thermostatType,
	)
	day.forEach((program, index, programs) => {
		const isLastItem = index === day.length - 1
		const name = programMap[program] || capitalize(program)
		const colorIndex =
			colorMap[program] >= 0 ? colorMap[program] % 10 : NOT_FOUND_INDEX

		if (
			program === emptyComfortSetting.climateRef ||
			(isLastItem && matchStack.length === 0) ||
			(!isLastItem &&
				matchStack.length === 0 &&
				programs[index + 1] !== program)
		) {
			schedule.push({
				colorIndex,
				dayIndex,
				end: timeMap[index + 1],
				endIndex: index + 1,
				id: program,
				isUnoccupied: program === emptyComfortSetting.climateRef,
				isOccupied: false,
				name,
				segments: ONE_SEGMENT,
				start: timeMap[index],
				startIndex: index,
			})
			return
		}

		if (programs[index + 1] === program) {
			matchStack.push(program)
			return
		}

		matchStack.push(program)
		schedule.push({
			colorIndex,
			dayIndex,
			end: timeMap[index + 1],
			endIndex: index + 1,
			id: matchStack[matchStack.length - 1],
			isUnoccupied: false,
			isOccupied: program === defaultComfortSetting.climateRef,
			name,
			segments: matchStack.length,
			start: timeMap[index - matchStack.length + 1],
			startIndex: index - matchStack.length + 1,
		})
		// Empty the matchStack
		matchStack.splice(0, matchStack.length)
	})

	return schedule
}

/**
 * Builds a two-dimensional array representing a schedule week and fills it with
 * the specified comfort setting
 *
 * @param  {string} climateRef Empty climate ref
 * @returns {string[][]} An array of arrays, each representing the blank schedule segments for a day
 */
export const buildBlankSchedule = (climateRef) =>
	new Array(DAYS_PER_WEEK)
		.fill()
		.map(() => new Array(SEGMENTS_PER_DAY).fill(climateRef))

export const buildBlankScheduleForThermostatType = (type) => {
	const emptyComfortSetting = getEmptyComfortSettingForThermostatType(type)
	return buildBlankSchedule(emptyComfortSetting.climateRef)
}

export const buildBlankObjectScheduleForThermostatType = (type) =>
	buildBlankScheduleForThermostatType(type).map((day, dayIndex) =>
		convertScheduleToObjects(day, type, dayIndex),
	)
