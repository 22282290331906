import { createAction } from '@/utils/actions'
import * as thermostatActionTypes from './constants'

export const getThermostatRequest = createAction(
	thermostatActionTypes.GET_THERMOSTAT_REQUEST,
)
export const getThermostatSuccess = createAction(
	thermostatActionTypes.GET_THERMOSTAT_SUCCESS,
)
export const getThermostatError = createAction(
	thermostatActionTypes.GET_THERMOSTAT_ERROR,
)
export const refreshThermostatRequest = createAction(
	thermostatActionTypes.REFRESH_THERMOSTAT_REQUEST,
)
export const refreshThermostatSuccess = createAction(
	thermostatActionTypes.REFRESH_THERMOSTAT_SUCCESS,
)
export const refreshThermostatError = createAction(
	thermostatActionTypes.REFRESH_THERMOSTAT_ERROR,
)
export const thermostatNotFoundError = createAction(
	thermostatActionTypes.THERMOSTAT_NOT_FOUND_ERROR,
)
export const updateThermostatRequest = createAction(
	thermostatActionTypes.UPDATE_THERMOSTAT_REQUEST,
)
export const updateThermostatWithFunctionRequest = createAction(
	thermostatActionTypes.UPDATE_THERMOSTAT_WITH_FUNCTION_REQUEST,
)
export const updateThermostatError = createAction(
	thermostatActionTypes.UPDATE_THERMOSTAT_ERROR,
)
export const updateThermostatSuccess = createAction(
	thermostatActionTypes.UPDATE_THERMOSTAT_SUCCESS,
)
export const resetThermostat = createAction(
	thermostatActionTypes.RESET_THERMOSTAT,
)
export const getRuntimeReportExportRequest = createAction(
	thermostatActionTypes.GET_RUNTIME_REPORT_EXPORT_REQUEST,
)
export const getRuntimeReportExportSuccess = createAction(
	thermostatActionTypes.GET_RUNTIME_REPORT_EXPORT_SUCCESS,
)
export const getRuntimeReportExportError = createAction(
	thermostatActionTypes.GET_RUNTIME_REPORT_EXPORT_ERROR,
)
