import { createAction } from '@/utils/actions'
import * as validationTokenActionTypes from './constants'

export const validationTokenRequest = createAction(
	validationTokenActionTypes.VALIDATION_TOKEN_REQUEST,
)
export const validationTokenReset = createAction(
	validationTokenActionTypes.VALIDATION_TOKEN_RESET,
)
export const validationTokenSuccess = createAction(
	validationTokenActionTypes.VALIDATION_TOKEN_SUCCESS,
)
export const validationTokenError = createAction(
	validationTokenActionTypes.VALIDATION_TOKEN_ERROR,
)
export const validationTokenExpired = createAction(
	validationTokenActionTypes.VALIDATION_TOKEN_EXPIRED,
)
