import { isEqual } from 'lodash'
import getMonth from 'date-fns/get_month'
import getYear from 'date-fns/get_year'
import format from 'date-fns/format'
import {
	COPY_FROM_COMFORT_SETTING,
	FAN_MODE_AUTO,
	ECOBEE_MAX_YEAR,
} from './constants'
import { timeMapMoment } from './maps'

const MAY_MONTH_INDEX = 4

const THERMOSTAT_DATE_FORMAT = 'YYYY-MM-DD'

export const isDirty = (settings, pristineSettings) => {
	if (!settings.name || !settings.setpointSelectionMode) {
		return false
	}

	if (
		settings.setpointSelectionMode === COPY_FROM_COMFORT_SETTING &&
		!settings.holdClimateRef
	) {
		return false
	}

	if (isEqual(pristineSettings, settings)) {
		return false
	}

	return true
}

export const isUniqueEventName = (name, events) => {
	const normalizedEventNames = new Set(
		events.map((event) => event.name.trim().toUpperCase()),
	)

	return !normalizedEventNames.has(name.trim().toUpperCase())
}

export const getIsoEventDates = (settings) => {
	const startDateString = settings.startDate.format(THERMOSTAT_DATE_FORMAT)
	const startTimeString = timeMapMoment[settings.startTime]
	const endDateString = settings.endDate.format(THERMOSTAT_DATE_FORMAT)
	const endTimeString = timeMapMoment[settings.endTime]

	return {
		startDate: `${startDateString}T${startTimeString}Z`,
		endDate: `${endDateString}T${endTimeString}Z`,
	}
}

export const buildEventsPayload = (
	thermostatPrograms,
	settings,
	pristineSettings,
) => {
	const { name } = settings
	const endTime = timeMapMoment[settings.endTime]
	const startTime = timeMapMoment[settings.startTime]
	const startDate = settings.startDate.format(THERMOSTAT_DATE_FORMAT)
	const endDate = settings.endDate.format(THERMOSTAT_DATE_FORMAT)
	let heatHoldTemp
	let coolHoldTemp
	let isOccupied
	let isHeatOff = false
	let isCoolOff = false
	let holdClimateRef = null
	let fan
	let fanMinOnTime
	// Set heat and cool temperature values
	if (settings.setpointSelectionMode === COPY_FROM_COMFORT_SETTING) {
		const climateRefIndex = thermostatPrograms.findIndex(
			(program) => program.value === settings.holdClimateRef,
		)
		holdClimateRef = thermostatPrograms[climateRefIndex].value
		coolHoldTemp = thermostatPrograms[climateRefIndex].coolTemp
		heatHoldTemp = thermostatPrograms[climateRefIndex].heatTemp
		isOccupied = thermostatPrograms[climateRefIndex].isOccupied
	} else {
		fan = settings.fan
		// if the fan is on auto, we need to set a minimum fan on time, otherwise we send zero.
		fanMinOnTime = fan === FAN_MODE_AUTO ? settings.fanMinOnTime : 0
		switch (settings.hvacMode) {
			case 'auto': {
				heatHoldTemp = settings.heatHoldTemp
				coolHoldTemp = settings.coolHoldTemp
				break
			}
			case 'heat': {
				isCoolOff = true
				heatHoldTemp = settings.heatHoldTemp
				coolHoldTemp = pristineSettings.coolHoldTemp
				break
			}
			case 'cool': {
				isHeatOff = true
				heatHoldTemp = pristineSettings.heatHoldTemp
				coolHoldTemp = settings.coolHoldTemp
				break
			}
			default: {
				isCoolOff = true
				isHeatOff = true
				heatHoldTemp = pristineSettings.heatHoldTemp
				coolHoldTemp = pristineSettings.coolHoldTemp
			}
		}
	}

	return {
		holdClimateRef,
		coolHoldTemp,
		isOccupied,
		endDate,
		endTime,
		fan,
		fanMinOnTime,
		name: name.trim(),
		heatHoldTemp,
		isCoolOff,
		isHeatOff,
		startDate,
		startTime,
	}
}

export const formatEventTime = (date) => {
	const formattedDate =
		getMonth(date) === MAY_MONTH_INDEX
			? format(date, 'h:mm A, MMM D')
			: format(date, 'h:mm A, MMM. D')

	return getYear(date) === ECOBEE_MAX_YEAR ? 'indefinitely' : formattedDate
}

export const getCustomSetpointParams = ({
	hvacMode,
	coolHoldTemp,
	heatHoldTemp,
}) => {
	switch (hvacMode) {
		case 'cool': {
			return { coolHoldTemp, heatHoldTemp: coolHoldTemp }
		}
		case 'heat':
		case 'auxHeatOnly': {
			return { coolHoldTemp: heatHoldTemp, heatHoldTemp }
		}
		case 'auto': {
			return { coolHoldTemp, heatHoldTemp }
		}
		/* istanbul ignore next */
		default:
			return {}
	}
}
