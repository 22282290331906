import * as applicationActionTypes from './constants'

/**
 *
 */
function getInitialState() {
	return {
		inProgress: false,
		isError: false,
		isReady: false,
	}
}

const applicationReducer = (state = getInitialState(), action) => {
	switch (action.type) {
		case applicationActionTypes.APPLICATION_REQUEST: {
			return {
				...state,
				inProgress: true,
				isError: false,
				isReady: false,
			}
		}
		case applicationActionTypes.APPLICATION_SUCCESS: {
			return {
				...state,
				inProgress: false,
				isError: false,
				isReady: true,
			}
		}
		case applicationActionTypes.APPLICATION_RESET:
			return getInitialState()
		case applicationActionTypes.APPLICATION_ERROR: {
			return {
				...state,
				inProgress: false,
				isError: true,
				isReady: false,
			}
		}
		default: {
			return state
		}
	}
}

export default applicationReducer
