import React from 'react'
import { Link } from 'react-router-dom'
import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import pluralize from 'pluralize'
import { partition } from 'lodash'
import { SMARTBUILDINGS_SELECTION_TYPE } from '@ecobee/smartbuildings-library-common/constants'
import { isAuthorizedToManageResources } from '@ecobee/smartbuildings-library-common/resources'
import { modalShow } from '@/store/modal/actions'
import * as modalActionTypes from '@/store/modal/constants'
import { toastShow } from '@/store/toast/actions'
import * as toastActionTypes from '@/store/toast/constants'
import { validateApiResponse, validateApiStatusCode } from '@/utils/validators'
import { sortList, getCompanyPath } from '@/utils/misc'
import history from '@/services/history'
import { safeFetch } from '@/services/fetch-api/fetch.api'
import { setQueryParams } from '@/services/query-params/query.params.service'
import { buildingIdSelector } from '@/store/building/selectors'
import { companyTagsAllTagsSelector } from '@/store/company-tags/selectors'
import { activeCompanyUserSelector } from '@/store/user/selectors'
import { FILTERS } from '@/utils/maps'
import combineThermostats from './sagas.helpers'
import * as thermostatListActionTypes from './constants'
import {
	bulkUpdateTagsSuccess,
	resetBulkUpdateAction,
	getThermostatListRequest,
	bulkUpdateTagsError,
	deselectThermostat,
} from './actions'
import {
	allThermostatSelector,
	filteredThermostatListSelector,
	searchQuerySelector,
	selectedThermostatIdsSelector,
} from './selectors'

/**
 * @param action
 */
export function* thermostatList(action) {
	const { forceUpdate } = action.payload
	const thermostats = yield select(allThermostatSelector)
	const buildingId = yield select(buildingIdSelector)
	const search = yield select(searchQuerySelector)

	if (!forceUpdate && thermostats && thermostats.length > 0) {
		// Use the existing passed in thermostat list
		yield put({
			type: thermostatListActionTypes.GET_THERMOSTAT_LIST_SUCCESS,
			payload: {
				thermostats,
			},
		})
	} else {
		const fetchParams = {
			selection: {
				selectionType: SMARTBUILDINGS_SELECTION_TYPE,
				selectionMatch: '/',
				includeAlerts: true,
				includeDevice: true,
				includeEquipmentStatus: true,
				includeEvents: true,
				includeExtendedRuntime: true,
				includeProgram: true,
				includeRuntime: true,
				includeSecuritySettings: true,
				includeSensors: true,
				includeSettings: true,
				includeNotificationSettings: true,
				includeVersion: true,
			},
		}
		if (buildingId) {
			fetchParams.buildingId = buildingId
		}
		if (search) {
			fetchParams.search = search
		}

		// Fetch thermostats from the API
		const fetchedThermostats = yield call(
			safeFetch,
			'getThermostatList',
			fetchParams,
		)

		if (validateApiResponse(fetchedThermostats)) {
			const combinedThermostats = combineThermostats(fetchedThermostats)

			yield put({
				type: thermostatListActionTypes.GET_THERMOSTAT_LIST_SUCCESS,
				payload: {
					thermostats: sortList(combinedThermostats, 'name', 'identifier'),
				},
			})
		} else {
			yield put({
				type: thermostatListActionTypes.GET_THERMOSTAT_LIST_ERROR,
			})
		}
	}
}

/**
 * @param action
 */
export function* applyFilter(action) {
	const { payload: appliedFilter } = action

	yield call(setQueryParams, { ...appliedFilter, page: undefined })
}

/**
 * @param action
 */
export function* removeFilter(action) {
	const { payload: removedFilter } = action

	yield call(setQueryParams, { [removedFilter]: undefined, page: undefined })
}

/**
 *
 */
export function* resetFilter() {
	const emptyFilterParams = Object.fromEntries(
		Object.keys(FILTERS).map((filterId) => [filterId, undefined]),
	)

	yield call(setQueryParams, { ...emptyFilterParams, page: undefined })
}

/**
 * @param action
 */
export function* applySearch(action) {
	const { payload: search } = action

	yield call(setQueryParams, { search, page: undefined }, { push: true })
}

/**
 * @param action
 */
export function* resetSearch() {
	yield call(
		setQueryParams,
		{ search: undefined, page: undefined },
		{ push: true },
	)
}

/**
 * @param root0
 * @param root0.payload
 */
export function* bulkUpdateTags({ payload }) {
	const {
		thermostatIds,
		tagsToAdd: existingTagsToAdd,
		tagsToRemove,
		tagsToCreate,
	} = payload
	const companyTags = yield select(companyTagsAllTagsSelector)

	const newlyCreatedTagsToAdd = []

	const errors = []

	if (tagsToCreate.length > 0) {
		const createTagResponses = yield all(
			tagsToCreate.map((name) => call(safeFetch, 'createTag', { name })),
		)

		const [successfulResponses, errorResponses] = partition(
			createTagResponses,
			({ response }) => response.ok,
		)

		newlyCreatedTagsToAdd.push(
			...successfulResponses.map(({ data }) => data.id),
		)

		companyTags.push(...successfulResponses.map(({ data }) => data))
		errors.push(...errorResponses)
	}

	const tagsToAdd = [...existingTagsToAdd, ...newlyCreatedTagsToAdd]

	if (tagsToAdd.length > 0) {
		const responses = yield all(
			thermostatIds.map((thermostatId) =>
				call(safeFetch, 'addTagsToThermostat', {
					thermostatId,
					tagIds: tagsToAdd,
				}),
			),
		)

		errors.push(...responses.filter(({ response }) => !response.ok))
	}

	if (tagsToRemove.length > 0) {
		const responses = yield all(
			thermostatIds.map((thermostatId) =>
				call(safeFetch, 'removeTagsFromThermostat', {
					thermostatId,
					tagIds: tagsToRemove,
				}),
			),
		)

		errors.push(...responses.filter(({ response }) => !response.ok))
	}

	if (errors.length === 0) {
		yield put(
			bulkUpdateTagsSuccess(
				{
					thermostatIds,
					tagsAdded: tagsToAdd.map((addedId) =>
						companyTags.find(({ id }) => id === addedId),
					),
					tagsRemoved: tagsToRemove.map((removedId) =>
						companyTags.find(({ id }) => id === removedId),
					),
				},
				{
					event: 'ThermostatTagsBulkUpdateSuccess',
					eventProperties: {
						thermostatIds,
					},
				},
			),
		)
		yield put(
			toastShow({
				message: `Updated tags on ${thermostatIds.length} ${pluralize(
					'thermostat',
					thermostatIds.length,
				)}`,
			}),
		)
		// If tags have been removed, some selected thermostats may no longer be
		// visible in the current filtered view, so they should be deselected
		if (tagsToRemove.length > 0) {
			const selectedThermostatIds = yield select(selectedThermostatIdsSelector)
			const visibleThermostats = yield select(filteredThermostatListSelector)
			const hiddenSelectedThermostatIds = selectedThermostatIds.filter(
				(selectedThermostatId) =>
					!visibleThermostats.some(({ id }) => id === selectedThermostatId),
			)
			if (hiddenSelectedThermostatIds.length > 0) {
				yield put(
					deselectThermostat({ thermostat: hiddenSelectedThermostatIds }),
				)
			}
		}
		yield put(resetBulkUpdateAction())
	} else {
		yield put(
			bulkUpdateTagsError(null, {
				event: 'ThermostatTagsBulkUpdateError',
				thermostatIds,
			}),
		)
		yield put(
			modalShow({
				title: 'Something went wrong',
				message: 'We were unable to update tags on some thermostats.',
			}),
		)
		yield put(getThermostatListRequest({ forceUpdate: true }))
	}
}

/**
 * @param root0
 * @param root0.payload
 * @param root0.payload.successToastMessageFormatter
 */
export function* bulkUpdateThermostat({
	payload: {
		successToastMessageFormatter = (responses) =>
			`Successfully updated ${responses.length} ${pluralize(
				'thermostat',
				responses.length,
			)}.`,
		...actionPayload
	},
}) {
	let errorCount = 0
	const responses = []
	const successfulThermostatIds = []
	const failedThermostatIds = []
	const { thermostats, settingsToUpdate } = actionPayload

	for (const [i, element] of thermostats.entries()) {
		const newSettingsToUpdate = Array.isArray(settingsToUpdate)
			? settingsToUpdate[i]
			: settingsToUpdate
		const payload = {
			id: element,
			settingsToUpdate: newSettingsToUpdate,
		}
		responses.push(yield call(safeFetch, 'updateThermostat', payload))
	}

	responses.forEach((response, index) => {
		if (
			!validateApiResponse(response) ||
			!validateApiStatusCode(response.data)
		) {
			failedThermostatIds.push(thermostats[index])
			errorCount += 1
		} else {
			successfulThermostatIds.push(thermostats[index])
		}
	})

	if (errorCount === 0) {
		yield put({
			type: thermostatListActionTypes.BULK_UPDATE_THERMOSTAT_SUCCESS,
			meta: {
				event: 'ThermostatsBulkUpdateSuccess',
				eventProperties: {
					thermostatIds: successfulThermostatIds,
					function: actionPayload.functions?.[0]?.type,
				},
			},
		})

		yield put({
			type: toastActionTypes.TOAST_SHOW,
			payload: {
				message: successToastMessageFormatter(responses),
			},
		})
	} else {
		yield put({
			type: thermostatListActionTypes.BULK_UPDATE_THERMOSTAT_ERROR,
			meta: {
				event: 'ThermostatsBulkUpdateError',
				eventProperties: {
					successfulThermostatIds,
					failedThermostatIds,
					function: actionPayload.functions?.[0]?.type,
				},
			},
		})
		// @todo show modal populated with error count
		yield put({
			type: modalActionTypes.MODAL_SHOW,
			payload: {
				title: 'Something went wrong',
				message: `We were unable to save ${errorCount} out of ${thermostats.length} thermostats.`,
			},
		})
	}

	yield put({
		type: thermostatListActionTypes.GET_THERMOSTAT_LIST_REQUEST,
		payload: {
			forceUpdate: true,
		},
	})

	yield put({
		type: thermostatListActionTypes.SET_BULK_UPDATE_ACTION,
		payload: {
			bulkAction: '',
		},
	})
}

/**
 * @param action
 */
export function* bulkUpdateMoveThermostat(action) {
	const failedThermostatIds = []
	const successfulThermostatIds = []
	const {
		building,
		settingsToUpdate,
		thermostats: thermostatIds,
	} = action.payload
	const payload = {
		thermostatIds,
		data: settingsToUpdate,
	}
	const responses = yield call(safeFetch, 'moveThermostats', payload)
	const { data } = responses
	const isValidResponse = validateApiResponse(responses)

	if (isValidResponse) {
		data.data.forEach((responseData, index) => {
			if (validateApiStatusCode(responseData)) {
				successfulThermostatIds.push(thermostatIds[index])
			} else {
				failedThermostatIds.push(thermostatIds[index])
			}
		})
	}

	if (isValidResponse && failedThermostatIds.length === 0) {
		yield put({
			type: thermostatListActionTypes.BULK_UPDATE_THERMOSTAT_SUCCESS,
			meta: {
				event: 'ThermostatsBulkMoveSuccess',
				eventProperties: {
					thermostatIds: successfulThermostatIds,
				},
			},
		})
		yield put({
			type: thermostatListActionTypes.DESELECT_ALL_THERMOSTAT,
		})
		yield put({
			type: toastActionTypes.TOAST_SHOW,
			payload: {
				message: (
					<span>
						{thermostatIds.length} {pluralize('thermostat', data.data.length)}{' '}
						{pluralize('has', data.data.length)} been moved to {building.name}
						.&nbsp;
						<Link to={getCompanyPath(`/building/${building.value}`)}>
							View {building.name}
						</Link>
					</span>
				),
			},
		})

		const buildingId = yield select(buildingIdSelector)

		// If there's no buildingId, we're on the flat thermostat list,
		// otherwise we're in a building, so we need to make sure the user still
		// has access to the building
		/* istanbul ignore next -- integration tests cover this */
		if (buildingId) {
			const buildingThermostats = yield select(allThermostatSelector)
			const companyUser = yield select(activeCompanyUserSelector)

			// If every thermostat in the building is in list of thermostat IDs we
			// just moved, the building is now empty, so we need to make sure the
			// user still has building access (they might just have device
			// resourceAccess to the devices they already moved)
			const buildingIsEmpty = buildingThermostats.every((thermostat) =>
				thermostatIds.includes(thermostat.identifier),
			)
			const buildingResource = { id: buildingId, type: 'building' }

			if (
				buildingIsEmpty &&
				!isAuthorizedToManageResources(companyUser, [buildingResource])
			) {
				// No permission to view this building anymore, so redirect back to
				// the building list
				yield put({
					type: thermostatListActionTypes.SET_BULK_UPDATE_ACTION,
					payload: {
						bulkAction: '',
					},
				})
				yield call([history, 'push'], { pathname: getCompanyPath('/building') })
				return
			}
		}
	} else {
		yield put({
			type: thermostatListActionTypes.BULK_UPDATE_THERMOSTAT_ERROR,
			meta: {
				event: 'ThermostatsBulkMoveError',
				eventProperties: {
					successfulThermostatIds,
					failedThermostatIds,
				},
			},
		})
		yield put({
			type: thermostatListActionTypes.DESELECT_THERMOSTAT,
			payload: {
				thermostat: successfulThermostatIds,
			},
		})
		yield put({
			type: modalActionTypes.MODAL_SHOW,
			payload: {
				title: 'Something went wrong',
				message: `We were unable to move ${
					isValidResponse ? failedThermostatIds.length : thermostatIds.length
				} out of ${thermostatIds.length} thermostats.`,
			},
		})
	}

	yield put({
		type: thermostatListActionTypes.GET_THERMOSTAT_LIST_REQUEST,
		payload: {
			forceUpdate: true,
		},
	})

	yield put({
		type: thermostatListActionTypes.SET_BULK_UPDATE_ACTION,
		payload: {
			bulkAction: '',
		},
	})
}

/**
 * @param action
 */
export function* bulkDeleteThermostat(action) {
	const failedThermostatIds = []
	const successfulThermostatIds = []
	const { thermostats } = action.payload
	const payload = {
		thermostatIds: thermostats,
	}
	const responses = yield call(safeFetch, 'deleteThermostatBulk', payload)
	const { data } = responses
	const isValidResponse = validateApiResponse(responses)

	if (isValidResponse) {
		data.data.forEach((responseData, index) => {
			if (validateApiStatusCode(responseData)) {
				successfulThermostatIds.push(thermostats[index])
			} else {
				failedThermostatIds.push(thermostats[index])
			}
		})
	}

	if (isValidResponse && failedThermostatIds.length === 0) {
		yield put({
			type: thermostatListActionTypes.BULK_UPDATE_THERMOSTAT_SUCCESS,
			meta: {
				event: 'ThermostatsBulkDeleteSuccess',
				eventProperties: {
					thermostatIds: successfulThermostatIds,
				},
			},
		})
		yield put({
			type: thermostatListActionTypes.DESELECT_ALL_THERMOSTAT,
		})
		yield put({
			type: toastActionTypes.TOAST_SHOW,
			payload: {
				message: `${thermostats.length} ${pluralize(
					'thermostat',
					thermostats.length,
				)} ${pluralize('has', thermostats.length)} been deleted`,
			},
		})
	} else {
		yield put({
			type: thermostatListActionTypes.BULK_UPDATE_THERMOSTAT_ERROR,
			meta: {
				event: 'ThermostatsBulkDeleteError',
				eventProperties: {
					successfulThermostatIds,
					failedThermostatIds,
				},
			},
		})
		yield put({
			type: thermostatListActionTypes.DESELECT_THERMOSTAT,
			payload: {
				thermostat: successfulThermostatIds,
			},
		})
		yield put({
			type: modalActionTypes.MODAL_SHOW,
			payload: {
				title: 'Something went wrong',
				message: `We were unable to delete ${
					isValidResponse ? failedThermostatIds.length : thermostats.length
				} out of ${thermostats.length} thermostats.`,
			},
		})
	}

	yield put({
		type: thermostatListActionTypes.GET_THERMOSTAT_LIST_REQUEST,
		payload: {
			forceUpdate: true,
		},
	})

	yield put({
		type: thermostatListActionTypes.SET_BULK_UPDATE_ACTION,
		payload: {
			bulkAction: '',
		},
	})
}

/**
 *
 */
export function* thermostatListRequestWatcher() {
	yield takeLatest(
		thermostatListActionTypes.GET_THERMOSTAT_LIST_REQUEST,
		thermostatList,
	)
	yield takeLatest(thermostatListActionTypes.APPLY_FILTER, applyFilter)
	yield takeLatest(thermostatListActionTypes.REMOVE_FILTER, removeFilter)
	yield takeLatest(thermostatListActionTypes.RESET_FILTER, resetFilter)
	yield takeLatest(thermostatListActionTypes.APPLY_SEARCH, applySearch)
	yield takeLatest(thermostatListActionTypes.RESET_SEARCH, resetSearch)
	yield takeLatest(
		thermostatListActionTypes.BULK_UPDATE_TAGS_REQUEST,
		bulkUpdateTags,
	)
	yield takeLatest(
		thermostatListActionTypes.BULK_UPDATE_THERMOSTAT_REQUEST,
		bulkUpdateThermostat,
	)
	yield takeLatest(
		thermostatListActionTypes.BULK_DELETE_THERMOSTAT_REQUEST,
		bulkDeleteThermostat,
	)
	yield takeLatest(
		thermostatListActionTypes.BULK_UPDATE_MOVE_THERMOSTAT_REQUEST,
		bulkUpdateMoveThermostat,
	)
}
