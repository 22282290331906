import { createAction } from '@/utils/actions'
import * as companyActionTypes from './constants'

export const updateCompanyRequest = createAction(
	companyActionTypes.UPDATE_COMPANY_REQUEST,
)
export const updateCompanySuccess = createAction(
	companyActionTypes.UPDATE_COMPANY_SUCCESS,
)
export const updateCompanyError = createAction(
	companyActionTypes.UPDATE_COMPANY_ERROR,
)

export const initializeMigratedCompanyRequest = createAction(
	companyActionTypes.INITIALIZE_MIGRATED_COMPANY_REQUEST,
)
export const initializeMigratedCompanyError = createAction(
	companyActionTypes.INITIALIZE_MIGRATED_COMPANY_ERROR,
)
export const initializeMigratedCompanySuccess = createAction(
	companyActionTypes.INITIALIZE_MIGRATED_COMPANY_SUCCESS,
)
