import { takeLatest, call, put } from 'redux-saga/effects'
import { safeFetch } from '@/services/fetch-api/fetch.api'
import { GET_COMPANY_USER_SINGLE_REQUEST } from './constants'
import * as getCompanyUserSingleActions from './actions'

/**
 * @param action
 */
export function* getCompanyUserSingle(action) {
	const { userId, companyId } = action.payload

	try {
		const { data } = yield call(safeFetch, 'getCompanyUserSingle', {
			userId,
			companyId,
		})
		yield put(getCompanyUserSingleActions.getCompanyUserSingleSuccess(data))
	} catch {
		yield put(getCompanyUserSingleActions.getCompanyUserSingleError())
	}
}

/**
 *
 */
export function* getCompanyUserSingleRequestWatcher() {
	yield takeLatest(GET_COMPANY_USER_SINGLE_REQUEST, getCompanyUserSingle)
}
