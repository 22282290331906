import PropTypes from 'prop-types'
import { useFeatureFlag } from './useFeatureFlag'

const FeatureFlag = ({ id, children }) => {
	const flagIsEnabled = Boolean(useFeatureFlag(id))
	return flagIsEnabled ? children : null
}

FeatureFlag.propTypes = {
	id: PropTypes.string.isRequired,
	children: PropTypes.node,
}

export default FeatureFlag
