import React from 'react'
import { withLoadingLayoutSimple } from '@/routes/route.helpers'
import { withDisplayName } from '@/utils/display.name.helpers'

export const loader = /* istanbul ignore next */ () =>
	import(/* webpackChunkName: 'login' */ '../../containers/login-container')

export default withLoadingLayoutSimple(
	withDisplayName(React.lazy(loader), 'LoginRoute'),
)
