import { createSelector } from 'reselect'

export const loginSelector = (state) => (state.login ? state.login : null)

export const fetchingSelector = createSelector([loginSelector], (login) =>
	login !== null && typeof login.isFetching === 'boolean'
		? login.isFetching
		: null,
)

export const authenticatedSelector = createSelector([loginSelector], (login) =>
	login !== null && typeof login.isAuthenticated === 'boolean'
		? login.isAuthenticated
		: null,
)

export const errorMessageSelector = createSelector([loginSelector], (login) =>
	login !== null && login.errorMessage ? login.errorMessage : null,
)
