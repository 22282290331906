import { createSelector } from 'reselect'

export const createSubscriptionSelector = (state) =>
	state.createSubscription ? state.createSubscription : null

export const createSubscriptionRequestSelector = createSelector(
	[createSubscriptionSelector],
	(register) =>
		register !== null && typeof register.inProgress === 'boolean'
			? register.inProgress
			: null,
)

export const createSubscriptionErrorSelector = createSelector(
	[createSubscriptionSelector],
	(register) =>
		register !== null && typeof register.isError === 'boolean'
			? register.isError
			: null,
)
