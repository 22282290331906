import queryString from 'query-string'
import { pick } from 'lodash'
import history from '@/services/history'

/**
 * Get specified values from the query params
 *
 * @param   {string|string[]} paramsToSelect String or Array of query param names to get
 * @returns {object}       Object containing query params with their values
 */
export const getQueryParams = (paramsToSelect) => {
	const currentParams = queryString.parse(history.location.search, {
		arrayFormat: 'bracket',
	})

	return pick(currentParams, [].concat(paramsToSelect))
}

/**
 * Merge new query param values with existing ones
 *
 * @param  {object}  newParams Updated values to push to the url
 * @param  {object}  options Options object (optional)
 * @param  {boolean} options.replace Should the new query params replace the current ones or be merged shallowly with them
 * @param  {boolean} options.push Should the history entry be pushed or replaced
 */
export const setQueryParams = (
	newParams,
	{ replace = false, push = false } = {},
) => {
	const currentPathname = history.location.pathname
	const currentParams = replace
		? {}
		: queryString.parse(history.location.search, { arrayFormat: 'bracket' })
	const newParamsObject = {
		...currentParams,
		...newParams,
	}
	const historyObject = {
		pathname: currentPathname,
		search: queryString.stringify(newParamsObject, { arrayFormat: 'bracket' }),
	}
	if (push) {
		history.push(historyObject)
	} else {
		history.replace(historyObject)
	}
}
