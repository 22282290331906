import moment from 'moment'
import parse from 'date-fns/parse'
import { SENSOR_TYPE_WIRED } from '@ecobee/smartbuildings-library-common/constants'
import { getAvailableHvacModes } from '@ecobee/smartbuildings-library-common/thermostatHelpers'
import { formatEventTime } from '@/utils/events.helpers'
import {
	ECOBEE_MAGIC_NUMBER,
	ENDASH,
	RADIX,
	UNACKNOWLEDGED,
	NEVER_ONLINE_STATUS_MODIFIED_DATE,
	SENSOR_STATUS_PARTICIPATING,
	SENSOR_STATUS_MONITOR,
	DEMAND_RESPONSE_EVENT_TYPE,
	SMART_AWAY_EVENT_TYPE,
	SMART_HOME_EVENT_TYPE,
	HOLD_EVENT_TYPE,
	VACATION_EVENT_TYPE,
} from '@/utils/constants'
import { getHvacModeOptions } from '@/utils/selectors.helpers'
import { timeMapMoment } from '@/utils/maps'
import { getClimate } from '@/utils/helpers/program.helpers'

const NOT_FOUND_INDEX = -1
const hvacModeMap = {
	auto: 'Auto mode',
	auxHeatOnly: 'Aux heat mode',
	cool: 'Cool mode',
	heat: 'Heat mode',
	off: 'HVAC off',
}
const emptyClimate = {
	name: '',
}
const eventTypes = new Set([
	HOLD_EVENT_TYPE,
	VACATION_EVENT_TYPE,
	DEMAND_RESPONSE_EVENT_TYPE,
	SMART_AWAY_EVENT_TYPE,
	SMART_HOME_EVENT_TYPE,
])
const SENSOR_ID_SEPARATOR = ':'
const SENSOR_NAME_RS = 'rs'
const SENSOR_TYPE_CONTROL = 'control_sensor'
const DEVICE_USAGE_CONTROL = 'indoor'

const SENSOR_DISCONNECTED_VALUES = new Set([
	'unknown',
	ECOBEE_MAGIC_NUMBER.toString(),
])

/**
 * Get the index of the currently running thermostat event
 *
 * @param  {object} thermostat The thermostat object
 * @returns {number}            Array index of the running event
 */
export const getRunningEventIndex = (thermostat) => {
	const { events } = thermostat
	return events.findIndex(
		(event) => event.running && eventTypes.has(event.type),
	)
}

export const getEventTemperature = ({
	eventCoolTemp,
	eventHeatTemp,
	hvacMode,
}) => {
	let heatTemp = ''
	let coolTemp = ''

	switch (hvacMode) {
		case 'heat':
		case 'auxHeatOnly':
			heatTemp = eventHeatTemp
			break
		case 'cool':
			coolTemp = eventCoolTemp
			break
		case 'auto':
			heatTemp = eventHeatTemp
			coolTemp = eventCoolTemp
			break

		/* istanbul ignore next */
		default:
	}

	return {
		heatTemp,
		coolTemp,
	}
}

export const getThermostatRunningDataWithEnergySavings = (
	{ events, runtime: { desiredHeat, desiredCool } },
	runningEventIndex,
) => {
	const demandResponseEvent = events[runningEventIndex]

	// If we have a vacation event, we use it as a reference to display 'Off'
	// Otherwise, we use the desiredHeat/desiredCool values as our source of truth
	const vacationEvent = events.find(
		(event) => event.type === VACATION_EVENT_TYPE && event.running,
	)

	let coolTemp
	let heatTemp

	if (vacationEvent) {
		coolTemp = vacationEvent.isCoolOff ? 'Off' : desiredCool
		heatTemp = vacationEvent.isHeatOff ? 'Off' : desiredHeat
	} else {
		coolTemp = demandResponseEvent.isCoolOff ? 'Off' : desiredCool
		heatTemp = demandResponseEvent.isHeatOff ? 'Off' : desiredHeat
	}

	return {
		hasDemandResponseEvent: true,
		canSetTemporaryHold: false,
		hasHold: false,
		endTime: formatEventTime(
			parse(`${demandResponseEvent.endDate}T${demandResponseEvent.endTime}`),
		),
		heatTemp,
		coolTemp,
		holdType: demandResponseEvent.type,
		isVoluntaryResponse: demandResponseEvent.isOptional,
		eventType: 'Energy Savings Event',
	}
}

export const getSmartEvent = (events) => {
	const smartAway = events.find(
		(event) => event.running && event.type === SMART_AWAY_EVENT_TYPE,
	)
	const smartHome = events.find(
		(event) => event.running && event.type === SMART_HOME_EVENT_TYPE,
	)
	return (smartAway && smartAway.type) || (smartHome && smartHome.type)
}

export const getThermostatRunningDataWithSmartEvent = (
	{ events, settings: { hvacMode }, runtime: { desiredHeat, desiredCool } },
	runningEventIndex,
) => {
	const currentEvent = events[runningEventIndex]

	const eventCoolTemp = currentEvent.isCoolOff ? 'Off' : desiredCool
	const eventHeatTemp = currentEvent.isHeatOff ? 'Off' : desiredHeat

	const { heatTemp, coolTemp } = getEventTemperature({
		eventCoolTemp,
		eventHeatTemp,
		hvacMode,
	})

	return {
		hasHold: false,
		isSmartEvent: true,
		isSmartAway: getSmartEvent(events) === SMART_AWAY_EVENT_TYPE,
		isSmartHome: getSmartEvent(events) === SMART_HOME_EVENT_TYPE,
		program:
			getSmartEvent(events) === SMART_AWAY_EVENT_TYPE
				? 'Smart Away'
				: 'Smart Home',
		coolTemp,
		heatTemp,
		endTime: formatEventTime(
			parse(`${currentEvent.endDate}T${currentEvent.endTime}`),
		),
	}
}

const getThermostatRunningDataWithNoHold = ({
	settings: { hvacMode },
	program: { currentClimateRef, climates },
	runtime: { desiredHeat, desiredCool },
}) => {
	const eventCoolTemp = desiredCool
	const eventHeatTemp = desiredHeat

	const { heatTemp, coolTemp } = getEventTemperature({
		eventCoolTemp,
		eventHeatTemp,
		hvacMode,
	})
	const currentClimate = getClimate(currentClimateRef, climates)
	return {
		canSetTemporaryHold: hvacMode !== 'off' || false,
		hasHold: false,
		program: currentClimate.name,
		heatTemp,
		coolTemp,
	}
}

const getThermostatRunningDataWithHold = (
	{
		program: { climates },
		events,
		settings: { hvacMode },
		runtime: { desiredHeat, desiredCool },
	},
	runningEventIndex,
) => {
	const currentEvent = events[runningEventIndex]

	const climateId = currentEvent.holdClimateRef

	const currentClimate =
		(climateId && getClimate(climateId, climates)) || emptyClimate

	const eventCoolTemp = currentEvent.isCoolOff ? 'Off' : desiredCool

	const eventHeatTemp = currentEvent.isHeatOff ? 'Off' : desiredHeat

	let eventType
	let hasTemporaryHold = false
	let program

	switch (currentEvent.type) {
		// Temporary Hold
		case 'hold':
			eventType = 'Temporary hold'
			hasTemporaryHold = true
			program = currentClimate.name
			break
		// Event hold
		case 'vacation':
			eventType = currentEvent.name
			program = currentClimate.name
			break
		/* istanbul ignore next */
		default:
	}
	const { heatTemp, coolTemp } = getEventTemperature({
		eventCoolTemp,
		eventHeatTemp,
		hvacMode,
	})

	return {
		canSetTemporaryHold: false,
		hasHold: true,
		endTime: formatEventTime(
			parse(`${currentEvent.endDate}T${currentEvent.endTime}`),
		),
		holdType: currentEvent.type,
		coolTemp,
		heatTemp,
		eventType,
		hasTemporaryHold,
		program,
	}
}

/**
 * Get running thermostat data
 *
 * @param  {object} thermostat Thermostat object form the API
 * @returns {object}            running thermostat data
 */
export const getRunningThermostatData = (thermostat) => {
	const runningEventIndex = getRunningEventIndex(thermostat)

	if (runningEventIndex === NOT_FOUND_INDEX) {
		// Standard Running Program
		return getThermostatRunningDataWithNoHold(thermostat)
	}
	// Running Events
	if (
		thermostat.events[runningEventIndex].type === DEMAND_RESPONSE_EVENT_TYPE
	) {
		return getThermostatRunningDataWithEnergySavings(
			thermostat,
			runningEventIndex,
		)
	}
	// Smart Events
	if (
		thermostat.events[runningEventIndex].type === SMART_AWAY_EVENT_TYPE ||
		thermostat.events[runningEventIndex].type === SMART_HOME_EVENT_TYPE
	) {
		return getThermostatRunningDataWithSmartEvent(thermostat, runningEventIndex)
	}
	// Temporary Hold Event
	return getThermostatRunningDataWithHold(thermostat, runningEventIndex)
}

/**
 * Generate a thermostat overview object for display
 *
 * @param  {object} thermostat A raw thermostat object from the api
 * @returns {object}            A thermostat overview object for display
 */
export const getThermostatOverview = (thermostat) => {
	const {
		alerts,
		runtime,
		settings,
		program,
		equipmentStatus,
		sbMetadata,
	} = thermostat

	if (sbMetadata?.isTenantMode) {
		return null
	}

	const { climates } = program
	const {
		coolRangeLow,
		coolRangeHigh,
		hasHeatPump,
		heatRangeLow,
		heatRangeHigh,
	} = settings
	const { actualTemperature, actualHumidity, desiredFanMode } = runtime
	const { hvacMode, heatCoolMinDelta } = settings
	const currentTemperature =
		actualTemperature === ECOBEE_MAGIC_NUMBER ? ENDASH : actualTemperature
	const humidity =
		actualHumidity === ECOBEE_MAGIC_NUMBER ? ENDASH : actualHumidity
	const alertCount = alerts.filter(
		(alert) => alert.showWeb && alert.acknowledgement === UNACKNOWLEDGED,
	).length
	const thermostatOverview = {
		alertCount,
		coolMinTemp: coolRangeLow,
		coolMaxTemp: coolRangeHigh,
		currentTemperature,
		desiredHeat: runtime.desiredHeat,
		desiredCool: runtime.desiredCool,
		desiredFanMode,
		hasTemporaryHold: false,
		heatMinTemp: heatRangeLow,
		heatMaxTemp: heatRangeHigh,
		humidity,
		rawHvacMode: hvacMode,
		heatCoolMinDelta,
		hvacMode: hvacModeMap[hvacMode],
		hvacModes: getHvacModeOptions(getAvailableHvacModes(thermostat)),
		climates: climates.map((climate) => ({
			value: climate.climateRef,
			title: climate.name,
			isOccupied: climate.isOccupied,
		})),
		...getRunningThermostatData(thermostat),
	}

	if (equipmentStatus && equipmentStatus.length !== 0) {
		thermostatOverview.equipment = equipmentStatus.split(',')
		thermostatOverview.auxIsHeat = !hasHeatPump
	}

	return thermostatOverview
}

/**
 * Determine the event HVAC Mode
 *
 * @param {object} options Options object
 * @param {boolean} options.isHeatOff If the heat is off
 * @param {boolean} options.isCoolOff If the cool is off
 * @returns {string} HVAC mode
 */
export const getEventHvacMode = ({ isHeatOff, isCoolOff }) => {
	if (!isHeatOff && isCoolOff) {
		return 'heat'
	}
	if (isHeatOff && !isCoolOff) {
		return 'cool'
	}
	if (isHeatOff && isCoolOff) {
		return ''
	}

	return 'auto'
}

/**
 * Get a custom event object
 *
 * @param {object} event The origanal thermostat event object
 * @returns {object} Custom event object
 */
export const getEvent = (event) => {
	// Round down start times to handle residential thermostat created events
	const [hour, minute] = event.startTime.split(':')
	const minuteRounded = Number.parseInt(minute, RADIX) < 30 ? '00' : '30'
	return {
		coolTemp: event.coolHoldTemp,
		endDate: moment(event.endDate).startOf('day'),
		endTime: timeMapMoment.indexOf(event.endTime),
		fan: event.fan,
		fanMinOnTime: event.fanMinOnTime,
		heatTemp: event.heatHoldTemp,
		holdClimateRef: event.holdClimateRef,
		hvacMode: getEventHvacMode(event),
		name: event.name,
		startDate: moment(event.startDate).startOf('day'),
		startTime: timeMapMoment.indexOf([hour, minuteRounded, '00'].join(':')),
		endDateFormatted: formatEventTime(`${event.endDate}T${event.endTime}`),
		type: event.type,
	}
}

export const getThermostatOnlineStatusData = ({ runtime }) => {
	const { connected, lastStatusModified, disconnectDateTime } = runtime

	const neverConnected =
		!connected && lastStatusModified === NEVER_ONLINE_STATUS_MODIFIED_DATE

	return {
		isConnected: connected,
		neverConnected,
		lastDisconnected: disconnectDateTime,
	}
}

/**
 * Get the participation status (participating or monitor) for a remote sensor
 *
 * @param {object} options Options object
 * @param {object} options.sensor Remote sensor data
 * @param {string} options.thermostatRemoteSensorType Sensor type supported by the thermostat (wired or wireless)
 * @param {Array} options.thermostatDevices List of thermostat devices
 * @param options.isDisconnected
 * @returns {string} Sensor participation status
 */
export const getSensorParticipationStatus = ({
	isDisconnected,
	sensor,
	thermostatRemoteSensorType,
	thermostatDevices,
}) => {
	const { id, type, inUse } = sensor

	if (isDisconnected) return SENSOR_STATUS_MONITOR

	if (thermostatRemoteSensorType === SENSOR_TYPE_WIRED) {
		const [name, deviceId, sensorId] = id.split(SENSOR_ID_SEPARATOR)
		const hasMeaningfulSensorType = name === SENSOR_NAME_RS

		const isControl = hasMeaningfulSensorType
			? type === SENSOR_TYPE_CONTROL
			: thermostatDevices.some(
					(device) =>
						device.name === name &&
						device.deviceId.toString() === deviceId &&
						device.sensors.some(
							(deviceSensor) =>
								deviceSensor.sensorId.toString() === sensorId &&
								deviceSensor.usage === DEVICE_USAGE_CONTROL,
						),
			  )

		return isControl ? SENSOR_STATUS_PARTICIPATING : SENSOR_STATUS_MONITOR
	}

	return inUse ? SENSOR_STATUS_PARTICIPATING : SENSOR_STATUS_MONITOR
}

export const serializeEventBasedOnType = (event) => {
	let name
	switch (event.type) {
		case DEMAND_RESPONSE_EVENT_TYPE:
			name = event.name
			break
		case SMART_AWAY_EVENT_TYPE:
			name = 'Smart Away'
			break
		case SMART_HOME_EVENT_TYPE:
			name = 'Smart Home'
			break
		default:
			name = event.name
			break
	}
	return {
		...event,
		name,
	}
}

export const formatMacAddress = (macAddress) =>
	macAddress.match(/.{1,2}/g).join(':')

export const hasDisconnectedValue = (sensorCapability) =>
	SENSOR_DISCONNECTED_VALUES.has(sensorCapability.value.toLowerCase())
