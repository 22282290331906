import routesList from '@/routes'
import routingService from '@/services/routing.service'
import * as routesActionTypes from './constants'

/**
 *
 */
function getInitialState() {
	return {
		routesList,
		activeRoute: routingService.getActiveRoute(),
		match: routingService.getMatch(),
		queryParams: routingService.getQueryParams(),
	}
}

const routesReducer = (state = getInitialState(), action = {}) => {
	switch (action.type) {
		case routesActionTypes.UPDATE_ROUTE: {
			const { activeRoute, match, queryParams } = action.payload
			return {
				...state,
				activeRoute,
				match,
				queryParams,
			}
		}
		default:
			return state
	}
}

export default routesReducer
