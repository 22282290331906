import * as toastActionTypes from './constants'

const initialState = {
	isVisible: false,
	link: null,
	message: null,
	type: null,
}

const toastReducer = (state = initialState, action) => {
	switch (action.type) {
		case toastActionTypes.TOAST_SHOW:
			return {
				...action.payload,
				isVisible: true,
			}
		case toastActionTypes.TOAST_DISMISS:
			return {
				...state,
				isVisible: false,
			}
		default:
			return state
	}
}

export default toastReducer
