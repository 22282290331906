import {
	EDL_COLOURS,
	GRAPH_COLOURS,
	NO_DATA_STRING,
} from '@ecobee/smartbuildings-library-common/constants'

export const GET_RUNTIME_REPORT_CHART_DATA_REQUEST =
	'GET_RUNTIME_REPORT_CHART_DATA_REQUEST'
export const GET_RUNTIME_REPORT_CHART_DATA_SUCCESS =
	'GET_RUNTIME_REPORT_CHART_DATA_SUCCESS'
export const GET_RUNTIME_REPORT_CHART_DATA_ERROR =
	'GET_RUNTIME_REPORT_CHART_DATA_ERROR'

export const GET_RUNTIME_REPORT_EXPORT_REQUEST =
	'GET_RUNTIME_REPORT_EXPORT_REQUEST'
export const GET_RUNTIME_REPORT_EXPORT_SUCCESS =
	'GET_RUNTIME_REPORT_EXPORT_SUCCESS'
export const GET_RUNTIME_REPORT_EXPORT_ERROR = 'GET_RUNTIME_REPORT_EXPORT_ERROR'

export const UPDATE_APPLIED_SENSORS = 'UPDATE_APPLIED_SENSORS'

export const scheduleSeriesMap = {
	OCCUPIED: 'occupied',
	UNOCCUPIED: 'unoccupied',
	HOME: 'home',
	AWAY: 'away',
	SLEEP: 'sleep',
	AWAKE: 'wakeup',
	CUSTOM_HOLD: 'customHold',
}

export const scheduleLabelsMap = {
	[scheduleSeriesMap.OCCUPIED]: 'Occupied',
	[scheduleSeriesMap.UNOCCUPIED]: 'Unoccupied',
	[scheduleSeriesMap.HOME]: 'Home',
	[scheduleSeriesMap.AWAY]: 'Away',
	[scheduleSeriesMap.SLEEP]: 'Sleep',
	[scheduleSeriesMap.AWAKE]: 'Awake',
}

export const eventSeriesMap = {
	DEMAND_RESPONSE: 'demandResponse',
	HOLD: 'hold',
	QUICK_SAVE: 'quickSave',
	SMART_AWAY: 'smartAway',
	SMART_HOME: 'smartHome',
	SMART_RECOVERY: 'smartRecovery',
	CUSTOM_EVENT: 'customEvent',
}

export const eventLabelsMap = {
	[eventSeriesMap.DEMAND_RESPONSE]: 'Demand Response',
	[eventSeriesMap.HOLD]: 'Custom Hold',
	[eventSeriesMap.QUICK_SAVE]: 'Quick Save',
	[eventSeriesMap.SMART_AWAY]: 'Smart Away',
	[eventSeriesMap.SMART_HOME]: 'Smart Home',
	[eventSeriesMap.SMART_RECOVERY]: 'Smart Recovery',
	[eventSeriesMap.CUSTOM_EVENT]: 'Event Hold',
}

const baseLineStyles = {
	strokeWidth: 1.5,
	strokeLinejoin: 'round',
	strokeLinecap: 'round',
}
const swimlaneHeight = 18

export const chartStylesMap = {
	outdoorTemp: {
		stroke: EDL_COLOURS.CADET_BLUE,
		...baseLineStyles,
	},
	zoneAveTemp: {
		stroke: EDL_COLOURS.CLOUD_BURST,
		...baseLineStyles,
	},
	zoneCoolTemp: {
		stroke: EDL_COLOURS.PICTON_BLUE,
		...baseLineStyles,
	},
	zoneHeatTemp: {
		stroke: EDL_COLOURS.TREE_POPPY,
		...baseLineStyles,
	},
	fan: {
		stroke: EDL_COLOURS.BLUE_BAYOUX,
		strokeWidth: swimlaneHeight,
	},
	compCool1: {
		stroke: EDL_COLOURS.PICTON_BLUE,
		strokeWidth: swimlaneHeight,
	},
	compCool2: {
		stroke: EDL_COLOURS.INDIGO,
		strokeWidth: swimlaneHeight,
	},
	compHeat1: {
		stroke: EDL_COLOURS.TREE_POPPY,
		strokeWidth: swimlaneHeight,
	},
	compHeat2: {
		stroke: EDL_COLOURS.INTERNATIONAL_ORANGE,
		strokeWidth: swimlaneHeight,
	},
	auxHeat1: {
		stroke: EDL_COLOURS.GUARDSMAN_RED,
		strokeWidth: swimlaneHeight,
	},
	auxHeat2: {
		stroke: GRAPH_COLOURS.AUX_HEAT_2,
		strokeWidth: swimlaneHeight,
	},
	auxHeat3: {
		stroke: EDL_COLOURS.BLACK,
		strokeWidth: swimlaneHeight,
	},
	outdoorHumidity: {
		stroke: EDL_COLOURS.CADET_BLUE,
		...baseLineStyles,
	},
	zoneHumidity: {
		stroke: EDL_COLOURS.CLOUD_BURST,
		...baseLineStyles,
	},
	[scheduleSeriesMap.OCCUPIED]: {
		stroke: GRAPH_COLOURS.SCHEDULE_OCCUPIED,
		strokeWidth: swimlaneHeight,
	},
	[scheduleSeriesMap.UNOCCUPIED]: {
		stroke: EDL_COLOURS.BLUE_BAYOUX,
		strokeWidth: swimlaneHeight,
	},
	[scheduleSeriesMap.SLEEP]: {
		stroke: EDL_COLOURS.INDIGO,
		strokeWidth: swimlaneHeight,
	},
	[scheduleSeriesMap.HOME]: {
		stroke: GRAPH_COLOURS.SCHEDULE_HOME,
		strokeWidth: swimlaneHeight,
	},
	[scheduleSeriesMap.AWAY]: {
		stroke: EDL_COLOURS.CADET_BLUE,
		strokeWidth: swimlaneHeight,
	},
	[scheduleSeriesMap.CUSTOM_HOLD]: {
		stroke: EDL_COLOURS.SEANCE,
		strokeWidth: swimlaneHeight,
	},
	[eventSeriesMap.HOLD]: {
		stroke: GRAPH_COLOURS.EVENT_HOLD,
		strokeWidth: swimlaneHeight,
	},
	[eventSeriesMap.SMART_RECOVERY]: {
		stroke: GRAPH_COLOURS.EVENT_SMARTRECOVERY,
		strokeWidth: swimlaneHeight,
	},
	[eventSeriesMap.SMART_HOME]: {
		stroke: GRAPH_COLOURS.EVENT_SMARTHOME,
		strokeWidth: swimlaneHeight,
	},
	[eventSeriesMap.SMART_AWAY]: {
		stroke: GRAPH_COLOURS.EVENT_SMARTAWAY,
		strokeWidth: swimlaneHeight,
	},
	[eventSeriesMap.QUICK_SAVE]: {
		stroke: EDL_COLOURS.AMARANTH,
		strokeWidth: swimlaneHeight,
	},
	[eventSeriesMap.DEMAND_RESPONSE]: {
		stroke: EDL_COLOURS.FERN,
		strokeWidth: swimlaneHeight,
	},
	[eventSeriesMap.CUSTOM_EVENT]: {
		stroke: GRAPH_COLOURS.EVENT_CUSTOMEVENT,
		strokeWidth: swimlaneHeight,
	},
}

export const legendMap = {
	outdoorTemp: {
		value: NO_DATA_STRING,
		color: EDL_COLOURS.CADET_BLUE,
		legendSymbolType: 'solid-line',
		legendPosition: 100,
		displayName: 'Outdoor',
	},
	zoneAveTemp: {
		value: NO_DATA_STRING,
		color: EDL_COLOURS.CLOUD_BURST,
		legendSymbolType: 'solid-line',
		legendPosition: 101,
		displayName: 'Indoor',
	},
	zoneCoolTemp: {
		value: NO_DATA_STRING,
		color: EDL_COLOURS.PICTON_BLUE,
		legendSymbolType: 'solid-line',
		legendPosition: 102,
		displayName: 'Cool',
	},
	zoneHeatTemp: {
		value: NO_DATA_STRING,
		color: EDL_COLOURS.TREE_POPPY,
		legendSymbolType: 'solid-line',
		legendPosition: 103,
		displayName: 'Heat',
	},
	outdoorHumidity: {
		value: NO_DATA_STRING,
		color: EDL_COLOURS.CADET_BLUE,
		legendSymbolType: 'solid-line',
		legendPosition: 200,
		displayName: 'Outdoor',
	},
	zoneHumidity: {
		value: NO_DATA_STRING,
		color: EDL_COLOURS.CLOUD_BURST,
		legendSymbolType: 'solid-line',
		legendPosition: 201,
		displayName: 'Indoor',
	},
	fanValues: {
		value: NO_DATA_STRING,
		color: EDL_COLOURS.BLUE_BAYOUX,
		legendSymbolType: 'square',
		legendPosition: 300,
		displayName: 'Fan',
	},
	coolValues: {
		value: NO_DATA_STRING,
		color: EDL_COLOURS.PICTON_BLUE,
		legendSymbolType: 'square',
		legendPosition: 301,
		displayName: 'Cool',
	},
	heatValues: {
		value: NO_DATA_STRING,
		color: EDL_COLOURS.TREE_POPPY,
		legendSymbolType: 'square',
		legendPosition: 302,
		displayName: 'Heat',
	},
	auxValues: {
		value: NO_DATA_STRING,
		color: EDL_COLOURS.GUARDSMAN_RED,
		legendSymbolType: 'square',
		legendPosition: 303,
		displayName: 'Aux',
		isHidden: true,
	},
	zoneCalendarEvent: {
		value: NO_DATA_STRING,
		color: null,
		legendSymbolType: 'square',
		legendPosition: 400,
		displayName: null,
	},
	zoneClimate: {
		value: NO_DATA_STRING,
		color: null,
		legendSymbolType: 'square',
		legendPosition: 401,
		displayName: null,
	},
}
