import { takeLatest, put, call } from 'redux-saga/effects'
import status from 'http-status-codes'
import { safeFetch } from '@/services/fetch-api/fetch.api'
import { validateApiResponse } from '@/utils/validators'
import { modalShow } from '@/store/modal/actions'
import { toastShow } from '@/store/toast/actions'
import {
	getCompanyTagsSuccess,
	getCompanyTagsError,
	createTagSuccess,
	createTagError,
	renameTagSuccess,
	renameTagError,
	deleteTagSuccess,
	deleteTagError,
} from './actions'
import {
	GET_COMPANY_TAGS_REQUEST,
	CREATE_TAG_REQUEST,
	RENAME_TAG_REQUEST,
	DELETE_TAG_REQUEST,
} from './constants'

/**
 *
 */
export function* getCompanyTags() {
	const fetchedCompanyTags = yield call(safeFetch, 'getCompanyTags')

	if (validateApiResponse(fetchedCompanyTags)) {
		yield put(getCompanyTagsSuccess(fetchedCompanyTags.data))
	} else {
		yield put(getCompanyTagsError())
	}
}

/**
 * @param action
 */
export function* createTag(action) {
	const { name } = action.payload
	const createTagResponse = yield call(safeFetch, 'createTag', { name })

	if (validateApiResponse(createTagResponse)) {
		const { data: tag } = createTagResponse
		yield put(createTagSuccess(tag))
		yield put(
			toastShow({
				message: `Successfully created the tag '${tag.name}'`,
			}),
		)
	} else if (
		createTagResponse.response.status === status.BAD_REQUEST &&
		createTagResponse.data
	) {
		const [error] = createTagResponse.data.errors || []
		yield put(createTagError({ error }))
	} else {
		yield put(createTagError())
		yield put(
			modalShow({
				title: 'Something went wrong',
				message: 'We were unable to create this tag. Please try again.',
			}),
		)
	}
}

/**
 * @param action
 */
export function* renameTag(action) {
	const { id, name } = action.payload
	const renameTagResponse = yield call(safeFetch, 'updateTag', {
		tagId: id,
		update: { name },
	})

	if (validateApiResponse(renameTagResponse)) {
		yield put(renameTagSuccess({ id, name }))
		yield put(
			toastShow({
				message: `Your tag has been successfully updated`,
			}),
		)
	} else if (
		renameTagResponse.response.status === status.BAD_REQUEST &&
		renameTagResponse.data
	) {
		const [error] = renameTagResponse.data.errors || []
		yield put(renameTagError({ error }))
	} else {
		yield put(renameTagError())
		yield put(
			modalShow({
				title: 'Something went wrong',
				message: 'We were unable to rename this tag. Please try again.',
			}),
		)
	}
}

/**
 * @param action
 */
export function* deleteTag(action) {
	const { id, name } = action.payload
	const deleteTagResponse = yield call(safeFetch, 'deleteTag', { tagId: id })

	if (validateApiResponse(deleteTagResponse)) {
		yield put(deleteTagSuccess({ id }))
		yield put(
			toastShow({
				message: `Your tag '${name}' has been successfully deleted`,
			}),
		)
	} else if (
		deleteTagResponse.response.status === status.BAD_REQUEST &&
		deleteTagResponse.data
	) {
		const [error] = deleteTagResponse.data.errors || []
		yield put(deleteTagError({ error }))
	} else {
		yield put(deleteTagError())
		yield put(
			modalShow({
				title: 'Something went wrong',
				message: 'We were unable to delete this tag. Please try again.',
			}),
		)
	}
}

/**
 *
 */
export function* companyTagsRequestWatcher() {
	yield takeLatest(GET_COMPANY_TAGS_REQUEST, getCompanyTags)
	yield takeLatest(CREATE_TAG_REQUEST, createTag)
	yield takeLatest(RENAME_TAG_REQUEST, renameTag)
	yield takeLatest(DELETE_TAG_REQUEST, deleteTag)
}
