import { takeLatest, call, put } from 'redux-saga/effects'
import { get } from 'lodash'
import { safeFetch } from '@/services/fetch-api/fetch.api'
import { OAUTH_VALIDATION_TOKEN_EXPIRED_ERROR_CODE } from '@/utils/constants'
import { VALIDATION_TOKEN_REQUEST } from './constants'
import * as validationTokenActions from './actions'

/**
 * @param data
 */
export function* handleServerError(data) {
	if (
		get(data, 'errors[0].code') === OAUTH_VALIDATION_TOKEN_EXPIRED_ERROR_CODE
	) {
		yield put(validationTokenActions.validationTokenExpired())
	} else {
		yield put(validationTokenActions.validationTokenError())
	}
}

/**
 * @param action
 */
export function* validationToken(action) {
	const { response, data } = yield call(
		safeFetch,
		'renewToken',
		action.payload.token,
	)

	if (response.ok) {
		yield put(validationTokenActions.validationTokenSuccess(data))
	} else {
		yield call(handleServerError, data)
	}
}

/**
 *
 */
export function* validationTokenRequestWatcher() {
	yield takeLatest(VALIDATION_TOKEN_REQUEST, validationToken)
}
