import { createAction } from '@/utils/actions'
import * as buildingActionTypes from './constants'

export const getBuildingRequest = createAction(
	buildingActionTypes.GET_BUILDING_REQUEST,
)
export const getBuildingSuccess = createAction(
	buildingActionTypes.GET_BUILDING_SUCCESS,
)
export const getBuildingError = createAction(
	buildingActionTypes.GET_BUILDING_ERROR,
)
export const buildingNotFoundError = createAction(
	buildingActionTypes.BUILDING_NOT_FOUND_ERROR,
)
export const resetBuilding = createAction(buildingActionTypes.RESET_BUILDING)
export const resetBuildingErrorAction = createAction(
	buildingActionTypes.RESET_BUILDING_ERROR,
)
export const updateBuildingRequest = createAction(
	buildingActionTypes.UPDATE_BUILDING_REQUEST,
)
export const updateBuildingError = createAction(
	buildingActionTypes.UPDATE_BUILDING_ERROR,
)
export const updateValidationError = createAction(
	buildingActionTypes.UPDATE_BUILDING_VALIDATION_ERROR,
)
export const updateBuildingSuccess = createAction(
	buildingActionTypes.UPDATE_BUILDING_SUCCESS,
)
