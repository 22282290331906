export const GET_COMPANY_TAGS_REQUEST = 'GET_COMPANY_TAGS_REQUEST'
export const GET_COMPANY_TAGS_SUCCESS = 'GET_COMPANY_TAGS_SUCCESS'
export const GET_COMPANY_TAGS_ERROR = 'GET_COMPANY_TAGS_ERROR'

export const CREATE_TAG_REQUEST = 'CREATE_TAG_REQUEST'
export const CREATE_TAG_SUCCESS = 'CREATE_TAG_SUCCESS'
export const CREATE_TAG_ERROR = 'CREATE_TAG_ERROR'

export const RENAME_TAG_REQUEST = 'RENAME_TAG_REQUEST'
export const RENAME_TAG_SUCCESS = 'RENAME_TAG_SUCCESS'
export const RENAME_TAG_ERROR = 'RENAME_TAG_ERROR'

export const DELETE_TAG_REQUEST = 'DELETE_TAG_REQUEST'
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS'
export const DELETE_TAG_ERROR = 'DELETE_TAG_ERROR'

export const RESET_TAG_ERRORS = 'RESET_TAG_ERRORS'
