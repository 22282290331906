const FLAG_DEFAULTS = {
	'show-peak-demand': false,
	'runtime-graphs-zoom-buttons': false,
	'show-bulk-set-tenant-mode': false,
	'thermostat-search': true,
	'delete-custom-comfort-settings': false,
	'secure-invite-flow': false,
}

export default FLAG_DEFAULTS
