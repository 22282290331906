import { createSelector } from 'reselect'
import { compareIgnoreCase } from '@/utils/misc'
import { allThermostatSelector } from '@/store/thermostat-list/selectors'

export const companyTagsSelector = (state) =>
	state.companyTags ? state.companyTags : null

export const companyTagsRequestSelector = createSelector(
	[companyTagsSelector],
	(data) =>
		data !== null && typeof data.isFetching === 'boolean'
			? data.isFetching
			: null,
)

export const companyTagsErrorSelector = createSelector(
	[companyTagsSelector],
	(data) =>
		data !== null && typeof data.isError === 'boolean' ? data.isError : null,
)

export const companyTagsUpdateErrorSelector = createSelector(
	[companyTagsSelector],
	(data) =>
		data !== null && typeof data.isUpdateError === 'boolean'
			? data.isUpdateError
			: null,
)

export const companyTagsErrorMessageSelector = createSelector(
	[companyTagsSelector],
	(data) => (data != null && data.errorMessage ? data.errorMessage : null),
)

export const companyTagsUpdatingSelector = createSelector(
	[companyTagsSelector],
	(data) =>
		data !== null && typeof data.isUpdating === 'boolean'
			? data.isUpdating
			: null,
)

export const companyTagsAllTagsSelector = createSelector(
	[companyTagsSelector],
	(data) => {
		if (data !== null && data.tags && data.tags.length > 0) {
			return [...data.tags].sort((a, b) => compareIgnoreCase(a.name, b.name))
		}
		return []
	},
)

export const companyTagsVisibleTagsSelector = createSelector(
	[companyTagsAllTagsSelector, allThermostatSelector],
	(tags, thermostats) => {
		const visibleTagIds = new Set(
			thermostats.flatMap((thermostat) =>
				thermostat.sbMetadata.tags.map((tag) => tag.id),
			),
		)

		return tags.filter((tag) => visibleTagIds.has(tag.id))
	},
)
