import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ReactIntercom, { IntercomAPI } from 'react-intercom'
import config from 'config'
import { debounce } from 'lodash'

import history from '@/services/history'
import { userInfoSelector } from '@/store/user/selectors'

export const INTERCOM_DEBOUNCE_TIME = 1000
const { INTERCOM_APP_ID } = config

class IntercomContainer extends Component {
	componentDidMount() {
		this.unlisten = history.listen(this.onHistoryChange)
	}

	componentWillUnmount() {
		this.unlisten()
	}

	onHistoryChange = debounce(() => {
		const { userInfo } = this.props
		const { id, userHash } = userInfo || {}

		if (id && userHash) {
			IntercomAPI('update')
		}
	}, INTERCOM_DEBOUNCE_TIME)

	render() {
		const { userInfo } = this.props
		const { firstName, lastName, id, userHash } = userInfo || {}

		const user = {
			user_id: id,
			name: `${firstName} ${lastName}`,
			user_hash: userHash,
		}

		return id && userHash ? (
			<ReactIntercom appID={INTERCOM_APP_ID} {...user} />
		) : null
	}
}

IntercomContainer.propTypes = {
	userInfo: PropTypes.shape({
		email: PropTypes.string,
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		id: PropTypes.string,
	}),
}

const mapStateToProps = /* istanbul ignore next */ (state) => ({
	userInfo: userInfoSelector(state),
})

export { IntercomContainer as IntercomContainerComponent }

export default connect(mapStateToProps)(IntercomContainer)
