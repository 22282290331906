import produce from 'immer'
import { mergeWith } from 'lodash'
import * as deleteAlertActionTypes from '@/store/delete-alert/constants'
import * as thermostatActionTypes from './constants'

// Lodash mergeWith accepts a customizer function which is invoked to produce
// the merged values of the destination and source properties. If the customizer
// returns undefined, merging is handled by the built-in Lodash merge function.
// When Lodash merges arrays, any new values are added to the existing array.
// However, when sending updates to server-core, objects are merged while arrays
// are replaced. This customizer replicates that logic so, for example, a new
// list of comfort settings will replace the old list instead of being merged
// with it. (Otherwise, it would not be possible to delete a comfort setting.)
// eslint-disable-next-line consistent-return
const replaceArraysCustomizer = (objValue, srcValue) => {
	if (Array.isArray(srcValue)) {
		return srcValue
	}
}

const serverCoreMerge = (...args) => mergeWith(...args, replaceArraysCustomizer)

/**
 *
 */
function getInitialState() {
	return {
		isFetching: false,
		isRefreshing: false,
		isError: false,
		isUpdating: false,
		isUpdateError: false,
	}
}

const thermostatReducer = (state = getInitialState(), action) => {
	switch (action.type) {
		case thermostatActionTypes.GET_THERMOSTAT_REQUEST:
			return {
				...state,
				isFetching: true,
			}
		case thermostatActionTypes.GET_THERMOSTAT_SUCCESS:
			return {
				...state,
				isFetching: false,
				isNotFound: false,
				isError: false,
				data: action.payload.thermostat,
			}
		case thermostatActionTypes.GET_THERMOSTAT_ERROR:
			return {
				...state,
				isError: true,
				isNotFound: false,
				isFetching: false,
			}
		case thermostatActionTypes.REFRESH_THERMOSTAT_REQUEST:
			return {
				...state,
				isRefreshing: true,
			}
		case thermostatActionTypes.REFRESH_THERMOSTAT_SUCCESS:
			return {
				...state,
				isRefreshing: false,
				data: action.payload.thermostat,
			}
		case thermostatActionTypes.REFRESH_THERMOSTAT_ERROR:
			return {
				...state,
				isError: true,
				isRefreshing: false,
			}
		case thermostatActionTypes.THERMOSTAT_NOT_FOUND_ERROR:
			return {
				...state,
				isError: false,
				isNotFound: true,
				isFetching: false,
			}
		case thermostatActionTypes.UPDATE_THERMOSTAT_REQUEST:
		case thermostatActionTypes.UPDATE_THERMOSTAT_WITH_FUNCTION_REQUEST:
		case deleteAlertActionTypes.DELETE_ALERT_REQUEST: {
			return {
				...state,
				isUpdating: true,
				isUpdateError: false,
			}
		}
		case thermostatActionTypes.UPDATE_THERMOSTAT_ERROR:
		case deleteAlertActionTypes.DELETE_ALERT_ERROR: {
			return {
				...state,
				isUpdating: false,
				isUpdateError: true,
			}
		}
		case thermostatActionTypes.UPDATE_THERMOSTAT_SUCCESS: {
			const { settingsToUpdate } = action.payload

			const newState = produce(state, (draftState) => {
				draftState.isUpdating = false
				draftState.isUpdateError = false

				if (settingsToUpdate) {
					serverCoreMerge(draftState.data, settingsToUpdate)

					if ('name' in settingsToUpdate) {
						draftState.data.sbMetadata.name = settingsToUpdate.name
					}
				}
			})

			return newState
		}
		case deleteAlertActionTypes.DELETE_ALERT_SUCCESS: {
			const deletedAcknowledgeRef = action.payload
			const newAlerts = state.data.alerts.filter(
				(alert) => alert.acknowledgeRef !== deletedAcknowledgeRef,
			)

			const newState = produce(state, (draftState) => {
				draftState.isUpdating = false
				draftState.isUpdateError = false
				draftState.data.alerts = newAlerts
			})

			return newState
		}
		case thermostatActionTypes.RESET_THERMOSTAT: {
			return getInitialState()
		}
		default:
			return state
	}
}

export default thermostatReducer
