import * as modalActionTypes from './constants'

const initialState = {
	title: 'Something went wrong',
	message: 'We were unable to save your changes. Please try again.',
	type: null,
	isVisible: false,
}

const modalReducer = (state = initialState, action) => {
	switch (action.type) {
		case modalActionTypes.MODAL_SHOW:
			return {
				...state,
				...action.payload,
				isVisible: true,
			}
		case modalActionTypes.MODAL_DISMISS:
			return {
				...state,
				isVisible: false,
			}
		default:
			return state
	}
}

export default modalReducer
