import * as companyActionTypes from './constants'

const getInitialState = () => ({
	isUpdating: false,
	isUpdateError: false,
	isInitializing: false,
	isInitializationError: false,
})

const companyReducer = (state = getInitialState(), action) => {
	switch (action.type) {
		case companyActionTypes.UPDATE_COMPANY_REQUEST: {
			return {
				...state,
				isUpdating: true,
				isUpdateError: false,
			}
		}
		case companyActionTypes.UPDATE_COMPANY_SUCCESS: {
			return {
				...state,
				isUpdating: false,
				isUpdateError: false,
			}
		}
		case companyActionTypes.UPDATE_COMPANY_ERROR: {
			return {
				...state,
				isUpdating: false,
				isUpdateError: true,
			}
		}
		case companyActionTypes.INITIALIZE_MIGRATED_COMPANY_REQUEST: {
			return {
				...state,
				isInitializing: true,
				isInitializationError: false,
			}
		}
		case companyActionTypes.INITIALIZE_MIGRATED_COMPANY_ERROR: {
			return {
				...state,
				isInitializing: false,
				isInitializationError: true,
			}
		}
		case companyActionTypes.INITIALIZE_MIGRATED_COMPANY_SUCCESS: {
			return {
				...state,
				isInitializing: false,
				isInitializationError: false,
			}
		}
		default: {
			return state
		}
	}
}

export default companyReducer
