import produce from 'immer'
import { mergeWith, uniqBy } from 'lodash'
import { NOT_FOUND_INDEX } from '@/utils/constants'
import * as thermostatListActionTypes from './constants'

/**
 *
 */
export function getInitialState() {
	return {
		bulkAction: '',
		isBulkUpdateError: false,
		isBulkUpdating: false,
		isError: false,
		isFetching: false,
		lastQueryString: '',
		selectedThermostatIds: [],
		showAddThermostat: false,
		thermostats: [],
	}
}

const thermostatListReducer = (state = getInitialState(), action) => {
	switch (action.type) {
		case thermostatListActionTypes.GET_THERMOSTAT_LIST_REQUEST:
			return {
				...state,
				isFetching: true,
				isError: false,
			}
		case thermostatListActionTypes.GET_THERMOSTAT_LIST_SUCCESS: {
			return {
				...state,
				isFetching: false,
				isError: false,
				thermostats: action.payload.thermostats,
			}
		}
		case thermostatListActionTypes.GET_THERMOSTAT_LIST_ERROR:
			return {
				...state,
				isError: true,
				isFetching: false,
			}
		case thermostatListActionTypes.SHOW_ADD_THERMOSTAT:
			return {
				...state,
				showAddThermostat: true,
			}
		case thermostatListActionTypes.DISMISS_ADD_THERMOSTAT:
			return {
				...state,
				showAddThermostat: false,
			}
		case thermostatListActionTypes.SELECT_THERMOSTAT: {
			const thermostats = action.payload.thermostat
			const thermostatToSelect = Array.isArray(thermostats)
				? thermostats
				: [thermostats]
			// Unique concat of the two arrays
			const selectedThermostatIds = [
				...new Set(state.selectedThermostatIds.concat(thermostatToSelect)),
			]
			return {
				...state,
				selectedThermostatIds,
			}
		}
		case thermostatListActionTypes.DESELECT_THERMOSTAT: {
			const thermostats = action.payload.thermostat
			const thermostatsToDeselect = Array.isArray(thermostats)
				? thermostats
				: [thermostats]
			const selectedThermostatIds = state.selectedThermostatIds.filter(
				(thermostat) => !thermostatsToDeselect.includes(thermostat),
			)
			return {
				...state,
				selectedThermostatIds,
			}
		}
		case thermostatListActionTypes.SELECT_ALL_THERMOSTAT: {
			const selectedThermostatIds = state.thermostats.map(
				(thermostat) => thermostat.identifier,
			)
			return {
				...state,
				selectedThermostatIds,
			}
		}
		case thermostatListActionTypes.DESELECT_ALL_THERMOSTAT:
			return {
				...state,
				selectedThermostatIds: [],
			}
		case thermostatListActionTypes.BULK_UPDATE_THERMOSTAT_REQUEST:
		case thermostatListActionTypes.BULK_UPDATE_TAGS_REQUEST:
		case thermostatListActionTypes.BULK_UPDATE_MOVE_THERMOSTAT_REQUEST:
		case thermostatListActionTypes.BULK_DELETE_THERMOSTAT_REQUEST:
			return {
				...state,
				isBulkUpdating: true,
				isBulkUpdateError: false,
			}
		case thermostatListActionTypes.BULK_UPDATE_THERMOSTAT_SUCCESS:
			return {
				...state,
				isBulkUpdating: false,
				isBulkUpdateError: false,
			}
		case thermostatListActionTypes.BULK_UPDATE_TAGS_SUCCESS: {
			const { thermostatIds, tagsAdded, tagsRemoved } = action.payload
			const tagIsRemoved = (tag) =>
				tagsRemoved.some((removedTag) => tag.id === removedTag.id)

			return {
				...state,
				isBulkUpdating: false,
				isBulkUpdateError: false,
				thermostats: state.thermostats.map((thermostat) => {
					if (!thermostatIds.includes(thermostat.id)) {
						return thermostat
					}

					const { tags } = thermostat.sbMetadata
					const updatedTags = uniqBy([...tags, ...tagsAdded], 'id').filter(
						(tag) => !tagIsRemoved(tag),
					)

					return produce(thermostat, (draftThermostat) => {
						draftThermostat.sbMetadata.tags = updatedTags
					})
				}),
			}
		}
		case thermostatListActionTypes.BULK_UPDATE_THERMOSTAT_ERROR:
		case thermostatListActionTypes.BULK_UPDATE_TAGS_ERROR:
			return {
				...state,
				isBulkUpdating: false,
				isBulkUpdateError: true,
			}
		case thermostatListActionTypes.SET_BULK_UPDATE_ACTION:
			return {
				...state,
				bulkAction: action.payload.bulkAction,
			}
		case thermostatListActionTypes.RESET_BULK_UPDATE_ACTION:
			return {
				...state,
				bulkAction: '',
			}
		case thermostatListActionTypes.INLINE_UPDATE_THERMOSTAT_LIST: {
			const { id, settingsToUpdate } = action.payload
			// id can be array of ids, or string converted to single element array
			const idList = Array.isArray(id) ? id : [id]
			const thermostats = state.thermostats.map((thermostat) => {
				const idIndex = idList.indexOf(thermostat.identifier)
				if (idIndex === NOT_FOUND_INDEX) {
					return thermostat
				}
				// settingsToUpdate can be an object or an array of objects
				const newSettingsToUpdate = Array.isArray(settingsToUpdate)
					? settingsToUpdate[idIndex]
					: settingsToUpdate

				const customizer = (objVal, srcVal) => {
					if (Array.isArray(objVal)) {
						return srcVal
					}
				}

				const newThermostat = produce(thermostat, (draftThermostat) => {
					mergeWith(draftThermostat, newSettingsToUpdate, customizer)
				})

				return newThermostat
			})

			return {
				...state,
				thermostats,
			}
		}
		case thermostatListActionTypes.RESET_THERMOSTAT_LIST_STATE:
			return getInitialState()
		case thermostatListActionTypes.APPLY_FILTER:
			return {
				...state,
				selectedThermostatIds: [],
			}
		case thermostatListActionTypes.UPDATE_LAST_QUERY_STRING:
			return {
				...state,
				lastQueryString: action.payload,
			}
		default:
			return state
	}
}

export default thermostatListReducer
