import { THERMOSTAT_TYPE_MIXED } from '@ecobee/smartbuildings-library-common/constants'
import { THERMOSTAT_MODEL_MAP } from '@ecobee/smartbuildings-library-common/maps'
import { hvacModeMap, FILTERS } from '@/utils/maps'
import {
	thermostatListGetHvacMode,
	getThermostatListSetTemperature,
} from '@/utils/helpers/hvac.helpers'
import { getClimate, getEventType } from '@/utils/helpers/program.helpers'
import { ECOBEE_MAGIC_NUMBER, EMPTY_INDEX, ENDASH } from '@/utils/constants'
import {
	getRunningEventIndex,
	getThermostatOnlineStatusData,
} from '@/store/thermostat/selectors.helpers'

/**
 * Generate an array of thermostat objects with data for thermostat list
 *
 * @param {object[]} thermostats Array of full thermostat objects
 * @param {object[]} buildings Array of building objects
 * @returns {object[]} Array of thermostat objects containing data required to render thermostat list
 */
export const getThermostatListArray = (thermostats, buildings) =>
	thermostats.map((thermostat) => {
		const { sbMetadata = {} } = thermostat
		const { buildingId, tags = [] } = sbMetadata
		const building = buildings.find(({ id }) => id === buildingId)
		const onlineStatusData = getThermostatOnlineStatusData(thermostat)

		const baseThermostatData = {
			id: thermostat.identifier,
			name: sbMetadata.name || thermostat.identifier,
			buildingName: building?.name || null,
			tags,
			...onlineStatusData,
		}

		if (sbMetadata.isTenantMode) {
			return {
				...baseThermostatData,
				isTenantMode: true,
			}
		}

		const eventIndex = getRunningEventIndex(thermostat)
		const hvacMode = thermostatListGetHvacMode(thermostat)
		const {
			actualTemperature,
			actualHumidity,
			desiredFanMode,
		} = thermostat.runtime
		const currentTemperature =
			actualTemperature === ECOBEE_MAGIC_NUMBER ? ENDASH : actualTemperature
		const humidity =
			actualHumidity === ECOBEE_MAGIC_NUMBER ? ENDASH : actualHumidity
		thermostatListGetHvacMode(thermostat)
		const alertCount = thermostat.alerts.filter((alert) => alert.showWeb).length

		return {
			...baseThermostatData,
			currentMode: hvacModeMap[hvacMode],
			currentTemperature,
			setTemperature: getThermostatListSetTemperature(thermostat),
			setIsHold: eventIndex !== EMPTY_INDEX,
			eventType:
				eventIndex === EMPTY_INDEX
					? ''
					: getEventType(thermostat.events[eventIndex]),
			currentProgram: getClimate(
				thermostat.program.currentClimateRef,
				thermostat.program.climates,
			).name,
			setFan: desiredFanMode,
			humidity,
			alertCount,
			isTenantMode: false,
		}
	})

/**
 * Returns the type (commercial, residential, legacy residential, or mixed) of a list of thermostats
 *
 * @param   {Array} thermostats A set of thermostats to check the types of
 * @returns {string}            The type of the thermostats
 */
export const getTypeForThermostats = (thermostats = []) => {
	if (thermostats.length === 0) {
		return null
	}
	return thermostats.reduce((lastType, { modelNumber }) => {
		const currentType = THERMOSTAT_MODEL_MAP[modelNumber]
			? THERMOSTAT_MODEL_MAP[modelNumber].type
			: modelNumber

		if (!lastType) {
			return currentType
		}
		if (lastType === currentType) {
			return lastType
		}
		return THERMOSTAT_TYPE_MIXED
	}, null)
}

/**
 * Determines if a given thermostat matches connection status provided
 *
 * @param   {object} thermostat     Thermostat object
 * @param   {Array}  filteredValues Array of fitered values to compare
 * @returns {boolean}               True if it matches, false otherwise
 */
export const matchesConnectionStatusFilter = (thermostat, filteredValues) => {
	const mappedValues = {
		online: true,
		offline: false,
	}
	return filteredValues.some(
		(value) => thermostat.runtime.connected === mappedValues[value],
	)
}

/**
 * Determines if a given thermostat matches the HVAC modes provided
 *
 * @param {object} thermostat Thermostat object
 * @param {string[]} filteredValues Array of filtered HVAC modes to compare
 * @returns {boolean} True if thermostat matches any of the provided HVAC modes
 */
export const matchesHvacModeFilter = (thermostat, filteredValues) => {
	const thermostatMode = thermostatListGetHvacMode(thermostat)
	return filteredValues.some((value) => thermostatMode === value)
}

/**
 * Determines if a given thermostat matches the programs provided
 *
 * @param {object} thermostat Thermostat object
 * @param {string[]} filteredValues Array of filtered climate refs to compare
 * @returns {boolean} True if thermostat matches any of the provided climate refs
 */
export const matchesProgramFilter = (thermostat, filteredValues) =>
	filteredValues.some(
		(value) =>
			!thermostat.sbMetadata.isTenantMode &&
			thermostat.program?.currentClimateRef === value,
	)

/**
 * Determines if a given thermostat is tagged with one of the filtered tag IDs
 *
 * @param   {object} thermostat     Thermostat object
 * @param   {Array}  filteredValues Array of fitered tagIds to compare
 * @returns {boolean}               True if it matches, false otherwise
 */
export const matchesTagFilter = (thermostat, filteredValues) =>
	filteredValues.some((filteredTagIds) =>
		thermostat.sbMetadata.tags.find(
			(thermostatTag) => thermostatTag.id === filteredTagIds,
		),
	)

/**
 * Removes invalid values from a provided array of values for a filter type
 *
 * @param {string} filterId Filter ID to return valid values for
 * @param {string[]} filterValues Array of unvalidated filter values
 * @returns {string[]} Array of validated filter values
 */
export const getValidFilterValues = (filterId, filterValues) => {
	const selectedFilter = FILTERS[filterId]

	if (!selectedFilter.items) {
		return filterValues
	}

	const validFilterValues = new Set(selectedFilter.items.map((item) => item.id))

	return filterValues.filter((value) => validFilterValues.has(value))
}
