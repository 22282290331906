import { takeLatest, put, call, select } from 'redux-saga/effects'

import {
	thermostatIdSelector,
	thermostatAlertsSelector,
} from '@/store/thermostat/selectors'

import * as toastActions from '@/store/toast/actions'
import * as modalActions from '@/store/modal/actions'
import { safeFetch } from '@/services/fetch-api/fetch.api'
import { validateApiResponse, validateApiStatusCode } from '@/utils/validators'
import { DELETE_ALERT_REQUEST } from './constants'
import { deleteAlertError, deleteAlertSuccess } from './actions'

/**
 * @param action
 */
export function* deleteAlert(action) {
	const alertId = action.payload

	const thermostatId = yield select(thermostatIdSelector)

	const apiResponse = yield call(safeFetch, 'updateThermostatWithFunction', {
		id: thermostatId,
		functions: [
			{
				type: 'acknowledge',
				params: {
					thermostatIdentifier: thermostatId,
					ackRef: alertId,
					ackType: 'accept',
				},
			},
		],
	})

	const { data } = apiResponse
	if (validateApiResponse(apiResponse) && validateApiStatusCode(data)) {
		yield put(deleteAlertSuccess(alertId))

		const alerts = yield select(thermostatAlertsSelector)

		if (alerts.length === 0) {
			yield put(
				toastActions.toastShow({
					message: 'All alerts have been cleared.',
				}),
			)
		}
	} else {
		yield put(deleteAlertError())

		yield put(
			modalActions.modalShow({
				title: 'Something went wrong',
				message: 'We were unable to dismiss your alert. Please try again.',
			}),
		)
	}
}

/**
 *
 */
export function* deleteAlertRequestWatcher() {
	yield takeLatest(DELETE_ALERT_REQUEST, deleteAlert)
}
