import { takeLatest, call, put } from 'redux-saga/effects'
import { userRequest } from '@/store/user/sagas'
import { updateRoute } from '@/store/routes/sagas'
import history from '@/services/history'
import { BASE_ROUTE } from '@/utils/constants'
import * as applicationActions from './actions'
import { APPLICATION_REQUEST } from './constants'

/**
 * @param action
 */
export function* application(action) {
	const isAuthenticated = action.payload
	const currentPath = history.location.pathname
	if (isAuthenticated) {
		yield call(userRequest)

		if (currentPath !== BASE_ROUTE) {
			yield call(updateRoute)
		}
	}
	yield put(applicationActions.applicationSuccess())
}

/**
 *
 */
export function* applicationRequestWatcher() {
	yield takeLatest(APPLICATION_REQUEST, application)
}
