import { combineReducers } from 'redux'
import addBuildingReducer from './add-building/reducers'
import applicationReducer from './application/reducers'
import buildingsListReducer from './building-list/reducers'
import buildingsReducer from './building/reducers'
import bulkFunctionUpdateReducer from './bulk-functions-update/reducers'
import companyReducer from './company/reducers'
import companyTagsReducer from './company-tags/reducers'
import companyUsersReducer from './company-users/reducers'
import createSubscriptionReducer from './create-subscription/reducers'
import createAccountReducer from './account-creation/reducers'
import deleteBuildingReducer from './building-delete/reducers'
import editCompanyUserReducer from './edit-company-user/reducers'
import forgotPasswordreducer from './forgot-password/reducers'
import getCompanyUserSingleReducer from './get-company-user-single/reducers'
import invitationAcceptReducer from './accept-invite/reducers'
import inviteUserReducer from './invite-user/reducers'
import loginReducer from './login/reducers'
import modalReducer from './modal/reducers'
import registerThermostatReducer from './register-thermostat/reducers'
import resetPasswordReducer from './reset-password/reducers'
import revokeUserReducer from './revoke-user/reducers'
import routesReducer from './routes/reducers'
import runtimeReportsReducer from './runtime-reports/reducers'
import thermostatListReducer from './thermostat-list/reducers'
import thermostatReducer from './thermostat/reducers'
import toastReducer from './toast/reducers'
import updateSensorReducer from './update-sensor/reducers'
import userReducer from './user/reducers'
import validateEmailReducer from './validate-email/reducers'
import validationTokenReducer from './validation-token/reducers'

export const makeRootReducer = (asyncReducers) =>
	combineReducers({
		acceptInvite: invitationAcceptReducer,
		addBuilding: addBuildingReducer,
		application: applicationReducer,
		building: buildingsReducer,
		buildingList: buildingsListReducer,
		bulkFunctionUpdate: bulkFunctionUpdateReducer,
		company: companyReducer,
		companyTags: companyTagsReducer,
		companyUsers: companyUsersReducer,
		createSubscription: createSubscriptionReducer,
		createAccount: createAccountReducer,
		deleteBuilding: deleteBuildingReducer,
		editCompanyUser: editCompanyUserReducer,
		forgotPassword: forgotPasswordreducer,
		getCompanyUserSingle: getCompanyUserSingleReducer,
		inviteUser: inviteUserReducer,
		login: loginReducer,
		modal: modalReducer,
		registerThermostat: registerThermostatReducer,
		resetPassword: resetPasswordReducer,
		revokeUser: revokeUserReducer,
		routes: routesReducer,
		runtimeReports: runtimeReportsReducer,
		thermostat: thermostatReducer,
		thermostatList: thermostatListReducer,
		toast: toastReducer,
		updateSensor: updateSensorReducer,
		user: userReducer,
		validateEmail: validateEmailReducer,
		validationToken: validationTokenReducer,
		...asyncReducers,
	})

export const injectReducer = (store, { key, reducer }) => {
	if (Object.hasOwnProperty.call(store.asyncReducers, key)) {
		return
	}

	store.asyncReducers[key] = reducer // eslint-disable-line no-param-reassign
	store.replaceReducer(makeRootReducer(store.asyncReducers))
}
