const { isGlobalAccessUser } = require('./permissions')

/**
 * Return an array of resource id strings by type
 * @param  {Array}  resources Array of resources
 * @param  {String} type      Type of resource to parse
 * @return {Array}            Array of resource id strings
 */
const getResourceIdsByType = (resources, type) => {
	return resources
		.filter((resource) => {
			return resource.type === type
		})
		.map((resource) => {
			return resource.id
		})
}

/**
 * Determines if a user's permissions grant access to any one of a list of resources
 * @param {object} companyUser Company user object containing permissions and resourceAccess
 * @param {object[]} resources An array of resource objects, each with an ID and type
 * @returns True if the user is authorized to manage any of the resources, otherwise false
 */
const isAuthorizedToManageResources = (companyUser, resources) => {
	const { resourceAccess } = companyUser

	if (isGlobalAccessUser(companyUser)) {
		return true
	}

	const authorizedResourceIds = new Set(
		resourceAccess.map((resource) => resource.id),
	)

	return resources.some((resource) => authorizedResourceIds.has(resource.id))
}

module.exports = {
	getResourceIdsByType,
	isAuthorizedToManageResources,
}
