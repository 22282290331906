import { getRunningThermostatData } from '@/store/thermostat/selectors.helpers'

const TEMPLATE = 'template'

/**
 * Get the human readable HVAC mode currently set on the thermostat
 *
 * @param   {object} thermostat The thermostat object from the API
 * @returns {string}            Human readable HVAC mode
 */
export const thermostatListGetHvacMode = (thermostat) => {
	const { settings, events, sbMetadata } = thermostat

	// We don't know the HVAC mode for tenant mode thermostats, so return null
	if (sbMetadata?.isTenantMode || !events || !settings) {
		return null
	}

	const runningEvent = events.find((eventObj) => eventObj.running)
	const settingMode = settings.hvacMode

	if (runningEvent && runningEvent.type !== TEMPLATE) {
		const { isHeatOff, isCoolOff } = runningEvent
		switch (settingMode) {
			case 'cool':
				if (isCoolOff) return 'off'
				break

			case 'heat':
				if (isHeatOff) return 'off'
				break

			case 'auto':
				if (isHeatOff && isCoolOff) {
					return 'off'
				}
				if (isHeatOff) {
					return 'cool'
				}
				if (isCoolOff) {
					return 'heat'
				}
				break

			case 'auxHeatOnly':
				if (isHeatOff) return 'off'
				break

			default:
		}
	}

	return settingMode
}

/**
 * Get a human readable display for the set mode of a thermostat
 *
 * @param   {object} thermostat The thermostat object from the API
 * @returns {string}            Current thermostat set mode
 */
export const getThermostatListSetTemperature = (thermostat) => {
	const thermostatData = getRunningThermostatData(thermostat)
	const mode = thermostatListGetHvacMode(thermostat)

	switch (mode) {
		case 'auto':
			return [thermostatData.heatTemp, thermostatData.coolTemp]
		case 'heat':
		case 'auxHeatOnly':
			return [thermostatData.heatTemp]

		case 'cool':
			return [thermostatData.coolTemp]
		default:
			return '\u2013'
	}
}
