import { takeLatest, put, call } from 'redux-saga/effects'
import { safeFetch } from '@/services/fetch-api/fetch.api'
import { validateApiResponse } from '@/utils/validators'
import {
	COMPANY_USERS_REQUEST,
	COMPANY_USERS_SUCCESS,
	COMPANY_USERS_ERROR,
} from './constants'

/**
 *
 */
export function* companyUsers() {
	const fetchedCompanyUsers = yield call(safeFetch, 'getCompanyUsers')
	if (validateApiResponse(fetchedCompanyUsers)) {
		yield put({
			type: COMPANY_USERS_SUCCESS,
			payload: fetchedCompanyUsers.data,
		})
	} else {
		yield put({
			type: COMPANY_USERS_ERROR,
		})
	}
}

/**
 *
 */
export function* companyUsersRequestWatcher() {
	yield takeLatest(COMPANY_USERS_REQUEST, companyUsers)
}
