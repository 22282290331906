import * as resetPasswordActionTypes from './constants'

/**
 *
 */
function getInitialState() {
	return {
		inProgress: false,
		isError: false,
	}
}

const resetPasswordReducer = (state = getInitialState(), action) => {
	switch (action.type) {
		case resetPasswordActionTypes.RESET_PASSWORD_REQUEST: {
			return {
				...state,
				inProgress: true,
				isError: false,
			}
		}
		case resetPasswordActionTypes.RESET_PASSWORD_SUCCESS:
		case resetPasswordActionTypes.RESET_PASSWORD_RESET:
			return getInitialState()
		case resetPasswordActionTypes.RESET_PASSWORD_ERROR: {
			return {
				...state,
				inProgress: false,
				isError: true,
			}
		}
		default: {
			return state
		}
	}
}

export default resetPasswordReducer
