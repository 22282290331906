import * as deleteBuildingActionTypes from './constants'

/**
 *
 */
function getInitialState() {
	return {
		isDeleting: false,
		isDeleteError: false,
	}
}

const addBuildingReducer = (state = getInitialState(), action) => {
	switch (action.type) {
		case deleteBuildingActionTypes.DELETE_BUILDING_REQUEST:
			return {
				...state,
				isDeleting: true,
			}
		case deleteBuildingActionTypes.DELETE_BUILDING_SUCCESS:
			return getInitialState()
		case deleteBuildingActionTypes.DELETE_BUILDING_RESET:
			return getInitialState()
		case deleteBuildingActionTypes.DELETE_BUILDING_ERROR: {
			return {
				...state,
				isDeleting: false,
				isDeleteError: true,
			}
		}
		default:
			return state
	}
}

export default addBuildingReducer
