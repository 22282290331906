module.exports.COMPANY_ID_HEADER = 'x-smartbuildings-company'
module.exports.PLATFORM_HEADER = 'x-smartbuildings-platform'
module.exports.SMARTBUILDINGS_SELECTION_TYPE = 'smartBuildings'
module.exports.CELSIUS_UNIT = 'C'
module.exports.FAHRENHEIT_UNIT = 'F'
module.exports.CANONICAL_VALUE_UNKNOWN = -5002
module.exports.CANONICAL_VALUE_CALIBRATING = -5003
module.exports.MIN_CANONICAL_VALUE = -100
module.exports.MAX_CANONICAL_VALUE = 1200
module.exports.MIN_HUMIDITY = 1
module.exports.MAX_HUMIDITY = 100
module.exports.MS_PER_SECOND = 1000
module.exports.QUERY_STRING_MAX_ARRAY_LENGTH = 50
module.exports.TAG_MAX_CHARS = 50
module.exports.MONTHS_PER_YEAR = 12
module.exports.CENTS_PER_DOLLAR = 100
module.exports.PRICE_DECIMAL_POINTS = 2
module.exports.EDL_COLOURS = {
	BLACK: '#000000',
	WHITE: '#ffffff',

	ALABASTER: '#f8f8f8',
	AMARANTH: '#e31c56',
	AQUA_SPRING: '#e6f8f8',
	ATHENS_GRAY: '#e5e8eb',
	BLUE_BAYOUX: '#4a5f78',
	BRIDESMAID: '#feeeee',
	CADET_BLUE: '#a9b8c7',
	CAPE_COD: '#38393a',
	CARNATION: '#f85757',
	CATSKILL_WHITE: '#f3f5f9',
	CERULEAN: '#00aaf7',
	CLOUD_BURST: '#203148',
	CORDUROY: '#5d5e5e',
	EGGSHELL_BLUE: '#00b8b3',
	FERN: '#5bbd76',
	GEYSER: '#d0d9e2',
	GOLD: '#ffd600',
	GUARDSMAN_RED: '#d50000',
	INDIGO: '#3e51b8',
	INTERNATIONAL_ORANGE: '#ff5300',
	JAVA: '#1ae7ac',
	MIDNIGHT_BLACK: '#101519',
	MONZA: '#dc0034',
	PEAR: '#cddc24',
	PERSIAN_GREEN: '#009788',
	PICTON_BLUE: '#32b9e6',
	PIZAZZ: '#ff9700',
	POLAR: '#fafefe',
	PRIMROSE: '#d4eb8d',
	SEANCE: '#9d22b1',
	SHARK: '#2a2d34',
	SILVER_SAND: '#babcbe',
	THUNDER: '#231f20',
	TREE_POPPY: '#f78c21',
}

module.exports.GRAPH_COLOURS = {
	AUX_HEAT_2: '#650000', // FIXME can this be derived from GUARDSMAN_RED?
	SCHEDULE_OCCUPIED: '#00b8b3',
	SCHEDULE_HOME: '#178387',
	EVENT_HOLD: '#9ff8f1',
	EVENT_SMARTRECOVERY: '#82ea3d',
	EVENT_SMARTHOME: '#5ed1ff',
	EVENT_SMARTAWAY: '#a558ff',
	EVENT_CUSTOMEVENT: '#54dbd4',
}

// Numbers are EDL colour IDs
module.exports.ROTATION_COLOURS = {
	GUARDSMAN_RED: 0,
	SEANCE: 1,
	INDIGO: 2,
	CERULEAN: 3,
	JAVA: 4,
	PERSIAN_GREEN: 5,
	PEAR: 6,
	GOLD: 7,
	PIZAZZ: 8,
	INTERNATIONAL_ORANGE: 9,
}

module.exports.CUSTOM_COLOURS = {
	EGGSHELL_BLUE: 100,
	FERN: 101,
	AMARANTH: 102,
	PICTON_BLUE: 103,
	TREE_POPPY: 104,
	CLOUD_BURST: 105,
	BLUE_BAYOUX: 106,
	CADET_BLUE: 107,
	GEYSER: 108,
	CATSKILL_WHITE: 109,
}

// VALIDATORS
module.exports.INVALID_NAME_REGEX = /[/\\]/

// THERMOSTAT EVENTS NAMES
module.exports.CALENDER_EVENT_HOLD = 'hold'
module.exports.CALENDER_EVENT_SMARTRECOVERY = 'smartRecovery'
module.exports.CALENDER_EVENT_SMARTHOME = 'smartHome'
module.exports.CALENDER_EVENT_SMARTAWAY = 'smartAway'
module.exports.CALENDER_EVENT_QUICKSAVE = 'quickSave'
module.exports.CALENDER_EVENT_DEMANDRESPONSE = 'demandResponse'

// Miscellaneous strings
module.exports.NO_DATA_STRING = ' - '
module.exports.DEGREE_SYMBOL = '°'
module.exports.PERCENT_SYMBOL = '%'

// Alert push notifications
module.exports.ALERT_NOTIFICATION_CHANNEL_ID = 'alerts'
module.exports.ALERT_NOTIFICATION_TTL_SECONDS = 60 * 60 * 24 // 1 day
module.exports.ALERT_NOTIFICATION_UNKNOWN_TEXT =
	'Please check the thermostat for details'

// Honeycomb serviceNames
module.exports.HONEYCOMB_SERVICE_NAME_OAUTH = 'OAuth Service'
module.exports.HONEYCOMB_SERVICE_NAME_ORCHESTRATION_LAYER =
	'Orchestration Layer'
module.exports.HONEYCOMB_SERVICE_NAME_RESOURCE = 'Resource Service'
module.exports.HONEYCOMB_DATASET_SMARTBUILDINGS = 'smartBuildings'
module.exports.HONEYCOMB_SERVICE_NAME_ALERT_DELIVERY = 'Alert Delivery'

// Thermostat types
module.exports.THERMOSTAT_TYPE_COMMERCIAL = 'commercial'
module.exports.THERMOSTAT_TYPE_RESIDENTIAL = 'residential'
module.exports.THERMOSTAT_TYPE_LEGACY_RESIDENTIAL = 'legacyResidential'
module.exports.THERMOSTAT_TYPE_MIXED = 'mixed'

// Miscellaneous sensor strings
module.exports.SENSOR_TYPE_THERMOSTAT = 'thermostat'
module.exports.SENSOR_TYPE_WIRED = 'wired'
module.exports.SENSOR_TYPE_WIRELESS = 'wireless'
module.exports.SENSOR_TYPE_UNKNOWN = 'unknown'

module.exports.SENSOR_MODE_CONTROL = 'Control'
module.exports.SENSOR_MODE_MONITOR = 'Monitor'

module.exports.MODEL_ID_SMART_SENSOR = 'smartSensor'
module.exports.MODEL_ID_ROOM_SENSOR = 'roomSensor'
module.exports.MODEL_ID_INTERNAL_SENSOR = 'thermostatInternalSensor'
module.exports.MODEL_ID_HECATE = 'hecateSensor'

module.exports.MAX_SENSOR_NAME_LENGTH = 32
