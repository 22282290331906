import { takeLatest, call, put } from 'redux-saga/effects'
import { safeFetch } from '@/services/fetch-api/fetch.api'
import { loginRequest } from '@/store/login/actions'
import { login } from '@/store/login/sagas'
import history from '@/services/history'
import { BASE_ROUTE } from '@/utils/constants'
import * as resetPasswordActions from './actions'
import { RESET_PASSWORD_REQUEST } from './constants'

/**
 * @param action
 */
export function* resetPassword(action) {
	const { payload } = action
	const { token, password } = payload
	const apiResponse = yield call(safeFetch, 'resetPassword', {
		token,
		password,
	})
	const { response, data } = apiResponse

	if (response.ok && data.email) {
		const username = data.email
		yield call(login, loginRequest({ username, password }))
		yield call([history, 'replace'], { pathname: BASE_ROUTE })
		yield put(resetPasswordActions.resetPasswordSuccess())
	} else {
		yield put(resetPasswordActions.resetPasswordError())
	}
}

/**
 *
 */
export function* resetPasswordRequestWatcher() {
	yield takeLatest(RESET_PASSWORD_REQUEST, resetPassword)
}
