import { Serializer as JSONApiSerializer } from 'jsonapi-serializer'

export const BuildingSerializer = new JSONApiSerializer('building', {
	attributes: [
		'address',
		'city',
		'country',
		'fullAddress',
		'energySettings',
		'latitude',
		'longitude',
		'name',
		'readOnly',
		'size',
		'state',
		'timeZone',
		'zipCode',
	],
	keyForAttribute: /* istanbul ignore next */ (key) => key,
	pluralizeType: false,
})

export const CompanySerializer = new JSONApiSerializer('company', {
	attributes: ['hostedPageId', 'password'],
	keyForAttribute: /* istanbul ignore next */ (key) => key,
	pluralizeType: false,
})

export const CompanyUserSerializer = new JSONApiSerializer('companyUser', {
	attributes: ['roles', 'resourceAccess'],
	keyForAttribute: /* istanbul ignore next */ (key) => key,
	pluralizeType: false,
})
