import { takeLatest, call, put } from 'redux-saga/effects'
import { get } from 'lodash'
import { safeFetch } from '@/services/fetch-api/fetch.api'
import history from '@/services/history'
import { SERVER_ERROR, TOKEN_KEY } from '@/utils/constants'
import { getPathPrefix } from '@/utils/misc'
import { FORGOT_PASSWORD_REQUEST } from './constants'
import * as forgotPasswordActions from './actions'

/**
 * @param email
 */
export function* isEmailValid(email) {
	const results = yield call(safeFetch, 'validateCompanyEmail', {
		email,
	})

	const { data, response } = results

	if (response.ok && !data.isValid) {
		return true
	}
	if (response.ok && data.isValid) {
		return false
	}

	throw new Error(
		get(results, 'error.returnedData.errors[0].code', SERVER_ERROR),
	)
}

/**
 * @param email
 */
export function* sendEmail(email) {
	const pathPrefix = getPathPrefix(history)
	const linkPathPrefix = `${pathPrefix}/reset?${TOKEN_KEY}=`
	const port = location.port ? { port: location.port } : {}

	const results = yield call(safeFetch, 'sendForgotPasswordEmail', {
		email,
		...port,
		linkPathPrefix,
	})

	if (results.response.ok) {
		return results.response
	}

	throw new Error(
		get(results, 'error.returnedData.errors[0].code', SERVER_ERROR),
	)
}

/**
 * @param email
 */
export function* sendPasswordResetEmail(email) {
	try {
		yield call(sendEmail, email)
		yield put(forgotPasswordActions.forgotPasswordSuccess())
	} catch (error) {
		yield put(forgotPasswordActions.forgotPasswordError(error.message))
	}
}

/**
 * @param action
 */
export function* forgotPassword(action) {
	const email = action.payload
	try {
		const emailValid = yield call(isEmailValid, email)
		if (emailValid) {
			yield call(sendPasswordResetEmail, email)
		} else {
			yield put(forgotPasswordActions.forgotPasswordInvalidEmail())
		}
	} catch (error) {
		yield put(forgotPasswordActions.forgotPasswordError(error.message))
	}
}

/**
 *
 */
export function* forgotPasswordRequestWatcher() {
	yield takeLatest(FORGOT_PASSWORD_REQUEST, forgotPassword)
}
