import * as getCompanyUserSingleActionTypes from './constants'

/**
 *
 */
function getInitialState() {
	return {
		inProgress: false,
		isError: false,
		user: null,
	}
}

const getCompanyUserSingleReducer = (state = getInitialState(), action) => {
	switch (action.type) {
		case getCompanyUserSingleActionTypes.GET_COMPANY_USER_SINGLE_REQUEST: {
			return {
				...state,
				inProgress: true,
				isError: false,
			}
		}
		case getCompanyUserSingleActionTypes.GET_COMPANY_USER_SINGLE_SUCCESS:
			return {
				...getInitialState(),
				user: action.payload,
			}
		case getCompanyUserSingleActionTypes.GET_COMPANY_USER_SINGLE_RESET:
			return getInitialState()
		case getCompanyUserSingleActionTypes.GET_COMPANY_USER_SINGLE_ERROR: {
			return {
				...state,
				inProgress: false,
				isError: true,
			}
		}
		default: {
			return state
		}
	}
}

export default getCompanyUserSingleReducer
