import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { isAuthorizedToManageResources } from '@ecobee/smartbuildings-library-common/resources'
import {
	userAuthorizedPermissionsSelector,
	activeCompanySelector,
} from '@/store/user/selectors'

export const checkIsAuthorized = (authorizedPermissions) =>
	authorizedPermissions.length > 0

export const usePermissions = (permissions) => {
	const authorizedPermissions = useSelector((state) =>
		userAuthorizedPermissionsSelector(state, permissions),
	)
	const isAuthorized = checkIsAuthorized(authorizedPermissions)

	// Values are returned as a tuple to encourage renaming
	return [isAuthorized, authorizedPermissions]
}

const useResourceAccess = (resource) => {
	const activeCompany = useSelector((state) => activeCompanySelector(state))

	if (!resource) {
		return true
	}
	if (!activeCompany) {
		return false
	}

	// If a single resource object is passed, this will wrap it in an array, and
	// if it's already an array of resources, it won't be changed
	const resources = [].concat(resource)

	return isAuthorizedToManageResources(activeCompany, resources)
}

const IfAuthorized = (props) => {
	const { permissions, resource, render, fallbackRender, children } = props

	const [isAuthorizedByRole, authorizedPermissions] = usePermissions(
		permissions,
	)
	const isAuthorizedForResource = useResourceAccess(resource)

	const isAuthorized = isAuthorizedByRole && isAuthorizedForResource

	const hasRenderProp = render != null
	const hasFallbackRenderProp = fallbackRender != null
	const hasChildren = children != null

	if (hasRenderProp && (isAuthorized || !hasFallbackRenderProp)) {
		return render(isAuthorized, authorizedPermissions)
	}
	if (hasChildren && isAuthorized) {
		return children
	}
	if (hasFallbackRenderProp && !isAuthorized) {
		return fallbackRender()
	}
	return null
}

IfAuthorized.propTypes = {
	permissions: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.object),
		PropTypes.object,
	]),
	render: PropTypes.func,
	fallbackRender: PropTypes.func,
	children: PropTypes.node,
}

export default IfAuthorized
