import * as validationTokenActionTypes from './constants'

/**
 *
 */
function getInitialState() {
	return {
		inProgress: false,
		isExpired: false,
		isError: false,
		isValid: false,
		key: null,
		email: null,
		type: null,
		id: null,
	}
}

const validationTokenReducer = (state = getInitialState(), action) => {
	switch (action.type) {
		case validationTokenActionTypes.VALIDATION_TOKEN_REQUEST: {
			return {
				...state,
				key: action.payload.token,
				inProgress: true,
				isExpired: false,
				isError: false,
				isValid: false,
			}
		}
		case validationTokenActionTypes.VALIDATION_TOKEN_SUCCESS: {
			return {
				...state,
				...action.payload,
				inProgress: false,
				isExpired: false,
				isError: false,
				isValid: true,
			}
		}
		case validationTokenActionTypes.VALIDATION_TOKEN_RESET:
			return getInitialState()
		case validationTokenActionTypes.VALIDATION_TOKEN_EXPIRED: {
			return {
				...state,
				inProgress: false,
				isExpired: true,
				isError: false,
				isValid: false,
			}
		}
		case validationTokenActionTypes.VALIDATION_TOKEN_ERROR: {
			return {
				...state,
				...action.payload,
				inProgress: false,
				isExpired: false,
				isError: true,
				isValid: false,
			}
		}
		default: {
			return state
		}
	}
}

export default validationTokenReducer
