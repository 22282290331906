import { createSelector } from 'reselect'
import { buildingIdSelector } from '@/store/building/selectors'
import { buildingToOption } from '@/utils/transformations'

export const buildingsListSelector = (state) =>
	state.buildingList ? state.buildingList : null

export const allBuildingsSelector = createSelector(
	buildingsListSelector,
	(buildingList) => {
		const { buildings } = buildingList
		return Array.isArray(buildings) ? buildings : []
	},
)

export const allBuildingsAsOptionsSelector = createSelector(
	buildingsListSelector,
	buildingIdSelector,
	(buildingList, buildingId) => {
		const { buildings } = buildingList
		if (!Array.isArray(buildings)) {
			return []
		}
		return buildings
			.filter((building) => {
				if (buildingId && buildingId === building.id) {
					return false
				}
				return true
			})
			.map(buildingToOption)
	},
)

export const numberOfBuildingsSelector = createSelector(
	allBuildingsSelector,
	(buildings) => buildings.length,
)

export const buildingsIsFetchingSelector = createSelector(
	buildingsListSelector,
	(buildings) =>
		buildings !== null && typeof buildings.isFetching === 'boolean'
			? buildings.isFetching
			: null,
)

export const buildingsIsErrorSelector = createSelector(
	buildingsListSelector,
	(buildings) =>
		buildings !== null && typeof buildings.isError === 'boolean'
			? buildings.isError
			: null,
)

export const showAddBuildingSelector = createSelector(
	buildingsListSelector,
	(buildings) =>
		buildings !== null && typeof buildings.showAddBuilding === 'boolean'
			? buildings.showAddBuilding
			: null,
)
