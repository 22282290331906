import * as LDClient from 'launchdarkly-js-client-sdk'
import config from 'config'
import { mapValues } from 'lodash'

const ANONYMOUS_USER = { anonymous: true }

const getUser = (userInfo) => {
	const { id, firstName, lastName, email } = userInfo || {}
	return id ? { key: id, firstName, lastName, email } : ANONYMOUS_USER
}

class FeatureFlagsService {
	flags = {}

	changeListeners = new Set()

	init = (userInfo) => {
		if (this.ldClient) {
			throw new Error(
				'FeatureFlagsService.init() was called on an already initialized instance',
			)
		}

		const options = { bootstrap: 'localStorage', streaming: true }

		this.ldClient = LDClient.initialize(
			config.LD_CLIENT_ID,
			getUser(userInfo),
			options,
		)
		this.ldClient.on('ready', this.onReady)
		this.ldClient.on('change', this.onChange)
	}

	identify = (userInfo) => this.ldClient.identify(getUser(userInfo))

	onReady = () => {
		this.flags = this.ldClient.allFlags()
		this.changeListeners.forEach((listener) => {
			listener(this.getFlags())
		})
	}

	onChange = (changes) => {
		// Extract the .current property from each flag in the changeset
		// (`changes` will look like: { 'flag-name': { previous: 'previous value', current: 'current value' } })
		const newFlags = mapValues(changes, 'current')

		this.flags = { ...this.flags, ...newFlags }

		this.changeListeners.forEach((listener) => {
			listener(this.getFlags())
		})
	}

	addListener = (listener) => {
		listener(this.getFlags())
		this.changeListeners.add(listener)
	}

	removeListener = (listener) => {
		this.changeListeners.delete(listener)
	}

	getFlags = () => this.flags

	getFlag = (key, defaultValue = null) =>
		this.ldClient.variation(key, defaultValue)
}

export { FeatureFlagsService as FeatureFlagsServiceClass }

const featureFlagsService = new FeatureFlagsService()
export default featureFlagsService
