import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './select.scss'

const PLACEHOLDER_VALUE = ''

class Select extends Component {
	handleChange = (event) => {
		const { onChange } = this.props
		if (onChange) {
			onChange(event.target.value)
		}
	}

	render() {
		const {
			value,
			options,
			label,
			isLabelHidden,
			id,
			isFixedWidth,
			isDisabled,
			placeholder,
			...selectProps
		} = this.props
		const labelClassNames = classNames('cmp__select__label', {
			'cmp__select__label--hidden': isLabelHidden,
		})
		const selectClassNames = classNames(
			'cmp__select__select',
			'edl-textfield',
			{
				'edl-textfield--disabled': isDisabled,
				'cmp__select__select--isFixedWidth': isFixedWidth,
			},
		)
		const inputClassNames = classNames('edl-textfield__input', {
			'cmp__select--placeholder-selected':
				placeholder && value === PLACEHOLDER_VALUE,
		})
		return (
			<div className="cmp__select">
				{label && (
					<div className={labelClassNames}>
						<label htmlFor={id}>{label}</label>
					</div>
				)}
				<div className={selectClassNames}>
					<select
						{...selectProps}
						id={id}
						value={value}
						onBlur={this.handleChange}
						onChange={this.handleChange}
						disabled={isDisabled}
						className={inputClassNames}
					>
						{placeholder && (
							<option disabled value={PLACEHOLDER_VALUE}>
								{placeholder}
							</option>
						)}
						{options.map((option) => (
							<option value={option.value} key={option.value}>
								{option.title}
							</option>
						))}
					</select>
				</div>
			</div>
		)
	}
}

Select.propTypes = {
	id: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	options: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
	).isRequired,
	label: PropTypes.string,
	isLabelHidden: PropTypes.bool,
	onChange: PropTypes.func,
	isFixedWidth: PropTypes.bool,
	isDisabled: PropTypes.bool,
	placeholder: PropTypes.string,
}

export default Select
