import { takeLatest, call, put } from 'redux-saga/effects'
import { userRequest } from '@/store/user/sagas'
import { loginRequest } from '@/store/login/actions'
import { login } from '@/store/login/sagas'
import { safeFetch } from '@/services/fetch-api/fetch.api'
import errorLoggingService from '@/services/error.logging.service'
import history from '@/services/history'
import * as invitationAcceptActions from './actions'
import { INVITATION_ACCEPT_REQUEST } from './constants'

/**
 * @param action
 */
export function* invitationAccept(action) {
	const { email, firstName, lastName, password, token } = action.payload
	const payload = {
		firstName,
		lastName,
		password,
	}
	const { authenticated } = action.payload
	let apiResponse
	if (authenticated) {
		apiResponse = yield call(safeFetch, 'acceptAuthenticatedInvite', {
			token,
		})
	} else {
		apiResponse = yield call(safeFetch, 'acceptInvite', {
			token,
			payload,
		})
	}

	const { response } = apiResponse

	if (response.ok) {
		if (!authenticated) {
			yield call(login, loginRequest({ username: email, password }))
		} else {
			const userPayload = { forceFetch: true }
			yield call(userRequest, { userPayload })
		}
		yield call([history, 'replace'], { pathname: '/' })
		yield put(invitationAcceptActions.invitationAcceptSuccess())
	} else {
		if (!authenticated) {
			errorLoggingService.notify('Cannot create account')
		} else {
			errorLoggingService.notify('Cannot accept invite')
		}
		yield put(invitationAcceptActions.invitationAcceptError())
	}
}

/**
 *
 */
export function* invitationAcceptRequestWatcher() {
	yield takeLatest(INVITATION_ACCEPT_REQUEST, invitationAccept)
}
