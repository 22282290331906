export const GET_THERMOSTAT_REQUEST = 'GET_THERMOSTAT_REQUEST'
export const GET_THERMOSTAT_SUCCESS = 'GET_THERMOSTAT_SUCCESS'
export const GET_THERMOSTAT_ERROR = 'GET_THERMOSTAT_ERROR'
export const REFRESH_THERMOSTAT_REQUEST = 'REFRESH_THERMOSTAT_REQUEST'
export const REFRESH_THERMOSTAT_SUCCESS = 'REFRESH_THERMOSTAT_SUCCESS'
export const REFRESH_THERMOSTAT_ERROR = 'REFRESH_THERMOSTAT_ERROR'
export const THERMOSTAT_NOT_FOUND_ERROR = 'THERMOSTAT_NOT_FOUND_ERROR'
export const UPDATE_THERMOSTAT_REQUEST = 'UPDATE_THERMOSTAT_REQUEST'
export const UPDATE_THERMOSTAT_WITH_FUNCTION_REQUEST =
	'UPDATE_THERMOSTAT_WITH_FUNCTION_REQUEST'
export const UPDATE_THERMOSTAT_ERROR = 'UPDATE_THERMOSTAT_ERROR'
export const UPDATE_THERMOSTAT_SUCCESS = 'UPDATE_THERMOSTAT_SUCCESS'
export const RESET_THERMOSTAT = 'RESET_THERMOSTAT'
export const GET_RUNTIME_REPORT_EXPORT_REQUEST =
	'GET_RUNTIME_REPORT_EXPORT_REQUEST'
export const GET_RUNTIME_REPORT_EXPORT_SUCCESS =
	'GET_RUNTIME_REPORT_EXPORT_SUCCESS'
export const GET_RUNTIME_REPORT_EXPORT_ERROR = 'GET_RUNTIME_REPORT_EXPORT_ERROR'
