import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './textfield.scss'

class TextField extends Component {
	handleChange = (event) => {
		const { onChange } = this.props
		if (onChange) {
			onChange(event.target.value)
		}
	}

	handleBlur = (event) => {
		const { onBlur } = this.props
		if (onBlur) {
			onBlur(event.target.value)
		}
	}

	handleFocus = (event) => {
		const { onFocus } = this.props
		if (onFocus) {
			onFocus(event.target.value)
		}
	}

	render() {
		const {
			ariaLabel,
			ariaLabelledBy,
			autoComplete,
			error,
			errorMessage,
			forwardRef,
			icon,
			id,
			isDisabled,
			isLabelHidden,
			label,
			maxLength,
			name,
			placeholder,
			textAlign,
			type,
			value,
			...inputProps
		} = this.props

		const textfieldClass = classNames({
			'edl-textfield': true,
			'edl-textfield--error': error,
			'edl-textfield--disabled': isDisabled,
		})
		const inputClass = classNames('edl-textfield__input', {
			[`edl-textfield__input--align-${textAlign}`]: textAlign != null,
			'edl-textfield__input--icon': icon != null,
		})
		const labelClass = classNames('edl-textfield__label', {
			'edl-textfield__label--hidden': isLabelHidden,
		})

		return (
			<div className={textfieldClass}>
				{label && (
					<label htmlFor={id} className={labelClass}>
						{label}
					</label>
				)}
				<div className="edl-textfield__wrapper">
					<input
						{...inputProps}
						data-testid={id}
						aria-invalid={error}
						aria-label={ariaLabel}
						aria-labelledby={ariaLabelledBy}
						autoComplete={autoComplete}
						className={inputClass}
						disabled={isDisabled}
						id={id}
						maxLength={maxLength}
						onBlur={this.handleBlur}
						onChange={this.handleChange}
						onFocus={this.handleFocus}
						placeholder={placeholder}
						type={type}
						name={name}
						value={value}
						ref={forwardRef}
					/>
					{icon && <div className="cmp-textfield__icon">{icon}</div>}
				</div>
				{errorMessage && (
					<div
						className="edl-textfield__error-message"
						data-testid={
							error
								? 'textfield-error-message-visible'
								: 'textfield-error-message-hidden'
						}
					>
						{errorMessage}
					</div>
				)}
			</div>
		)
	}
}

TextField.defaultProps = {
	type: 'text',
	icon: null,
	isLabelHidden: false,
}

TextField.propTypes = {
	ariaLabel: PropTypes.string,
	ariaLabelledBy: PropTypes.string,
	autoComplete: PropTypes.string,
	error: PropTypes.bool,
	errorMessage: PropTypes.string,
	forwardRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
	]),
	icon: PropTypes.node,
	id: PropTypes.string,
	isDisabled: PropTypes.bool,
	isLabelHidden: PropTypes.bool,
	label: PropTypes.string,
	maxLength: PropTypes.number,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	placeholder: PropTypes.string,
	textAlign: PropTypes.oneOf(['left', 'center', 'right']),
	type: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export { TextField as TextFieldComponent }

export default React.forwardRef((props, ref) => (
	<TextField {...props} forwardRef={ref} />
))
