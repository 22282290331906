import {
	DEMAND_RESPONSE_EVENT_TYPE,
	HOLD_EVENT_TYPE,
	VACATION_EVENT_TYPE,
	SMART_AWAY_EVENT_TYPE,
	SMART_HOME_EVENT_TYPE,
} from '@/utils/constants'
/**
 * Get the name of the hold based on event type
 *
 * @param   {object} event event object
 * @returns {string}       Name of event
 */
export const getEventType = (event) => {
	switch (event.type) {
		case HOLD_EVENT_TYPE: {
			return 'Temporary hold'
		}
		case VACATION_EVENT_TYPE: {
			return event.name
		}
		case DEMAND_RESPONSE_EVENT_TYPE: {
			return 'Energy Savings'
		}
		case SMART_AWAY_EVENT_TYPE: {
			return 'Smart Away'
		}
		case SMART_HOME_EVENT_TYPE: {
			return 'Smart Home'
		}
		default:
			return ''
	}
}

/**
 * Gets a climate object given the climate id
 *
 * @param   {string} id        Climate machine id
 * @param   {Array}  climates  An array of climate objects
 * @returns {object}           The climate object that matches
 */
export const getClimate = (id, climates) => {
	const climateObject = climates.find((climate) => climate.climateRef === id)
	return climateObject
}
