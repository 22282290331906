const { ALERT_NUMBERS, ALERTS } = require('./maps')
const {
	convertFahrenheitToCanonical,
	convertTemperatureDifferenceToCanonical,
	convertUserTemperatureToCanonical,
} = require('./conversions')
const {
	CELSIUS_UNIT,
	FAHRENHEIT_UNIT,
	MIN_CANONICAL_VALUE,
	MAX_CANONICAL_VALUE,
} = require('./constants')

const NUMBER_REGEX = /[+-]?\d{1,3}(\.\d{1,2})?/g

/**
 * Scrape data from an alert
 * @param  {number} alertNumber The alert identifier
 * @param  {string} text The original text from the alert
 * @param  {string} thermostatTemperatureUnit Unit used on the thermostat, either "F" or "C"
 * @return {Array} Resulting scraped data as an array of strings and numbers
 */
const getAlertData = (
	alertNumber,
	text,
	thermostatTemperatureUnit = FAHRENHEIT_UNIT,
) => {
	const isCelsius = thermostatTemperatureUnit === CELSIUS_UNIT

	switch (alertNumber) {
		case ALERT_NUMBERS.LOW_TEMPERATURE:
		case ALERT_NUMBERS.HIGH_TEMPERATURE:
		case ALERT_NUMBERS.TOO_WARM_FOR_AUX_HEAT: {
			const result = text.match(NUMBER_REGEX)
			if (result && result.length) {
				const value = result[0].trim()
				return [convertUserTemperatureToCanonical(value, isCelsius)]
			}
			return []
		}
		case ALERT_NUMBERS.LOW_HUMIDITY:
		case ALERT_NUMBERS.HIGH_HUMIDITY: {
			const result = text.match(NUMBER_REGEX)
			if (result && result.length) {
				return [result[0].trim()]
			}
			return []
		}
		case ALERT_NUMBERS.HEATING_ISSUE_1:
		case ALERT_NUMBERS.HEATING_ISSUE_2:
		case ALERT_NUMBERS.HEATING_ISSUE_3:
		case ALERT_NUMBERS.HEATING_ISSUE_4: {
			const result = text.match(NUMBER_REGEX)
			if (result && result.length) {
				const temperature = result[1].trim()
				const increaseDecreaseText = text.includes('decreased')
					? 'decreased'
					: 'increased'
				return [
					increaseDecreaseText,
					convertTemperatureDifferenceToCanonical(temperature, isCelsius),
				]
			}
			return []
		}
		case ALERT_NUMBERS.AUX_HEAT_ON_FOR_TOO_LONG: {
			const result = text.match(NUMBER_REGEX)
			if (result && result.length) {
				return [result[1].trim()]
			}
			return []
		}
		case ALERT_NUMBERS.SENSOR_BATTERY_LOW:
		case ALERT_NUMBERS.SENSOR_COMMUNICATION_ISSUE:
		case ALERT_NUMBERS.SENSOR_COMMUNICATION_RESTORED: {
			const result = text.match(/\byour\b(.+?)\bsensor\b/i)
			if (result && result.length) {
				return [result[1].trim()]
			}
			return []
		}
	}
	return []
}

/**
 * Decorate an alert with parsed data for display
 * @param  {Object} alert Alert object from a thermostat object
 * @param  {string} thermostatTemperatureUnit Unit used on the thermostat, either "F" or "C"
 * @return {Object} Parsed alert object
 */
const parseAlert = (alert, thermostatTemperatureUnit) => {
	const { alertNumber, date, text, time } = alert
	return {
		...alert,
		title: ALERTS[alertNumber] || `Alert ${alertNumber}`,
		data: getAlertData(alertNumber, text, thermostatTemperatureUnit),
		date: `${date} ${time}`,
	}
}

/**
 * Get available HVAC modes for a given thermostat
 * @param {Object} thermostat Thermostat object from the API
 * @returns {Array} Array of HVAC mode strings
 */
const getAvailableHvacModes = (thermostat) => {
	const { sbMetadata = {}, settings } = thermostat

	// Thermostats in tenant mode will have no settings object
	// We can pretend that they aren't capable of any HVAC modes so they never get included in the list of capable thermostats for bulk operations
	if (sbMetadata.isTenantMode || !settings) {
		return []
	}

	const { coolStages, hasHeatPump, heatStages } = settings
	const hasCooling = coolStages > 0
	const hasHeating = heatStages > 0

	const modes = ['off']

	if (hasHeatPump) {
		modes.push('heat', 'cool', 'auto')
		// Heat pumps use the cool terminal for both heating and cooling
		// (the direction, i.e., heat vs. cool, is controlled using a signal on a separate wire)
		// If there are also heatStages, those are used for separate equipment (i.e., aux heat)
		if (hasHeating) modes.push('auxHeatOnly')
	} else {
		if (hasHeating) modes.push('heat')
		if (hasCooling) modes.push('cool')
		if (hasCooling && hasHeating) modes.push('auto')
	}

	return modes
}

const isFahrenheitTemperatureValid = (temp) => {
	const canonical = convertFahrenheitToCanonical(temp)
	return canonical >= MIN_CANONICAL_VALUE && canonical <= MAX_CANONICAL_VALUE
}

module.exports = {
	parseAlert,
	getAlertData,
	getAvailableHvacModes,
	isFahrenheitTemperatureValid,
}
