import React from 'react'
import ErrorBoundary from '@/components/error-boundary'
import { getDisplayName, withDisplayName } from './display.name.helpers'

export const withLoadingState = (LoadingComponent, ErrorComponent) => (
	WrappedComponent,
) => {
	const WithLoadingState = (props) => (
		<ErrorBoundary FallbackComponent={() => <ErrorComponent {...props} />}>
			<React.Suspense fallback={<LoadingComponent {...props} />}>
				<WrappedComponent {...props} />
			</React.Suspense>
		</ErrorBoundary>
	)

	const displayName = `WithLoadingState(${getDisplayName(WrappedComponent)})`
	return withDisplayName(WithLoadingState, displayName)
}
