import produce from 'immer'
import AuthService from '@/services/auth.service'
import * as companyActionTypes from '@/store/company/constants'
import * as subscriptionActionTypes from '@/store/create-subscription/constants'
import * as userActionTypes from './constants'

const getInitialState = () => ({
	userInfo: AuthService.getUser(),
	isFetching: false,
	isError: false,
	isUpdating: false,
	isUpdateError: false,
})

const userReducer = (state = getInitialState(), action) => {
	switch (action.type) {
		case userActionTypes.GET_USER_REQUEST:
			return {
				...state,
				isFetching: true,
				isError: false,
			}
		case userActionTypes.GET_USER_ERROR:
			return {
				...state,
				isFetching: false,
				isError: true,
			}
		case userActionTypes.GET_USER_SUCCESS:
			return {
				...state,
				isFetching: false,
				isError: false,
				userInfo: action.payload.userInfo,
			}
		case userActionTypes.RESET_USER:
			return getInitialState()
		case userActionTypes.UPDATE_USER_REQUEST: {
			return {
				...state,
				isUpdating: true,
				isUpdateError: false,
			}
		}
		case userActionTypes.UPDATE_USER_SUCCESS: {
			const { userInfo } = action.payload || {}
			const preferences = userInfo?.preferences
			return {
				...state,
				userInfo: {
					...state.userInfo,
					...userInfo,
					preferences: {
						...state.userInfo.preferences,
						...preferences,
					},
				},
				isUpdating: false,
				isUpdateError: false,
			}
		}
		case userActionTypes.UPDATE_USER_RESET:
			return getInitialState()
		case userActionTypes.UPDATE_USER_ERROR: {
			return {
				...state,
				isUpdating: false,
				isUpdateError: true,
			}
		}
		case subscriptionActionTypes.CREATE_SUBSCRIPTION_SUCCESS: {
			const { companyId } = action.payload

			return produce(state, (draftState) => {
				draftState.userInfo?.companies?.forEach((draftCompany) => {
					if (draftCompany.id === companyId) {
						draftCompany.hasActiveSubscription = true
					}
				})
			})
		}
		case companyActionTypes.UPDATE_COMPANY_SUCCESS: {
			const { companyId, companyName, country } = action.payload

			return produce(state, (draftState) => {
				draftState.userInfo?.companies?.forEach((draftCompany) => {
					if (draftCompany.id === companyId) {
						draftCompany.name = companyName
						draftCompany.country = country
					}
				})
			})
		}
		default:
			return state
	}
}

export default userReducer
