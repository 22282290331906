import { createAction } from '@/utils/actions'
import * as deleteBuildingActionTypes from './constants'

export const deleteBuildingRequest = createAction(
	deleteBuildingActionTypes.DELETE_BUILDING_REQUEST,
)
export const deleteBuildingReset = createAction(
	deleteBuildingActionTypes.DELETE_BUILDING_RESET,
)
export const deleteBuildingSuccess = createAction(
	deleteBuildingActionTypes.DELETE_BUILDING_SUCCESS,
)
export const deleteBuildingError = createAction(
	deleteBuildingActionTypes.DELETE_BUILDING_ERROR,
)
