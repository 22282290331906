import * as updateSensorActionTypes from './constants'

/**
 *
 */
function getInitialState() {
	return {
		isUpdating: false,
		isError: false,
	}
}

const updateSensorReducer = (state = getInitialState(), action) => {
	switch (action.type) {
		case updateSensorActionTypes.UPDATE_SENSOR_REQUEST: {
			return {
				isUpdating: true,
				isError: false,
			}
		}
		case updateSensorActionTypes.UPDATE_SENSOR_SUCCESS:
			return getInitialState()
		case updateSensorActionTypes.UPDATE_SENSOR_ERROR: {
			return {
				isUpdating: false,
				isError: true,
			}
		}
		default: {
			return state
		}
	}
}

export default updateSensorReducer
