import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { permissions } from '@ecobee/smartbuildings-library-common/permissions'
import { initializeMigratedCompanyRequest } from '@/store/company/actions'
import {
	activeCompanyEmsMetadataSelector,
	activeCompanyNameSelector,
	hasActiveSubscriptionSelector,
	hasManagementRefSelector,
	userIsFetchingSelector,
} from '@/store/user/selectors'
import SBModal from '@/components/sb-modal'
import MigrationSetupModal from '@/components/migration-setup-modal'
import { usePermissions } from '@/utils/auth'
import { companyIsInitializingSelector } from '@/store/company/selectors'

const MigrationSetupContainer = () => {
	const hasActiveSubscription = useSelector((state) =>
		hasActiveSubscriptionSelector(state),
	)
	const emsMetadata = useSelector((state) =>
		activeCompanyEmsMetadataSelector(state),
	)
	const hasManagementRef = useSelector((state) =>
		hasManagementRefSelector(state),
	)
	const companyName = useSelector((state) => activeCompanyNameSelector(state))
	const isFetchingUser = useSelector((state) => userIsFetchingSelector(state))
	const [isAuthorized] = usePermissions(permissions.SUBSCRIPTION.MANAGE)

	const dispatch = useDispatch()

	const initializeMigratedCompany = (companyData) =>
		dispatch(initializeMigratedCompanyRequest(companyData))
	const isInitializing = useSelector((state) =>
		companyIsInitializingSelector(state),
	)

	const title = 'Confirm your organization'

	return (
		<SBModal
			label={title}
			isOpen={
				isAuthorized &&
				hasActiveSubscription === false &&
				hasManagementRef === true
			}
		>
			<MigrationSetupModal
				emsCompanyName={companyName}
				emsMetadata={emsMetadata}
				isFetchingUser={isFetchingUser}
				initializeMigratedCompany={initializeMigratedCompany}
				isInitializing={isInitializing}
				title={title}
			/>
		</SBModal>
	)
}

export default MigrationSetupContainer
