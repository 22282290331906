import { matchPath } from 'react-router-dom'
import queryString from 'query-string'
import { updateRoute } from '@/store/routes/actions'
import history from './history'
import AuthService from './auth.service'

export class RoutingService {
	constructor() {
		this.routes = []
		this.onRouteChange = () => {}
	}

	init(routeList) {
		this.routes = routeList

		this.updateRoute()
		history.listen((location) => {
			this.updateRoute(location.pathname, location.search)
		})
	}

	updateRoute = (
		pathname = history.location.pathname,
		search = history.location.search,
	) => {
		const appAuthenticatedState = AuthService.isAuthenticated()
		const routeAuthMatches = (route) =>
			route.authenticated === appAuthenticatedState
		const routePathMatches = (match) => match !== null

		const initialRoute = { activeRoute: null, match: null }

		const { activeRoute, match } = this.routes.reduce(
			(possibleMatchedRoute, currentRoute) => {
				if (possibleMatchedRoute !== initialRoute) {
					return possibleMatchedRoute
				}

				const { exact, pattern } = currentRoute
				const matchedPath = matchPath(pathname, { exact, path: pattern })

				return routePathMatches(matchedPath) && routeAuthMatches(currentRoute)
					? {
							activeRoute: currentRoute,
							match: matchedPath,
					  }
					: possibleMatchedRoute
			},
			initialRoute,
		)

		const queryParams = queryString.parse(search, { arrayFormat: 'bracket' })

		this.activeRoute = activeRoute
		this.match = match
		this.queryParams = queryParams

		this.onRouteChange({ activeRoute, match, queryParams })
	}

	getMatch() {
		return this.match
	}

	getActiveRoute() {
		return this.activeRoute
	}

	getQueryParams() {
		return this.queryParams
	}

	subscribe(store) {
		this.onRouteChange = ({ activeRoute, match, queryParams }) => {
			store.dispatch(updateRoute({ activeRoute, match, queryParams }))
		}
	}
}

const routingService = new RoutingService()

export { RoutingService as RoutingServiceClass }

export default routingService
