import { useState, useEffect } from 'react'
import { noop } from 'lodash'

// Based on https://usehooks.com/useScript/

const cachedScriptSrcs = new Set()

/* istanbul ignore next - integration test */
/**
 * Inject an external script and track loading and error state. The script
 * source URL is cached and will only be injected into the DOM once.
 *
 * Note that the onInitialLoad handler is only run once, on initial injection,
 * and not when the script URL is already in the cache of injected scripts.
 *
 * @param {object} opts Script options
 * @param {string} opts.src The script source URL
 * @param {Function} opts.onInitialLoad A function to run after the script is initially injected
 * @returns {boolean[]} A tuple with two booleans: isLoaded and isError
 */
export default function useScript({ src, onInitialLoad = noop }) {
	const [state, setState] = useState({
		loaded: false,
		error: false,
	})

	useEffect(() => {
		if (cachedScriptSrcs.has(src)) {
			setState({
				loaded: true,
				error: false,
			})

			return
		}

		cachedScriptSrcs.add(src)

		const script = document.createElement('script')

		const onScriptLoad = async () => {
			await onInitialLoad()

			setState({
				loaded: true,
				error: false,
			})
		}

		const onScriptError = () => {
			cachedScriptSrcs.delete(src)
			document.body.removeChild(script)

			setState({
				loaded: false,
				error: true,
			})
		}

		script.src = src
		script.async = true
		script.addEventListener('load', onScriptLoad)
		script.addEventListener('error', onScriptError)

		document.body.appendChild(script)

		// eslint-disable-next-line consistent-return -- not relevant for useEffect cleanup function
		return () => {
			script.removeEventListener('load', onScriptLoad)
			script.removeEventListener('error', onScriptError)
		}
	}, [src, onInitialLoad])

	return [state.loaded, state.error]
}
