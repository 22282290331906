import { createAction } from '@/utils/actions'

import * as accountActionTypes from './constants'

export const accountCreationRequest = createAction(
	accountActionTypes.ACCOUNT_CREATION_REQUEST,
)
export const accountCreationSuccess = createAction(
	accountActionTypes.ACCOUNT_CREATION_SUCCESS,
)
export const accountCreationReset = createAction(
	accountActionTypes.ACCOUNT_CREATION_RESET,
)
export const accountCreationError = createAction(
	accountActionTypes.ACCOUNT_CREATION_ERROR,
)
