import { hvacModeMap } from './maps'

/**
 * Get available HVAC mode options for a list of available modes
 *
 * @param {string[]} hvacModes List of HVAC mode strings to generate options for
 * @returns {object[]} Ordered list of objects containing titles and values for HVAC mode radio buttons
 */
export const getHvacModeOptions = (hvacModes) => {
	const unorderedOptions = hvacModes.map((mode) => ({
		value: mode,
		title: hvacModeMap[mode],
	}))

	// Return mode options in the order of the keys in hvacModeMap
	return Object.keys(hvacModeMap)
		.map((mode) => unorderedOptions.find(({ value }) => value === mode))
		.filter((mode) => mode)
}
