import { takeLatest, call, put, delay, select } from 'redux-saga/effects'
import { loginRequest } from '@/store/login/actions'
import { login } from '@/store/login/sagas'
import { activeCompanyIdSelector } from '@/store/user/selectors'
import { safeFetch } from '@/services/fetch-api/fetch.api'
import history from '@/services/history'
import errorLoggingService from '@/services/error.logging.service'
import * as createSubscriptionActions from './actions'
import { CREATE_SUBSCRIPTION_REQUEST } from './constants'

const DELAY_TIMEOUT = 1000

/**
 * @param action
 */
export function* createSubscription(action) {
	const { payload } = action
	const { addToActiveCompany, companyData, hostedPageId } = payload

	if (addToActiveCompany) {
		const { response } = yield call(safeFetch, 'createFreeSubscription')

		if (response.ok) {
			const activeCompanyId = yield select(activeCompanyIdSelector)
			yield put(
				createSubscriptionActions.createSubscriptionSuccess({
					companyId: activeCompanyId,
				}),
			)
		} else {
			errorLoggingService.leaveBreadcrumb('cannot create free subscription', {
				payload,
				response,
			})
			yield put(createSubscriptionActions.createSubscriptionError())
		}
	} else {
		const { email, password } = companyData
		const apiResponse = yield call(safeFetch, 'createCompany', {
			hostedPageId,
			password,
		})
		const { response } = apiResponse
		// Introduce a one-second delay to improve user experience
		yield delay(DELAY_TIMEOUT)
		if (response.ok) {
			const companyId = apiResponse.data.id
			yield put(
				createSubscriptionActions.createSubscriptionSuccess({ companyId }),
			)
			yield call(login, loginRequest({ username: email, password }))
			yield call([history, 'replace'], {
				pathname: `/${companyId}/building`,
				search: '?newUser=true',
			})
		} else {
			errorLoggingService.leaveBreadcrumb('cannot create subscription', {
				payload,
				response,
			})
			errorLoggingService.notify('Cannot create account')
			yield put(createSubscriptionActions.createSubscriptionError())
		}
	}
}

/**
 *
 */
export function* createSubscriptionRequestWatcher() {
	yield takeLatest(CREATE_SUBSCRIPTION_REQUEST, createSubscription)
}
