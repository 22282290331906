import React from 'react'
import { CONTACT_SUPPORT_URL } from '@/utils/constants'
import ExternalLink from '@/atoms/external-link'

const NO_SUBSCRIPTION_ID_ERROR_CODE = 'missing_subscription_service_id'

/**
 * Gets all values which are duplicates from thermostat field values
 *
 * @param {Array}   thermostats  Array of thermostat field values
 * @returns {Array}               Array of values which are duplicates
 */
export function getDuplicateValues(thermostats) {
	const duplicateCounts = thermostats.reduce((acc, thermostat) => {
		if (!acc[thermostat.value]) {
			acc[thermostat.value] = 0
		}
		acc[thermostat.value] += 1
		return acc
	}, {})

	return Object.keys(duplicateCounts).filter((key) => duplicateCounts[key] > 1)
}

/**
 * Get the indexes of all thermostats that have values
 *
 * @param  {Array}   thermostats  Array of thermostat field values
 * @param  {boolean} isRegistered The isRegistered status to filter on
 * @returns {Array}                An array of thermostat indexes
 */
export function getValueIndexes(thermostats, isRegistered = false) {
	const valueIndexes = []
	thermostats.forEach((thermostat, index) => {
		if (thermostat.value && thermostat.isRegistered === isRegistered) {
			valueIndexes.push(index)
		}
	})
	return valueIndexes
}

/**
 * When passed an array of error objects from API, returns true if one of
 * them is a subscription id * error code
 *
 * @param  {Array}   Array of errors object returned from API
 * @returns {boolean} Returns true if error code is no subscription id code is in array
 */

/**
 * @param errorsArray
 */
export function isSubscriptionError(errorsArray) {
	return errorsArray.some(
		(error) => error.code === NO_SUBSCRIPTION_ID_ERROR_CODE,
	)
}

export const ERRORS = {
	duplicate:
		'There are duplicate serial numbers. Please review before moving on to the next step.',
	already_registered:
		'This serial number is already registered to your account.',
	invalid:
		'These serial numbers are either invalid or already registered to another ecobee account.',
	subscription: (
		<>
			You need a subscription plan to add a thermostat. Please{' '}
			<ExternalLink href={CONTACT_SUPPORT_URL}>contact support.</ExternalLink>
		</>
	),
	general:
		'There was a problem registering some of the thermostats. Click on each highlighted row for details.',
}
