import * as buildingActionTypes from './constants'

/**
 *
 */
function getInitialState() {
	return {
		isFetching: false,
		isError: false,
		isNotFound: false,
		isUpdating: false,
		isUpdateError: false,
		isValidationError: false,
		validationErrorMessage: '',
	}
}

const buildingReducer = (state = getInitialState(), action) => {
	switch (action.type) {
		case buildingActionTypes.GET_BUILDING_REQUEST:
			return {
				...state,
				isFetching: true,
			}
		case buildingActionTypes.GET_BUILDING_SUCCESS:
			return {
				...state,
				isFetching: false,
				isNotFound: false,
				isError: false,
				data: action.payload.building,
			}
		case buildingActionTypes.GET_BUILDING_ERROR:
			return {
				...state,
				isError: true,
				isFetching: false,
				isNotFound: false,
			}
		case buildingActionTypes.BUILDING_NOT_FOUND_ERROR:
			return {
				...state,
				isError: false,
				isFetching: false,
				isNotFound: true,
			}
		case buildingActionTypes.RESET_BUILDING: {
			return getInitialState()
		}
		case buildingActionTypes.RESET_BUILDING_ERROR: {
			return {
				...state,
				isError: false,
				isNotFound: false,
				isUpdateError: false,
				isValidationError: false,
				validationErrorMessage: '',
			}
		}
		case buildingActionTypes.UPDATE_BUILDING_REQUEST: {
			return {
				...state,
				isUpdating: true,
			}
		}
		case buildingActionTypes.UPDATE_BUILDING_ERROR: {
			return {
				...state,
				isUpdating: false,
				isUpdateError: true,
			}
		}
		case buildingActionTypes.UPDATE_BUILDING_VALIDATION_ERROR: {
			const { message } = action.payload
			return {
				...state,
				isUpdating: false,
				isValidationError: true,
				validationErrorMessage: message,
			}
		}
		case buildingActionTypes.UPDATE_BUILDING_SUCCESS: {
			return {
				...state,
				isUpdating: false,
				isUpdateError: false,
				isValidationError: false,
				data: {
					...state.data,
					...action.payload,
				},
			}
		}
		default:
			return state
	}
}

export default buildingReducer
