import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { keyboardEventExecute } from '@/utils/misc'
import './icon.scss'

class Icon extends Component {
	onKeyPress(event) {
		const { onClick } = this.props
		if (onClick) {
			keyboardEventExecute(event, 'Enter', onClick)
		}
	}

	render() {
		const {
			name,
			className,
			title,
			onClick,
			ariaHidden,
			role,
			tabIndex,
		} = this.props
		const componentClassNames = classNames(
			'edl-icon',
			`edl-icon--${name}`,
			className,
		)
		return (
			<i
				className={componentClassNames}
				title={title}
				onClick={onClick}
				onKeyUp={this.onKeyPress}
				aria-hidden={ariaHidden}
				role={role}
				tabIndex={tabIndex}
			/>
		)
	}
}

Icon.defaultProps = {
	role: 'button',
	ariaHidden: false,
}

Icon.propTypes = {
	name: PropTypes.string.isRequired,
	className: PropTypes.string,
	title: PropTypes.string,
	onClick: PropTypes.func,
	ariaHidden: PropTypes.bool,
	role: PropTypes.string,
	tabIndex: PropTypes.string,
}

export default Icon
