import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'

import useModalTracking from '@/hooks/useModalTracking'
import './sb.modal.scss'

const TRANSITION_MS = 400

const SBModal = ({
	analyticsId,
	children,
	isOpen,
	label,
	className,
	onCancel,
}) => {
	const modalClassName = className || ''
	const modalContentClassName = className ? `${className}__content` : ''
	const contentRef = useRef(null)

	const { trackModalClose } = useModalTracking(analyticsId, isOpen)

	/* istanbul ignore next - better to test this at the integration level */
	// eslint-disable-next-line consistent-return
	useEffect(() => {
		// This is a hack to work around a race condition in the bulk actions menu
		// where focus is stolen from the modal after selecting a menu item.
		// Because Reakit waits to restore focus until after the menu's fade
		// transition is complete, focus first goes to the modal, then back to the
		// menu button 200ms later after the menu has been hidden. This hack adds
		// a listener that waits until the modal's transition is complete, then
		// steals focus back.
		// A better solution would be to switch to using Reakit's Dialog component
		// instead of react-modal, in which case focus would be managed
		// automatically between the menu and the embedded modal Dialog.
		if (isOpen) {
			const timeoutId = setTimeout(
				() => contentRef.current?.focus(),
				TRANSITION_MS,
			)
			return () => clearTimeout(timeoutId)
		}
	}, [isOpen])

	const onRequestClose = (...args) => {
		trackModalClose()
		return onCancel?.(...args)
	}

	return (
		<Modal
			contentRef={
				/* istanbul ignore next */ (el) => {
					contentRef.current = el
				}
			}
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			className={{
				base: `cmp__sb-modal__content ${modalContentClassName}`,
				afterOpen: 'cmp__sb-modal__content--after-open',
				beforeClose: 'cmp__sb-modal__content--before-close',
			}}
			overlayClassName={{
				base: `cmp__sb-modal ${modalClassName}`,
				afterOpen: 'cmp__sb-modal--after-open',
				beforeClose: 'cmp__sb-modal--before-close',
			}}
			contentLabel={label}
			closeTimeoutMS={400}
		>
			{children}
		</Modal>
	)
}

SBModal.propTypes = {
	analyticsId: PropTypes.string,
	isOpen: PropTypes.bool.isRequired,
	onCancel: PropTypes.func,
	children: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.array,
		PropTypes.element,
	]).isRequired,
	label: PropTypes.string.isRequired,
	className: PropTypes.string,
}

export default SBModal
