import {
	THERMOSTAT_TYPE_COMMERCIAL,
	THERMOSTAT_TYPE_LEGACY_RESIDENTIAL,
	THERMOSTAT_TYPE_RESIDENTIAL,
} from '@ecobee/smartbuildings-library-common/constants'
import {
	AWAKE_CLIMATE_REF,
	AWAKE_DEFAULT_COOL_INITIAL,
	AWAKE_DEFAULT_HEAT_INITIAL,
	AWAY_CLIMATE_REF,
	AWAY_DEFAULT_COOL_INITIAL,
	AWAY_DEFAULT_HEAT_INITIAL,
	CONNECTION_FILTER,
	DEFAULT_MAX,
	DEFAULT_MIN,
	DUPLICATE_BUILDING_NAME_ERROR,
	HOME_CLIMATE_REF,
	HOME_DEFAULT_COOL_INITIAL,
	HOME_DEFAULT_HEAT_INITIAL,
	INVALID_BUILDING_NAME_ERROR,
	OCCUPIED_CLIMATE_REF,
	OCCUPIED_DEFAULT_COOL_INITIAL,
	OCCUPIED_DEFAULT_HEAT_INITIAL,
	OFFLINE,
	ONLINE,
	PROGRAM_FILTER,
	SET_MODE_FILTER,
	SLEEP_CLIMATE_REF,
	SLEEP_DEFAULT_COOL_INITIAL,
	SLEEP_DEFAULT_HEAT_INITIAL,
	TAGS_FILTER,
	UNOCCUPIED_CLIMATE_REF,
	UNOCCUPIED_DEFAULT_COOL_INITIAL,
	UNOCCUPIED_DEFAULT_HEAT_INITIAL,
} from './constants'

export const hvacModeMap = {
	auto: 'Auto',
	heat: 'Heat',
	cool: 'Cool',
	auxHeatOnly: 'Aux',
	off: 'Off',
}

export const timeMap = [
	'12:00 AM',
	'12:30 AM',
	'1:00 AM',
	'1:30 AM',
	'2:00 AM',
	'2:30 AM',
	'3:00 AM',
	'3:30 AM',
	'4:00 AM',
	'4:30 AM',
	'5:00 AM',
	'5:30 AM',
	'6:00 AM',
	'6:30 AM',
	'7:00 AM',
	'7:30 AM',
	'8:00 AM',
	'8:30 AM',
	'9:00 AM',
	'9:30 AM',
	'10:00 AM',
	'10:30 AM',
	'11:00 AM',
	'11:30 AM',
	'12:00 PM',
	'12:30 PM',
	'1:00 PM',
	'1:30 PM',
	'2:00 PM',
	'2:30 PM',
	'3:00 PM',
	'3:30 PM',
	'4:00 PM',
	'4:30 PM',
	'5:00 PM',
	'5:30 PM',
	'6:00 PM',
	'6:30 PM',
	'7:00 PM',
	'7:30 PM',
	'8:00 PM',
	'8:30 PM',
	'9:00 PM',
	'9:30 PM',
	'10:00 PM',
	'10:30 PM',
	'11:00 PM',
	'11:30 PM',
	'11:59 PM',
]

export const timeMapMoment = [
	'00:00:00',
	'00:30:00',
	'01:00:00',
	'01:30:00',
	'02:00:00',
	'02:30:00',
	'03:00:00',
	'03:30:00',
	'04:00:00',
	'04:30:00',
	'05:00:00',
	'05:30:00',
	'06:00:00',
	'06:30:00',
	'07:00:00',
	'07:30:00',
	'08:00:00',
	'08:30:00',
	'09:00:00',
	'09:30:00',
	'10:00:00',
	'10:30:00',
	'11:00:00',
	'11:30:00',
	'12:00:00',
	'12:30:00',
	'13:00:00',
	'13:30:00',
	'14:00:00',
	'14:30:00',
	'15:00:00',
	'15:30:00',
	'16:00:00',
	'16:30:00',
	'17:00:00',
	'17:30:00',
	'18:00:00',
	'18:30:00',
	'19:00:00',
	'19:30:00',
	'20:00:00',
	'20:30:00',
	'21:00:00',
	'21:30:00',
	'22:00:00',
	'22:30:00',
	'23:00:00',
	'23:30:00',
	'23:59:00',
]

export const dayMap = {
	monday: {
		full: 'Monday',
		abbreviation: 'MON',
	},
	tuesday: {
		full: 'Tuesday',
		abbreviation: 'TUE',
	},
	wednesday: {
		full: 'Wednesday',
		abbreviation: 'WED',
	},
	thursday: {
		full: 'Thursday',
		abbreviation: 'THU',
	},
	friday: {
		full: 'Friday',
		abbreviation: 'FRI',
	},
	saturday: {
		full: 'Saturday',
		abbreviation: 'SAT',
	},
	sunday: {
		full: 'Sunday',
		abbreviation: 'SUN',
	},
}

const createHeatString = (stage) => `Heat on stage ${stage}`
const createAuxString = (stage, auxIsHeat) =>
	auxIsHeat ? createHeatString(stage) : `Aux heat on stage ${stage}`

export const timeSelectOptions = timeMap.map((time, index) => ({
	value: index,
	title: time,
}))

export const THERMOSTAT_PROGRAM_MAP = {
	[THERMOSTAT_TYPE_COMMERCIAL]: [
		{
			name: 'Unoccupied',
			climateRef: UNOCCUPIED_CLIMATE_REF,
		},
		{
			name: 'Occupied',
			climateRef: OCCUPIED_CLIMATE_REF,
		},
	],
	[THERMOSTAT_TYPE_RESIDENTIAL]: [
		{
			name: 'Away',
			climateRef: AWAY_CLIMATE_REF,
		},
		{
			name: 'Home',
			climateRef: HOME_CLIMATE_REF,
		},
		{
			name: 'Sleep',
			climateRef: SLEEP_CLIMATE_REF,
		},
	],
	[THERMOSTAT_TYPE_LEGACY_RESIDENTIAL]: [
		{
			name: 'Away',
			climateRef: AWAY_CLIMATE_REF,
		},
		{
			name: 'Home',
			climateRef: HOME_CLIMATE_REF,
		},
		{
			name: 'Sleep',
			climateRef: SLEEP_CLIMATE_REF,
		},
		{
			name: 'Awake',
			climateRef: AWAKE_CLIMATE_REF,
		},
	],
}

export const DEFAULT_COMFORT_SETTINGS = {
	occupied: {
		coolMax: DEFAULT_MAX,
		heatMax: DEFAULT_MAX,
		coolMin: DEFAULT_MIN,
		heatMin: DEFAULT_MIN,
		coolTemp: OCCUPIED_DEFAULT_COOL_INITIAL,
		heatTemp: OCCUPIED_DEFAULT_HEAT_INITIAL,
	},
	unoccupied: {
		coolMax: DEFAULT_MAX,
		heatMax: DEFAULT_MAX,
		coolMin: DEFAULT_MIN,
		heatMin: DEFAULT_MIN,
		coolTemp: UNOCCUPIED_DEFAULT_COOL_INITIAL,
		heatTemp: UNOCCUPIED_DEFAULT_HEAT_INITIAL,
	},
	home: {
		coolMax: DEFAULT_MAX,
		heatMax: DEFAULT_MAX,
		coolMin: DEFAULT_MIN,
		heatMin: DEFAULT_MIN,
		coolTemp: HOME_DEFAULT_COOL_INITIAL,
		heatTemp: HOME_DEFAULT_HEAT_INITIAL,
	},
	away: {
		coolMax: DEFAULT_MAX,
		heatMax: DEFAULT_MAX,
		coolMin: DEFAULT_MIN,
		heatMin: DEFAULT_MIN,
		coolTemp: AWAY_DEFAULT_COOL_INITIAL,
		heatTemp: AWAY_DEFAULT_HEAT_INITIAL,
	},
	sleep: {
		coolMax: DEFAULT_MAX,
		heatMax: DEFAULT_MAX,
		coolMin: DEFAULT_MIN,
		heatMin: DEFAULT_MIN,
		coolTemp: SLEEP_DEFAULT_COOL_INITIAL,
		heatTemp: SLEEP_DEFAULT_HEAT_INITIAL,
	},
	wakeup: {
		coolMax: DEFAULT_MAX,
		heatMax: DEFAULT_MAX,
		coolMin: DEFAULT_MIN,
		heatMin: DEFAULT_MIN,
		coolTemp: AWAKE_DEFAULT_COOL_INITIAL,
		heatTemp: AWAKE_DEFAULT_HEAT_INITIAL,
	},
}

export const getEquipmentListString = (listString, auxIsHeat) => {
	const equipmentListMap = {
		heatPump: createHeatString(1),
		heatPump2: createHeatString(2),
		heatPump3: createHeatString(3),
		auxHeat1: createAuxString(1, auxIsHeat),
		auxHeat2: createAuxString(2, auxIsHeat),
		auxHeat3: createAuxString(3, auxIsHeat),
		compCool1: 'Cool on stage 1',
		compCool2: 'Cool on stage 2',
		fan: 'Fan on',
	}

	return equipmentListMap[listString]
}

export const ERROR_VALIDATION_MAP = [
	{
		match: DUPLICATE_BUILDING_NAME_ERROR,
		response: 'This building name already exists',
	},
	{
		match: INVALID_BUILDING_NAME_ERROR,
		response: 'Alphanumeric characters only',
	},
]

export const FILTERS = {
	[CONNECTION_FILTER]: {
		title: 'Connection',
		items: [
			{
				label: 'Online',
				id: ONLINE,
			},
			{
				label: 'Offline',
				id: OFFLINE,
			},
		],
	},
	[SET_MODE_FILTER]: {
		title: 'Mode',
		items: Object.keys(hvacModeMap).map((key) => ({
			label: hvacModeMap[key],
			id: key,
		})),
	},
	[PROGRAM_FILTER]: {
		title: 'Comfort setting',
		items: [
			{
				label: 'Occupied',
				id: OCCUPIED_CLIMATE_REF,
			},
			{
				label: 'Unoccupied',
				id: UNOCCUPIED_CLIMATE_REF,
			},
			{
				label: 'Home',
				id: HOME_CLIMATE_REF,
			},
			{
				label: 'Away',
				id: AWAY_CLIMATE_REF,
			},
			{
				label: 'Sleep',
				id: SLEEP_CLIMATE_REF,
			},
		],
	},
	[TAGS_FILTER]: {
		title: 'Tags',
	},
}

export const COUNTRIES = [
	{
		value: 'CA',
		title: '🇨🇦 Canada',
	},
	{
		value: 'US',
		title: '🇺🇸 United States',
	},
]
