import { INVALID_NAME_REGEX } from '@ecobee/smartbuildings-library-common/constants'
import { RESPONSE_SUCCESS } from '@ecobee/smartbuildings-library-common/services/ecobeePublicApi/constants'
import { MIN_PASSWORD_LENGTH } from './constants'

const DEFAULT_PASSCODE = '****'

/**
 * Check if an email address is valid
 *
 * @param  {string}  email The email address
 * @returns {boolean}       True if email is valid, false otherwise
 */
export const isValidEmail = (email) => {
	const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line no-useless-escape
	return emailRegex.test(email)
}

/**
 * Check if a first name or last name is valid
 *
 * @param  {string}  name The name
 * @returns {boolean}      True if name is valid, false otherwise
 */
export const isValidName = (name) => !INVALID_NAME_REGEX.test(name)

/**
 * Check if a password is valid
 *
 * @param  {string}  password The password
 * @returns {boolean}       True if password is valid, false otherwise
 */
export const isValidPassword = (password) =>
	password.length >= MIN_PASSWORD_LENGTH

/**
 * Check if a passcode is a valid passcode
 *
 * @param  {string}  code The passcode
 * @returns {boolean}      True if passcode is valid, false otherwise
 */
export const isValidPasscode = (code) => {
	if (code === DEFAULT_PASSCODE) {
		return true
	}
	return /^\d{4}$/.test(code)
}

/**
 * Validate the api response
 *
 * @param  {Mixed} fetchedData Response or array of responses from the API
 * @returns {boolean}           True if all responses are ok, false otherwise
 */
export const validateApiResponse = (fetchedData) => {
	const fetchedArray = Array.isArray(fetchedData) ? fetchedData : [fetchedData]
	return fetchedArray.every(({ response }) => response.ok)
}
/**
 * Validate the api response returned a successful status code of 0.
 *
 * @param  {Mixed} fetchedData Response or array of responses from the API
 * @returns {boolean} True if status code is zero, false otherwise
 */

export const validateApiStatusCode = (fetchedData) => {
	const fetchedArray = Array.isArray(fetchedData) ? fetchedData : [fetchedData]
	return fetchedArray.every(({ status }) => status.code === RESPONSE_SUCCESS)
}

/**
 * Validate user text input
 *
 * @param  {string}  input      User provided input string
 * @param  {boolean} allowEmpty Boolean indicating if empty string passes validation
 * @returns {boolean}       True if valid input, false otherwise
 */
export const isValidInput = (input, allowEmpty = false) => {
	const validationRegex = /^[\w!"#$%&'()*+,./:;<=>?@[\] ^`{|}~-]*$/i
	if (allowEmpty) {
		return validationRegex.test(input)
	}
	return validationRegex.test(input) && input.trim().length > 0
}
