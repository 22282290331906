import * as buildingListActionTypes from './constants'

/**
 *
 */
function getInitialState() {
	return {
		buildings: [],
		isFetching: false,
		isError: false,
		showAddBuilding: false,
	}
}

const buildingsListReducer = (state = getInitialState(), action) => {
	switch (action.type) {
		case buildingListActionTypes.GET_BUILDING_LIST_REQUEST:
			return {
				...state,
				isFetching: true,
				isError: false,
			}
		case buildingListActionTypes.GET_BUILDING_LIST_SUCCESS:
			return {
				...state,
				isFetching: false,
				isError: false,
				buildings: action.payload.buildings,
			}
		case buildingListActionTypes.GET_BUILDING_LIST_ERROR:
			return {
				...state,
				isError: true,
				isFetching: false,
			}
		case buildingListActionTypes.SHOW_ADD_BUILDING:
			return {
				...state,
				showAddBuilding: true,
			}
		case buildingListActionTypes.HIDE_ADD_BUILDING:
			return {
				...state,
				showAddBuilding: false,
			}
		case buildingListActionTypes.RESET_BUILDING_LIST:
			return getInitialState()
		default:
			return state
	}
}

export default buildingsListReducer
