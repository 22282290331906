import { createSelector } from 'reselect'

export const companySelector = (state) => state.company ?? null

export const companyIsUpdatingSelector = createSelector(
	companySelector,
	(company) => company?.isUpdating ?? false,
)

export const companyUpdateIsErrorSelector = createSelector(
	companySelector,
	(company) => company?.isUpdateError ?? false,
)

export const companyIsInitializingSelector = createSelector(
	companySelector,
	(company) => company?.isInitializing ?? false,
)

export const companyInitializationIsErrorSelector = createSelector(
	companySelector,
	(company) => company?.isInitializationError ?? false,
)
