import { get } from 'lodash'
import config from 'config'
import {
	COMPANY_ID_HEADER,
	PLATFORM_HEADER,
} from '@ecobee/smartbuildings-library-common/constants'
import AuthService from '@/services/auth.service'
import routingService from '@/services/routing.service'

export const serverUrl = config.AUTH_SERVER_URL
export const apiUrl = config.AUTH_API_URL
export const applicationKey = config.AUTH_API_APPLICATION_KEY
export const applicationSecret = config.AUTH_API_APPLICATION_SECRET
export const defaultUrlParam = { format: 'json' }
export const PLATFORM_WEB = 'web'

export const getHeader = (authRequired) => {
	const companyId = get(routingService.getMatch(), 'params.companyId')

	const header = {
		Accept: 'application/json',
		'Content-Type': 'application/json;charset=UTF-8',
		[PLATFORM_HEADER]: PLATFORM_WEB,
	}

	if (companyId) {
		header[COMPANY_ID_HEADER] = companyId
	}

	if (authRequired) {
		const token = AuthService.getAccessToken()

		if (token) {
			header.Authorization = `Bearer ${token}`
		}
	}

	return header
}

export const getDefaultFetchOptions = (
	methodType = 'POST',
	authRequired = false,
	additionalHeaders = {},
) => ({
	method: methodType,
	cache: 'no-cache',
	credentials: 'same-origin',
	headers: {
		...getHeader(authRequired),
		...additionalHeaders,
	},
})
