import { createAction } from '@/utils/actions'
import * as getCompanyUserSingleActionTypes from './constants'

export const getCompanyUserSingleRequest = createAction(
	getCompanyUserSingleActionTypes.GET_COMPANY_USER_SINGLE_REQUEST,
)
export const getCompanyUserSingleReset = createAction(
	getCompanyUserSingleActionTypes.GET_COMPANY_USER_SINGLE_RESET,
)
export const getCompanyUserSingleSuccess = createAction(
	getCompanyUserSingleActionTypes.GET_COMPANY_USER_SINGLE_SUCCESS,
)
export const getCompanyUserSingleError = createAction(
	getCompanyUserSingleActionTypes.GET_COMPANY_USER_SINGLE_ERROR,
)
