import * as createSubscriptionActionTypes from './constants'

/**
 *
 */
function getInitialState() {
	return {
		inProgress: false,
		isError: false,
	}
}

const createSubscriptionReducer = (state = getInitialState(), action) => {
	switch (action.type) {
		case createSubscriptionActionTypes.CREATE_SUBSCRIPTION_REQUEST: {
			return {
				...state,
				inProgress: true,
				isError: false,
			}
		}
		case createSubscriptionActionTypes.CREATE_SUBSCRIPTION_SUCCESS:
		case createSubscriptionActionTypes.CREATE_SUBSCRIPTION_RESET:
			return getInitialState()
		case createSubscriptionActionTypes.CREATE_SUBSCRIPTION_ERROR: {
			return {
				...state,
				inProgress: false,
				isError: true,
			}
		}
		default: {
			return state
		}
	}
}

export default createSubscriptionReducer
