import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import analyticsService from '@/services/analytics.service'
import Spinner from '@/components/spinner'
import './button.scss'

class Button extends Component {
	buttonRef = React.createRef()

	handleClick = (event) => {
		const {
			analyticsEvent,
			analyticsEventProperties,
			children,
			isActive,
			isDisabled,
			label,
			onClick,
		} = this.props

		if (!isActive && !isDisabled && typeof onClick === 'function') {
			analyticsService.trackEvent(
				analyticsEvent ?? 'ButtonClick',
				analyticsEvent
					? analyticsEventProperties
					: {
							label: typeof children === 'string' ? children : label,
					  },
			)
			onClick(event)
		}
	}

	focus = () => {
		this.buttonRef.current?.focus()
	}

	render() {
		const {
			activeElement,
			analyticsEvent,
			analyticsEventProperties,
			children,
			className,
			fixedSize,
			isActive,
			isCallToAction,
			isContentSize,
			isDestructive,
			isDisabled,
			isFixedSize,
			isSecondary,
			isSlim,
			isStealth,
			label,
			lowercaseText,
			onClick,
			spinnerColor,
			type,
			...extraProps
		} = this.props
		const componentClassNames = classNames(className, {
			'edl-button': true,
			'edl-button--cta': isCallToAction,
			'edl-button--secondary': isSecondary,
			'edl-button--destructive': isDestructive,
			'edl-button--active': isActive,
			'edl-button--stealth': isStealth,
			[`edl-button--size-fixed-${fixedSize}`]: isFixedSize,
			'edl-button--size-content': isContentSize,
			'edl-button--slim': isSlim,
			'edl-button--no-text-transform': lowercaseText,
		})

		return (
			// The lint error is a false positive caused by the "type" attribute not being a string value
			<button
				{...extraProps}
				disabled={isDisabled || undefined}
				className={componentClassNames}
				onClick={this.handleClick}
				aria-label={label}
				type={type} // eslint-disable-line react/button-has-type
				ref={this.buttonRef}
			>
				<div className="edl-button__wrapper">
					{isActive && (
						<Spinner
							className="edl-button__progress"
							data-testid=""
							size={20}
							opacity={0.7}
							color={spinnerColor}
						/>
					)}
					{(isActive && activeElement) || children}
				</div>
			</button>
		)
	}
}

Button.defaultProps = {
	fixedSize: 'medium',
	spinnerColor: '#ffffff',
	type: 'button',
}

Button.propTypes = {
	activeElement: PropTypes.node,
	analyticsEvent: PropTypes.string,
	analyticsEventProperties: PropTypes.objectOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.arrayOf(PropTypes.string.isRequired),
		]),
	),
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	fixedSize: PropTypes.string,
	isActive: PropTypes.bool,
	isCallToAction: PropTypes.bool,
	isContentSize: PropTypes.bool,
	isDestructive: PropTypes.bool,
	isDisabled: PropTypes.bool,
	isFixedSize: PropTypes.bool,
	isSecondary: PropTypes.bool,
	isSlim: PropTypes.bool,
	isStealth: PropTypes.bool,
	label: PropTypes.string,
	lowercaseText: PropTypes.bool,
	onClick: PropTypes.func,
	spinnerColor: PropTypes.string,
	type: PropTypes.oneOf(['button', 'submit', 'reset']),
}

export default Button
