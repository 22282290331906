import { createAction } from '@/utils/actions'
import * as resetPasswordActionTypes from './constants'

export const resetPasswordRequest = createAction(
	resetPasswordActionTypes.RESET_PASSWORD_REQUEST,
)
export const resetPasswordReset = createAction(
	resetPasswordActionTypes.RESET_PASSWORD_RESET,
)
export const resetPasswordSuccess = createAction(
	resetPasswordActionTypes.RESET_PASSWORD_SUCCESS,
)
export const resetPasswordError = createAction(
	resetPasswordActionTypes.RESET_PASSWORD_ERROR,
)
