import React from 'react'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import { COUNTRIES } from '@/utils/maps'
import Button from '@/components/button'
import Select from '@/components/select'
import TextField from '@/components/textfield'

import * as S from './migration.setup.modal.styled'

const CHARGEBEE_COMPANY_MAX_LENGTH = 250
const EMPTY_COUNTRY = {
	value: '',
	title: '',
}
const MigrationSetupModal = ({
	emsCompanyName,
	emsMetadata,
	isFetchingUser,
	title,
	initializeMigratedCompany,
	isInitializing,
}) => {
	let emsCountry = ''
	if (emsMetadata?.isCA) emsCountry = 'CA'
	if (emsMetadata?.isUS) emsCountry = 'US'

	const { control, handleSubmit, watch } = useForm({
		defaultValues: {
			companyName: emsCompanyName || '',
			country: emsCountry,
		},
	})
	const onSubmit = (data) => {
		initializeMigratedCompany(data)
	}

	// If we were able to infer the company's country from the EMS portal, it
	// will be pre-selected, so there's no need to include an empty option
	const countryOptions = emsCountry ? COUNTRIES : [EMPTY_COUNTRY, ...COUNTRIES]

	const companyName = watch('companyName')
	const country = watch('country')

	const isActive = isInitializing || isFetchingUser

	return (
		<S.Wrapper>
			<S.Title>{title}</S.Title>
			<S.Message>
				To get started, please enter the correct information for your
				organization.
			</S.Message>

			<S.FormRow>
				<Controller
					as={
						<TextField
							id="MigrationSetupCompanyName"
							label="Company name"
							maxLength={CHARGEBEE_COMPANY_MAX_LENGTH}
						/>
					}
					control={control}
					name="companyName"
				/>
			</S.FormRow>
			<S.FormRow>
				<Controller
					as={
						<Select
							id="MigrationSetupCountry"
							label="Country"
							options={countryOptions}
						/>
					}
					control={control}
					name="country"
				/>
			</S.FormRow>

			<S.ButtonWrapper>
				<Button
					isDisabled={!companyName || !country}
					onClick={handleSubmit(onSubmit)}
					isActive={isActive}
				>
					{isActive ? 'Setting up SmartBuildings' : 'Done'}
				</Button>
			</S.ButtonWrapper>
		</S.Wrapper>
	)
}

MigrationSetupModal.propTypes = {
	emsCompanyName: PropTypes.string,
	emsMetadata: PropTypes.shape({
		isCA: PropTypes.bool.isRequired,
		isUS: PropTypes.bool.isRequired,
	}),
	initializeMigratedCompany: PropTypes.func.isRequired,
	isFetchingUser: PropTypes.bool.isRequired,
	isInitializing: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
}

export default MigrationSetupModal
