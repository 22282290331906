import React, { Component } from 'react'
import { CONTACT_SUPPORT_URL } from '@/utils/constants'

import ExternalLink from '@/atoms/external-link'
import LogoDark from '@/components/svg-icon/logo-dark'

import './unrecoverable.error.page.scss'

class UnrecoverableErrorPage extends Component {
	render() {
		return (
			<div className="cmp__unrecoverable-error-page">
				<LogoDark />
				<div className="cmp__unrecoverable-error-page__text">
					Something went wrong. Please reload the page or{' '}
					<ExternalLink href={CONTACT_SUPPORT_URL}>
						contact support
					</ExternalLink>
					.
				</div>
			</div>
		)
	}
}

UnrecoverableErrorPage.propTypes = {}

export default UnrecoverableErrorPage
