import * as validateEmailActionTypes from './constants'

/**
 *
 */
function getInitialState() {
	return {
		inProgress: false,
		isValidateEmailError: false,
		isValidateEmailErrorMessage: '',
		isValidateEmailSuccess: false,
		isValidateEmailSendError: false,
		isValidateEmailSendErrorMessage: '',
		isValidateEmailSendSuccess: false,
	}
}

const validateEmailReducer = (state = getInitialState(), action) => {
	switch (action.type) {
		case validateEmailActionTypes.VALIDATE_EMAIL_REQUEST:
			return {
				...state,
				inProgress: true,
				isValidateEmailError: false,
				isValidateEmailSendError: false,
			}
		case validateEmailActionTypes.VALIDATE_EMAIL_RESET:
			return getInitialState()
		case validateEmailActionTypes.VALIDATE_EMAIL_ERROR:
			return {
				...state,
				inProgress: false,
				isValidateEmailError: true,
				isValidateEmailSuccess: false,
				isValidateEmailErrorMessage: action.payload,
			}
		case validateEmailActionTypes.VALIDATE_EMAIL_SUCCESS:
			return {
				...state,
				inProgress: false,
				isValidateEmailError: false,
				isValidateEmailSuccess: true,
			}
		case validateEmailActionTypes.VALIDATE_EMAIL_SEND_ERROR:
			return {
				...state,
				inProgress: false,
				isValidateEmailSendError: true,
				isValidateEmailSendSuccess: false,
				isValidateEmailSendErrorMessage: action.payload,
			}
		case validateEmailActionTypes.VALIDATE_EMAIL_SEND_SUCCESS:
			return {
				...state,
				inProgress: false,
				isValidateEmailSendError: false,
				isValidateEmailSendSuccess: true,
			}
		default: {
			return state
		}
	}
}

export default validateEmailReducer
