import { takeLatest, put, call, select } from 'redux-saga/effects'
import { safeFetch } from '@/services/fetch-api/fetch.api'
import { setQueryParams } from '@/services/query-params/query.params.service'
import { modalShow } from '@/store/modal/actions'
import { createSubscriptionRequest } from '@/store/create-subscription/actions'
import { createSubscription } from '@/store/create-subscription/sagas'
import { createSubscriptionErrorSelector } from '@/store/create-subscription/selectors'
import { activeCompanyIdSelector, userIdSelector } from '@/store/user/selectors'
import {
	updateCompanyRequest,
	updateCompanySuccess,
	updateCompanyError,
	initializeMigratedCompanySuccess,
	initializeMigratedCompanyError,
} from './actions'
import {
	UPDATE_COMPANY_REQUEST,
	INITIALIZE_MIGRATED_COMPANY_REQUEST,
} from './constants'
import { companyUpdateIsErrorSelector } from './selectors'

/**
 * @param action
 */
export function* updateCompany(action) {
	const { companyName, country, billingUserId } = action.payload

	try {
		const { response } = yield call(safeFetch, 'updateCompany', {
			companyName,
			country,
			billingUserId,
		})

		if (response.ok) {
			const companyId = yield select(activeCompanyIdSelector)
			yield put(updateCompanySuccess({ companyId, companyName, country }))
		} else {
			yield put(updateCompanyError())
		}
	} catch {
		yield put(updateCompanyError())
	}
}

/**
 * @param action
 */
export function* initializeMigratedCompany(action) {
	const { companyName, country } = action.payload
	const billingUserId = yield select(userIdSelector)
	const updateCompanyAction = updateCompanyRequest({
		companyName,
		country,
		billingUserId,
	})

	yield call(updateCompany, updateCompanyAction)

	const companyUpdateIsError = yield select(companyUpdateIsErrorSelector)

	if (!companyUpdateIsError) {
		yield call(
			createSubscription,
			createSubscriptionRequest({ addToActiveCompany: true }),
		)
	}

	const subscriptionIsError = yield select(createSubscriptionErrorSelector)

	if (companyUpdateIsError || subscriptionIsError) {
		yield put(initializeMigratedCompanyError())
		yield put(
			modalShow({
				message:
					'An error occurred while setting up your account. Please try again.',
			}),
		)
	} else {
		yield put(initializeMigratedCompanySuccess())
		yield call(setQueryParams, { newUser: true })
	}
}

/**
 *
 */
export function* companyRequestWatcher() {
	yield takeLatest(UPDATE_COMPANY_REQUEST, updateCompany)
	yield takeLatest(
		INITIALIZE_MIGRATED_COMPANY_REQUEST,
		initializeMigratedCompany,
	)
}
