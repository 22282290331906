import { createAction } from '@/utils/actions'
import * as forgotPasswordActionTypes from './constants'

export const forgotPasswordRequest = createAction(
	forgotPasswordActionTypes.FORGOT_PASSWORD_REQUEST,
)
export const forgotPasswordReset = createAction(
	forgotPasswordActionTypes.FORGOT_PASSWORD_RESET,
)
export const forgotPasswordSuccess = createAction(
	forgotPasswordActionTypes.FORGOT_PASSWORD_SUCCESS,
)
export const forgotPasswordError = createAction(
	forgotPasswordActionTypes.FORGOT_PASSWORD_ERROR,
)
export const forgotPasswordInvalidEmail = createAction(
	forgotPasswordActionTypes.FORGOT_PASSWORD_INVALID_EMAIL,
)
