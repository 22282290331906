import { get } from 'lodash'
import { ERROR_VALIDATION_MAP } from '@/utils/maps'

class RemoteErrorService {
	/**
	 * Returns an error object if one is found in an error array that contains the same error code as the one passed
	 * Else returns undefined
	 *
	 * @param {*} errorCode
	 * @param {*} response
	 * @returns {object} The error object
	 */
	static hasErrorByCode(errorCode, response) {
		return response.errors.find(
			(responseError) => responseError.code === errorCode,
		)
	}

	/**
	 * Checks an API response to see if errors occurred
	 *
	 * @param {*} response
	 * @returns {boolean} If errors exists on the response object
	 */
	static isErrorResponse(response) {
		return response.errors !== undefined
	}

	/**
	 * Retrieve the message the UI should show when errors occur
	 *
	 * @param {*} response
	 * @returns {string} The response the UI should show based on a error code
	 */
	static getMessage(response) {
		return get(
			ERROR_VALIDATION_MAP.find((mapping) =>
				response.errors.find(
					(errorResponse) => mapping.match === errorResponse.code,
				),
			),
			'response',
		)
	}

	/**
	 * Determines if a response with errors contains a validation error as opposed to an all-purpose error like a 500
	 *
	 * @param {*} response
	 * @returns {boolean}
	 */
	static isValidationError(response) {
		if (!RemoteErrorService.isErrorResponse(response)) {
			return null
		}

		return Boolean(RemoteErrorService.findValidationError(response.errors))
	}

	/**
	 * Retrieve response to display when a validation error occurs
	 *
	 * @param {*} response
	 * @returns {string} The response to show
	 */
	static getValidationResponse(response) {
		if (!RemoteErrorService.isErrorResponse(response)) {
			return null
		}

		return get(
			RemoteErrorService.findValidationError(response.errors),
			'response',
		)
	}

	/**
	 * Find a validation local mapping if a matching code is found in the remote error array
	 *
	 * @param {Array} errors
	 * @returns {object} The error mapping if a match is found
	 */
	static findValidationError(errors) {
		return ERROR_VALIDATION_MAP.find((mapping) =>
			errors.find((errorResponse) => mapping.match === errorResponse.code),
		)
	}
}

export default RemoteErrorService
