import React from 'react'
import PropTypes from 'prop-types'
import Transition from '@/components/transition'
import useDelay from '@/hooks/useDelay'
import styles from './loading.module.scss'

const LOADING_TIMEOUT_MS = 400

const Loading = ({ label }) => {
	const isVisible = useDelay(LOADING_TIMEOUT_MS)

	return (
		<Transition isVisible={isVisible} wrapChildren>
			<div className={styles.cmp__loading} aria-busy="true" aria-live="polite">
				<div className={styles.cmp__loading__label}>{label}</div>
				<div className={styles.cmp__loading__indicator} />
			</div>
		</Transition>
	)
}

Loading.defaultProps = {
	label: 'Loading...',
}

Loading.propTypes = {
	label: PropTypes.string,
}

export default Loading
