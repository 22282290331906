import config from 'config'
import React from 'react'
import { ApolloProvider } from 'react-apollo'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import Modal from 'react-modal'
import TagManager from 'react-gtm-module'
import { Helmet } from 'react-helmet'

import history from './services/history'
import AuthService from './services/auth.service'
import errorLoggingService from './services/error.logging.service'
import featureFlagsService from './services/feature-flags'
import routingService from './services/routing.service'
import analyticsService from './services/analytics.service'
import client from './services/graphql/client'

import createStore from './store/createStore'
import routesList from './routes'
import AppContainer from './containers/app-container'
import ScrollToTop from './components/scroll-to-top'
import FeatureFlagsProvider from './components/feature-flags-provider'
import UnrecoverableErrorPage from './components/unrecoverable-error-page'
import UserPreferencesProvider from './components/user-preferences-provider'

// ========================================================
// Service Instantiation
// ========================================================
featureFlagsService.init(AuthService.getUser())
routingService.init(routesList)
analyticsService.init()

// ========================================================
// Store Instantiation
// ========================================================
const initialState = window.__INITIAL_STATE__
const store = createStore(initialState)

// ========================================================
// Render Setup
// ========================================================
const ErrorBoundary = errorLoggingService.getReactErrorBoundary()

// ========================================================
// Analytics Page Tracking
// ========================================================
analyticsService.subscribeToLocationChanges(history)
TagManager.initialize({
	auth: config.GOOGLE_TAG_MANAGER_AUTH,
	gtmId: config.GOOGLE_TAG_MANAGER_ID,
	preview: config.GOOGLE_TAG_MANAGER_ENV,
})

// ========================================================
// Developer friendly console message!
// ========================================================

const ecobeeMessage = () => {
	const message = `
%c********************************************************************************
                                     _
                       ___  ___ ___ | |__   ___  ___
                      / _ \\/ __/ _ \\| '_ \\ / _ \\/ _ \\
                     |  __/ (_| (_) | |_) |  __/  __/
                      \\___|\\___\\___/|_.__/ \\___|\\___|


                                       #####
                                     ##############
                                    ###     #########
                    ############## ####     #########
               ########################################
            ########              ##############     ###
           #####                    ############     ###
          ####                      ####################
          #####                    ###################
           ######              #####################
             ##############################      #####
                 ##############       ####         ####
                        ########       ###          ####
                         #########     ###           ####
                     ##   ################           ####
                     ####   ###############           ###
                     ######    ############           ####
                      ########    #########           ####
                        ###########      ####         ####
                     #    ##########      #####      ####
                     ####                   #############
                     #######                  #########
                                                 ###

     ____                       _   ____        _ _     _ _
    / ___| _ __ ___   __ _ _ __| |_| __ ) _   _(_) | __| (_)_ __   __ _ ___
    \\___ \\| '_ \` _ \\ / _\` | '__| __|  _ \\| | | | | |/ _\` | | '_ \\ / _\` / __|
     ___) | | | | | | (_| | |  | |_| |_) | |_| | | | (_| | | | | | (_| \\__ \\
    |____/|_| |_| |_|\\__,_|_|   \\__|____/ \\__,_|_|_|\\__,_|_|_| |_|\\__, |___/
                                                                  |___/

We're always looking for smart, motivated people at ecobee.  If you think
working in a fun, casual workspace, while making a meaningful impact on the
environment sounds interesting, take a look at our careers page to see
what positions are currently available:

https://www.ecobee.com/careers/

********************************************************************************
`

	console.log(message, 'font-family:monospace;color:#5bbd76;') // eslint-disable-line no-console
}

const render = () => {
	ecobeeMessage()

	ReactDOM.render(
		<ErrorBoundary FallbackComponent={UnrecoverableErrorPage}>
			<Helmet titleTemplate="%s | ecobee SmartBuildings" />
			<FeatureFlagsProvider>
				<Provider store={store}>
					<ApolloProvider client={client}>
						<UserPreferencesProvider>
							<Router history={history}>
								<ScrollToTop>
									<AppContainer />
								</ScrollToTop>
							</Router>
						</UserPreferencesProvider>
					</ApolloProvider>
				</Provider>
			</FeatureFlagsProvider>
		</ErrorBoundary>,
		document.querySelector('#root'),
	)
}

// Tell Modal what the app element is
Modal.setAppElement('#root')

// ========================================================
// Go!
// ========================================================
render()
