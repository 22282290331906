import config from 'config'
import queryString from 'query-string'

/**
 * Gets an array of place suggestions from Bing Maps for a user-entered string
 *
 * @param {string} userInput Text entered by the user to find suggestions for
 * @returns {Promise<object[]>} Place suggestions based on the user's input text, or an empty array if suggestions can't be fetched
 */
export const getBingMapsSuggestions = async (userInput) => {
	const query = queryString.stringify({
		includeEntityTypes: 'Address,Place',
		key: config.BING_MAPS_API_KEY,
		q: userInput,
	})

	const res = await fetch(`${config.BING_MAPS_AUTOSUGGEST_URL}?${query}`)
	const jsonRes = await res.json()

	return jsonRes.resourceSets?.[0]?.resources?.[0]?.value ?? []
}

/**
 * Geocodes a provided address string from the Bing Maps autosuggest API
 *
 * @param  {string} displayAddress Formatted display address from Bing Maps autosuggest
 * @returns {Promise<object|null>} Object containing location details includeing latitude and longitude, or null if address cannot be geocoded
 */
export const geocodeBingMapsAddress = async (displayAddress) => {
	try {
		const query = queryString.stringify({
			q: displayAddress,
			incl: 'ciso2',
			key: config.BING_MAPS_API_KEY,
		})

		const res = await fetch(`${config.BING_MAPS_LOCATIONS_URL}?${query}`)
		const jsonRes = await res.json()

		if (jsonRes.resourceSets?.[0]?.resources?.length > 0) {
			const { address, geocodePoints } = jsonRes.resourceSets[0].resources[0]

			const locationData = {
				address: address.addressLine || '',
				city: address.locality || '',
				state: address.adminDistrict || '',
				zipCode: address.postalCode || '',
				country: address.countryRegionIso2 || '',
				fullAddress: displayAddress,
			}

			if (geocodePoints?.length > 0) {
				const {
					coordinates: [latitude, longitude],
				} = geocodePoints[0]

				Object.assign(locationData, { latitude, longitude })
			}

			return locationData
		}

		return null
	} catch {
		// TODO Notify?
		return null
	}
}
