import { Deserializer as JSONApiDeserializer } from 'jsonapi-serializer'
import saveAs from 'file-saver'

import AuthService from '@/services/auth.service'
import history from '@/services/history'
import errorLoggingService from '@/services/error.logging.service'
import { SERVER_ERROR, BASE_ROUTE, LOGOUT_ROUTE } from '@/utils/constants'
import apiErrorFactory from './api.error.factory'
import fetchParamsFormatter from './fetch.params.formatter'
import apiEndpoints from './api.endpoints'

const UNAUTHORIZED = 401
const FORBIDDEN = 403
const TIMEOUT = 408

const deserializer = new JSONApiDeserializer({
	keyForAttribute: /* istanbul ignore next */ (key) => key,
})

const deserializeResponse = async (data, response) => {
	const results = await deserializer.deserialize(data)
	return { data: results, response }
}

/**
 * Validates if a response is a timeout
 *
 * @param  {object}  response fetch response object
 * @returns {boolean}          If the response is a tiemout
 */
function isFailedAPI408Timeout(response) {
	const isTimeout = response.status === TIMEOUT
	return isTimeout
}

/**
 * Validates if a response is unauthorized (at this time only occurs when token is expired)
 *
 * @param response.response
 * @param {object} response fetch response object
 * @returns {boolean}          If the response is a unauthorized
 */
function isUnauthorized401({ response }) {
	return response.status === UNAUTHORIZED
}

/**
 * Validates if a response is unauthorized
 *
 * @param response.response
 * @param {object} response fetch response object
 * @returns {boolean}          If the response is a unauthorized
 */
function isForbidden403({ response }) {
	return response.status === FORBIDDEN
}

/**
 * fetch wrapper for ecobee commercial API
 *
 * @param  {string} apiType The API endpoint type
 * @param  {object} apiData Data being sent to the API
 * @returns {Promise}        Promise resolved with fetch as either response data or error
 */
export default function fetchAPI(apiType, apiData) {
	const {
		deserializeForJsonApi,
		download = false,
		...apiConfig
	} = apiEndpoints(apiType, apiData)
	const fetchParams = fetchParamsFormatter(apiConfig)

	errorLoggingService.leaveBreadcrumb('HTTP request', {
		url: fetchParams.url,
		...fetchParams.fetchOptions,
	})
	return fetch(fetchParams.url, fetchParams.fetchOptions)
		.then(
			// This block changes response to json format
			async (response) => {
				if (download) {
					if (response.status >= 400) {
						const err = { data: null, response }
						throw err
					}

					// NB -- for better safety and spec compliance, we could import `content-disposition`
					// However, this comes with a bundle size impact (~10k minified + gzipped)
					const contentDisposition = response.headers.get('content-disposition')
					const filenameMatch =
						contentDisposition &&
						contentDisposition.match(/filename="([\w\-.]+)"/)
					const filename = filenameMatch && filenameMatch[1]

					saveAs(await response.blob(), filename)

					return { data: null, response }
				}

				// don't try to parse json if we have no content returned
				if (response.status !== 204) {
					return response.json().then((data) => {
						// only resolve response if http request status code is successful
						if (response.ok) {
							return { data, response }
						}

						// if http request status code is bad
						const rejectObject = { data, response }

						// if the error is a 408 timeout, treat error as a network connection error
						if (isFailedAPI408Timeout(response)) {
							rejectObject.data = null
						}

						return Promise.reject(rejectObject)
					})
				}

				return Promise.resolve({
					data: null,
					response,
				})
			},
			(error) =>
				Promise.reject({
					data: {
						error: SERVER_ERROR,
						error_description: error,
					},
					response: {
						ok: false,
					},
				}),
		)
		.then(
			// Return final values
			({ data, response }) =>
				deserializeForJsonApi && data
					? deserializeResponse(data, response)
					: { data, response },
		)
		.catch((error) => {
			// default error type is the api type
			const errorType = apiType

			errorLoggingService.leaveBreadcrumb('HTTP request error', {
				status: error.response.status,
				ok: error.response.ok,
				...error.data,
			})

			if (isUnauthorized401(error)) {
				// rethrow so can be picked up by safeFetch function
				return Promise.reject(error)
			}

			// if the error is a 403 fobidden, redirect the user to the base route
			if (
				apiConfig.fetchOptions.method.toUpperCase() === 'GET' &&
				isForbidden403(error)
			) {
				history.push(BASE_ROUTE)
			}

			return {
				...error,
				error: apiErrorFactory(
					errorType,
					apiType,
					apiData ? apiData.data : undefined,
					error,
				),
			}
		})
}

let refreshTokenPromise
let promiseIsActive = false

/**
 * Refresh API tokens
 *
 * @returns {Promise} A promise which resolves or rejects if tokens were refreshed
 */
export function refreshTokens() {
	if (!promiseIsActive) {
		const refreshPayload = {
			data: {
				refreshToken: AuthService.getRefreshToken(),
			},
		}

		refreshTokenPromise = fetchAPI('refresh', refreshPayload).then(
			(response) => {
				promiseIsActive = false

				if (
					response.data &&
					response.data.access_token &&
					response.data.refresh_token
				) {
					// update tokens to get new tokens
					const { access_token, refresh_token } = response.data
					AuthService.authenticate(access_token, refresh_token)
					return Promise.resolve(true)
				}

				errorLoggingService.notify(new Error('Token refresh failed'), {
					metaData: {
						refreshToken: refreshPayload.data.refreshToken,
					},
				})

				return Promise.reject(response)
			},
		)

		promiseIsActive = true
	}

	return refreshTokenPromise
}

/**
 * fetch that if needed will refreshes tokens and retry original fetch after refresh.
 *
 * @param  {string} apiType The type of API to call
 * @param  {object} apiData Data sent to the API
 * @returns {Mixed}          Object containing response and data objects otherwise false
 */
export function safeFetch(apiType, apiData) {
	return fetchAPI(apiType, apiData).catch(() =>
		// fetchAPI will only throw in case of a 401 unauthorized response
		refreshTokens()
			.then(() => fetchAPI(apiType, apiData))
			.catch((error) => {
				// token could not be refreshed; log the user out
				history.push(LOGOUT_ROUTE)
				return error
			}),
	)
}

/**
 * Fetch that if needed will fetch all pages of responses from the API.
 *
 * @param  {string} apiType The type of API to call
 * @param  {object} apiData Data sent to the API
 * @returns {Array}          Array of object containing response and data objects
 */
export function pagedFetch(apiType, apiData) {
	return safeFetch(apiType, apiData).then((response) => {
		const { data } = response
		const pageRequests = [response]
		if (data.page && data.page.totalPages > 1) {
			// Push each request to the array
			for (let i = 2; i <= data.page.totalPages; i += 1) {
				apiData.page = {
					page: i,
				}
				pageRequests.push(safeFetch(apiType, apiData))
			}
		}
		return Promise.all(pageRequests)
	})
}

/**
 * Perform multiple api fetches and consolodate promises
 *
 * @param  {string} apiType The type of API to call
 * @param  {Array}  apiData An array of API data for each request
 * @returns {Array}          Array of response and data objects
 */
export function fetchAPIMultiple(apiType, apiData) {
	const promiseArray = []
	const dataArray = Array.isArray(apiData) ? apiData : [apiData]

	dataArray.forEach((data) => {
		promiseArray.push(safeFetch(apiType, data))
	})

	return Promise.all(promiseArray)
}
