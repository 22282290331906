import config from 'config'

export class UserMonitoringService {
	constructor(environment) {
		this.isValidEnvironment =
			environment === 'test' ||
			environment === 'staging' ||
			environment === 'production'

		if (this.isValidEnvironment) {
			window._mfq = window._mfq || []
			window._mfq.push((mf) => {
				this.sessionId = mf.getSessionId()
			})
		}
	}

	getSessionId() {
		return this.sessionId
	}

	identify(user) {
		if (this.isValidEnvironment) {
			window._mfq.push(['setVariable', 'userId', user.id])
		}
	}

	setCompanyId(companyId) {
		if (this.isValidEnvironment) {
			window._mfq.push(['setVariable', 'companyId', companyId])
		}
	}

	tagRecording(tag) {
		if (this.isValidEnvironment) {
			window._mfq.push(['tag', tag])
		}
	}
}

const userMonitoringService = new UserMonitoringService(config.ENV)

export default userMonitoringService
