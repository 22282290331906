import React from 'react'
import { withLoadingLayout } from '@/routes/route.helpers'
import { withDisplayName } from '@/utils/display.name.helpers'

export const loader = /* istanbul ignore next */ () =>
	import(
		/* webpackChunkName: 'buildingList' */ '../../containers/building-list-container'
	)

export default withLoadingLayout(
	withDisplayName(React.lazy(loader), 'BuildingListRoute'),
)
