import React from 'react'
import { withDisplayName } from '@/utils/display.name.helpers'
import { withLoadingLayoutSimple } from '@/routes/route.helpers'

export const loader = /* istanbul ignore next */ () =>
	import(
		/* webpackChunkName: 'acceptUserInvitation' */ '../../containers/accept-user-invitation-container'
	)

export default withLoadingLayoutSimple(
	withDisplayName(React.lazy(loader), 'AcceptUserInvitationRoute'),
)
