import { takeLatest, call, put, select } from 'redux-saga/effects'
import { get } from 'lodash'
import * as toastActions from '@/store/toast/actions'
import * as modalActions from '@/store/modal/actions'
import * as companyUserActions from '@/store/company-users/actions'
import { getPathPrefix } from '@/utils/misc'
import { activeCompanyIdSelector } from '@/store/user/selectors'
import history from '@/services/history'
import { SERVER_ERROR, TOKEN_KEY, INVITATION_ROUTE } from '@/utils/constants'
import { safeFetch } from '@/services/fetch-api/fetch.api'
import * as inviteUserActions from './actions'
import { INVITE_USER_REQUEST } from './constants'

export const USER_ALREADY_IN_COMPANY_ERROR_CODE = 'user_already_in_company'

/**
 * @param root0
 * @param root0.email
 * @param root0.role
 * @param root0.resourceAccess
 */
export function* sendInvite({ email, role, resourceAccess, secureInviteFlow }) {
	const pathPrefix = getPathPrefix(history)
	const linkPathPrefix = `${pathPrefix}${INVITATION_ROUTE}?${TOKEN_KEY}=`
	/* istanbul ignore next */
	const port = window.location.port ? { port: window.location.port } : {}

	const results = yield call(safeFetch, 'sendInvite', {
		email,
		...port,
		linkPathPrefix,
		role,
		resourceAccess,
		secureInviteFlow,
	})

	if (results.response.ok) {
		return results.response
	}

	throw new Error(
		get(results, 'error.returnedData.errors[0].code', SERVER_ERROR),
	)
}

/**
 * @param root0
 * @param root0.payload
 */
export function* inviteUser({ payload }) {
	const { email } = payload
	const activeCompanyId = yield select(activeCompanyIdSelector)

	try {
		yield call(sendInvite, payload)
		yield put(
			inviteUserActions.inviteUserSuccess(null, {
				event: 'UserInviteSuccess',
				eventDetails: {
					email,
				},
			}),
		)
		yield put(companyUserActions.companyUsersRequest(activeCompanyId))
		yield put(
			toastActions.toastShow({
				message: `${email} has been invited.`,
			}),
		)
	} catch (error) {
		yield put(
			inviteUserActions.inviteUserError(error.message, {
				event: 'UserInviteError',
				eventDetails: {
					email,
				},
			}),
		)
		const modalErrorMessage =
			error.message === USER_ALREADY_IN_COMPANY_ERROR_CODE
				? 'You’ve invited a user who has already been added to your account.'
				: 'An error has occurred. Your invite has not been sent.'
		yield put(
			modalActions.modalShow({
				title: 'Something went wrong',
				message: modalErrorMessage,
			}),
		)
	}
}

/**
 *
 */
export function* inviteUserRequestWatcher() {
	yield takeLatest(INVITE_USER_REQUEST, inviteUser)
}
