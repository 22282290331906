import { createAction } from '@/utils/actions'
import * as runtimeReportActionTypes from './constants'

export const getRuntimeReportChartDataRequest = createAction(
	runtimeReportActionTypes.GET_RUNTIME_REPORT_CHART_DATA_REQUEST,
)
export const getRuntimeReportChartDataSuccess = createAction(
	runtimeReportActionTypes.GET_RUNTIME_REPORT_CHART_DATA_SUCCESS,
)
export const getRuntimeReportChartDataError = createAction(
	runtimeReportActionTypes.GET_RUNTIME_REPORT_CHART_DATA_ERROR,
)

export const getRuntimeReportExportRequest = createAction(
	runtimeReportActionTypes.GET_RUNTIME_REPORT_EXPORT_REQUEST,
)
export const getRuntimeReportExportSuccess = createAction(
	runtimeReportActionTypes.GET_RUNTIME_REPORT_EXPORT_SUCCESS,
)
export const getRuntimeReportExportError = createAction(
	runtimeReportActionTypes.GET_RUNTIME_REPORT_EXPORT_ERROR,
)

export const updateAppliedSensors = createAction(
	runtimeReportActionTypes.UPDATE_APPLIED_SENSORS,
)
