import { takeLatest, call, put } from 'redux-saga/effects'
import { safeFetch } from '@/services/fetch-api/fetch.api'
import errorLoggingService from '@/services/error.logging.service'
import * as accountCreationActions from './actions'
import { ACCOUNT_CREATION_REQUEST } from './constants'

/**
 * @param action
 */
export function* createAccount(action) {
	const { firstName, lastName, password, token } = action.payload
	const payload = {
		firstName,
		lastName,
		password,
	}

	const { response } = yield call(safeFetch, 'createAccount', {
		token,
		payload,
	})

	if (response.ok) {
		yield put(accountCreationActions.accountCreationSuccess())
	} else {
		errorLoggingService.notify('Cannot create account')
		yield put(accountCreationActions.accountCreationError())
	}
}

/**
 *
 */
export function* createAccountRequestWatcher() {
	yield takeLatest(ACCOUNT_CREATION_REQUEST, createAccount)
}
