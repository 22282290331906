import { createBrowserHistory as createHistory } from 'history'
import config from 'config'

// Allow build time override of basename
const basename = process.env.SB_PUBLIC_PATH || config.ROUTER_BASE_NAME

const history = createHistory({
	basename,
})

export default history
