import React from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import './transition.scss'

export const TRANSITION_DURATION_MS = 400
export const TRANSITION_SLIDE_DURATION_MS = 300

const Transition = (props) => {
	const {
		children,
		type,
		animateOnAppear,
		animateOnEnter,
		animateOnLeave,
		isVisible,
		wrapChildren,
		...transitionProps
	} = props
	const mountProps =
		isVisible == null ? {} : { mountOnEnter: true, unmountOnExit: true }
	const isSlide = type === 'slide-left' || type === 'slide-right'
	const duration = isSlide
		? TRANSITION_SLIDE_DURATION_MS
		: TRANSITION_DURATION_MS

	return (
		<CSSTransition
			in={isVisible == null ? true : isVisible}
			classNames={type}
			appear={animateOnAppear}
			timeout={{ exit: duration, enter: duration }}
			enter={animateOnEnter}
			exit={animateOnLeave}
			{...mountProps}
			{...transitionProps}
		>
			{wrapChildren ? <div>{children}</div> : children}
		</CSSTransition>
	)
}

Transition.defaultProps = {
	animateOnAppear: true,
	animateOnEnter: true,
	animateOnLeave: true,
	duration: 400,
	type: 'transition',
	wrapChildren: false,
}

Transition.propTypes = {
	animateOnAppear: PropTypes.bool,
	animateOnEnter: PropTypes.bool,
	animateOnLeave: PropTypes.bool,
	children: PropTypes.node,
	duration: PropTypes.number,
	isVisible: PropTypes.bool,
	type: PropTypes.oneOf(['transition', 'fade', 'slide-left', 'slide-right']),
	wrapChildren: PropTypes.bool,
}

export default Transition
