import { createAction } from '@/utils/actions'
import * as registerThermostatActionTypes from './constants'

export const registerThermostatUpdate = createAction(
	registerThermostatActionTypes.REGISTER_THERMOSTAT_UPDATE,
)
export const registerThermostatAdd = createAction(
	registerThermostatActionTypes.REGISTER_THERMOSTAT_ADD,
)
export const registerThermostatRequest = createAction(
	registerThermostatActionTypes.REGISTER_THERMOSTAT_REQUEST,
)
export const registerThermostatSuccess = createAction(
	registerThermostatActionTypes.REGISTER_THERMOSTAT_SUCCESS,
)
export const registerThermostatError = createAction(
	registerThermostatActionTypes.REGISTER_THERMOSTAT_ERROR,
)
export const resetRegisterThermostat = createAction(
	registerThermostatActionTypes.RESET_REGISTER_THERMOSTAT,
)

export const registerThermostatUpdateAll = createAction(
	registerThermostatActionTypes.REGISTER_THERMOSTAT_UPDATE_ALL,
)
export const registerThermostatSetDuplicates = createAction(
	registerThermostatActionTypes.REGISTER_THERMOSTAT_SET_DUPLICATES,
)
