import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import analyticsService from '@/services/analytics.service'
import { EDL_COLOURS } from '@ecobee/smartbuildings-library-common/constants'

const StyledExternalLink = styled.a`
	&:active,
	&:link,
	&:visited {
		color: ${EDL_COLOURS.EGGSHELL_BLUE};
	}
`

const ExternalLink = ({
	href,
	children,
	analyticsEvent,
	analyticsEventProperties,
	...linkProps
}) => (
	<StyledExternalLink
		href={href}
		target="_blank"
		rel="noopener noreferrer"
		{...linkProps}
		onClick={(e) => {
			const { onClick } = linkProps

			analyticsService.trackEvent(
				analyticsEvent ?? 'ExternalLinkClick',
				analyticsEvent
					? analyticsEventProperties
					: {
							label: typeof children === 'string' ? children : null,
							href,
					  },
			)

			return onClick?.(e)
		}}
	>
		{children}
	</StyledExternalLink>
)

ExternalLink.propTypes = {
	analyticsEvent: PropTypes.string,
	analyticsEventProperties: PropTypes.objectOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.arrayOf(PropTypes.string.isRequired),
		]),
	),
	href: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
}

export default ExternalLink
