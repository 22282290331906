import AuthService from '@/services/auth.service'
import * as loginActionTypes from './constants'

const initialState = {
	isFetching: false,
	isAuthenticated: AuthService.isAuthenticated(),
	errorMessage: '',
}

const loginReducer = (state = initialState, action) => {
	switch (action.type) {
		case loginActionTypes.LOGIN_REQUEST:
			return {
				...state,
				isFetching: true,
			}
		case loginActionTypes.LOGIN_ERROR:
			return {
				...state,
				isFetching: false,
				isAuthenticated: false,
				errorMessage: action.payload.message,
			}
		case loginActionTypes.LOGIN_SUCCESS:
			return {
				...state,
				isFetching: false,
				isAuthenticated: true,
				errorMessage: '',
			}
		case loginActionTypes.LOGOUT:
			return {
				...state,
				isAuthenticated: false,
				isFetching: false,
				errorMessage: '',
			}
		default:
			return state
	}
}

export default loginReducer
