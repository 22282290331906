/* istanbul ignore file: this is a config file, so nothing is gained by unit testing it */

import queryString from 'query-string'
import { get } from 'lodash'
import { SMARTBUILDINGS_SELECTION_TYPE } from '@ecobee/smartbuildings-library-common/constants'

import routingService from '@/services/routing.service'
import {
	serverUrl,
	apiUrl,
	applicationKey,
	defaultUrlParam,
	getHeader,
	getDefaultFetchOptions,
	applicationSecret,
} from './api.config'

import {
	BuildingSerializer,
	CompanySerializer,
	CompanyUserSerializer,
} from './json.api.serializers'

const getCurrentTime = () => new Date().getTime()

const getBuildingId = () => get(routingService.getMatch(), 'params.buildingId')

const apiEndpoints = {
	refresh: (params) => ({
		baseUrl: `${serverUrl}/login`,
		fetchOptions: {
			method: 'POST',
			headers: {
				...getHeader(true),
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: queryString.stringify({
				client_id: applicationKey,
				client_secret: applicationSecret,
				grant_type: 'refresh_token',
				refresh_token: params.data.refreshToken,
			}),
		},
	}),
	authorize: (params) => ({
		baseUrl: `${serverUrl}/login`,
		fetchOptions: {
			...getDefaultFetchOptions('POST', true),
			headers: {
				...getHeader(true),
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: queryString.stringify({
				client_id: applicationKey,
				client_secret: applicationSecret,
				username: params.username,
				password: params.password,
				grant_type: 'password',
			}),
		},
	}),
	registerThermostat: ({ buildingId = getBuildingId(), thermostatIds }) => ({
		baseUrl: `${apiUrl}/thermostat/register`,
		urlParams: {
			...defaultUrlParam,
		},
		fetchOptions: {
			...getDefaultFetchOptions('POST', true),
			body: JSON.stringify({ buildingId, thermostatIds }),
		},
	}),
	getCompanyUsers: () => ({
		baseUrl: `${apiUrl}/company/relationships/users`,
		urlParams: {
			...defaultUrlParam,
		},
		fetchOptions: getDefaultFetchOptions('GET', true),
		deserializeForJsonApi: true,
	}),
	getCompanyUserSingle: ({ userId }) => ({
		baseUrl: `${apiUrl}/company/relationships/users/${userId}`,
		urlParams: {
			...defaultUrlParam,
		},
		fetchOptions: getDefaultFetchOptions('GET', true),
		deserializeForJsonApi: true,
	}),
	updateCompanyUser: ({ userId, ...updates }) => {
		const body = CompanyUserSerializer.serialize({
			...updates,
			id: userId,
		})
		return {
			baseUrl: `${apiUrl}/company/relationships/users/${userId}`,
			fetchOptions: {
				...getDefaultFetchOptions('PATCH', true),
				body: JSON.stringify(body),
			},
			deserializeForJsonApi: true,
		}
	},
	getCompanyTags: () => ({
		baseUrl: `${apiUrl}/company/relationships/tags`,
		urlParams: {
			...defaultUrlParam,
		},
		fetchOptions: getDefaultFetchOptions('GET', true),
		deserializeForJsonApi: true,
	}),
	createTag: ({ name }) => ({
		baseUrl: `${apiUrl}/company/relationships/tags`,
		fetchOptions: {
			...getDefaultFetchOptions('POST', true),
			body: JSON.stringify({ name }),
		},
		deserializeForJsonApi: true,
	}),
	updateTag: ({ tagId, update }) => ({
		baseUrl: `${apiUrl}/company/relationships/tags/${tagId}`,
		fetchOptions: {
			...getDefaultFetchOptions('PATCH', true),
			body: JSON.stringify(update),
		},
	}),
	deleteTag: ({ tagId }) => ({
		baseUrl: `${apiUrl}/company/relationships/tags/${tagId}`,
		fetchOptions: {
			...getDefaultFetchOptions('DELETE', true),
		},
	}),
	addTagsToThermostat: ({
		buildingId = getBuildingId(),
		thermostatId,
		tagIds,
	}) => ({
		baseUrl: `${apiUrl}/thermostat/${thermostatId}/relationships/tags`,
		fetchOptions: {
			...getDefaultFetchOptions('POST', true),
			body: JSON.stringify({ buildingId, tagIds }),
		},
	}),
	removeTagsFromThermostat: ({
		buildingId = getBuildingId(),
		thermostatId,
		tagIds,
	}) => ({
		baseUrl: `${apiUrl}/thermostat/${thermostatId}/relationships/tags`,
		fetchOptions: {
			...getDefaultFetchOptions('DELETE', true),
			body: JSON.stringify({ buildingId, tagIds }),
		},
	}),
	getThermostat: ({ id, buildingId = getBuildingId(), selection }) => ({
		baseUrl: `${apiUrl}/thermostat/${id}`,
		urlParams: {
			buildingId,
			selection: JSON.stringify(selection),
		},
		fetchOptions: getDefaultFetchOptions('GET', true),
		deserializeForJsonApi: true,
	}),
	getThermostatList: ({ buildingId = getBuildingId(), search, selection }) => {
		const getThermostatListObj = {
			baseUrl: `${apiUrl}/thermostat`,
			urlParams: {
				selection: JSON.stringify(selection),
				buildingId,
				search,
			},
			fetchOptions: getDefaultFetchOptions('GET', true),
			deserializeForJsonApi: true,
		}

		return getThermostatListObj
	},
	updateThermostat: ({ id, settingsToUpdate }) => ({
		baseUrl: `${apiUrl}/thermostat`,
		urlParams: {
			...defaultUrlParam,
			_timestamp: getCurrentTime(),
		},
		fetchOptions: {
			...getDefaultFetchOptions('POST', true),
			body: JSON.stringify({
				selection: {
					selectionType: SMARTBUILDINGS_SELECTION_TYPE,
					selectionMatch: id,
				},
				thermostat: settingsToUpdate,
			}),
		},
	}),
	moveThermostats: ({ thermostatIds, data }) => ({
		baseUrl: `${apiUrl}/thermostat`,
		urlParams: {
			...defaultUrlParam,
			_timestamp: getCurrentTime(),
		},
		fetchOptions: {
			...getDefaultFetchOptions('PUT', true),
			body: JSON.stringify({ thermostatIds, data }),
		},
	}),
	updateThermostatWithFunction: ({
		id,
		buildingId = getBuildingId(),
		functions,
	}) => ({
		baseUrl: `${apiUrl}/thermostat`,
		urlParams: {
			...defaultUrlParam,
			_timestamp: getCurrentTime(),
		},
		fetchOptions: {
			...getDefaultFetchOptions('POST', true),
			body: JSON.stringify({
				selection: {
					selectionType: SMARTBUILDINGS_SELECTION_TYPE,
					selectionMatch: id,
				},
				buildingId,
				functions,
			}),
		},
	}),
	deleteThermostatBulk: ({ buildingId = getBuildingId(), thermostatIds }) => ({
		baseUrl: `${apiUrl}/thermostat`,
		urlParams: {
			...defaultUrlParam,
			_timestamp: getCurrentTime(),
		},
		fetchOptions: {
			...getDefaultFetchOptions('DELETE', true),
			body: JSON.stringify({ buildingId, thermostatIds }),
		},
	}),
	getRuntimeReportExport: ({
		thermostatId,
		buildingId = getBuildingId(),
		startDate,
		endDate,
	}) => ({
		baseUrl: `${apiUrl}/thermostat/${thermostatId}/runtimeReport/export`,
		urlParams: {
			buildingId,
			startDate,
			endDate,
		},
		fetchOptions: getDefaultFetchOptions('GET', true, { Accept: 'text/csv' }),
		download: true,
	}),
	getRuntimeReportChartData: ({
		thermostatId,
		buildingId = getBuildingId(),
		startDate,
		endDate,
	}) => ({
		baseUrl: `${apiUrl}/thermostat/${thermostatId}/runtimeReport/graph`,
		urlParams: {
			buildingId,
			startDate,
			endDate,
		},
		fetchOptions: getDefaultFetchOptions('GET', true),
	}),
	getBuildingsList: (params) => ({
		baseUrl: `${apiUrl}/building${params.id ? `/${params.id}` : ''}`,
		fetchOptions: getDefaultFetchOptions('GET', true, {
			accountReference: params.accountReference,
		}),
		deserializeForJsonApi: true,
	}),
	getUser: () => ({
		baseUrl: `${apiUrl}/user`,
		urlParams: {
			selection: JSON.stringify({}),
		},
		fetchOptions: getDefaultFetchOptions('GET', true),
		deserializeForJsonApi: true,
	}),
	addBuilding: (params) => {
		const { accountReference } = params

		const parsedObject = BuildingSerializer.serialize(params)

		return {
			baseUrl: `${apiUrl}/building`,
			fetchOptions: {
				...getDefaultFetchOptions('POST', true, {
					accountReference,
				}),
				body: JSON.stringify(parsedObject),
			},
			deserializeForJsonApi: true,
		}
	},
	updateBuilding: (params) => {
		const { accountReference, id } = params

		const parsedObject = BuildingSerializer.serialize(params)

		return {
			baseUrl: `${apiUrl}/building/${id}`,
			fetchOptions: {
				...getDefaultFetchOptions('PATCH', true, {
					accountReference,
				}),
				body: JSON.stringify(parsedObject),
			},
			deserializeForJsonApi: true,
		}
	},
	deleteBuilding: (params) => {
		const { accountReference, id } = params

		return {
			baseUrl: `${apiUrl}/building/${id}`,
			fetchOptions: getDefaultFetchOptions('DELETE', true, {
				accountReference,
			}),
			deserializeForJsonApi: true,
		}
	},
	createCompany: ({ hostedPageId, password }) => {
		const body = CompanySerializer.serialize({ hostedPageId, password })

		return {
			baseUrl: `${serverUrl}/company`,
			fetchOptions: {
				...getDefaultFetchOptions('POST'),
				body: JSON.stringify(body),
			},
			deserializeForJsonApi: true,
		}
	},
	createFreeSubscription: () => ({
		baseUrl: `${apiUrl}/company/relationships/freeSubscriptions`,
		fetchOptions: {
			...getDefaultFetchOptions('POST', true),
		},
		deserializeForJsonApi: false,
	}),
	updateCompany: (companyData) => ({
		baseUrl: `${apiUrl}/company`,
		fetchOptions: {
			...getDefaultFetchOptions('PATCH', true),
			body: JSON.stringify(companyData),
		},
		deserializeForJsonApi: false,
	}),
	updateCompanyBillingAddress: (companyData) => ({
		baseUrl: `${apiUrl}/company/billingAddress`,
		fetchOptions: {
			...getDefaultFetchOptions('PATCH', true),
			body: JSON.stringify(companyData),
		},
		deserializeForJsonApi: false,
	}),
	validateCompanyEmail: (params) => ({
		baseUrl: `${serverUrl}/company/validate`,
		fetchOptions: {
			...getDefaultFetchOptions('POST', true),
			body: JSON.stringify(params),
		},
		deserializeForJsonApi: false,
	}),
	sendValidationEmail: ({
		email,
		firstName,
		lastName,
		country,
		port,
		linkPathPrefix,
		programId,
	}) => {
		const payload = {
			linkPathPrefix,
			data: { email, firstName, lastName, country, programId },
		}
		if (port) {
			payload.port = port
		}
		const body = JSON.stringify({
			data: {
				type: 'sendValidation',
				attributes: payload,
			},
		})

		return {
			baseUrl: `${serverUrl}/company/sendValidation`,
			fetchOptions: {
				...getDefaultFetchOptions('POST'),
				body,
			},
			deserializeForJsonApi: false,
		}
	},
	sendForgotPasswordEmail: ({ email, port, linkPathPrefix }) => {
		const payload = { email, linkPathPrefix }
		if (port) {
			payload.port = port
		}

		const body = JSON.stringify({
			data: {
				type: 'sendResetEmail',
				attributes: payload,
			},
		})

		return {
			baseUrl: `${serverUrl}/reset/sendEmail`,
			fetchOptions: {
				...getDefaultFetchOptions('POST'),
				body,
			},
			deserializeForJsonApi: false,
		}
	},
	getChargebeePortalSession: () => ({
		baseUrl: `${apiUrl}/subscription/portal`,
		fetchOptions: {
			...getDefaultFetchOptions('POST', true),
			body: JSON.stringify({}),
		},
		deserializeForJsonApi: false,
	}),
	getChargebeeHostedPageForActiveCompany: ({ planQuantity }) => ({
		baseUrl: `${apiUrl}/subscription/hostedPage`,
		fetchOptions: {
			...getDefaultFetchOptions('PATCH', true),
			body: JSON.stringify({ planQuantity }),
		},
		deserializeForJsonApi: false,
	}),
	renewToken: (token) => {
		const fetchOptions = getDefaultFetchOptions('POST', false, {
			Authorization: `Basic ${token}`,
		})

		return {
			baseUrl: `${apiUrl}/validation/renew`,
			fetchOptions,
			deserializeForJsonApi: true,
		}
	},
	resetPassword: ({ token, password }) => {
		const fetchOptions = getDefaultFetchOptions('POST', false, {
			Authorization: `Basic ${token}`,
		})
		return {
			baseUrl: `${serverUrl}/reset`,
			fetchOptions: {
				...fetchOptions,
				body: JSON.stringify({
					data: {
						type: 'reset',
						attributes: {
							password,
						},
					},
				}),
			},
			deserializeForJsonApi: true,
		}
	},
	sendInvite: ({
		email,
		port,
		linkPathPrefix,
		role,
		resourceAccess,
		secureInviteFlow,
	}) => {
		const payload = { email, linkPathPrefix, role }
		if (port) {
			payload.port = port
		}

		if (secureInviteFlow) {
			payload.secureInviteFlow = secureInviteFlow
		}

		if (resourceAccess) {
			payload.resourceAccess = resourceAccess
		}

		const body = JSON.stringify({
			data: {
				type: 'invitation',
				attributes: payload,
			},
		})

		return {
			baseUrl: `${apiUrl}/invite`,
			fetchOptions: {
				...getDefaultFetchOptions('POST', true),
				body,
			},

			deserializeForJsonApi: false,
		}
	},
	acceptInvite: ({ token, payload }) => {
		const body = JSON.stringify({
			data: {
				type: 'acceptInvitation',
				attributes: payload,
			},
		})
		const fetchOptions = getDefaultFetchOptions('POST', false, {
			Authorization: `Basic ${token}`,
		})

		return {
			baseUrl: `${apiUrl}/invite/accept`,
			fetchOptions: {
				...fetchOptions,
				body,
			},
			deserializeForJsonApi: false,
		}
	},
	acceptAuthenticatedInvite: ({ token, payload }) => {
		const body = JSON.stringify({
			data: {
				type: 'acceptAuthenticatedInvitation',
				attributes: payload,
				token,
			},
		})
		const fetchOptions = getDefaultFetchOptions('POST', true)

		return {
			baseUrl: `${apiUrl}/user/invite/accept`,
			fetchOptions: {
				...fetchOptions,
				body,
			},
			deserializeForJsonApi: false,
		}
	},
	createAccount: ({ token, payload }) => {
		const body = JSON.stringify({
			data: {
				type: 'accountCreation',
				attributes: payload,
			},
		})
		const fetchOptions = getDefaultFetchOptions('POST', false, {
			Authorization: `Basic ${token}`,
		})

		return {
			baseUrl: `${apiUrl}/account`,
			fetchOptions: {
				...fetchOptions,
				body,
			},
			deserializeForJsonApi: false,
		}
	},
	revokeUserFromCompany: ({ userId }) => {
		const body = JSON.stringify({
			data: {
				type: 'user',
				id: userId,
			},
		})

		return {
			baseUrl: `${apiUrl}/company/relationships/users`,
			fetchOptions: {
				...getDefaultFetchOptions('DELETE', true),
				body,
			},
			deserializeForJsonApi: false,
		}
	},
	updateUser: ({ userId, attributes }) => {
		const body = JSON.stringify({
			data: {
				type: 'user',
				id: userId,
				attributes,
			},
		})

		return {
			baseUrl: `${apiUrl}/user`,
			fetchOptions: {
				...getDefaultFetchOptions('PATCH', true),
				body,
			},
			deserializeForJsonApi: false,
		}
	},
	updateSensor: ({
		buildingId,
		sensorId,
		thermostatId,
		name,
		isParticipating,
	}) => {
		const body = JSON.stringify({
			buildingId,
			updates: {
				name,
				isParticipating,
			},
		})

		return {
			baseUrl: `${apiUrl}/thermostat/${thermostatId}/relationships/sensors/${sensorId}`,
			urlParams: {
				...defaultUrlParam,
			},
			fetchOptions: {
				...getDefaultFetchOptions('PATCH', true),
				body,
			},
		}
	},
}

const apiEndpointsFactory = (apiType, data) => apiEndpoints[apiType](data)

export default apiEndpointsFactory
