import React from 'react'
import PlaceholderLayout from '@/components/placeholder-layout'
import { withLoadingState } from '@/utils/loading.state.helpers'

const LayoutLoading = /* istanbul ignore next */ () => <PlaceholderLayout />
const LayoutError = /* istanbul ignore next */ () => (
	<PlaceholderLayout isError onRetry={() => window.location.reload()} />
)
export const withLoadingLayout = withLoadingState(LayoutLoading, LayoutError)

const LayoutSimpleLoading = /* istanbul ignore next */ () => (
	<PlaceholderLayout isSimple />
)
const LayoutSimpleError = /* istanbul ignore next */ () => (
	<PlaceholderLayout
		isSimple
		isError
		onRetry={() => window.location.reload()}
	/>
)
export const withLoadingLayoutSimple = withLoadingState(
	LayoutSimpleLoading,
	LayoutSimpleError,
)
