import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class SvgIcon extends PureComponent {
	render() {
		const { children, height, name, title, width } = this.props
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={width || 'auto'}
				height={height || 'auto'}
				className={`cmp__svg-icon cmp__svg-icon--${name}`}
			>
				<title>{title}</title>
				{children}
			</svg>
		)
	}
}

SvgIcon.propTypes = {
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.element])
		.isRequired,
	height: PropTypes.number,
	name: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	width: PropTypes.number,
}

export default SvgIcon
