import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { authenticatedSelector } from '@/store/login/selectors'
import { applicationRequest } from '@/store/application/actions'
import { applicationIsReadySelector } from '@/store/application/selectors'
import { activeCompanyIdSelector } from '@/store/user/selectors'
import RoutesList from '@/components/routes-list'
import PaywallContainer from '@/containers/paywall-container'
import MigrationSetupContainer from '@/containers/migration-setup-container'
import IntercomContainer from '@/containers/intercom-container'
import PlaceholderLayout from '@/components/placeholder-layout'
import userMonitoringService from '@/services/user.monitoring.service'

import '@/styles/styles.scss'

const AppContainer = () => {
	const isAuthenticated = useSelector(authenticatedSelector)
	const isReady = useSelector(applicationIsReadySelector)
	const activeCompanyId = useSelector(activeCompanyIdSelector)

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(applicationRequest(isAuthenticated))

		if (isAuthenticated) {
			localStorage.setItem('sb.hasAuthenticated', true)
		}
	}, [dispatch, isAuthenticated])

	useEffect(() => {
		if (activeCompanyId) {
			userMonitoringService.setCompanyId(activeCompanyId)
		}
	}, [activeCompanyId])

	if (!isReady) {
		return <PlaceholderLayout isSimple={!isAuthenticated} />
	}

	if (isAuthenticated && isReady) {
		return (
			<>
				<RoutesList isAuthenticated={isAuthenticated} />
				<PaywallContainer />
				<MigrationSetupContainer />
				<IntercomContainer />
			</>
		)
	}

	return <RoutesList isAuthenticated={isAuthenticated} />
}

export default withRouter(AppContainer)
