import bugsnag from 'bugsnag-js'
import config from 'config'
import React from 'react'
import createPlugin from 'bugsnag-react'
import userMonitoringService from './user.monitoring.service'

const client = bugsnag({
	apiKey: config.BUGSNAG_API_KEY,
	appVersion: process.env.APP_VERSION,
	releaseStage: config.ENV,
	notifyReleaseStages: ['test', 'production', 'staging'],
	/* istanbul ignore next - can't be effectively unit tested */
	beforeSend(report) {
		// Add the Mouseflow session ID to the error report if it exists
		const sessionId = userMonitoringService.getSessionId()
		if (sessionId) {
			report.updateMetaData('mouseflow', { sessionId })
		}

		// Tag the current Mouseflow recording as having an error report
		userMonitoringService.tagRecording('has-error-report')
	},
})

export class ErrorLoggingService {
	constructor(bugsnagClient) {
		this.client = bugsnagClient
	}

	notify(error, opts) {
		this.client.notify(error, opts)
	}

	setUser(user) {
		this.client.user = user
	}

	setMetaData(metaData) {
		this.client.metaData = metaData
	}

	getReactErrorBoundary() {
		return this.client.use(createPlugin(React))
	}

	leaveBreadcrumb(...args) {
		this.client.leaveBreadcrumb(...args)
	}
}

const service = new ErrorLoggingService(client)

export default service
