import * as invitationAcceptActionTypes from './constants'

/**
 *
 */
function getInitialState() {
	return {
		inProgress: false,
		isError: false,
	}
}

const invitationAcceptReducer = (state = getInitialState(), action) => {
	switch (action.type) {
		case invitationAcceptActionTypes.INVITATION_ACCEPT_REQUEST:
			return {
				...state,
				inProgress: true,
				isError: false,
			}
		case invitationAcceptActionTypes.INVITATION_ACCEPT_SUCCESS:
		case invitationAcceptActionTypes.INVITATION_ACCEPT_RESET:
			return getInitialState()
		case invitationAcceptActionTypes.INVITATION_ACCEPT_ERROR:
			return {
				...state,
				inProgress: false,
				isError: true,
			}
		default: {
			return state
		}
	}
}

export default invitationAcceptReducer
