import { identity, isFunction, isNil } from 'lodash'

// Heavily inspired by https://github.com/redux-utilities/redux-actions/blob/master/src/createAction.js

/**
 * Generate a redux action creator function with the specified type and payload/meta creators. Unlike the version from
 * redux-actions, the resulting action creator accepts separate arguments for the payload and meta objects.
 *
 * The payload creator function, if supplied, is called with all the arguments supplied to the action creator. The meta
 * creator function is called with the second and subsequent arguments, only if the second argument exists and is not
 * null. If the action creator is called with only one argument, no meta object is added to the resulting action,
 * regardless of whether a meta creator function is provided.
 *
 * @param {string} type Action type
 * @param {Function?} [payloadCreator] Optional payload creator function (defaults to the identity function)
 * @param {Function} [metaCreator] Optional meta creator function (defaults to the identity function)
 * @returns {Function} A Redux action creator function that accepts payload and meta arguments
 */
export const createAction = (
	type,
	payloadCreator = identity,
	metaCreator = identity,
) => {
	const finalPayloadCreator = isFunction(payloadCreator)
		? (payload, ...args) =>
				payload instanceof Error ? payload : payloadCreator(payload, ...args)
		: identity
	const finalMetaCreator = isFunction(metaCreator) ? metaCreator : identity

	const actionCreator = (payload, meta, ...args) => {
		const action = { type }

		const finalPayload = finalPayloadCreator(payload, meta, ...args)
		const finalMeta = finalMetaCreator(meta, ...args)

		if (!isNil(finalPayload)) {
			action.payload = finalPayload
		}

		if (finalPayload instanceof Error) {
			action.error = true
		}

		if (!isNil(finalMeta)) {
			action.meta = finalMeta
		}

		return action
	}

	return actionCreator
}
