import { createAction } from '@/utils/actions'

import * as invitationAcceptActionTypes from './constants'

export const invitationAcceptRequest = createAction(
	invitationAcceptActionTypes.INVITATION_ACCEPT_REQUEST,
)
export const invitationAcceptReset = createAction(
	invitationAcceptActionTypes.INVITATION_ACCEPT_RESET,
)
export const invitationAcceptSuccess = createAction(
	invitationAcceptActionTypes.INVITATION_ACCEPT_SUCCESS,
)
export const invitationAcceptError = createAction(
	invitationAcceptActionTypes.INVITATION_ACCEPT_ERROR,
)
