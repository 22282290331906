import { takeLatest, call, select } from 'redux-saga/effects'
import { BASE_ROUTE } from '@/utils/constants'
import history from '@/services/history'
import { setQueryParams } from '@/services/query-params/query.params.service'
import {
	activeCompanyIdSelector,
	activeCompanySelector,
} from '@/store/user/selectors'
import { UPDATE_ROUTE, PREVIOUS_PAGE, NEXT_PAGE, SET_PAGE } from './constants'
import { pageParamSelector } from './selectors'

/**
 *
 */
export function* updateRoute() {
	const company = yield select(activeCompanySelector)
	const companyId = yield select(activeCompanyIdSelector)

	// No company matches the supplied companyId
	if (companyId && !company) {
		yield call([history, 'replace'], {
			pathname: BASE_ROUTE,
		})
	}
}

/**
 *
 */
export function* previousPage() {
	const currentPage = yield select(pageParamSelector)
	const newPage = currentPage - 1

	if (newPage === 1) {
		yield call(setQueryParams, { page: undefined }, { push: true })
	} else if (newPage > 1) {
		yield call(setQueryParams, { page: newPage }, { push: true })
	}
}

/**
 *
 */
export function* nextPage() {
	const currentPage = yield select(pageParamSelector)
	const newPage = currentPage + 1

	yield call(setQueryParams, { page: newPage }, { push: true })
}

/**
 * @param action
 */
export function* setPage(action) {
	const { payload: newPage } = action
	const currentPage = yield select(pageParamSelector)

	if (newPage !== currentPage) {
		if (newPage === 1) {
			yield call(setQueryParams, { page: undefined })
		} else if (newPage > 1) {
			yield call(setQueryParams, { page: newPage })
		}
	}
}

/**
 *
 */
export function* routesWatcher() {
	yield takeLatest(UPDATE_ROUTE, updateRoute)
	yield takeLatest(PREVIOUS_PAGE, previousPage)
	yield takeLatest(NEXT_PAGE, nextPage)
	yield takeLatest(SET_PAGE, setPage)
}
