import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import link from './links'

export const cache = new InMemoryCache()

const client = new ApolloClient({
	link,
	cache,
})

export default client
