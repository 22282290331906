import produce from 'immer'
import {
	takeLatest,
	takeEvery,
	put,
	call,
	select,
	delay,
} from 'redux-saga/effects'
import { SENSOR_TYPE_WIRED } from '@ecobee/smartbuildings-library-common/constants'

import * as thermostatListActions from '@/store/thermostat-list/actions'
import * as toastActions from '@/store/toast/actions'
import * as modalActions from '@/store/modal/actions'
import { allThermostatSelector } from '@/store/thermostat-list/selectors'
import { safeFetch } from '@/services/fetch-api/fetch.api'
import { validateApiResponse, validateApiStatusCode } from '@/utils/validators'
import * as thermostatActions from './actions'
import * as thermostatActionTypes from './constants'
import { thermostatRemoteSensorTypeSelector } from './selectors'

const THERMOSTAT_REFETCH_DELAY_MS = 1000

const fetchParams = () => ({
	selection: {
		includeAlerts: true,
		includeDevice: true,
		includeEquipmentStatus: true,
		includeEvents: true,
		includeExtendedRuntime: true,
		includeProgram: true,
		includeRuntime: true,
		includeSecuritySettings: true,
		includeSensors: true,
		includeSettings: true,
		includeNotificationSettings: true,
		includeVersion: true,
	},
})

/**
 * @param action
 */
export function* getThermostat(action) {
	const { id, forceFetch } = action.payload
	const thermostatList = yield select(allThermostatSelector)
	const alreadyFetchedThermostat = thermostatList.find(
		(thermostat) => thermostat.identifier === id,
	)

	// If we found the thermostat in the local store use that
	if (!forceFetch && alreadyFetchedThermostat) {
		yield put(
			thermostatActions.getThermostatSuccess({
				thermostat: { ...alreadyFetchedThermostat },
			}),
		)
		/* istanbul ignore next */
		return alreadyFetchedThermostat
	}

	// This is for the refetch case where we just updated the thermostat, so add a delay
	if (forceFetch && alreadyFetchedThermostat) {
		yield delay(THERMOSTAT_REFETCH_DELAY_MS)
	}

	// Otherwise fetch the thermostat from the API
	const thermostats = yield call(safeFetch, 'getThermostat', {
		id,
		...fetchParams(),
	})

	if (thermostats.response.ok) {
		yield put(
			thermostatActions.getThermostatSuccess({
				thermostat: thermostats.data,
			}),
		)
		/* istanbul ignore next */
		return thermostats.data
	}
	if (thermostats.response.status === 404) {
		yield put(thermostatActions.thermostatNotFoundError())
	} else {
		yield put(thermostatActions.getThermostatError())
	}
	/* istanbul ignore next */
	return null
}

/**
 * @param action
 */
export function* refreshThermostat(action) {
	const { id } = action.payload
	// attempt to avoid a race condition when fetching immediately after an update
	yield delay(THERMOSTAT_REFETCH_DELAY_MS)

	const thermostats = yield call(safeFetch, 'getThermostat', {
		id,
		...fetchParams(),
	})

	if (thermostats.response.ok) {
		yield put(
			thermostatActions.refreshThermostatSuccess({
				thermostat: thermostats.data,
			}),
		)
		/* istanbul ignore next */
		return thermostats.data
	}
	if (thermostats.response.status === 404) {
		yield put(thermostatActions.thermostatNotFoundError())
	} else {
		yield put(thermostatActions.refreshThermostatError())
	}
	/* istanbul ignore next */
	return null
}

/**
 * @param action
 */
export function* updateThermostat(action) {
	const refresh =
		action.payload.refresh === undefined ? false : action.payload.refresh

	// When we update comfort settings, we pass all properties for each climate
	// in the climates array so we can do an inline update for the thermostat
	// without refetching. However, the ecobee API returns an error when
	// updating thermostats that support wired sensors if there are sensors in
	// the climate objects in the climates array, so we remove them here if
	// necessary.
	const remoteSensorType = yield select(thermostatRemoteSensorTypeSelector)
	const payload =
		remoteSensorType === SENSOR_TYPE_WIRED &&
		action.payload.settingsToUpdate.program?.climates
			? produce(action.payload, (draftPayload) => {
					draftPayload.settingsToUpdate.program.climates.forEach(
						(draftClimate) => {
							delete draftClimate.sensors
						},
					)
			  })
			: action.payload

	const apiResponse = yield call(safeFetch, 'updateThermostat', payload)

	const { data } = apiResponse
	// Our API data returns HTTP 200 even when there's a server error,
	// so we want to check the status code is 0 (which is the all-clear)
	if (validateApiResponse(apiResponse) && validateApiStatusCode(data)) {
		yield put(
			thermostatActions.updateThermostatSuccess(
				{
					settingsToUpdate: action.payload.settingsToUpdate,
				},
				{
					event: 'ThermostatUpdateSuccess',
					eventProperties: {
						thermostatId: action.payload.id,
					},
				},
			),
		)

		if (refresh) {
			yield put(
				thermostatActions.refreshThermostatRequest({
					id: action.payload.id,
				}),
			)
		} else {
			yield put(
				thermostatListActions.inlineUpdateThermostatList({
					...action.payload,
				}),
			)
		}
		yield put(
			toastActions.toastShow({
				message: 'Your changes have been successfully saved.',
			}),
		)
	} else {
		yield put(
			thermostatActions.updateThermostatError(null, {
				event: 'ThermostatUpdateError',
				eventProperties: {
					thermostatId: action.payload.id,
				},
			}),
		)
		yield put(
			modalActions.modalShow({
				title: 'Something went wrong',
				message: 'We were unable to save your changes. Please try again.',
			}),
		)
	}
}

/**
 * @param action
 */
export function* updateThermostatWithFunction(action) {
	const apiResponse = yield call(
		safeFetch,
		'updateThermostatWithFunction',
		action.payload,
	)

	const { data, response } = apiResponse

	// Our API data returns HTTP 200 even when there's a server error,
	// so we want to check the status code is 0 (which is the all-clear)
	if (response.ok && validateApiStatusCode(data)) {
		const thermostat = yield call(getThermostat, {
			payload: {
				id: action.payload.id,
				forceFetch: true,
			},
		})

		yield put(
			thermostatActions.updateThermostatSuccess(
				{
					settingsToUpdate: thermostat,
				},
				{
					event: 'ThermostatUpdateSuccess',
					eventProperties: {
						thermostatId: action.payload.id,
						function: action.payload.functions?.[0]?.type,
					},
				},
			),
		)
		if (thermostat) {
			yield put(
				thermostatListActions.inlineUpdateThermostatList({
					id: action.payload.id,
					settingsToUpdate: thermostat,
				}),
			)
		}
		yield put(
			toastActions.toastShow({
				message: 'Your changes have been successfully saved.',
			}),
		)
	} else {
		yield put(
			thermostatActions.updateThermostatError(null, {
				event: 'ThermostatUpdateError',
				eventProperties: {
					thermostatId: action.payload.id,
					function: action.payload.functions?.[0]?.type,
				},
			}),
		)
		yield put(
			modalActions.modalShow({
				title: 'Something went wrong',
				message: 'We were unable to save your changes. Please try again.',
			}),
		)
	}
}

/**
 *
 */
export function* thermostatRequestWatcher() {
	yield takeLatest(thermostatActionTypes.GET_THERMOSTAT_REQUEST, getThermostat)
	yield takeLatest(
		thermostatActionTypes.REFRESH_THERMOSTAT_REQUEST,
		refreshThermostat,
	)
	yield takeEvery(
		thermostatActionTypes.UPDATE_THERMOSTAT_REQUEST,
		updateThermostat,
	)
	yield takeEvery(
		thermostatActionTypes.UPDATE_THERMOSTAT_WITH_FUNCTION_REQUEST,
		updateThermostatWithFunction,
	)
}
