import { call, put, takeLatest, all } from 'redux-saga/effects'
import pluralize from 'pluralize'
import { safeFetch } from '@/services/fetch-api/fetch.api'
import * as modalActionTypes from '@/store/modal/constants'
import * as toastActionTypes from '@/store/toast/constants'
import * as thermostatListActionTypes from '@/store/thermostat-list/constants'
import { validateApiResponse, validateApiStatusCode } from '@/utils/validators'
import * as thermostatListBulkFunctionActionTypes from './constants'

/**
 * @param action
 */
export default function* bulkUpdateWithFunction(action) {
	let errorCount = 0
	const successfulResponses = []
	const { thermostatIds, functions } = action.payload

	const responses = yield all(
		thermostatIds.map((id) =>
			call(safeFetch, 'updateThermostatWithFunction', { id, functions }),
		),
	)

	responses.forEach((response, index) => {
		if (
			!validateApiResponse(response) ||
			!validateApiStatusCode(response.data)
		) {
			errorCount += 1
		} else {
			successfulResponses.push(thermostatIds[index])
		}
	})

	if (errorCount === 0) {
		yield put({
			type:
				thermostatListBulkFunctionActionTypes.BULK_UPDATE_WITH_FUNCTION_SUCCESS,
		})

		yield put({
			type: toastActionTypes.TOAST_SHOW,
			payload: {
				message: `Successfully updated ${responses.length} ${pluralize(
					'thermostat',
					responses.length,
				)}.`,
			},
		})
	} else {
		yield put({
			type:
				thermostatListBulkFunctionActionTypes.BULK_UPDATE_WITH_FUNCTION_ERROR,
		})

		yield put({
			type: modalActionTypes.MODAL_SHOW,
			payload: {
				title: 'Something went wrong',
				message: `We were unable to save ${errorCount} out of ${thermostatIds.length} thermostats.`,
			},
		})
	}
	yield put({
		type: thermostatListActionTypes.GET_THERMOSTAT_LIST_REQUEST,
		payload: {
			forceUpdate: true,
		},
	})

	yield put({
		type: thermostatListActionTypes.SET_BULK_UPDATE_ACTION,
		payload: {
			bulkAction: '',
		},
	})
}

/**
 *
 */
export function* bulkFunctionUpdateWatcher() {
	yield takeLatest(
		thermostatListBulkFunctionActionTypes.BULK_UPDATE_WITH_FUNCTION_REQUEST,
		bulkUpdateWithFunction,
	)
}
